import PropTypes from 'prop-types';
import React from 'react';
import { ModalForm, AddressPickerControl, FieldGroup, MaterialCheckbox, FloatingLabelFormField, InfoTooltip } from 'reactifi';
import i18n from 'lib/i18n';

export const VenueForm = props => {
  const {
    clearMessages, createVenue, currentVenue, errorMessage,
    googleMapsApiKey, isEventManagement, selectVenue, updateVenue
  } = props;

  const modalTitle = currentVenue?.id ? i18n.t('Venue Details') : i18n.t('Add a New Venue');

  const onCancel = () => {
    selectVenue(null);
    clearMessages();
  }

  return (
    <ModalForm
      data={currentVenue}
      errorMessage={errorMessage}
      title={modalTitle}
      onCancel={onCancel}
      addAction={createVenue}
      updateAction={updateVenue}
    >
      <FieldGroup className="container-fluid">
        {!isEventManagement && (
          <div className="row">
            <div className="col-12">
              <h4>{i18n.t('Venue Details')}</h4>
            </div>
          </div>
        )}
        <FieldGroup className="row">
          <FieldGroup className={isEventManagement ? 'col-12' : 'col-6'}>
            <FloatingLabelFormField
              caption={i18n.t("Venue Name")}
              name="title"
              required={true}
            />
          </FieldGroup>
          {!isEventManagement && (
            <FieldGroup className="col-6">
              <FloatingLabelFormField
                caption={i18n.t("Capacity")}
                name="capacity"
                required={true}
                tooltip={i18n.t("The maximum number of people permitted in this location.")}
                trigger="hover"
              />
            </FieldGroup>
          )}
        </FieldGroup>
        <FieldGroup className="row">
          <FieldGroup className="col-12">
            <FloatingLabelFormField name="location_latitude" caption={i18n.t("Address (Type and select below)")} required={true} className="col-half-width" >
              <AddressPickerControl googleMapsApiKey={googleMapsApiKey} initialData={currentVenue} placeholder="" />
            </FloatingLabelFormField>
          </FieldGroup>
        </FieldGroup>
        <FieldGroup className="row">
          <FieldGroup className="col-12">
            <FloatingLabelFormField name="location_room" caption={i18n.t("Address Line 2")} className="col-half-width" />
          </FieldGroup>
        </FieldGroup>
        {!isEventManagement ? (
          <FieldGroup>
            <div className="row">
              <div className="col-12">
                <h4>{i18n.t('Venue Contact Information')}</h4>
              </div>
            </div>
            <FieldGroup className="row">
              <FieldGroup className="col-6">
                <FloatingLabelFormField name="contact_name" caption={i18n.t("Name")} required={true} />
              </FieldGroup>
              <FieldGroup className="col-6">
                <FloatingLabelFormField name="contact_phone" caption={i18n.t("Phone Number")} required={true} />
              </FieldGroup>
            </FieldGroup>
            <FieldGroup className="row">
              <FieldGroup className="col-6">
                <FloatingLabelFormField type="email" name="contact_email" caption={i18n.t("Email")} required={true} />

              </FieldGroup>
              <div className="col-6" />
            </FieldGroup>

            <div className="row">
              <div className="col-12">
                <h4>{i18n.t('Facilities')}</h4>
              </div>
            </div>
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FieldGroup className="checkbox-group">
                  <MaterialCheckbox name="computer_lab" inline={true}>{i18n.t('Individual Devices')}</MaterialCheckbox>
                  <InfoTooltip
                    trigger="hover"
                    iconTooltip="help"
                    tooltip={i18n.t('Venue provides individual devices with internet connections for attendees to use at the event')}
                    tooltipPosition="top" />
                  <MaterialCheckbox name="projector" inline={true}>{i18n.t('Projector')}</MaterialCheckbox>
                  <InfoTooltip
                    trigger="hover"
                    iconTooltip="help"
                    tooltip={i18n.t('Venue provides projector for volunteers to use at the event')}
                    tooltipPosition="top" />
                  <MaterialCheckbox name="accessibility" inline={true}>{i18n.t('Accessibility')}</MaterialCheckbox>
                  <InfoTooltip
                    trigger="hover"
                    iconTooltip="help"
                    tooltip={i18n.t('Venue is accessible to persons with disabilities')}
                    tooltipPosition="top" />
                </FieldGroup>
              </FieldGroup>
            </FieldGroup>
          </FieldGroup>
        ) : (
          <FieldGroup>
            <input
              name="skip_contact_validation"
              type="hidden"
              value={isEventManagement}
            />
            <input
              name="skip_capacity_validation"
              type="hidden"
              value={isEventManagement}
            />
            <input
              name="location_skip_geocode_and_lmi"
              type="hidden"
              value={isEventManagement}
            />
          </FieldGroup>
        )}
      </FieldGroup>
    </ModalForm>
  );
}

VenueForm.propTypes = {
  clearMessages: PropTypes.func.isRequired,
  createVenue: PropTypes.func.isRequired,
  currentVenue: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string
  }),
  errorMessage: PropTypes.string,
  googleMapsApiKey: PropTypes.string.isRequired,
  isEventManagement: PropTypes.bool,
  selectVenue: PropTypes.func.isRequired,
  updateVenue: PropTypes.func.isRequired
}

export default VenueForm;
