import React from 'react';
import PropTypes from 'prop-types';
import { AutoSavingForm, FieldGroup, FloatingLabelFormField } from 'reactifi';

export default class AdditionalDetailsComponent extends React.Component {

  static propTypes = {
    caption: PropTypes.string,
    componentClass: PropTypes.string,
    data: PropTypes.object,
    errorMessage: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    updateAction: PropTypes.func
  };

  render() {
    const { caption, componentClass, data, errorMessage, name, type, updateAction } = this.props;

    return (
      <div className="auto-saving-form">
        <AutoSavingForm data={data} updateAction={updateAction}
          saveMessageClassName="autosave-message events-autosave-form-save-message" errorMessage={errorMessage}>
          <FieldGroup className="container">
            <FieldGroup className="row">
              <FieldGroup className="col-lg-12">
                <FieldGroup className="form-group">
                  <FloatingLabelFormField
                    caption={caption}
                    className="m-0"
                    componentClass={componentClass}
                    name={name}
                    type={type}
                  />
                </FieldGroup>
              </FieldGroup>
            </FieldGroup>
          </FieldGroup>
        </AutoSavingForm>
      </div>
    );
  }
}

