import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as contentActionCreators from '../actions/contentActionCreators';
import { buildGenericProps, JsonApiDecorator } from 'reactifi';
import ContentList from '../components/ContentList';

function mapStateToProps(state, ownProps) {
  let props               = buildGenericProps(state, 'everfi_contents');
  props.currentContent    = props.currentEntity;
  props.contentServiceUrl = ownProps.route.contentServiceUrl;
  props.jwtToken          = ownProps.route.jwtToken;
  let apiStore = new JsonApiDecorator(state.api);

  if (apiStore.contents && props.currentContent){
    props.currentContent.content = apiStore.contents.find(props.currentContent.content_id);
  }
  return props;
}

class ContentContainer extends Component {

  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(contentActionCreators, this.props.dispatch);
  }

  render() {
    return (
      <ContentList {...this.props} {...this.actions} />
    );
  }
}

export default connect(mapStateToProps)(ContentContainer);
