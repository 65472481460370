import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ConfirmationModal, Icon, SelectControl } from 'reactifi';
import i18n from 'lib/i18n';
import EverfiContentListByType from './EverfiContentListByTypeComponent';
import {
  filterOfflineContentsByLanguageAndContent,
  filterEmptyOfflineContentsByLanguage
} from "../../libs/VolunteerTabOfflineContentFunctions";
import OfflineContentForm from '../../../FinancialEducationContent/containers/OfflineContentForm';

const OfflineContentSection = props => {
  const {
    clearMessages,
    createContent,
    currentOfflineContent,
    currentTopic,
    dispatch,
    everfiContents,
    languages,
    offlineContents,
    selectContent,
    setTopic,
    topicId,
    updateContent,
    uploadInProgress
  } = props;

  const [deleteTopicData, setDeleteTopicData] = useState();
  const [language, setLanguage] = useState(languages.find(language => language.code === 'en'));

  useEffect(() => {
    if (offlineContents?.length) {
      let defaultLang = offlineContents.find(content => content.language === 'en')?.language ?? offlineContents.find(content => content.language === 'es')?.language;
      defaultLang = defaultLang ?? offlineContents.at(-1).language;
      const language = languages.find(language => language.code === defaultLang);
      setLanguage(language);
    }
  }, [offlineContents]);

  const languageChangeHandler = language => setLanguage(language);

  const deleteContent = topic => setDeleteTopicData(topic);

  const onCancelDeleteContent = () => setDeleteTopicData(null);

  const onConfirmDeleteContent = topic => {
    props.deleteContent(topic);
    onCancelDeleteContent();
  };

  const addContent = topicId => {
    setTopic(topicId);
    selectContent('add');
  };

  const filterByLanguage = lang => filterEmptyOfflineContentsByLanguage(offlineContents)(lang);

  const filterByLanguageAndContent = lang => everfiContent => {
    return filterOfflineContentsByLanguageAndContent(offlineContents)(lang)(everfiContent);
  };

  const generalOfflineContents = filterByLanguage(language);

  return (
    <div className="offline-content-section">

      <div className="col-md-12 row offline-content-section-item">
        <div className="col-md-6 add-new">
          <a className="add-offline-resource" onClick={(e) => addContent(topicId)} ><Icon iconType="plus" />{i18n.t(' Add Resources')}</a>
        </div>

        <div className="offline-content-section-item col-md-6 lang-select">
          {languages.length > 0 && (
            <div>
              <h4>{i18n.t('Available Languages')}</h4>
              <SelectControl
                clearable={false}
                dataValue={language}
                labelKey="name"
                multi={false}
                onChange={languageChangeHandler}
                options={languages}
                valueKey="code"
                valueOverride={true}
              />
            </div>
          )}
        </div>
      </div>

      <div className="col-lg-12 row offline-content-section-item offline-content-subsection">

        {generalOfflineContents.length > 0 && (
          <EverfiContentListByType
            deleteContent={deleteContent}
            offlineContents={generalOfflineContents}
            selectContent={selectContent}
          />
        )}

        <EverfiContentListByType
          selectContent={selectContent}
          everfiContents={everfiContents}
          deleteContent={deleteContent}
          filterByEverfiContentFunction={filterByLanguageAndContent(language)} />

      </div>

      <OfflineContentForm
        currentOfflineContent={currentOfflineContent}
        everfiContents={everfiContents}
        currentTopic={currentTopic}
        uploadFiles={createContent}
        selectContent={selectContent}
        clearMessages={clearMessages}
        dispatch={dispatch}
        uploadInProgress={uploadInProgress}
        updateContent={updateContent} />

      <ConfirmationModal
        canClickOff={true}
        confirmButtonText={i18n.t("Delete Item")}
        data={deleteTopicData}
        onCancel={onCancelDeleteContent}
        onConfirm={onConfirmDeleteContent}
        title={i18n.t("Delete Item")}
      >
        <div>{i18n.t('Are you sure you would like to delete this item?')}</div>
      </ConfirmationModal>

    </div>
  );
}

OfflineContentSection.propTypes = {
  clearMessages: PropTypes.func,
  createContent: PropTypes.func,
  currentOfflineContent: PropTypes.object,
  currentTopic: PropTypes.object,
  deleteContent: PropTypes.func,
  dispatch: PropTypes.func,
  everfiContents: PropTypes.array,
  languages: PropTypes.array,
  offlineContents: PropTypes.array,
  selectContent: PropTypes.func,
  setTopic: PropTypes.func,
  topicId: PropTypes.string,
  updateContent: PropTypes.func,
  uploadInProgress: PropTypes.bool
};

export default OfflineContentSection;
