import PropTypes from 'prop-types';
import React from 'react';
import { Tabs, Tab } from 'react-bootstrap'
import moment from 'moment';
import i18n from 'lib/i18n';
import BeforeYourEventTabContent from './VolunteerTab/BeforeYourEventTabContentComponent';
import DayOfEventTabContent from './VolunteerTab/DayOfEventTabContentComponent';

export default class VolunteerTabs extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    checkIn: PropTypes.func,
    checkInDisabled: PropTypes.bool,
    currentEventUser: PropTypes.object,
    currentLead: PropTypes.bool.isRequired,
    displayErrorMessage: PropTypes.func,
    displaySuccessMessage: PropTypes.func,
    errorMessage: PropTypes.string,
    evaluFiUrl: PropTypes.string.isRequired,
    event: PropTypes.object.isRequired,
    everfiContents: PropTypes.array,
    eventUsers: PropTypes.array,
    googleMapsApiKey: PropTypes.string.isRequired,
    goToAttendeeRegistrationSite: PropTypes.func,
    isEventManagement: PropTypes.bool,
    isWebinar: PropTypes.bool,
    preRegister: PropTypes.bool,
    updateEvent: PropTypes.func.isRequired,
    updateEventUser: PropTypes.func.isRequired,
    viewer: PropTypes.string
  };

  dataObjects = (attribute) => {
    return {
      dataObjectType: "events",
      dataObjectId: this.props.event.id,
      dataObjectAttribute: attribute
    }
  }

  render() {
    const {
      actions,
      checkIn,
      checkInDisabled,
      currentEventUser,
      currentLead,
      displayErrorMessage,
      displaySuccessMessage,
      errorMessage,
      evaluFiUrl,
      event,
      eventUsers,
      everfiContents,
      googleMapsApiKey,
      goToAttendeeRegistrationSite,
      isEventManagement,
      isWebinar,
      preRegister,
      updateEvent,
      updateEventUser,
      viewer
    } = this.props;
    const activeTab = moment(this.props.event.start_time).startOf('day').isSame(moment().startOf('day')) ? 2 : 1;

    return (
      <Tabs defaultActiveKey={activeTab} id="eventTabs">
        <Tab eventKey={1} title={i18n.t("Before your Event")}>
          <BeforeYourEventTabContent
            actions={actions}
            dataObjects={this.dataObjects}
            displayErrorMessage={displayErrorMessage}
            displaySuccessMessage={displaySuccessMessage}
            event={event}
            everfiContents={everfiContents}
            isEventManagement={isEventManagement}
            isWebinar={isWebinar}
            viewer={viewer}
          />
        </Tab>

        <Tab eventKey={2} title={i18n.t("Day of Event")}>
          <DayOfEventTabContent
            actions={actions}
            checkIn={checkIn}
            checkInDisabled={checkInDisabled}
            currentEventUser={currentEventUser}
            currentLead={currentLead}
            dataObjects={this.dataObjects}
            errorMessage={errorMessage}
            evaluFiUrl={evaluFiUrl}
            event={event}
            eventUsers={eventUsers}
            googleMapsApiKey={googleMapsApiKey}
            goToAttendeeRegistrationSite={goToAttendeeRegistrationSite}
            isEventManagement={isEventManagement}
            isWebinar={isWebinar}
            preRegister={preRegister}
            updateEvent={updateEvent}
            updateEventUser={updateEventUser}
          />
        </Tab>
      </Tabs>
    )
  }
}
