import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import i18n from 'lib/i18n';
import * as eventActionCreators from '../actions/eventActionCreators';
import * as partnerActionCreators from '../../Partner/actions/partnerActionCreators';
import * as topicsActionCreators from '../../Topics/actions/topicsActionCreators';
import * as venueActionCreators from '../../Venue/actions/venueActionCreators';
import * as volunteerActionCreators from '../../Volunteer/actions/volunteerActionCreators';
import {
  buildGenericProps,
  clearMessages,
  displayErrorMessage,
  JsonApiDecorator,
  Page,
  Wizard,
  RegenerateDateWithTZ
} from 'reactifi';
import EventWizardDetails from '../components/wizard/EventWizardDetails';
import EventWizardOrganizers from '../components/wizard/EventWizardOrganizers';
import { registrationQuestions } from '../functions/helpers';
import { getEventTypes } from '../functions/helpers';

function mapStateToProps(state, ownProps) {
  let apiStore = new JsonApiDecorator(state.api);
  let props = buildGenericProps(state, 'events');
  Object.assign(props, {
    accountId: ownProps.route.accountId,
    currentUserId: ownProps.route.currentUserId,
    googleMapsApiKey: ownProps.route.googleMapsApiKey,
    isEventManagement: ownProps.route.isEventManagement,
    newEntity: apiStore.newObject,
    showEditPartner: !!state.eventReducer.showEditPartner,
    showEditVenue: !!state.eventReducer.showEditVenue,
    showAddRegQuestions: !!state.eventReducer.showAddRegQuestions,
    viewer: ownProps.route.viewer
  });

  props.event = props.currentEntity;
  if (props.event) {
    props.event.venue = {};
  }
  props.account = apiStore.accounts ? apiStore.accounts.first('topic') : props.account = {};
  props.venues = apiStore.venues ? apiStore.venues.all() : [];
  props.currentUser = apiStore.volunteers ? apiStore.volunteers.data[0] : null;
  props.partners = apiStore.partners ? apiStore.partners.all() : [];

  addLanguages(props);
  addEventTypes(props);

  return props;
}

const addLanguages = props => {
  if (props.account.languages) {
    const languageKeys = Object.keys(props.account.languages);
    const languages = props.account.languages;
    props.languageOptions = languageKeys.map((k) => {
      return { label: k, value: languages[k] };
    });
  }
}

const addEventTypes = props => {
  const { account, isEventManagement } = props;

  const orgEventTypes = getEventTypes(isEventManagement);

  if (account?.event_types) {
    const typeKeys = Object.keys(props.account.event_types);
    const eventTypes = props.account.event_types;
    props.eventTypes =
      typeKeys
        .map(eventTypeKey => ({ label: eventTypeKey, value: eventTypes[eventTypeKey] }))
        .filter(eventType => orgEventTypes.includes(eventType.value));
  }
}

class EventWizardContainer extends React.Component {
  static propTypes = {
    account: PropTypes.shape({
      id: PropTypes.string,
      topics: PropTypes.array,
      name: PropTypes.string
    }),
    accountId: PropTypes.number.isRequired,
    currentUser: PropTypes.object,
    currentUserId: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    isEventManagement: PropTypes.bool,
    location: PropTypes.object,
    partners: PropTypes.array,
    router: PropTypes.shape({
      goBack: PropTypes.func
    }),
    topics: PropTypes.array,
    venues: PropTypes.array,
    viewer: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(
      {
        ...eventActionCreators,
        ...partnerActionCreators,
        ...topicsActionCreators,
        ...venueActionCreators,
        ...volunteerActionCreators,
        displayErrorMessage,
        clearMessages
      },
      this.props.dispatch
    );
    this.state = {
      additionalInfo: null,
      date: null,
      isPopulatingEventWizard: true,
      languages: [],
      partnerId: null,
      regQuestions: registrationQuestions,
      topics: [],
      venueId: null
    }

  }

  componentDidMount() {
    const { accountId, currentUserId, isEventManagement, location } = this.props;
    const eventType = location?.query?.event_type;

    this.actions.loadAccount(accountId);
    this.actions.selectVolunteer(currentUserId);
    this.actions.createEventEntity({
      event_type: eventType,
      allow_pre_registration: isEventManagement,
      time_zone: 'America/New_York',
      topic_id: location.query && location.query.topicId ? location.query.topicId : null,
      description_en: location.query && location.query.description ? location.query.description : null,
      description_es: location.query && location.query.description_es ? location.query.description_es : null,
      title_en: location.query && location.query.title ? location.query.title : null,
      title_es: location.query && location.query.title_es ? location.query.title_es : null
    });
  }

  componentWillReceiveProps(newProps) {
    const { account, currentEntity } = newProps;
    const { isPopulatingEventWizard } = this.state;

    if (isPopulatingEventWizard && account?.topics?.length && currentEntity?.event_type) {
      this.setState({ isPopulatingEventWizard: false }, () => {
        this.selectEventType({ value: currentEntity.event_type }, account.topics, false)
      });
    }
  }

  selectEventType = (eventType, propTopics, clearTopicData) => {
    if (clearTopicData) this.selectTopic();
    this.setState({ topics: [] }, () => {
      if (propTopics && eventType) {
        const topics = propTopics
          .filter(topic => topic.event_types.includes(eventType.value) && topic.enabled)
          .map(topic => ({ label: topic.name, value: topic.id }));
        this.setState({
          eventType: typeof eventType === 'object' ? eventType.value : eventType,
          topics: topics
        });
      }
    });
  }

  selectTopic = topic => {
    if (this.props.account.topics && topic) {
      const selectedTopic = this.props.account.topics.find(t => [t.id, t.value].includes(topic.value));
      this.actions.setEventData({
        description_en: selectedTopic.description_en,
        description_es: selectedTopic.description_es,
        title_en: selectedTopic.name_en,
        title_es: selectedTopic.name_es,
        topic_id: selectedTopic.id
      });
    } else {
      this.actions.setEventData({
        description_en: '',
        description_es: '',
        title_en: '',
        title_es: '',
        topic_id: null
      });
    }
  }

  selectVenue = event => {
    const venueId = event.venue && event.venue.id ? event.venue.id : event.venue_id;
    this.setState({ venueId: venueId });
    this.actions.setEventData({ venue_id: venueId });
    this.actions.toggleEditVenue(false);
  }

  selectLead = user => {
    if (user && user.id) {
      this.actions.setEventData({ lead_user_id: user.id });
    }
  }

  selectPartner = event => {
    const partnerId = event.partner.id || event.partner_id;
    const partner = this.props.partners.find(partner => partner.id === partnerId);

    this.setState({ partnerId: partnerId });
    this.actions.setEventData({
      partner: partner,
      partner_id: partnerId
    });

    this.actions.toggleEditPartner(false);
  }

  removePartner = () => {
    this.setState({ partnerId: null });
  }

  selectRegQuestions = event => {
    const questions = this.state.regQuestions.map(question => {
      return { ...question, state: event[question.id] };
    });
    this.setState({ regQuestions: questions });
    this.actions.toggleAddRegQuestions(false);
  }

  getSelectedRegQuestions = () => {
    return this.state.regQuestions.filter(question => question.state);
  }

  createEvent = async event => {
    event.title = event.title_en;
    event.description = event.description_en;

    event.start_time = RegenerateDateWithTZ(event.date, event.start_time, event.time_zone);
    event.end_time = RegenerateDateWithTZ(event.date, event.end_time, event.time_zone);

    if (event.event_type === "Events::Webinar") {
      event.venue = {};
      event.venue_id = null;
    }

    if (!["Events::Webinar", "Events::InPersonAndWebinar"].includes(event.event_type)) {
      event.additional_info_en = null;
      event.additional_info_es = null;
      event.url_en = null;
      event.url_es = null;
    }

    if (event.estimated_expense === '') {
      event.estimated_expense = null;
    }
    this.state.regQuestions.forEach(question => {
      event[question.id] = question.state;
    });
    await this.actions.createEvent(event, this.props.viewer);
  }

  getWizardSteps = () => {
    const {
      eventDescription,
      eventTitle,
      eventType,
      partnerId,
      topics,
      venueId
    } = this.state;
    const { partners, venues } = this.props;

    const selectedVenue = venueId && venues.find(venue => venue.id === venueId);
    const selectedPartner = partnerId && partners.find(partner => partner.id === partnerId);
    const selectedRegQuestions = this.getSelectedRegQuestions();

    return [
      <EventWizardDetails
        actions={this.actions}
        clearMessages={this.actions.clearMessages}
        eventDescription={eventDescription}
        displayErrorMessage={this.actions.displayErrorMessage}
        eventTitle={eventTitle}
        eventType={eventType}
        selectEventType={this.selectEventType}
        selectTopic={this.selectTopic}
        selectVenue={this.selectVenue}
        selectedVenue={selectedVenue}
        title={i18n.t('Event Details')}
        topics={topics}
        venueId={venueId}
        {...this.props}
      />,
      <EventWizardOrganizers
        actions={this.actions}
        clearMessages={this.actions.clearMessages}
        createEvent={this.createEvent}
        createPartner={this.actions.createPartner}
        displayErrorMessage={this.actions.displayErrorMessage}
        regQuestions={this.state.regQuestions}
        removePartner={this.removePartner}
        selectLead={this.selectLead}
        selectPartner={this.selectPartner}
        selectRegQuestions={this.selectRegQuestions}
        selectedRegQuestions={selectedRegQuestions}
        selectedPartner={selectedPartner}
        title={i18n.t('Event Organizers')}
        {...this.props}
      />
    ];
  }

  goBack = () => {
    window.history.back();
  }

  onComplete = () => { /* do nothing, the action will redirect after the save succeeds */ }

  render() {
    const { errorMessage, router } = this.props;

    return (
      <Page
        backButton={router.goBack}
        clearMessages={this.actions.clearMessages}
        contentType="form single-column"
        errorMessage={errorMessage}
        pageType="child"
        title={i18n.t('Create Event')}
        usePageWrapper={true}
      >
        <div className="page-panel event-wizard-form">
          <div className="page-panel-body p-b-30">
            <Wizard
              children={this.getWizardSteps()}
              finalButtonLabel={i18n.t("Confirm")}
              initialStep={0}
              onCancel={this.goBack}
              onComplete={this.onComplete}
            />
          </div>
        </div>
      </Page >
    );
  }
}

export default connect(mapStateToProps)(EventWizardContainer);
