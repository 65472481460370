import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ActionButtons, ResponsiveDataList, Page } from 'reactifi';
import i18n from 'lib/i18n';

export default class OrganizationUsers extends Component {

  static propTypes = {
    account_admins: PropTypes.array,
    clearMessages: PropTypes.func.isRequired,
    currentEntity: PropTypes.object,
    errorMessage: PropTypes.string,
    isLoadingAccountAdmins: PropTypes.bool,
    location: PropTypes.obj,
    router: PropTypes.obj,
    successMessage: PropTypes.string,
    updateUser: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
  }

  pageTitle = () => {
    const listName = i18n.t('Users List');

    if(!this.props.location.state)
      return listName;

    const orgName = this.props.location.state.orgName;

    if(orgName) {
      return `${orgName} - ${listName}`;
    } else {
      return listName;
    }
  }

  disableUserToggle(user) {
    user.enabled = !user.enabled;
    this.props.updateUser(user);
  }

  render() {
    let fields = [
      { name: 'title', title: i18n.t('Name'), formatter: (cell, row) => `${row.first_name} ${row.last_name}` },
      { name: 'title', title: i18n.t('Title'), formatter: (cell, row) => row.title ? row.title : '' },
      { name: 'email', title: i18n.t('E-Mail'), formatter: (cell, row) => row.email ? row.email : '' },
      { name: 'phone', title: i18n.t('Phone'), formatter: (cell, row) => row.phone ? row.phone : '' },
      {
        name: 'actions', title: i18n.t('Actions'), formatter: (cell, row) => {
          let disenableButton = {
            type: "view",
            actionType: "callToAction",
            action: e => {
              e.preventDefault();
              e.stopPropagation();
              this.disableUserToggle(row);
            }
          };
          if (row.enabled) {
            Object.assign(disenableButton, {
              icon: "zmdi-block",
              title: i18n.t('Disable'),
              tooltip: i18n.t('Disable User')
            })
          } else {
            Object.assign(disenableButton, {
              icon: "zmdi-replay",
              title: i18n.t('Enable'),
              tooltip: i18n.t('Enable User')
            })
          }

          return <ActionButtons buttons={[disenableButton]} row={row}/>;
        }
      }
    ];

    const tableOptions = {
      bordered: false,
      hover: true,
      striped: true
    };

    return (
      <Page
        pageType="parent"
        contentType="fullwidth"
        backButton={this.props.router.goBack}
        title={this.pageTitle()}
        errorMessage={this.props.currentEntity ? null : this.props.errorMessage}
        successMessage={this.props.currentEntity ? null : this.props.successMessage}
        clearMessages={this.props.clearMessages}
        usePageWrapper={true}
      >
        <div className="page-panel">
          <div><span className="results-label">{i18n.t('Results: ')}</span> {this.props.account_admins.length}</div>
          <ResponsiveDataList
            data={this.props.account_admins}
            isLoadingData={this.props.isLoadingAccountAdmins}
            fields={fields}
            keyField="id"
            options={tableOptions}
          />
        </div>
      </Page>
    )
  }
}
