import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { JsonApiDecorator } from 'reactifi';
import {
  buildGenericProps,
  Header,
  MessagesModule,
  NoResultsCard,
  PageWrapper,
  Section,
  Storage
} from 'reactifi';
import * as attendeeActionCreators from '../actions/attendeeActionCreators'
import AttendeeList from '../components/AttendeeList';
import AttendeeSearchForm from '../components/AttendeeSearchForm';
import utils from 'lib/utils';
import i18n from 'lib/i18n';

const mapStateToProps = (state, ownProps) => {
  let apiStore = new JsonApiDecorator(state.api);
  let props = buildGenericProps(state, 'attendees');

  props.isEventManagement = ownProps.route.isEventManagement;
  if (ownProps.location && ownProps.location.query && ownProps.location.query.filterField) {
    props.filters = { [ownProps.location.query.filterField]: ownProps.location.query.filterValue };
  }
  if (ownProps.route.accountId) {
    props.accountId = ownProps.route.accountId.toString();
  }
  if (!apiStore.accounts) {
    props.account = { eventTypes: [], eventStatuses: [], languageOptions: [] };
  } else {
    props.account = apiStore.accounts.first();
    props.account.languageOptions = utils.mapObjToOptions(props.account.languages);
  }

  return props;
}

const AttendeeContainer = props => {
  const {
    accountId,
    attendees,
    attendeesMeta,
    dispatch,
    downloadUrl,
    downloadProcessing,
    downloadTimedOut,
    errorMessage,
    isEventManagement,
    isLoadingAttendees,
    successMessage,
    filters: externalFilters
  } = props;

  const actions = bindActionCreators({
    ...attendeeActionCreators
  }, dispatch)

  const [activePage, setActivePage] = useState(1);
  const [filters, setFilters] = useState({});
  const [keyword, setKeyword] = useState('');
  const [pageSize, setPageSize] = useState(Storage.pageSize);
  const [sort, setSort] = useState({ value: 'full_name', direction: 'asc' });

  useEffect(() => {
    if (accountId) actions.loadAccount(accountId);
  }, [accountId]);

  useEffect(() => {
    actions.findAttendees({
      ...filters,
      ...keyword,
      event_id: props.filters?.event_id
    }, sort, activePage);
  }, [activePage, filters, keyword, pageSize, sort, props.filters?.event_id]);

  useEffect(() => {
    if (downloadUrl) window.location.href = downloadUrl;
  }, [downloadUrl]);

  const handlePageChange = useCallback(page => {
    setActivePage(page)
    setPageSize(Storage.pageSize);
  }, [setActivePage]);

  const handleSortChange = useCallback(sort => {
    setActivePage(1);
    setSort(sort)
  }, [setSort]);

  const handleFilterChange = useCallback(filters => {
    setActivePage(1);
    setFilters(filters)
  });

  const handleSearch = useCallback(searchValue => {
    setActivePage(1);
    setKeyword(searchValue)
  });

  const prepareAttendeeReportDownload = (accountId) => {
    actions.downloadAttendees(
      accountId,
      Object.assign({}, filters, keyword, { 
        sort,
        event_id: externalFilters?.event_id
      })
    );
  }

  return (
    <PageWrapper>
      <MessagesModule
        clearMessages={actions.clearMessages}
        errorMessage={errorMessage}
        successMessage={successMessage}
      />
      <Header title={i18n.t('Attendee List')} />
      <Section>
        <AttendeeSearchForm
          disabled={isLoadingAttendees}
          handleFilterChange={handleFilterChange}
          handleSearch={handleSearch}
          keyword={keyword}
        />
      </Section>
      <Section type="list">
        {(isLoadingAttendees || attendees.length) ?
          <AttendeeList
            accountId={accountId}
            activePage={activePage}
            attendees={attendees}
            closeTimeoutModal={actions.endTimeout}
            downloadAttendees={prepareAttendeeReportDownload}
            downloadProcessing={downloadProcessing}
            downloadTimedOut={downloadTimedOut}
            handlePageChange={handlePageChange}
            handleSortChange={handleSortChange}
            isEventManagement={isEventManagement}
            isLoadingData={isLoadingAttendees}
            totalCount={(attendeesMeta || {}).total_count || attendees.length}
          /> : <NoResultsCard message={i18n.t("Attendee data will appear here")} />
        }
      </Section>
    </PageWrapper>
  );
};

AttendeeContainer.propTypes = {
  account: PropTypes.shape({
    languageOptions: PropTypes.object
  }),
  accountId: PropTypes.string,
  attendees: PropTypes.array,
  attendeesMeta: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  downloadUrl: PropTypes.string,
  downloadProcessing: PropTypes.bool,
  downloadTimedOut: PropTypes.bool,
  errorMessage: PropTypes.string,
  filters: PropTypes.object,
  isEventManagement: PropTypes.bool,
  isLoadingAttendees: PropTypes.bool,
  successMessage: PropTypes.string
};

export default connect(mapStateToProps)(AttendeeContainer);
