import React from 'react';
import PropTypes from 'prop-types';
import { MaterialCheckbox } from 'reactifi';

export default class LogisticsCheckbox extends React.Component {

  static propTypes = {
    dataAction: PropTypes.string,
    eventId: PropTypes.string,
    label: PropTypes.string
  };

  render() {
    const { dataAction, eventId, label } = this.props;

    return (
      <MaterialCheckbox
        className="m-t-10"
        data-object-type="events"
        data-object-id={eventId}
        data-action={dataAction}
      >
        {label}
      </MaterialCheckbox>
    );
  }
}
