const filterOfflineContentsByContent = (offlineContents, everfiContent) =>
  offlineContents.filter(offlineContent =>
    collectContentIdsForOfflineContent(offlineContent).indexOf(everfiContent.id) > -1
  );

const collectContentIdsForOfflineContent = offlineContent =>
  offlineContent.relationships.everfi_contents.data.map(content => content.id);

const filterOfflineContentsWithNoContent = offlineContents =>
  offlineContents.filter(content =>
    content.relationships.everfi_contents.data.length === 0
  );

const filterOfflineContentsByLanguage = (contents, language) =>
  contents.filter(content => language && content.language === language.code);

export const filterOfflineContentsByLanguageAndContent = offlineContents => language => everfiContent =>
  filterOfflineContentsByLanguage(
    filterOfflineContentsByContent(offlineContents, everfiContent),
    language
  );

export const filterEmptyOfflineContentsByLanguage = offlineContents => language =>
  filterOfflineContentsByLanguage(
    filterOfflineContentsWithNoContent(offlineContents),
    language
  );
