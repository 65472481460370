export const prepareReportFilters = (filters) => {
  if(!filters.keyword) {
    delete filters.keyword;
  }

  if(filters.sort) {
    filters.sort = `${filters.sort.direction === 'desc' ? '-' : ''}${filters.sort.value}`
  }

  return filters;
}