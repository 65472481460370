import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { FilterBar, SelectFilter, ToggleFilter } from 'reactifi';
import i18n from 'lib/i18n';

const availabilityOptions = [
  { id: 1, label: i18n.t('Available'), value: true },
  { id: 2, label: i18n.t('Unavailable'), value: false }
];
const reportOptions = [
  { id: 1, label: i18n.t('Shows in Reports'), value: true },
  { id: 2, label: i18n.t('Does not show in Reports'), value: false }
];

const PartnerSearchForm = ({ searchAction, disabled, viewer }) => {
  const [filters, setFilters] = useState({})

  useEffect(() => {
    searchAction(filters)
  }, [filters])

  const filtersConfig = [
    {
      name: 'serves_lmi',
      label: i18n.t('LMI'),
      isMulti: false,
      checkedLabel: i18n.t('Filtering by Lower Middle Income'),
      uncheckedLabel: i18n.t('Not Filtering by Lower Middle Income'),
      type: ToggleFilter
    },
    ...viewer === 'account_admin' ? [{
      name: 'enabled',
      label: i18n.t('Reports'),
      options: reportOptions,
      isMulti: false,
      type: SelectFilter
    },
    {
      name: 'master',
      label: i18n.t('Availability'),
      options: availabilityOptions,
      isMulti: false,
      type: SelectFilter
    }] : []
  ];

  const handleSearch = ({ searchValue }) => {
    setFilters({ ...filters, keyword: searchValue })
  };

  const filterPartners = filterOptions => {
    let filtersArr = {};
    Object.entries(filterOptions).forEach(filter => {
      const [key, value] = filter;
      filtersArr[key] = value?.value ?? value?.id;
    });
    if (filters.keyword) filtersArr.keyword = filters.keyword
    setFilters(filtersArr)
  }

  return (
    <FilterBar
      filterConfig={filtersConfig}
      searchBox={{
        isOnlySearch: false,
        fullWidth: false,
        searchAction: handleSearch,
        placeholder: i18n.t('Search Partner Name')
      }}
      disabled={disabled}
      onSelectFilters={filterPartners}
    />
  );
}

PartnerSearchForm.propTypes = {
  disabled: PropTypes.bool,
  searchAction: PropTypes.func.isRequired,
  viewer: PropTypes.string
};

export default PartnerSearchForm;
