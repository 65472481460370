import React from 'react';
import i18n from "lib/i18n";

const EmptyEventListPlaceholder = (props) => (
  <div className="col-lg-12 text-center empty-event-list-placeholder">
    <div className="wrapper-md" />
    <h3>{ i18n.t("Sorry, we didn't find any workshops or events") }</h3>
    <h4>{ i18n.t("Try changing your keywords or search selections") }</h4>
  </div>
);

export default EmptyEventListPlaceholder;
