import PropTypes from 'prop-types';
import React from 'react';
import { Modal }  from 'react-bootstrap';
import { ModalHeader, MappedLocation, Icon }  from 'reactifi';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import i18n from 'lib/i18n';

export default class VenueDetails extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    selectVenue: PropTypes.func.isRequired,
    show: PropTypes.boolean,
    googleMapsApiKey: PropTypes.string.isRequired
  };

  constructor(props){
    super(props);
    this.onCancel = this.onCancel.bind(this);
    this.getMarkerInfoContent = this.getMarkerInfoContent.bind(this);
    this.displayFacilities = this.displayFacilities.bind(this);
  }

  onCancel() {
    this.props.selectVenue(null);
  }

  getMarkerInfoContent(selectedPlace){
    let venue = this.props.data;
    if(venue && venue.id === selectedPlace.id){
      return (
        <div>
          <span> <b> {venue.title} </b> </span> <br/>
          <span> {venue.location_formatted_address} </span>
        </div>)
    }
  }

  displayFacilities(venue){
    return (<span>
      {venue.computer_lab ?
        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-enabled">{i18n.t('Individual Devices')}</Tooltip>}>
          <Icon iconType="devices" />
        </OverlayTrigger> : null}
      {venue.projector ?
        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-enabled">{i18n.t('Projector')}</Tooltip>}>
          <Icon iconType="projector" fontFamily="engage" additionalClasses="status-icon" />
        </OverlayTrigger> : null}
      {venue.accessibility ?
        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-enabled">{i18n.t('Accessibility')}</Tooltip>}>
          <Icon iconType="accessible" fontFamily="engage" additionalClasses="status-icon" />
        </OverlayTrigger> : null}
    </span>);
  }

  render(){
    let venue = this.props.data;
    let loc = {
      id: venue && venue.id,
      lat: venue && venue.location_latitude,
      lng: venue && venue.location_longitude
    };
    return (<div>
      { venue ?
        <Modal className="form-modal" show={this.props.show === undefined ? !!this.props.data : this.props.show} onHide={this.onCancel}>
          <ModalHeader onCancel={this.onCancel} canClickOff={true} title="Venue Details"/>
          <Modal.Body className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h4>{venue.title}</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {venue.location_formatted_address ? <MappedLocation googleMapsApiKey={this.props.googleMapsApiKey} locations={[loc]} getMarkerInfoContent={this.getMarkerInfoContent} width="100%" height="200px"/> : null}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {venue.location_formatted_address}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {i18n.t('Facilities:')}
                {this.displayFacilities(venue)}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {i18n.t('Capacity:')} {venue.capacity}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="pull-left">
                  {i18n.t('Contact:')}
                </div>
                <div className="pull-left">
                  {venue.contact_name} <br/>
                  {venue.contact_phone} <br/>
                  {venue.contact_email}
                </div>
                <div className="clear"/>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {i18n.t('Notes:')} <br/>{venue.mission}
              </div>
            </div>
          </Modal.Body>
        </Modal> : null }
    </div>);
  }
}
