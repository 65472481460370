import PropTypes from 'prop-types';
import React from 'react';
import { ENGAGE_DEMO_IMG } from 'lib/constants';
import { Icon, Util } from 'reactifi';
import i18n from 'lib/i18n';

const TopicDetails = props => {
  const {
    currentEventUser,
    event,
    toggleEditEventType,
    viewer
  } = props;

  const canEdit = currentEventUser && !event.cancelled && viewer !== 'attendee';

  return (
    <div className="card event-card">
      <div className="card-header">
        <img alt={event.topic ? i18n.t(event.topic.name + ' Topic Image') : i18n.t('Topic Image')}
          src={event?.topic?.image_url || ENGAGE_DEMO_IMG} />
      </div>
      <div className="card-body topic-details">
        <div className="container">
          <div className="row">
            <p className="course-content-topic-body">{event.description}</p>
            {canEdit && 
              <div className="btn btn-link">
                <Icon iconType="edit" />
                <a
                  {...Util.dataAttribsForEl(event, {
                    action: "edit-topic-details"
                  })}
                  onClick={() => toggleEditEventType(true)}
                >
                  {i18n.t("Edit")}
                </a>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

TopicDetails.propTypes = {
  currentEventUser: PropTypes.object,
  event: PropTypes.shape({
    cancelled: PropTypes.bool,
    description: PropTypes.string,
    languageDisplay: PropTypes.string,
    id: PropTypes.string,
    topic: PropTypes.shape({
      image_url: PropTypes.string,
      name: PropTypes.string,
      languageDisplay: PropTypes.string
    })
  }).isRequired,
  toggleEditEventType: PropTypes.func,
  viewer: PropTypes.string
};

export default TopicDetails;
