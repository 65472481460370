import thunkMiddleware from 'redux-thunk';
import reducers from '../reducers/index'
import { compose, createStore, applyMiddleware, combineReducers } from 'redux';

export default props => {
  const initialState = {};
  const reducer = combineReducers(reducers);
  const composedStore = compose(
    applyMiddleware(thunkMiddleware)
  );
  const storeCreator = composedStore(createStore);
  const store = storeCreator(reducer, initialState);

  return store;
};