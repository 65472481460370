import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import {
  Form,
  FieldGroup,
  FloatingLabelFormField,
  Icon,
  ProgressSpinner,
  SelectAsyncControl,
  Step
} from 'reactifi';

import EventPartnerForm from '../EventPartnerForm';
import RegQuestionsForm from '../RegQuestionsForm';

export default class EventWizardOrganizers extends Step {
  static propTypes = {
    actions: PropTypes.shape({
      clearMessages: PropTypes.func.isRequired,
      createVenuePartner: PropTypes.func.isRequired,
      loadPartner: PropTypes.func,
      toggleEditPartner: PropTypes.func.isRequired,
      updateEvent: PropTypes.func.isRequired
    }).isRequired,
    createEvent: PropTypes.func,
    createPartner: PropTypes.func,
    currentUser: PropTypes.object,
    displayErrorMessage: PropTypes.func,
    errorMessage: PropTypes.string,
    event: PropTypes.object,
    googleMapsApiKey: PropTypes.string.isRequired,
    isEventManagement: PropTypes.bool,
    languageOptions: PropTypes.array,
    newEntity: PropTypes.func,
    partners: PropTypes.array,
    regQuestions: PropTypes.object,
    selectLead: PropTypes.func,
    selectedPartner: PropTypes.shape({
      name: PropTypes.string
    }),
    selectPartner: PropTypes.func,
    showEditPartner: PropTypes.bool,
    selectRegQuestions: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.state = {
      activeLanguage: 'en',
      event: props.event
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({ event: newProps.event });
  }

  buildVolunteerDisplay = volunteer => {
    if (volunteer) {
      return `${volunteer.first_name} ${volunteer.last_name} (${volunteer.email})`;
    }
  }

  selectLanguage = language => {
    this.setState({
      activeLanguage: Object.keys(language)[0]
    });
  }

  goForward = async () => {
    const savePromise = new Promise((resolve, reject) => {
      this.saveSuccess = resolve;
      this.saveFailed = reject;
    })
    this.setState({ triggerSave: true })
    return savePromise;
  }

  saveComplete = success => {
    this.setState({ triggerSave: false }, () => {
      // successful save will hit createEvent below
      if (!success) {
        this.saveFailed(false);
      }
    });
  }

  createEvent = async (event) => {
    let success;
    try {
      await this.props.createEvent(event);
      success = true;
    } catch (e) {
      success = false;
    } finally {
      this.setState({ triggerSave: false }, () => {
        if (success) {
          this.saveSuccess(true);
        } else {
          this.saveFailed(false);
        }
      });
    }
  }

  getOrganizerForm = () => {
    const { currentUser, selectLead } = this.props;
    const { event } = this.state;

    const initialData = currentUser && currentUser.attributes ? { display: `${currentUser.attributes.first_name} ${currentUser.attributes.last_name} (${currentUser.attributes.email})`, id: currentUser.id } : null;

    return (
      <div className="form-well p-20">
        <h4>{i18n.t('Event Organizers')}</h4>
        {!event ?
          <ProgressSpinner /> :
          <Form
            addAction={this.createEvent}
            cancelButton={false}
            data={event}
            saveButton={false}
            saveComplete={this.saveComplete}
            triggerSave={this.state.triggerSave}
          >
            <FieldGroup>
              <FloatingLabelFormField
                caption={i18n.t("Lead Volunteer")}
                name="lead_user_id"
                required={true}
              >
                <SelectAsyncControl
                  buildDisplay={this.buildVolunteerDisplay}
                  dataUrl="/api/data/volunteers.json"
                  displayProperty="name"
                  filterField="keyword"
                  initialData={initialData}
                  multi={false}
                  onChange={selectLead}
                  scrollMenuIntoView={true}
                  sortBy="title"
                />
              </FloatingLabelFormField>
            </FieldGroup>
            <FieldGroup>
              <FloatingLabelFormField
                caption={i18n.t("Volunteers Needed (including yourself)")}
                name="desired_volunteer_count"
                required={true}
              />
            </FieldGroup>
            <FieldGroup>
              <FloatingLabelFormField
                caption={i18n.t("Estimated Expense")}
                min={0}
                name="estimated_expense"
                type="number"
              />
            </FieldGroup>
          </Form>
        }
      </div>
    );
  }

  getPartnerForm = () => {
    const { actions, removePartner, selectedPartner } = this.props;

    return (
      <div className="form-well p-20">
        <h4>{i18n.t('Event Partner')}</h4>
        <p>{i18n.t('If you are partnering with an external organization to deliver this event, please add a partner.')}</p>
        {selectedPartner && <p><strong>{selectedPartner.name}</strong></p>}
        <a
          className="btn btn-secondary m-0"
          data-action="add-partner"
          data-object-type="events"
          onClick={() => actions.toggleEditPartner(true)}
        >
          {!selectedPartner ? i18n.t('Add Partner') : i18n.t('Edit Partner')}
        </a>
        {selectedPartner &&
          <a className="btn text-secondary" onClick={removePartner}>
            <Icon iconType="delete" additionalClasses="m-l-0 m-r-5" />
            {i18n.t('Delete')}
          </a>
        }
      </div>
    );
  }

  getRegQuestionsForm = () => {
    const { actions, selectedRegQuestions } = this.props;
    const questions = [];
    selectedRegQuestions.map((question, index) => {
      questions.push(<div key={index} className="custom-reg-question">{i18n.t(question.name)}</div>);
    });
    const buttonText = questions.length > 0 ? i18n.t('Add / Remove Questions') : i18n.t('Add Registration Questions');
    return (
      <div className="form-well p-20">
        <h4>{i18n.t('Registration Questions')}</h4>
        <p>{i18n.t('If you would like to add custom registration questions, please do so here.')}</p>
        {questions}
        <a
          className="btn btn-secondary m-0"
          data-action="add-registration-questions"
          data-object-type="events"
          onClick={() => actions.toggleAddRegQuestions(true)}> {buttonText} </a>
      </div>
    );
  }


  createVenuePartner = (newPartner, eventId, toggleEditPartner) => {
    const { createVenuePartner } = this.props.actions;
    createVenuePartner(newPartner, null, toggleEditPartner);
  }

  render() {
    const {
      actions,
      createPartner,
      errorMessage,
      googleMapsApiKey,
      isEventManagement,
      languageOptions,
      newEntity,
      partners,
      regQuestions,
      selectPartner,
      showEditPartner,
      showAddRegQuestions,
      selectRegQuestions
    } = this.props;

    const { event } = this.state;

    return (
      <>
        {this.getOrganizerForm()}
        {this.getPartnerForm()}
        {isEventManagement && this.getRegQuestionsForm()}

        <EventPartnerForm
          clearMessages={actions.clearMessages}
          createPartner={createPartner}
          createVenuePartner={actions.createVenuePartner}
          errorMessage={errorMessage}
          event={showEditPartner ? event : null}
          googleMapsApiKey={googleMapsApiKey}
          isCreateEvent={true}
          isEventManagement={isEventManagement}
          languages={languageOptions}
          loadPartner={actions.loadPartner}
          newEntity={newEntity}
          partners={partners}
          toggleEditPartner={actions.toggleEditPartner}
          updateEvent={selectPartner}
          updatePartner={actions.updatePartner}
        />

        <RegQuestionsForm
          clearMessages={actions.clearMessages}
          errorMessage={errorMessage}
          event={showAddRegQuestions ? event : null}
          regQuestions={regQuestions}
          toggleAddRegQuestions={actions.toggleAddRegQuestions}
          updateEvent={selectRegQuestions}
        />
      </>
    );
  }
}
