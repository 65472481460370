import PropTypes from 'prop-types';
import React from 'react';
import { Icon, WizardModal } from 'reactifi';
import Button from 'react-bootstrap/lib/Button';
import AttendeeForm from './AttendeeForm';
import AttendeeAssessment from './AttendeeAssessment';
import i18n from 'lib/i18n';

export default class AttendeeWizard extends React.Component {
  static propTypes = {
    actions: PropTypes.shape({
      selectAttendee: PropTypes.func.isRequired,
      createAttendee: PropTypes.func.isRequired,
      updateAttendee: PropTypes.func.isRequired,
      loadAttendeeResponseSets: PropTypes.func.isRequired,
      selectWizardStep: PropTypes.func.isRequired,
      setWizardCancelMessage: PropTypes.func.isRequired
    }).isRequired,
    courseModules: PropTypes.array,
    currentAttendee: PropTypes.object,
    disableAssessment: PropTypes.bool,
    errorMessage: PropTypes.string,
    evaluFiUrl: PropTypes.string.isRequired,
    event: PropTypes.object,
    eventType: PropTypes.string,
    googleMapsApiKey: PropTypes.string.isRequired,
    isEventManagement: PropTypes.bool,
    wizardCancelConfirm: PropTypes.shape({
      confirmMessage: PropTypes.string,
      onConfirm: PropTypes.func
    }),
    wizardStep: PropTypes.number
  };

  constructor(props) {
    super(props);

    this.state = {
      createAndAdd: false
    };
  }

  onClose = () => {
    this.props.actions.selectAttendee(null);
  }

  getWizardSteps = () => {
    const steps = [
      this.getPersonalInformationStep(),
      this.getAssessmentDataStep()
    ].filter(step => !!step);

    return steps;
  }

  getPersonalInformationStep = () => {
    const {
      actions: {
        createAttendee,
        updateAttendee
      },
      currentAttendee,
      disableAssessment,
      errorMessage,
      event,
      eventType,
      googleMapsApiKey,
      isEventManagement
    } = this.props;
    const attendee = currentAttendee || {};

    return (
      <AttendeeForm
        createAttendee={createAttendee}
        currentAttendee={attendee}
        disableAssessment={disableAssessment}
        errorMessage={errorMessage}
        event={event}
        eventType={eventType}
        googleMapsApiKey={googleMapsApiKey}
        isEventManagement={isEventManagement}
        onCancel={this.onClose}
        title={i18n.t('Personal Information')}
        updateAttendee={updateAttendee}
      />
    );
  }

  getAssessmentDataStep = () => {
    const attendee = this.props.currentAttendee || {};
    const {
      actions: {
        selectAttendee,
        loadAttendeeResponseSets,
        updateAttendee,
        selectWizardStep,
        setWizardCancelMessage
      },
      errorMessage,
      courseModules,
      evaluFiUrl,
      disableAssessment
    } = this.props;

    if (disableAssessment || attendee.is_protected) {
      return null;
    }

    return (
      <AttendeeAssessment
        title={i18n.t('Assessment Data')}
        currentAttendee={attendee}
        errorMessage={errorMessage}
        selectAttendee={selectAttendee}
        loadAttendeeResponseSets={loadAttendeeResponseSets}
        updateAttendee={updateAttendee}
        selectWizardStep={selectWizardStep}
        setWizardCancelMessage={setWizardCancelMessage}
        courseModules={courseModules}
        evaluFiUrl={evaluFiUrl}
        createAndAdd={this.state.createAndAdd}
      />
    );
  }

  renderExtraWizardButtons = (currentStep, goingToStep, isLastStep) => {
    if (this.props.disableAssessment || !isLastStep || !this.props.currentAttendee.id) {
      return null;
    }

    return (
      <Button bsStyle="primary" className="btn-primary-branded" onClick={() => this.setState({ createAndAdd: true })} disabled={!!goingToStep}>
        {goingToStep ? <Icon iconType="spinner" spin={true} /> : null}
        {i18n.t('Save & Add Another')}
      </Button>
    );
  }

  render() {
    const { currentAttendee, wizardStep, errorMessage } = this.props;

    if (!currentAttendee) {
      return null;
    }

    const modalTitle = currentAttendee.id ? `Edit ${currentAttendee.first_name} ${currentAttendee.last_name}` : i18n.t('Add Attendee');

    return (
      <WizardModal
        title={modalTitle}
        finalButtonLabel={i18n.t('Save')}
        initialStep={wizardStep}
        limitHeight={true}
        onCancel={this.onClose}
        errorMessage={errorMessage}
        renderExtraButtons={this.renderExtraWizardButtons}
        onResetSteps={() => this.setState({ createAndAdd: false })}
      >
        {this.getWizardSteps()}
      </WizardModal>
    );
  }
}
