import PropTypes from 'prop-types';
import React from 'react';
import EventResult from '../../Event/components/vportal/EventResult';

const EventList = ({ events, ...props }) => (
  <div className="event-list-container events-list col-lg-12">
    { events.map((event, index) => (
      <EventResult key={index}
        showEventRow={true}
        event={event}
        {...props} />
    )) }
  </div>
);

EventList.propTypes = {
  events: PropTypes.array.isRequired
};

export default EventList;
