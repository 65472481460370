import React from 'react';
import PropTypes from 'prop-types';
import { ModalForm, FieldGroup, SelectAsyncControl, FloatingLabelFormField, MaterialCheckbox } from 'reactifi';
import i18n from 'lib/i18n';

const MAX_TOTAL_COUNT = 20;

export default class ContentForm extends React.Component {
  static propTypes = {
    clearMessages: PropTypes.func.isRequired,
    contentServiceUrl: PropTypes.string,
    createContent: PropTypes.func.isRequired,
    currentContent: PropTypes.object,
    errorMessage: PropTypes.string,
    jwtToken: PropTypes.string,
    readContentServiceContentById: PropTypes.func.isRequired,
    selectContent: PropTypes.func.isRequired,
    updateContent: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    let { currentContent, contentServiceUrl, jwtToken } = this.props;
    if (currentContent && currentContent.content_id && !currentContent.content) {
      this.props.readContentServiceContentById(contentServiceUrl, currentContent.content_id, jwtToken);
    }
  }

  onCancel() {
    this.props.selectContent(null);
    this.props.clearMessages();
  }

  render() {
    const {
      contentServiceUrl,
      currentContent,
      createContent,
      errorMessage,
      jwtToken,
      updateContent
    } = this.props;
    const title = currentContent && currentContent.id ? i18n.t('editNumber', { postProcess: 'sprintf', sprintf: [currentContent.name] }) : i18n.t('Add New Content');
    const content = currentContent && currentContent.content;
    const languages = [{ en: 'English' }, { es: 'Spanish' }];
    if (currentContent.content_id && !currentContent.content) {
      //don't construct the form until the content is loaded
      //since that needs to be the initial value of the content select control
      return null;
    }

    return (
      <ModalForm data={currentContent} errorMessage={errorMessage} title={title}
        onCancel={this.onCancel} addAction={createContent} updateAction={updateContent} languages={languages} size="large" >
        <FieldGroup className="container-fluid">
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField name="name" caption={i18n.t("Name")} type="text" required={true} multilingual={true} />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField name="description" caption={i18n.t("Description")} type="text" required={false} multilingual={true} />
            </FieldGroup>
          </FieldGroup>

          <FieldGroup className="row">
            <div className="col-12">
              <MaterialCheckbox name="hide_from_event_users" dataValue={currentContent.hide_from_event_users}>
                {i18n.t('Hide content from Admins/Staff')}
              </MaterialCheckbox>
            </div>
          </FieldGroup>

          <div className="row">
            <div className="col-12">
              <h4>{i18n.t('EverFi Content')}</h4>
            </div>
          </div>

          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField name="content_id" caption={i18n.t("Content")} required={true} multi={true} >
                <SelectAsyncControl
                  authToken={jwtToken}
                  dataUrl={`${contentServiceUrl}/api/v1/contents.json`}
                  displayProperty="name"
                  filterField="keyword"
                  initialData={content}
                  multi={false}
                  optionsToPrepend={[{ id: '', display: i18n.t('Other') }]}
                  pageSize={MAX_TOTAL_COUNT}
                  placeholder=""
                  scrollMenuIntoView={true}
                  searchPromptText={i18n.t("Select Content:")}
                  sortBy="name"
                  valueKey="content_id"
                />
              </FloatingLabelFormField>
            </FieldGroup>
          </FieldGroup>
        </FieldGroup>
      </ModalForm>
    )
  }
}
