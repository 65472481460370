import PropTypes from 'prop-types';
import React from 'react';
import VolunteerList from '../components/VolunteerList';
import AddVolunteer from '../components/AddVolunteer';
import EventConferencingInfoForm from '../components/EventConferencingInfoForm';
import EventDetails from '../components/EventDetails';
import EventTypeForm from '../components/EventTypeForm';
import EventDateTimeForm from '../components/EventDateTimeForm';
import EventVenueForm from '../components/EventVenueForm';
import EventPartnerForm from '../components/EventPartnerForm';
import EventCancelForm from '../components/EventCancelForm';
import VolunteerTabs from '../components/VolunteerTabs';
import TopicDetails from './TopicDetails';
import {
  AlertMessage,
  AlertMessageTypes,
  AutoSavingForm,
  ConfirmationModal,
  Icon,
  ModalForm,
  SelectControl,
  Util
} from 'reactifi';
import { Button, Radio } from 'react-bootstrap';
import moment from 'moment';
import i18n from 'lib/i18n';
import AddToCalendarButton from './AddToCalendarButton';
import { displayAttendeeButtons } from '../libs/addToCalendar';
import RegQuestionsForm from './RegQuestionsForm';
import { registrationQuestions } from '../functions/helpers';

export default class MyEvent extends React.Component {
  static propTypes = {
    accountName: PropTypes.string,
    actions: PropTypes.shape({
      clearMessages: PropTypes.func.isRequired,
      createVenuePartner: PropTypes.func.isRequired,
      deleteEventUser: PropTypes.func.isRequired,
      loadPartner: PropTypes.func,
      loadVenue: PropTypes.func.isRequired,
      selectReservation: PropTypes.func.isRequired,
      toggleEditConferencingInfo: PropTypes.func.isRequired,
      toggleEditDateTime: PropTypes.func.isRequired,
      toggleEditEventType: PropTypes.func.isRequired,
      toggleEditPartner: PropTypes.func.isRequired,
      toggleEditRegistrationQuestions: PropTypes.func.isRequired,
      toggleEditVenue: PropTypes.func.isRequired,
      updateEvent: PropTypes.func.isRequired,
      updateEventUser: PropTypes.func.isRequired,
      updatePartner: PropTypes.func.isRequired
    }).isRequired,
    adminifiUrl: PropTypes.string,
    className: PropTypes.string,
    clearMessages: PropTypes.func,
    currentEventUser: PropTypes.object.isRequired,
    displayErrorMessage: PropTypes.func,
    displaySuccessMessage: PropTypes.func,
    errorMessage: PropTypes.string,
    evaluFiUrl: PropTypes.string.isRequired,
    event: PropTypes.object,
    eventUsers: PropTypes.array,
    everfiContents: PropTypes.array,
    googleMapsApiKey: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool,
    isEventManagement: PropTypes.bool,
    isWebinar: PropTypes.bool,
    joinEvent: PropTypes.func.isRequired,
    languages: PropTypes.array,
    newEntity: PropTypes.func,
    partners: PropTypes.array,
    preRegister: PropTypes.bool,
    showAlertMessage: PropTypes.bool,
    showEditConferencingInfo: PropTypes.bool,
    showEditDateTime: PropTypes.bool,
    showEditEventType: PropTypes.bool,
    showEditPartner: PropTypes.bool,
    showEditVenue: PropTypes.bool,
    showEditRegQuestions: PropTypes.bool,
    subdomain: PropTypes.string,
    successMessage: PropTypes.string,
    topics: PropTypes.array,
    venues: PropTypes.array,
    viewer: PropTypes.string
  };

  static defaultProps = {
    eventUsers: []
  };

  constructor(props) {
    super(props);
    this.state = {
      consentAnswer: false,
      makeLeadUserData: null,
      removeVolunteerUserData: null,
      showAttendeeRegistrationConfirmModal: false,
      showEventCancelModal: false,
      showMediaConsentModal: false,
      showMediaConsentWarning: false
    };
  }

  setLeadEventUser = eventUser => {
    this.setState({ makeLeadUserData: eventUser });
  }

  onCancelMakeLead = () => {
    this.setState({ makeLeadUserData: null });
  }

  onConfirmMakeLead = eventUser => {
    this.props.actions.updateEvent(
      Object.assign(this.props.event, {
        'lead_user_id': eventUser.user_id
      }));

    this.setState({ makeLeadUserData: null });
  }

  removeVolunteer = eventUser => {
    this.setState({ removeVolunteerUserData: eventUser });
  }

  onCancelRemoveVolunteer = () => {
    this.setState({ removeVolunteerUserData: null });
  }

  onCloseConsentModal = () => {
    this.setState({ showMediaConsentModal: false });
  }

  onConfirmRemoveVolunteer = eventUser => {
    this.props.actions.deleteEventUser(eventUser, "Volunteer removed successfully");
    this.setState({ removeVolunteerUserData: null });
  }

  onEventCheckIn = () => {
    this.setState({ showMediaConsentModal: true });
  }

  onCancelGoToAttendeeRegistration = () => {
    this.setState({ showAttendeeRegistrationConfirmModal: false });
  }

  onConfirmGoToAttendeeRegistration = eventUser => {
    const { adminifiUrl, event, isAdmin, preRegister, subdomain } = this.props;
    this.setState({ showAttendeeRegistrationConfirmModal: false });

    const location_href = preRegister ? `/events/${event.code}/attendees/enter` : `/events/${event.code}/attendees/enter?from=vportal`;
    const engageLoginUrl = `/${i18n.language}/users/login`;
    const foundryLogOutUrl = `${adminifiUrl}/${subdomain}/sign_out`;


    window.history.pushState(null, null, engageLoginUrl);

    if (isAdmin) {
      window.open(location_href, '_blank'); // Opens attendee event detail page in new tab
      window.location.assign(foundryLogOutUrl); // Signs admin user out in current tab
    } else {
      window.location.href = location_href;
    }
  }

  goToAttendeeRegistrationSite = () => {
    this.setState({ showAttendeeRegistrationConfirmModal: true });
  }

  markDone = () => {
    this.props.actions.updateEvent(
      Object.assign(this.props.event, {
        completed: true
      }));
  }

  toggleCancelEvent = show => {
    this.setState({ showEventCancelModal: show });
  }

  eventCanMarkAsDone(event, currentLead, users) {
    return !moment(event.end_time).isAfter(new Date())
      && !event.completed;
  }

  onChangeConsent = answer => {
    this.setState({ consentAnswer: answer });
  }

  joinEvent = eventUser => {
    this.props.joinEvent(eventUser, "Volunteer Added!");
  }

  onUpdateConsent = eventUser => {
    this.props.actions.updateEventUser(Object.assign(eventUser, {
      media_consent: this.state.consentAnswer,
      checked_in: true
    }), i18n.t("Checked in!"));
    this.setState({ showMediaConsentModal: false });
  }

  handleMediaConsentWarningDismiss = () => {
    this.setState({ showMediaConsentWarning: false });
  }

  getVolunteerCount = () => {
    const numberOfVolunteers = Math.max(this.props.event.desired_volunteer_count, 100);
    return [...Array(numberOfVolunteers)].map((_, index) => ({ label: ++index, value: index }));
  }

  // eslint-disable-next-line complexity
  render() {
    const {
      accountName,
      actions,
      className,
      clearMessages,
      currentEventUser,
      displayErrorMessage,
      displaySuccessMessage,
      errorMessage,
      evaluFiUrl,
      event,
      eventUsers,
      everfiContents,
      googleMapsApiKey,
      isEventManagement,
      isWebinar,
      languages,
      newEntity,
      partners,
      preRegister,
      showAlertMessage,
      showEditConferencingInfo,
      showEditDateTime,
      showEditEventType,
      showEditPartner,
      showEditVenue,
      showEditRegQuestions,
      successMessage,
      topics,
      venues,
      viewer
    } = this.props;

    const currentLead = currentEventUser.user_id.toString() === event.lead_user_id.toString();
    const volsNeeded = event.event_users ? event.desired_volunteer_count - event.event_users.length : 0;
    const checkInDisabled = !moment(event.start_time).startOf('day').isSame(moment().startOf('day')) ||
      (currentEventUser && currentEventUser.checked_in) || event.cancelled;

    const registerButton = (
      <Button bsStyle="primary" disabled={checkInDisabled} onClick={this.onEventCheckIn}>
        {!currentEventUser.checked_in ? i18n.t('Event Check-In') : <span className="">{i18n.t('Checked-In')}<Icon iconType="check" additionalClasses="m-l-5" /></span>}
      </Button>
    );


    const registerButtonWithLabel = (<div>
      <div className="action-label">
        {(!currentEventUser.checked_in && !this.eventCanMarkAsDone(event, currentLead, eventUsers)
          ? 'Have you checked in?'
          : '')
        }
      </div>
      {registerButton}
    </div>);

    return (
      <div className={Util.formatClassName([
        'my-event',
        'container',
        className
      ])}>
        <AlertMessage
          message={i18n.t("Please check the Media Consent checkbox before proceeding.")}
          messageType={AlertMessageTypes.warning}
          onAlertDismiss={this.handleMediaConsentWarningDismiss}
          visible={this.state.showMediaConsentWarning}
        />
        <AlertMessage
          clearMessages={clearMessages}
          message={errorMessage}
          messageType={AlertMessageTypes.danger}
          visible={errorMessage && showAlertMessage}
        />
        <AlertMessage
          clearMessages={clearMessages}
          message={successMessage}
          messageType={AlertMessageTypes.success}
          visible={successMessage && showAlertMessage}
        />
        <div className="row event-title">
          <div className="col-lg-8 offset-lg-1 col-12 mobileCenter">
            <h1 className="event-header">
              <a className="event-header-action-back" onClick={() => { window.history.back() }}>
                <Icon iconType="arrow-left" />
              </a>
              {event.title} - {moment(event.start_time).format('LL')}
              <h3 className="event-header-status"><span className="title">{i18n.t('Status:')} </span>{event.statusDisplay}</h3>
            </h1>
          </div>
          <div className="col-lg-2 col-12 mobileCenter">
            <div className="header-actions">
              {displayAttendeeButtons(event, viewer, currentEventUser) && <AddToCalendarButton accountName={accountName} event={event} />}
              {!event.cancelled && currentEventUser ?
                <span>
                  {!isWebinar && (
                    <div className="action">
                      {registerButtonWithLabel}
                    </div>
                  )}
                  {this.eventCanMarkAsDone(event, currentLead, eventUsers) ?
                    <div className="action">
                      <Button
                        {...Util.dataAttribsForEl(event, { action: 'mark-done' })}
                        bsStyle="primary"
                        onClick={this.markDone}
                      >
                        {i18n.t('Mark Done')}
                      </Button>
                    </div>
                    : null}
                </span>
                : null}
            </div>
          </div>
        </div>

        <div className="row rowcards event-row-card">
          <div className="col-10 col-lg-6 rowcard col-lg-offset-1 event-row-card-left" id="topic-details">
            <TopicDetails
              currentEventUser={currentEventUser}
              event={event}
              toggleEditEventType={actions.toggleEditEventType}
              viewer={viewer}
            />
          </div>
          <div className="col-lg-4 rowcard event-row-card-right" id="event-details">
            <EventDetails
              currentEventUser={currentEventUser}
              currentLead={currentLead}
              deleteEventUser={actions.deleteEventUser}
              errorMessage={errorMessage}
              event={event}
              isWebinar={isWebinar}
              isEventManagement={isEventManagement}
              languages={languages}
              toggleCancelEvent={this.toggleCancelEvent}
              toggleEditConferencingInfo={actions.toggleEditConferencingInfo}
              toggleEditDateTime={actions.toggleEditDateTime}
              toggleEditEventType={actions.toggleEditEventType}
              toggleEditPartner={actions.toggleEditPartner}
              toggleEditVenue={actions.toggleEditVenue}
              toggleEditRegistrationQuestions={actions.toggleEditRegistrationQuestions}
              topics={topics}
              viewer={viewer}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-4">
                      <h2>{i18n.t('Volunteers')}</h2>
                    </div>
                    <div className="col-lg-8">
                      <div className="pull-right">
                        {i18n.t('Volunteers Needed')}
                        <AutoSavingForm data={event} updateAction={actions.updateEvent}
                          saveMessageClassName="autosave-message events-volunteers-autosave-form-save-message" errorMessage={errorMessage}>
                          <SelectControl {...Util.dataAttribsForEl(event, { action: 'add-volunteer' })} name="desired_volunteer_count" valueKey="value" labelKey="label" options={this.getVolunteerCount()} />
                        </AutoSavingForm>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <VolunteerList
                  currentEventUser={currentEventUser}
                  currentLead={currentLead}
                  deleteEventUser={this.removeVolunteer}
                  eventUsers={eventUsers}
                  setLeadEventUser={this.setLeadEventUser}
                  updateEventUser={actions.updateEventUser}
                />
                {volsNeeded > 0 ? <AddVolunteer event_id={event.id} joinEvent={this.joinEvent} /> : null}
              </div>
            </div>
          </div>
        </div>
        <div className="row volunteer-tabs">
          <div className="col-lg-12">
            <div className="card event-card">
              <div className="card-body">
                <VolunteerTabs
                  actions={actions}
                  checkIn={this.onEventCheckIn}
                  checkInDisabled={checkInDisabled}
                  currentEventUser={currentEventUser}
                  currentLead={currentLead}
                  displayErrorMessage={displayErrorMessage}
                  displaySuccessMessage={displaySuccessMessage}
                  errorMessage={errorMessage}
                  evaluFiUrl={evaluFiUrl}
                  event={event}
                  eventUsers={eventUsers}
                  everfiContents={everfiContents}
                  googleMapsApiKey={googleMapsApiKey}
                  goToAttendeeRegistrationSite={this.goToAttendeeRegistrationSite}
                  isEventManagement={isEventManagement}
                  isWebinar={isWebinar}
                  preRegister={preRegister}
                  updateEvent={actions.updateEvent}
                  updateEventUser={actions.updateEventUser}
                  viewer={viewer}
                />
              </div>

            </div>
          </div>

          <EventTypeForm
            clearMessages={actions.clearMessages}
            errorMessage={errorMessage}
            event={showEditEventType ? event : null}
            languages={languages}
            toggleEditEventType={actions.toggleEditEventType}
            topics={topics}
            updateEvent={actions.updateEvent}
          />

          <EventDateTimeForm
            clearMessages={actions.clearMessages}
            errorMessage={errorMessage}
            event={showEditDateTime ? event : null}
            toggleEditDateTime={actions.toggleEditDateTime}
            updateEvent={actions.updateEvent}
          />

          <EventVenueForm
            clearMessages={actions.clearMessages}
            createVenuePartner={actions.createVenuePartner}
            errorMessage={errorMessage}
            event={showEditVenue ? event : null}
            googleMapsApiKey={googleMapsApiKey}
            isEventManagement={isEventManagement}
            loadVenue={actions.loadVenue}
            newEntity={newEntity}
            toggleEditVenue={actions.toggleEditVenue}
            updateEvent={actions.updateEvent}
            venues={venues}
          />

          <EventPartnerForm
            clearMessages={actions.clearMessages}
            createVenuePartner={actions.createVenuePartner}
            errorMessage={errorMessage}
            event={showEditPartner ? event : null}
            googleMapsApiKey={googleMapsApiKey}
            isEventManagement={isEventManagement}
            languages={languages}
            loadPartner={actions.loadPartner}
            newEntity={newEntity}
            partners={partners}
            toggleEditPartner={actions.toggleEditPartner}
            updateEvent={actions.updateEvent}
            updatePartner={actions.updatePartner}
            isWebinar={isWebinar}
          />
          <RegQuestionsForm
            clearMessages={actions.clearMessages}
            event={showEditRegQuestions ? event : null}
            errorMessage={errorMessage}
            regQuestions={registrationQuestions}
            toggleAddRegQuestions={actions.toggleEditRegistrationQuestions}
            updateEvent={actions.updateEvent}
          />
          <EventConferencingInfoForm
            clearMessages={actions.clearMessages}
            errorMessage={errorMessage}
            event={showEditConferencingInfo ? event : null}
            toggleEditConferencingInfo={actions.toggleEditConferencingInfo}
            updateEvent={actions.updateEvent}
          />

          <EventCancelForm
            errorMessage={errorMessage}
            event={event}
            show={this.state.showEventCancelModal}
            toggleCancelEvent={this.toggleCancelEvent}
            updateEvent={actions.updateEvent}
          />
        </div>

        <ConfirmationModal
          canClickOff={true}
          confirmButtonText="Change Lead"
          data={this.state.makeLeadUserData}
          title="Change Lead"
          onCancel={this.onCancelMakeLead}
          onConfirm={this.onConfirmMakeLead}
        >
          <div>{i18n.t("Are you sure you would like to change the lead volunteer?")}</div>
        </ConfirmationModal>

        <ConfirmationModal
          canClickOff={true}
          confirmButtonText="Remove Volunteer"
          data={this.state.removeVolunteerUserData}
          onCancel={this.onCancelRemoveVolunteer}
          onConfirm={this.onConfirmRemoveVolunteer}
          title="Remove Volunteer"
        >
          <div>{i18n.t("Are you sure you would like to remove this Volunteer?")}</div>
        </ConfirmationModal>

        <ConfirmationModal
          canClickOff={true}
          confirmButtonText={i18n.t("Leave Site")}
          data={this.state.showAttendeeRegistrationConfirmModal}
          onCancel={this.onCancelGoToAttendeeRegistration}
          onConfirm={this.onConfirmGoToAttendeeRegistration}
          title={i18n.t("Leave Portal")}
        >
          <div>{i18n.t("Are you sure you want to log out and enter the Attendee Registration Site? You will be logged out of the portal.")}</div>
        </ConfirmationModal>

        <ModalForm
          bsSize="sm"
          data={currentEventUser}
          errorMessage={errorMessage}
          onCancel={this.onCloseConsentModal}
          saveMessageClassName="autosave-message events-mediaconsent-autosave-message"
          show={this.state.showMediaConsentModal}
          skipCancelConfirmation={true}
          title="Media Consent"
          updateAction={this.onUpdateConsent}
        >
          <div className="container">
            <div className="action col-12">
              <p>{accountName} {i18n.t('will not use pictures of you from the event without permission.')}</p>
              <Radio onChange={() => this.onChangeConsent(true)} name="media_consent">
                <i className="input-helper" />
                {i18n.t('Yes, I Consent')}
              </Radio>
              <Radio onChange={() => this.onChangeConsent(false)} name="media_consent">
                <i className="input-helper" />
                {i18n.t('No, Thanks')}
              </Radio>
            </div>
          </div>
        </ModalForm>
      </div>)
  }
}
