import PropTypes from 'prop-types';
import React from 'react';
import { ModalForm, FieldGroup, FloatingLabelFormField } from 'reactifi';
import i18n from 'lib/i18n';

export default class OrganizationsForm extends React.Component {
  static propTypes = {
    currentOrganization: PropTypes.object,
    errorMessage: PropTypes.string,
    selectOrganization: PropTypes.func.isRequired,
    createOrganization: PropTypes.func.isRequired,
    updateOrganization: PropTypes.func.isRequired,
    clearMessages: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
  }

  onCancel() {
    this.props.selectOrganization(null);
    this.props.clearMessages();
  }

  render() {
    let title = this.props.currentOrganization && this.props.currentOrganization.id ? i18n.t('editNumber', { postProcess: 'sprintf', sprintf: [this.props.currentOrganization.name] }) : i18n.t('Add a New Organization');

    return(
      <ModalForm data={this.props.currentOrganization} errorMessage={this.props.errorMessage} title={title}
        onCancel={this.onCancel} addAction={this.props.createOrganization} updateAction={this.props.updateOrganization} >
        <FieldGroup className="container-fluid">
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField name="name" caption={i18n.t("Name")} type="text" required={true} />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField name="code" caption={i18n.t("Code (If none provided, one will be created for you)")} type="text" required={false} />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField name="subdomain" caption={i18n.t("Subdomain")} type="text" required={true} tooltip={i18n.t("Please use the same value that will be used in Foundry AdminiFi for this organization's other EverFi applications.")}/>
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField name="organization_uuid" caption={i18n.t("Foundry UUID")} type="text" required={true} tooltip={i18n.t("This must be the same value as the organization's AdminiFi record to support SSO from AdminiFi to Engage.")}/>
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField name="disclaimer" caption={i18n.t("Attendee Registration Legal Disclaimer")} type="text" />
            </FieldGroup>
          </FieldGroup>
        </FieldGroup>
      </ModalForm>
    )
  }

}
