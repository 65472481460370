import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as orgTopicsActionCreators from '../actions/orgTopicsActionCreators';
import { buildGenericProps, JsonApiDecorator } from 'reactifi';

import OrganizationTopics from '../components/OrganizationTopics';

function mapStateToProps(state, ownProps) {
  let props = buildGenericProps(state, 'topics', ['offline_contents', 'events', 'everfi_content', 'course_modules']);
  let apiStore = new JsonApiDecorator(state.api);

  props.currentOrganizationId = ownProps.routeParams.id.toString();

  const { offline_contents, everfi_contents } = apiStore;
  props.everfiContents = everfi_contents ? everfi_contents.all('course_modules') : [];
  props.offlineContents = offline_contents ? offline_contents.all() : [];
  props.uploadInProgress = state.topics.uploadInProgress;

  if(state.api.currentEntityId && state.api.currentEntityType === 'topics'){
    props.currentTopic = props.currentEntity;
    if (props.currentTopic?.everfi_content_id){
      props.currentTopic.everfi_content_id = props.currentTopic.everfi_content_id.toString();
    }
  } else if(state.api.currentEntityId === 'add' && state.api.currentEntityType === 'offline_contents'){
    props.currentOfflineContent = apiStore.newObject('offline_contents', 'topic');
    if (props.currentOfflineContent) {
      props.currentOfflineContent.topic_id = state.topics.currentTopicId;
    }
  } else if (state.api.currentEntityId && state.api.currentEntityType === 'offline_contents') {
    props.currentOfflineContent = offline_contents ? offline_contents.find(state.api.currentEntityId, 'topic') : {};
    const { offline_content_for_everfi_contents } = props.currentOfflineContent;
    if(offline_content_for_everfi_contents) {
      props.currentOfflineContent.everfi_content_ids = offline_content_for_everfi_contents.map((offlineContent) => {
        return offlineContent?.everfi_content_id;
      });
    }
  }
  return props;
}

class OrganizationTopicsContainer extends Component {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    currentOrganizationId: PropTypes.string
  }

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(orgTopicsActionCreators, this.props.dispatch);
  }

  componentDidMount() {
    this.actions.loadTopics({ account_id: this.props.currentOrganizationId });
    this.actions.loadContent();
  }

  render() {
    return (
      <OrganizationTopics {...this.props} {...this.actions} />
    );
  }
}



export default connect(mapStateToProps)(OrganizationTopicsContainer);
