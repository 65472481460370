import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import i18n from 'lib/i18n';
import { ENGAGE_DEMO_IMG } from 'lib/constants';
import { Icon, TimezoneAbbr } from 'reactifi';
import { sanitizeUrl } from '@braintree/sanitize-url';

export default class EventResult extends React.Component {
  static propTypes = {
    event: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      start_time: PropTypes.string.isRequired,
      end_time: PropTypes.string.isRequired,
      joined: PropTypes.bool.isRequired,
      image_url: PropTypes.string,
      topic: PropTypes.shape({
        image_url: PropTypes.string
      }),
      venue: PropTypes.shape({
        location_locality: PropTypes.string.isRequired,
        location_formatted_address: PropTypes.string,
        location_administrative_area_level_1: PropTypes.string.isRequired
      }).isRequired,
      lead_volunteer: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    generateViewEventLink: PropTypes.func.isRequired,
    showEventRow: PropTypes.bool
  };

  eventStatus(event, volsNeeded) {
    if (event.status === "cancelled") {
      return 'cancelled';
    }
    if (event.event_over) {
      return 'past';
    }
    if (event.joined) {
      return 'joined';
    }
    if (volsNeeded <= 0) {
      return "";
    } else {
      return event.joined ? 'joined' : '';
    }
  }

  eventStatusLabel(status) {
    switch (status) {
    case "cancelled":
      return i18n.t("Cancelled");
    case "past":
      return i18n.t("Past");
    case "joined":
      return i18n.t("Joined!")
    default:
      return "";
    }
  }

  getImageUrl = () => this.props.event.image_url || (this.props.event.topic
    && this.props.event.topic.image_url) || ENGAGE_DEMO_IMG;

  render() {
    const {
      event,
      generateViewEventLink,
      showEventRow
    } = this.props;
    let startDate = moment(event.start_time);
    let endDate = moment(event.end_time);

    if (!showEventRow) {
      return (
        <div className="col-lg-6">
          <div className="event-item-card card">
            <div className="event-item-card-body">
              <div className="event-item-card-image-container">
                <img alt={event.title} className="fixed-aspect-ratio-image" src={this.getImageUrl()} />
              </div>
              <div className="event-item-card-details-container">
                <h4>{event.title}</h4>
                <Icon iconType="time" />
                <span>{startDate.format('dddd, MMM Do - LT')} - {endDate.format('LT')} {TimezoneAbbr()}</span><br />
                {event.venue && (
                  <>
                    <Icon iconType="pin" />{event.venue.location_formatted_address}
                  </>
                )}
              </div>
              <div className="event-item-card-see-details-container">
                <a href={sanitizeUrl(generateViewEventLink(event))}>{i18n.t("Event Details")}</a>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="event-item-card">
          <div className="event-item-card-body">
            <div className="event-item-card-image-container">
              <img alt={event.title} className="fixed-aspect-ratio-image" src={this.getImageUrl()} />
            </div>
            <div className="event-item-card-details-container">
              <h4>{event.title}</h4>
              <Icon iconType="time" /><span data-attribute="start_time">{startDate.format('dddd, MMM Do - LT')}</span> - <span data-attribute="end_time">{endDate.format('LT')}</span> <span data-attribute="time_zone">{TimezoneAbbr()}</span><br />
              {event.venue ?
                (
                  <>
                    <Icon iconType="pin" />{event.venue.location_formatted_address}
                  </>
                ) : (
                  <>
                    <Icon iconType="account-box" />{i18n.t('Virtual Event')}
                  </>
                )
              }
            </div>
            <div className="event-item-card-see-details-container">
              <a href={sanitizeUrl(generateViewEventLink(event))}>{i18n.t("View Event")} <Icon iconType="arrow-right" /></a>
            </div>
          </div>
        </div>
      );
    }
  }
}
