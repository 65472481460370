import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import {
  FieldGroup,
  Icon,
  MaterialCheckbox
} from 'reactifi';

export default class AdditionalDetailsComponent extends React.Component {
  static propTypes = {
    canPreregister: PropTypes.bool,
    demo: PropTypes.bool,
    event: PropTypes.object,
    forKids: PropTypes.bool,
    isEventManagement: PropTypes.bool,
    isPrivate: PropTypes.bool,
    onChangeDemo: PropTypes.func,
    onChangeForKids: PropTypes.func,
    onChangePreReg: PropTypes.func,
    onChangePrivate: PropTypes.func
  };

  constructor(props) {
    super(props);
  }

  additionalInfoCheckBox = (dataValue, name, text, onChange, tooltipText) => {
    return (
      <FieldGroup>
        <MaterialCheckbox
          id={name}
          className="m-b-10 m-r-5 m-l-0"
          dataValue={dataValue}
          inline={true}
          name={name}
          onChange={onChange}
        >
          {text}
        </MaterialCheckbox>
        <OverlayTrigger
          overlay={<Tooltip>{tooltipText}</Tooltip>}
          placement="top"
        >
          <span><Icon iconType="info-outline" additionalClasses="zmdi-hc-lg text-info" /></span>
        </OverlayTrigger>
      </FieldGroup>
    );
  }

  render() {
    const { demo, isEventManagement, event, forKids, canPreregister, isPrivate, onChangeDemo, onChangeForKids, onChangePrivate, onChangePreReg } = this.props;

    return (
      <FieldGroup {...this.props} event={event}>
        {isEventManagement && this.additionalInfoCheckBox(
          canPreregister,
          "allow_pre_registration",
          i18n.t('Allow pre-registration for this event'),
          onChangePreReg,
          i18n.t('Pre-registration enables you to send emails or other communications to attendees in advance.')
        )}
        {this.additionalInfoCheckBox(
          forKids,
          "for_kids",
          i18n.t('This event is for children'),
          onChangeForKids,
          i18n.t('Check yes if any of your attendees are under 18. In this case, do not collect personal information from anyone.')
        )}
        {this.additionalInfoCheckBox(
          isPrivate,
          "private",
          i18n.t('This is a private event'),
          onChangePrivate,
          i18n.t('Check yes if your event should not be accessible via public search. Private Events will be hidden from attendee search results.')
        )}
        {this.additionalInfoCheckBox(
          demo,
          "demo",
          i18n.t('This is a demo event'),
          onChangeDemo,
          i18n.t('Check yes if this will be used for internal training-the-trainer sessions or similar tests. You may also want to make it a private event (see above)')
        )}
      </FieldGroup>
    );
  }
}
