import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'reactifi';

const OfflineContentItem = ({ offlineContent }) => {
  const { content_type, content_url, name } = offlineContent;

  const disabled = !content_url;

  return (
    <div>
      <Button
        disabled={disabled}
        href={content_url}
        label={name}
        style="link"
        target="_blank"
      />
      {content_type === "guide_medium_tech" && <Icon iconType="projector" fontFamily="engage" additionalClasses="status-icon" />}
      {content_type === "guide_high_tech" && <Icon iconType="computerlab" fontFamily="engage" additionalClasses="status-icon" />}
    </div>
  );
}

OfflineContentItem.propTypes = {
  offlineContent: PropTypes.shape({
    content_type: PropTypes.string,
    content_url: PropTypes.string,
    name: PropTypes.string
  }).isRequired
};

export default OfflineContentItem;
