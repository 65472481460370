import React from 'react';
import { ErrorBoundedProvider } from 'reactifi';
import createStore from './store/eventStore';
import List from './containers/ListContainer';
import Show from './containers/ShowContainer';
import EventWizardContainer from './containers/EventWizardContainer';
import AttendeeRegistration from './containers/AttendeeRegistrationContainer';
import QrCodeContainer from './containers/QrCodeContainer';
import Dashboard from './containers/DashboardContainer';
import { Router, Route, hashHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'

export default (props, _railsContext) => {
  const store = createStore(props);
  const history = syncHistoryWithStore(hashHistory, store);

  const reactComponent = (
    <ErrorBoundedProvider store={store}>
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <Route
          path="/registration"
          component={AttendeeRegistration}
          accountId={props.accountId}
          currentUserId={props.currentUserId}
          eventId={props.eventId}
          viewer={props.viewer}
          eventType={props.eventType}
          from={props.from}
          googleMapsApiKey={_railsContext.googleMapsApiKey}
        />
        <Route
          path="/:id/dashboard"
          component={Dashboard}
          accountId={props.accountId}
          currentUserId={props.currentUserId}
          contentServiceUrl={_railsContext.contentServiceUrl}
          eventId={props.eventId}
          isEventManagement={props.isEventManagement}
          viewer={props.viewer}
        />
        {!props.eventId ?
          <Route path="/"
            accountId={props.accountId}
            component={List}
            currentUserId={props.currentUserId}
            il8n={props.il8n}
            isEventManagement={props.isEventManagement}
            viewer={props.viewer}
          />
          :
          null
        }
        {!props.eventId ?
          <Route
            path="dashboard"
            component={List}
            accountId={props.accountId}
            currentUserId={props.currentUserId}
            il8n={props.il8n}
            viewer={props.viewer}
          />
          :
          null
        }
        <Route path="/event_wizard"
          accountId={props.accountId}
          component={EventWizardContainer}
          currentUserId={props.currentUserId}
          googleMapsApiKey={_railsContext.googleMapsApiKey}
          isEventManagement={props.isEventManagement}
          viewer={props.viewer}
        />
        <Route
          path={props.eventId ? "/(:id)" : "/:id"}
          component={Show}
          account={props.account}
          accountId={props.accountId}
          adminifiUrl={_railsContext.adminifiUrl}
          currentUserId={props.currentUserId}
          evaluFiUrl={_railsContext.evaluFiUrl}
          eventId={props.eventId}
          eventLanguages={props.eventLanguages}
          eventType={props.eventType}
          from={props.from}
          googleMapsApiKey={_railsContext.googleMapsApiKey}
          isAdmin={props.isAdmin}
          isEventManagement={props.isEventManagement}
          subdomain={props.subdomain}
          viewer={props.viewer}
        />
        <Route
          path={props.eventId ? "/(:id)/qr_code" : "/:id/qr_code"}
          component={QrCodeContainer}
        />
      </Router>
    </ErrorBoundedProvider>
  );
  return reactComponent;
};
