import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import {
  MultilingualForm,
  ProgressSpinner,
  Step
} from 'reactifi';
import TopicDetailsComponent from './TopicDetailsComponent';
import MultilingualAssistanceComponent from './MultilingualAssistanceComponent';
import WebinarDetailsComponent from './WebinarDetailsComponent';
import DateTimeComponent from './DateTimeComponent';
import VenueDetailsComponent from './VenueDetailsComponent';
import AdditionalDetailsComponent from './AdditionalDetailsComponent';
import EventVenueForm from '../EventVenueForm';

export default class EventWizardDetails extends Step {
  static propTypes = {
    actions: PropTypes.shape({
      clearMessages: PropTypes.func.isRequired,
      createVenue: PropTypes.func.isRequired,
      createVenuePartner: PropTypes.func.isRequired,
      loadVenue: PropTypes.func.isRequired,
      toggleEditVenue: PropTypes.func.isRequired,
      updateEvent: PropTypes.func.isRequired
    }).isRequired,
    displayErrorMessage: PropTypes.func,
    errorMessage: PropTypes.string,
    event: PropTypes.object,
    eventType: PropTypes.string,
    eventTypes: PropTypes.array,
    googleMapsApiKey: PropTypes.string.isRequired,
    isEventManagement: PropTypes.bool,
    languageOptions: PropTypes.array,
    newEntity: PropTypes.func,
    showEditVenue: PropTypes.bool,
    selectDate: PropTypes.func,
    selectTimezone: PropTypes.func,
    selectVenue: PropTypes.func,
    topics: PropTypes.array,
    venueId: PropTypes.string,
    venues: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.state = {
      activeLanguage: 'en',
      event: props.event
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.event !== state.event) {
      return {
        event: props.event
      }
    } else {
      return null;
    }
  }

  onEventTitleChange = title => {
    this.props.actions.setEventData({ [`title_${this.state.activeLanguage}`]: title });
  }

  onEventDescriptionChange = description => {
    this.props.actions.setEventData({ [`description_${this.state.activeLanguage}`]: description });
  }

  onLanguageChange = language => {
    const activeLanguage = Object.keys(language)[0];
    this.setState({ activeLanguage })
  }

  validateTimes = (start, end) => {
    const { displayErrorMessage } = this.props;
    const [startHour, startMinute] = start.split(':');
    const [endHour, endMinute] = end.split(':');
    const isValid = startHour < endHour || (startHour === endHour && startMinute < endMinute);
    if (!isValid) {
      displayErrorMessage(i18n.t('Start time must be earlier than the end time.'));
    }
    return isValid;
  }

  checkValid = () => {
    const {
      displayErrorMessage,
      event: { start_time, end_time },
      isEventManagement,
      venueId
    } = this.props;

    if (start_time && end_time) {
      if (!this.validateTimes(start_time, end_time)) {
        return false;
      }
    }

    if (!isEventManagement && !venueId) {
      displayErrorMessage(i18n.t("Please select a venue."));
      return false;
    }

    return true;
  }

  goForward = async () => {
    if (this.checkValid()) {
      const savePromise = new Promise((resolve, reject) => {
        this.saveSuccess = resolve;
        this.saveFailed = reject;
      })
      this.setState({ triggerSave: true })
      return savePromise;
    }
  }

  saveComplete = success => {
    this.setState({ triggerSave: false }, () => {
      // successful save will hit storeEventData below
      if (!success) {
        this.saveFailed(false);
      }
    });
  }

  storeEventData = eventData => {
    this.props.actions.setEventData(eventData);
    this.saveSuccess(true);
  }

  render() {
    const {
      actions,
      errorMessage,
      eventType,
      googleMapsApiKey,
      isEventManagement,
      newEntity,
      selectVenue,
      showEditVenue,
      venues
    } = this.props;
    const { activeLanguage, event } = this.state;
    const availableLanguages = [{ en: 'English' }, { es: 'Spanish' }];

    const displayWebinarComponent = isEventManagement && ["Events::InPersonAndWebinar", "Events::Webinar"].includes(eventType);
    const displayVenueComponent = !isEventManagement || ["Events::InPersonAndWebinar", "Events::InPerson"].includes(eventType);

    return (
      <div>
        <div className="form-well p-20">
          <h4>{i18n.t('Event Details')}</h4>
          {!event ? <ProgressSpinner /> :
            <MultilingualForm
              activeLanguage={activeLanguage}
              addAction={this.storeEventData}
              cancelButton={false}
              data={event}
              languages={availableLanguages}
              onLanguageChange={this.onLanguageChange}
              saveButton={false}
              saveComplete={this.saveComplete}
              triggerSave={this.state.triggerSave}
            >
              <TopicDetailsComponent
                activeLanguage={activeLanguage}
                availableLanguages={availableLanguages}
                onEventDescriptionChange={this.onEventDescriptionChange}
                onEventTitleChange={this.onEventTitleChange}
                {...this.props}
              />
              <MultilingualAssistanceComponent {...this.props} />
              {displayWebinarComponent &&
                <WebinarDetailsComponent
                  activeLanguage={activeLanguage}
                  availableLanguages={availableLanguages}
                  {...this.props}
                />
              }
              <DateTimeComponent {...this.props} />
              <AdditionalDetailsComponent {...this.props} />
            </MultilingualForm>
          }
        </div>
        {displayVenueComponent &&
          <VenueDetailsComponent {...this.props} />
        }

        <EventVenueForm
          clearMessages={actions.clearMessages}
          createVenue={actions.createVenue}
          createVenuePartner={actions.createVenuePartner}
          errorMessage={errorMessage}
          event={showEditVenue ? event : null}
          googleMapsApiKey={googleMapsApiKey}
          isCreateEvent={true}
          isEventManagement={isEventManagement}
          loadVenue={actions.loadVenue}
          newEntity={newEntity}
          toggleEditVenue={actions.toggleEditVenue}
          updateEvent={selectVenue}
          venues={venues}
        />
      </div>
    );
  }
}
