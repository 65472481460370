import PropTypes from 'prop-types';
import React from 'react';
import { ModalForm, FieldGroup, FloatingLabelFormField, SelectControl, SelectAsyncControl } from 'reactifi';
import * as topicsActionCreators from '../actions/topicsActionCreators'
import { bindActionCreators } from 'redux';
import i18n from 'lib/i18n';

export default class TopicForm extends React.Component {
  static propTypes = {
    currentTopic: PropTypes.object,
    errorMessage: PropTypes.string,
    selectTopic: PropTypes.func.isRequired,
    createTopic: PropTypes.func.isRequired,
    clearMessages: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    show: PropTypes.bool,
    accounts: PropTypes.array,
    everfiContents: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(topicsActionCreators, this.props.dispatch);
    this.state = {
      files: []
    }
    this.onCancel = this.onCancel.bind(this);
    this.updateTopic = this.updateTopic.bind(this);
    this.storeFileForUpload = this.storeFileForUpload.bind(this);
  }

  onCancel() {
    this.props.selectTopic(null);
    this.props.clearMessages();
  }

  storeFileForUpload(files){
    this.setState({
      files: files
    })
  }

  updateTopic(currentTopic){
    this.actions.updateTopic(currentTopic)
  }

  render() {
    let title = this.props.currentTopic && this.props.currentTopic.id ? i18n.t('editNumber', { postProcess: 'sprintf', sprintf: [this.props.currentTopic.name] }) : i18n.t('Add a New Topic');
    let event_types = this.props.currentTopic && this.props.currentTopic.id ? this.props.currentTopic.event_types : null ;

    return (
      <ModalForm data={this.props.currentTopic} title={title} errorMessage={this.props.errorMessage}
        onCancel={this.onCancel} addAction={this.props.createTopic} updateAction={this.updateTopic} show={this.props.show}>
        <FieldGroup className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h4>{i18n.t('Topic Details')}</h4>
            </div>
          </div>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField name="name" caption={i18n.t("Name")} required={true} />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField name="description" caption={i18n.t("Topic Description")} required={true} />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField name="account_id" caption={i18n.t("Select Account")} required={true}>
                <SelectControl options={this.props.accounts} valueKey="id" labelKey="name" placeholder="" />
              </FloatingLabelFormField>
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField name="event_types" caption={i18n.t("Event Type")} required={true} multi={true} >
                <SelectAsyncControl
                  multi={true}
                  placeholder=""
                  valueKey="event_types"
                  filterField="event_types"
                  initialData = {event_types}
                  dataUrl={"/api/data/events/types.json"}
                  displayProperty="label"
                  sortBy="label"
                  searchPromptText={i18n.t("Select Event Type:")}
                  scrollMenuIntoView={true} />
              </FloatingLabelFormField>
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField name="everfi_content_ids" caption={i18n.t("Select Content")} required={true}>
                <SelectControl options={this.props.everfiContents} valueKey="id" labelKey="name" placeholder="" multi={true} />
              </FloatingLabelFormField>
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <h4>{i18n.t('Uploaded Files')}</h4>
              <ul>
                {this.props.currentTopic && this.props.currentTopic.offline_contents ? this.props.currentTopic.offline_contents.map((file, i) => {
                  return <li key={file.id}>{file.name}</li>
                }) : null}
              </ul>
            </FieldGroup>
          </FieldGroup>
        </FieldGroup>
      </ModalForm>)
  }
}
