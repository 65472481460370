import React from 'react';
import { ErrorBoundedProvider } from 'reactifi';
import createStore from './store/customerAdminStore';
import CustomerAdminContainer from './containers/CustomerAdminContainer';
import { syncHistoryWithStore } from 'react-router-redux';
import { Router, Route, hashHistory } from 'react-router';

export default (props, _railsContext) => {
  const store = createStore(props);
  const history = syncHistoryWithStore(hashHistory, store);
  const reactComponent = (
    <ErrorBoundedProvider store={store}>
      <Router history={history}>
        <Route 
          path="/" 
          component={CustomerAdminContainer} 
          accountId={props.accountId} 
          isEventManagement={props.isEventManagement}
        />
      </Router>
    </ErrorBoundedProvider>
  );
  return reactComponent;
};
