import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import moment from 'moment';
import { CopyUtil, Icon, Storage } from 'reactifi';
import LetterCircleSection from './LetterCircleContentSectionComponent';
import LetterCircleComponent from './LetterCircleContentComponent';
import PreregListContainer from '../../containers/PreregListContainer';
import GenerateAttendeeViewEventLink from '../../../AttendeeEvent/components/GenerateAttendeeViewEventLink';
import OfflineContentSection from './OfflineContentSectionComponent';
import LogisticsCheckbox from './LogisticsCheckboxComponent';

const copyUrl = (url, props) => {
  const { displayErrorMessage, displaySuccessMessage } = props;
  const success = CopyUtil.copyText(url);
  if (success) {
    displaySuccessMessage(i18n.t('Copied!'));
  } else {
    displayErrorMessage(i18n.t('Unable to copy'));
  }
}

const generateWorkshopDashboardLink = (event, viewer) => {
  const locale = Storage.getItem('i18nextLng') || 'en'
  return `/${locale}/events/${event.code}#/${event.id}/dashboard?viewer=${viewer}`;
};

const BeforeYourEventTabContent = props => {
  const {
    dataObjects,
    event,
    everfiContents,
    isEventManagement,
    isWebinar,
    viewer
  } = props;

  const displayPromoteEvent = () => {
    const evertUrl = window.location.host + GenerateAttendeeViewEventLink(event)
    return (
      <LetterCircleComponent
        {...dataObjects('promote_this_event')}
        header={i18n.t('Promote This Event')}>
        <ul>
          <li>{isWebinar ? i18n.t('Share this link with your partner, social media, and more.') : i18n.t('Share this link with your partner, venue, social media, and more.')}</li>
          <li>
            <a
              data-object-type="events"
              data-object-id={event.id}
              data-action="open-promote-url-before-event"
              href={GenerateAttendeeViewEventLink(event)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {evertUrl}
            </a>
            <a
              className="m-l-10"
              onClick={() => copyUrl(`${window.location.origin + GenerateAttendeeViewEventLink(event)}`, props)}
            >
              <Icon iconType="copy" />
            </a>
          </li>
        </ul>
      </LetterCircleComponent>
    );
  }

  const displayDownloadAndPrint = () => {
    if (event.topic && event.event_type !== "Events::OtherCommunityService" && (event.topic.languages && event.topic.languages.length > 0)) {
      return (
        <LetterCircleComponent
          {...dataObjects('download_materials')}
          header={i18n.t('Download and print/copy all relevant materials for the session you are facilitating')}
        >
          <OfflineContentSection languages={event.topic.languages}
            offlineContents={event.topic.offline_contents}
            everfiContents={everfiContents} />
        </LetterCircleComponent>
      )
    }
  }

  const displayWorkshopDashboard = () => {
    const workshopDashboardLink = generateWorkshopDashboardLink(event, viewer)

    return (
      <LetterCircleComponent
        {...dataObjects('access_workshop_link')}
        header={i18n.t('Select link below to access the online workshop content')}
      >
        <p>
          <b>{i18n.t('Link: ')}</b>
          <a
            data-object-type="events"
            data-object-id={event.id}
            data-action="visit-link"
            href={workshopDashboardLink}
          >
            {i18n.t('Workshop Dashboard')}
          </a>
          <a
            className="m-l-10"
            onClick={() => copyUrl(`${window.location.origin + workshopDashboardLink}`, props)}
          >
            <Icon iconType="copy" />
          </a>
        </p>
      </LetterCircleComponent>
    );
  }

  const displayEventDetails = () => {

    const venue = event.venue;
    const venueDisplay = venue ? `${venue.location_street_number} ${venue.location_route} ${venue.location_locality}, ` +
      `${venue.location_administrative_area_level_1} ${venue.location_postal_code}` : '';
    const timeZone = new Date().toString().match(/\(([A-Za-z\s\d+-]+)\)/)[1];

    return (
      <LetterCircleComponent
        {...dataObjects('event_details')}
        header={i18n.t('Make sure your event details are correct and invite more volunteers if needed')}
      >
        <b>{i18n.t('Your event details')}</b><br />
        {i18n.t('Date:')} {moment(event.start_time).format('LL')}<br />
        {i18n.t('Time:')} {moment(event.start_time).format('LT')} - {moment(event.end_time).format('LT')} {timeZone}<br />
        {i18n.t('Venue:')} {venueDisplay}<br />
        {event.partner_id ? <div>{i18n.t('Partner:')}  {event.partner_contact_name} | {event.partner_contact_email} | {event.partner_contact_phone}</div> : null}
        {i18n.t('Course Content:')} {event.topic ? event.topic.languageDisplay : null}<br />
        {i18n.t('Multilingual Assistance:')}  {event.languageDisplay}
      </LetterCircleComponent>
    );
  }

  const displayLogistics = () => {
    return (
      <LetterCircleComponent
        {...dataObjects('location_logistics')}
        header={i18n.t('Determine logistics for the event location')}>
        <ul>
          <li>{i18n.t('Will you be presenting in a no tech or low tech setting? If so, it is important to come with enough hard copies of all materials.')}</li>
          <li>{i18n.t('Or are you presenting in a high tech setting? If so, how many computers will be available? Is the internet connection strong? What display hardware options are available?')}</li>
          <li>{i18n.t('How many attendees do you expect?')}</li>
          <li>{i18n.t('Touch base with fellow volunteers and make sure they join event through this portal')}</li>
          <li>{i18n.t('Make sure that you have everything:')}
            <div className="col-lg-12">
              <LogisticsCheckbox eventId={event.id} dataAction="edit-logistics-print-outs" label={i18n.t('Print outs')} />
              <LogisticsCheckbox eventId={event.id} dataAction="edit-logistics-computer" label={i18n.t('Computer (if applicable)')} />
              <LogisticsCheckbox eventId={event.id} dataAction="edit-logistics-phone" label={i18n.t('Phone (if applicable)')} />
              <LogisticsCheckbox eventId={event.id} dataAction="edit-logistics-camera" label={i18n.t('Camera (if applicable)')} />
              <LogisticsCheckbox eventId={event.id} dataAction="edit-logistics-contact-info" label={i18n.t('Contact info (if applicable)')} />
            </div>
          </li>
        </ul>
      </LetterCircleComponent>
    );
  }

  const displayPreregistered = () => {
    return (
      <LetterCircleComponent
        {...dataObjects('pre_registered_list')}
        header={i18n.t('See who has pre-registered')}>
        <PreregListContainer event={event} />
      </LetterCircleComponent>);
  }

  return (
    <LetterCircleSection header={i18n.t('Preparing for your Event')}>
      {displayPromoteEvent()}
      {displayDownloadAndPrint()}
      {displayWorkshopDashboard()}
      {!isEventManagement && (
        <>
          {displayEventDetails()}
          {displayLogistics()}
        </>
      )}
      {isEventManagement && displayPreregistered()}
    </LetterCircleSection>
  );
};

BeforeYourEventTabContent.propTypes = {
  dataObjects: PropTypes.func,
  displayErrorMessage: PropTypes.func,
  displaySuccessMessage: PropTypes.func,
  event: PropTypes.object,
  everfiContents: PropTypes.array,
  isEventManagement: PropTypes.bool,
  isWebinar: PropTypes.bool,
  viewer: PropTypes.string
};

export default BeforeYourEventTabContent;
