import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment-timezone';
import {
  FieldGroup,
  ModalForm,
  DateTimePickerControl,
  FloatingLabelFormField,
  RegenerateDateWithTZ,
  ExtractTimeFromDatetime,
  TimezoneControl,
  TimePickerControl
} from 'reactifi';
import i18n from 'lib/i18n';

export default class EventDateTimeForm extends React.Component {
  static propTypes = {
    event: PropTypes.object,
    errorMessage: PropTypes.string,
    toggleEditDateTime: PropTypes.func.isRequired,
    updateEvent: PropTypes.func.isRequired,
    clearMessages: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.event) {
      let event = {
        ...nextProps.event,
        date: nextProps.event.start_time
      };
      const timeZone = nextProps.event.time_zone || 'America/New_York';
      event.start_time = ExtractTimeFromDatetime(moment(nextProps.event.start_time).tz(timeZone).format());
      event.end_time = ExtractTimeFromDatetime(moment(nextProps.event.end_time).tz(timeZone).format());
      this.setState({ event: event });
      this.setRestrictions(moment(nextProps.event.start_time));
    } else {
      this.setState({ minDate: null, event: null });
    }
  }

  onCancel = () => {
    this.props.toggleEditDateTime(false);
    this.props.clearMessages();
  }

  onUpdate = (event) => {
    event.start_time = RegenerateDateWithTZ(event.date, event.start_time, event.time_zone);
    event.end_time = RegenerateDateWithTZ(event.date, event.end_time, event.time_zone);
    delete event.partner_website; // the BE chokes with this on the event. Told to remove it.
    this.props.updateEvent(event, null, this.props.toggleEditDateTime);
  }

  onStartDateChange = (startDate) => {
    if (startDate) {
      this.setRestrictions(startDate);
    }
  }

  setRestrictions = (startDate) => {
    this.setState({ minDate: new Date(moment(startDate).add(1, 'hour')) });
  }


  render() {
    let disableDates = this.props.event && this.props.event.status === "completed";
    return (
      <ModalForm
        cancelButtonText={i18n.t('Cancel')}
        className="date-time-modal"
        data={this.state.event}
        errorMessage={this.props.errorMessage}
        onCancel={this.onCancel}
        saveButtonText={i18n.t('Save')}
        title={i18n.t('Date and Time')}
        updateAction={this.onUpdate}
      >
        <FieldGroup className="row">
          <FieldGroup className="col-lg-6 col-xl-6 col-12">
            <FloatingLabelFormField name="date" caption={i18n.t("Date")} required={true} className="icon-label">
              <DateTimePickerControl timeFormat={false} />
            </FloatingLabelFormField>
          </FieldGroup>
          <FieldGroup className="col-lg-6 col-xl-6 col-12">
            <FloatingLabelFormField name="time_zone" caption={i18n.t("Time Zone")} required={true} className="icon-label end-time-constrained" >
              <TimezoneControl />
            </FloatingLabelFormField>
          </FieldGroup>
        </FieldGroup>
        <FieldGroup className="row">
          <FieldGroup className="col-lg-6 col-xl-6 col-12">
            <FloatingLabelFormField populatedOverride={true} name="start_time" caption={i18n.t("Start Time")} required={true} className="icon-label">
              <TimePickerControl onChange={this.onStartDateChange} disablePicker={disableDates} timeFormat={true} />
            </FloatingLabelFormField>
          </FieldGroup>
          <FieldGroup className="col-lg-6 col-xl-6 col-12">
            <FloatingLabelFormField populatedOverride={true} name="end_time" caption={i18n.t("End Time")} required={true} className="icon-label end-time-constrained" >
              <TimePickerControl minDate={this.state.minDate} disablePicker={disableDates} timeFormat={true} />
            </FloatingLabelFormField>
          </FieldGroup>
        </FieldGroup>
      </ModalForm>)
  }
}
