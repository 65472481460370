import PropTypes from 'prop-types';
import React from 'react';
import { FieldGroup, ModalForm, SelectControl, FloatingLabelFormField, RadioGroupControl } from 'reactifi';

import i18n from 'lib/i18n';

export default class EventTypeForm extends React.Component {
  static propTypes = {
    event: PropTypes.object,
    errorMessage: PropTypes.string,
    toggleEditEventType: PropTypes.func.isRequired,
    updateEvent: PropTypes.func.isRequired,
    clearMessages: PropTypes.func.isRequired,
    languages: PropTypes.array,
    topics: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.state = {};
  }

  onCancel() {
    this.props.toggleEditEventType(false);
    this.props.clearMessages();
  }

  onUpdate(event){
    this.props.updateEvent(event, "", this.props.toggleEditEventType);
  }

  render() {
    let languages = [{ en: 'English' }, { es: 'Spanish' }];
    let eventType = this.props.event ? this.props.event.event_type : null;
    return (
      <ModalForm data={this.props.event} errorMessage={this.props.errorMessage} title="Event Details"
        onCancel={this.onCancel} updateAction={this.onUpdate} languages={languages} className="event-type">
        <FieldGroup className="container-fluid">
          <FieldGroup className="row">
            {eventType === "Events::FinancialEducation" ?
              <FieldGroup className="col-12">
                <FloatingLabelFormField name="topic_id" caption={i18n.t("Topic")} required={true} >
                  <SelectControl options={this.props.topics} valueKey="id" labelKey="name" placeholder="" />
                </FloatingLabelFormField>
              </FieldGroup> : null}
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField name="languages" caption={i18n.t("Multilingual Support")} required={true} >
                <SelectControl options={this.props.languages} multi={true} valueKey="value" labelKey="label" placeholder="" />
              </FloatingLabelFormField>
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField name="title" caption={i18n.t("Title")} required={true} multilingual={true} />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField name="description" componentClass="textarea" caption={i18n.t("Description")} multilingual={true} />
            </FieldGroup>
          </FieldGroup>

          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <RadioGroupControl valueList={[{ value: "true", label: "Yes" }, { value: "false", label: "No" }]}
                name="for_kids"
                valueKey="value"
                labelKey="label"
                dataValue={this.props.event && this.props.event.for_kids}
                controlLabelText={i18n.t("Is this event for children?")}
                tooltip={i18n.t("Check yes if any of your attendees are under 18. In this case, do not collect personal information from anyone.")}
                inline={true}
              />
            </FieldGroup>
          </FieldGroup>

          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <RadioGroupControl valueList={[{ value: "true", label: "Yes" }, { value: "false", label: "No" }]}
                name="private"
                valueKey="value"
                labelKey="label"
                dataValue={this.props.event && this.props.event.private}
                controlLabelText={i18n.t("Is this a private event?")}
                tooltip={i18n.t("Check yes if your event should not be accessible via public search. Private Events will be hidden from attendee search results.")}
                inline={true}
              />
            </FieldGroup>
          </FieldGroup>

          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <RadioGroupControl valueList={[{ value: "true", label: "Yes" }, { value: "false", label: "No" }]}
                name="demo"
                valueKey="value"
                labelKey="label"
                dataValue={this.props.event && this.props.event.demo}
                controlLabelText={i18n.t("Is this a demo event?")}
                tooltip={i18n.t("Check yes if this will be used for internal training-the-trainer sessions or similar tests. You may also want to make it a private event (see above)")}
                inline={true}
              />
            </FieldGroup>
          </FieldGroup>
        </FieldGroup>
      </ModalForm>
    )
  }
}
