import $ from 'jquery';

$(function () {
  var getCurrentTimeZone = function () {
    var date = (new Date()).toString();
    return date.slice(date.indexOf('('));
  };

  var getCurrentTimeZoneOffsetHours = function () {
    var minutesPerHour = 60;
    return (new Date()).getTimezoneOffset() / minutesPerHour;
  };

  $(".current-time-zone").each(function () {
    if (window.location.pathname.split('/')[1] === 'en') {
      $(this).text(getCurrentTimeZone());
    } else {
      $(this).text('(UTC ' + -getCurrentTimeZoneOffsetHours() + ')');
    }
  });

  $(".time-zone-offset").each(function () {
    $(this).val(getCurrentTimeZoneOffsetHours());
  });

  setTimeout(function () {
    $(".flash.flash-autohide").addClass('fully-hide');
    $(".modal.autohide").modal('hide')
  }, 2000);

  $('[data-toggle="tooltip"]').tooltip();
  $('.anchor-row').click(function () {
    window.document.location = $(this).data("href")
  })

  $(".modal-show").modal();

  $(".edit_event #event_co_sponsored").click(function (e) {
    if ($(this).prop('checked') === false) {
      var checkbox = this;
      $(this).closest('form').find('input[type="text"], textarea').each(function () {
        if (this !== checkbox) {
          $(this).prop('disabled', true);
          $(this).addClass('disabled');
          $(this).closest('.form-group').addClass('disabled');
        }
      });
      $(this).next().text('Partner data below will be erased upon save');
    }
    else {
      $(this).closest('form').find('input, textarea').each(function () {
        $(this).prop('disabled', false);
        $(this).removeClass('disabled');
        $(this).closest('.form-group').removeClass('disabled');
      });
      $(this).next().text('');
    }
  });
});

$(document).ready(function ($) {
  $(window).on('load', function () {
    $('body').on('click', 'footer a.dropdown-toggle', function (e) {
      $(e.currentTarget).parent().toggleClass('expanded');
    });
  });
});


