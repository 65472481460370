import React from 'react';
import { ErrorBoundedProvider } from 'reactifi';
import createStore from './store/workLocationStore';
import WorkLocationContainer from './containers/WorkLocationContainer';

export default (props, _railsContext) => {
  const store = createStore(props);
  const reactComponent = (
    <ErrorBoundedProvider store={store}>
      <WorkLocationContainer googleMapsApiKey={_railsContext.googleMapsApiKey}/>
    </ErrorBoundedProvider>
  );
  return reactComponent;
};
