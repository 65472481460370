import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { buildGenericProps, AlertMessage, AlertMessageTypes, ResponsiveDataList, ConfirmationModal, JsonApiDecorator, Icon } from 'reactifi';
import * as topicsActionCreators from '../actions/topicsActionCreators';
import TopicsForm from '../components/TopicsForm';
import OfflineContentForm from '../components/OfflineContentForm';
import i18n from 'lib/i18n';


function mapStateToProps(state, ownProps) {
  let props = buildGenericProps(state, 'topics', ['offline_contents', 'events', 'course_modules']);
  let apiStore = new JsonApiDecorator(state.api);

  if(state.api.currentEntityId && state.api.currentEntityType === 'topics'){
    props.currentTopic = props.currentEntity;
  } else if(state.api.currentEntityId === 'add' && state.api.currentEntityType === 'offline_contents'){
    props.currentOfflineContent = apiStore.newObject('offline_contents');
    props.currentOfflineContent.topic_id = state.topics.currentTopicId;
  }
  props.uploadInProgress = state.topics.uploadInProgress;
  if (apiStore.accounts) {
    props.accounts = apiStore.accounts.all();
  }
  if (apiStore.everfi_contents) {
    props.everfi_contents = apiStore.everfi_contents.all();
  }
  return props;
}

class TopicsContainer extends React.Component {
  static propTypes = {
    topics: PropTypes.array,
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    showAlertMessage: PropTypes.bool,
    successMessage: PropTypes.string,
    currentTopic: PropTypes.object,
    createOfflineContent: PropTypes.func,
    currentOfflineContent: PropTypes.object,
    uploadInProgress: PropTypes.bool,
    isLoadingTopics: PropTypes.bool,
    accounts: PropTypes.array,
    everfi_contents: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.state = {
      deleteTopicData: null
    }
    this.actions = bindActionCreators(topicsActionCreators, this.props.dispatch);
    this.deleteTopic = this.deleteTopic.bind(this);
    this.onCancelDeleteTopic = this.onCancelDeleteTopic.bind(this);
    this.onConfirmDeleteTopic = this.onConfirmDeleteTopic.bind(this);
    this.addContent = this.addContent.bind(this);
  }

  componentWillMount() {
    this.actions.loadAccounts();
    this.actions.loadEverfiContents();
  }

  deleteTopic(topic) {
    this.setState({ deleteTopicData: topic });
  }

  onCancelDeleteTopic() {
    this.setState({ deleteTopicData: null });
  }

  onConfirmDeleteTopic(topic) {
    this.actions.deleteTopic(topic);
    this.setState({ deleteTopicData: null });
  }

  addContent(topicId){
    this.actions.setTopic(topicId);
    this.actions.selectContent('add');
  }

  getCurrentTopic() {
    if (this.props.currentOfflineContent) {
      return this.props.topics.find(topic => topic.id === this.props.currentOfflineContent.topic_id);
    }
  }

  render()  {
    let fields = [
      { name: 'id', title: i18n.t('Name'), canSort: true, formatter: (cell, row) => {
        return (
          <span className="action-icon-floatleft">
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-edit">{i18n.t('Edit Topic')}</Tooltip>}>
              <a data-id={row.id} onClick={(e) => this.actions.selectTopic(row.id)}><Icon iconType="edit" /> {row.name}</a>
            </OverlayTrigger>
          </span>)
      }, mobileFormatter: (cell, row) => `${row.name}` },
      { name: 'description', title: i18n.t('Description'), canSort: true, formatter: (cell, row) => {
        if(row.description && row.description.length >= 150){
          let substring = row.description.substring(0, 150);
          substring += "..."
          return substring;
        }
        return row.description}
      },
      { name: 'course_modules', title: i18n.t('Course Modules'), columnOptions: { width: '120' }, canSort: true, formatter: (cell, row) => row.course_modules.length },
      { name: 'offline_content', title: i18n.t('Offline Content'),  canSort: false, formatter: (cell, row) => {
        return (<div>
          <ul>{row.offline_contents.map((content, i) => {
            return (<li key={i}>
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-offline-content-name" style={{ width:'auto' }}>{content.name}</Tooltip>}>
                <a href={content.content_url}>{content.name}</a>
              </OverlayTrigger>
              <a onClick={(e) => this.deleteTopic(content)}>
                <Icon iconType="delete" />
              </a>
            </li>)
          })}
          </ul>
          <a onClick={(e) => this.addContent(row.id)}>{i18n.t('Add File')}</a>
        </div>)
      }
      },
      { name: 'delete_topic', title: i18n.t('Actions'), canSort: false, columnOptions: { width: '100' }, formatter: (cell, row) => {
        return <span className="action-icon-floatright">{i18n.t('Delete Topic:')} <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-delete">{i18n.t('Delete Topic')}</Tooltip>}>
          <a data-id={row.id} onClick={(e) => this.deleteTopic(row)}><Icon iconType="delete" /></a>
        </OverlayTrigger></span>
      } }
    ];

    const tableOptions = {
      bordered: false,
      hover: true,
      striped: false
    };

    return (
      <div className="card_wrapper">
        <div className="clearfix section-heading">
          <h1 className="section-top-header">{i18n.t('Topics List')}</h1>
          <Button bsStyle="primary" onClick={(e) => this.actions.selectTopic('add')}>{i18n.t('Create New')}</Button>
        </div>
        <AlertMessage message={this.props.errorMessage} messageType={AlertMessageTypes.danger} visible={this.props.errorMessage && this.props.showAlertMessage} clearMessages={this.actions.clearMessages}/>
        <AlertMessage message={this.props.successMessage} messageType={AlertMessageTypes.success} visible={this.props.successMessage && this.props.showAlertMessage} clearMessages={this.actions.clearMessages}/>
        <div className="card">
          <div className="card-body">
            <TopicsForm currentTopic={this.props.currentTopic}
              clearMessages={this.actions.clearMessages}
              updateTopic={this.actions.updateTopic}
              selectTopic={this.actions.selectTopic}
              createTopic={this.actions.createTopic}
              dispatch={this.props.dispatch}
              accounts={this.props.accounts}
              everfiContents={this.props.everfi_contents}
              createOfflineContent={this.props.createOfflineContent}/>
            <div className="container">
              <div className="row data-options">
                <div className="col-lg-6 text-left">{i18n.t('numResultsFound', { postProcess: 'sprintf', sprintf: [this.props.topics.length] })}</div>
              </div>
            </div>
            <ResponsiveDataList data={this.props.topics} fields={fields} options={tableOptions} keyField="id" actions={this.actions} isLoadingData={this.props.isLoadingTopics} />
          </div>
        </div>
        <OfflineContentForm currentContent={this.props.currentOfflineContent} currentTopic={this.getCurrentTopic()} everfiContents={this.props.everfi_contents} uploadFiles={this.actions.createContent} selectContent={this.actions.selectContent} clearMessages={this.actions.clearMessages} dispatch={this.props.dispatch} uploadInProgress={this.props.uploadInProgress} />
        <ConfirmationModal title="Delete Item" data={this.state.deleteTopicData} canClickOff={true} onCancel={this.onCancelDeleteTopic} onConfirm={this.onConfirmDeleteTopic} confirmButtonText={i18n.t("Delete Item")}>
          <div>{i18n.t('Are you sure you would like to delete this item?')}</div>
        </ConfirmationModal>
      </div>
    );
  }
}

export default connect(mapStateToProps)(TopicsContainer);
