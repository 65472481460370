import reducers from '../reducers';
import { genericStoreSetup } from 'reactifi';
import i18n from 'lib/i18n';
import { setHeader } from 'redux-json-api'

export default props => {
  const initialState = {};
  const store = genericStoreSetup(reducers, initialState);
  store.dispatch(setHeader({ "Accept-Language": i18n.language }));

  return store;
};
