import { readEntity, selectEntity, createEntity, updateEntity, clearAndRead } from 'reactifi';
export { clearMessages, displayError } from 'reactifi';
import i18n from 'lib/i18n';

export function loadOrganizations(filters, page) {
  return readEntity('accounts', '', filters, null, page);
}

export function selectOrganization(accountId){
  return selectEntity(accountId, 'accounts', 'account_admins');
}

export function updateOrganization(organization, isEnableUpdate){
  if(isEnableUpdate){
    return updateEntity(organization, (dispatch) => {
      dispatch(readEntity('accounts', ['account_admins', 'topics']));
    });
  } else {
    return updateEntity(organization, (dispatch)=>{
      dispatch(selectEntity(null, 'accounts'));
    }, i18n.t("Organization updated successfully"));
  }
}

export function createOrganization(organization, addAnother, page){
  return createEntity(organization, async (dispatch, getState, entity) => {
    let url = `accounts.json?&include=account_admins,topics&page[number]=${page}`;
    await clearAndRead(dispatch, getState, 'accounts', url);
    dispatch(selectEntity(null, 'accounts'));
    setTimeout(() => {
      dispatch(readEntity('topics'));
    }, 2000);
    if (addAnother) {
      dispatch(selectEntity('add', 'accounts'));
    }
  }, null, null, true);
}

export function findOrganizations(filters){
  return readEntity('accounts', '', filters);
}
