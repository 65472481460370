import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListView } from 'reactifi';
import i18n from 'lib/i18n';

import ContentForm from './ContentForm'

export default class ContentList extends Component {

  static propTypes = {
    clearMessages: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string,
    everfi_contents: PropTypes.array,
    currentContent: PropTypes.object,
    selectContent: PropTypes.func,
    updateContent: PropTypes.func,
    createContent: PropTypes.func,
    contentServiceUrl: PropTypes.string,
    loadContent: PropTypes.func,
    everfi_contentsMeta: PropTypes.shape({
      total_count: PropTypes.number
    }),
    isLoadingData: PropTypes.bool,
    jwtToken: PropTypes.string,
    readContentServiceContentById: PropTypes.func
  }

  disableEverfiContentToggle = (content) => {
    content.enabled = !content.enabled;
    this.props.updateContent(content, true);
  }

  render() {
    let fields = [
      { name: 'id', defaultSort: true, title: i18n.t('Name'), formatter: (cell, row) => row.name },
      {
        name: 'description',
        title: i18n.t('Description'),
        formatter: (cell, row) => row.description ? row.description : ''
      }
    ];

    const actions = [
      {
        icon: 'edit',
        title: i18n.t('Edit'),
        tooltip: i18n.t('Edit Content'),
        type: 'view',
        actionType: 'callToAction',
        action: (e, row) => {
          e.preventDefault();
          e.stopPropagation();
          this.props.selectContent(row.id);
        }
      },
      {
        icon: "block",
        title: i18n.t('Disable'),
        tooltip: i18n.t('Disable Content'),
        type: "view",
        filter: row => row.enabled,
        actionType: "callToAction",
        action: (e, row) => {
          e.preventDefault();
          e.stopPropagation();
          this.disableEverfiContentToggle(row);
        }
      },
      {
        icon: "replay",
        title: i18n.t('Enable'),
        tooltip: i18n.t('Enable Content'),
        type: "view",
        filter: row => !row.enabled,
        actionType: "callToAction",
        action: (e, row) => {
          e.preventDefault();
          e.stopPropagation();
          this.disableEverfiContentToggle(row);
        }
      }
    ];

    let actionButton = {
      label: i18n.t('Create New'),
      action: (e) => this.props.selectContent('add')
    };

    return (
      <React.Fragment>
        <ListView
          loadAction={this.props.loadContent}
          data={this.props.everfi_contents}
          fields={fields}
          actions={actions}
          actionButton={actionButton}
          isLoadingData={this.props.isLoadingData}
          totalCount={this.props.everfi_contentsMeta ? this.props.everfi_contentsMeta.total_count : 0}
          title={i18n.t('EverFi Content List')}
          errorMessage={this.props.currentContent ? null : this.props.errorMessage}
          successMessage={this.props.currentContent ? null : this.props.successMessage}
          clearMessages={this.props.clearMessages}
          usePageWrapper={true}
        />
        {this.props.currentContent &&
          <ContentForm currentContent={this.props.currentContent} jwtToken={this.props.jwtToken}
            selectContent={this.props.selectContent} contentServiceUrl={this.props.contentServiceUrl}
            updateContent={this.props.updateContent} createContent={this.props.createContent}
            clearMessages={this.props.clearMessages} errorMessage={this.props.errorMessage}
            readContentServiceContentById={this.props.readContentServiceContentById} />
        }
      </React.Fragment>
    )
  }
}
