import i18n from 'lib/i18n';

export default {
  mapObjToOptions(obj) {
    return Object.keys(obj).map((k) => {
      return { label: k, value: obj[k] };
    });
  },
  translatedMapObjToOptions(obj){
    return Object.keys(obj).map((k) => {
      return { label: i18n.t(k), value: i18n.t(obj[k]) }
    });
  },
  toLargeNumber(input){
    let curr = input.toString();
    let no = input.toString();

    if (input >= 1000000000) {
      let bigNo = (input / 1000000000).toFixed(1);
      return curr.replace(no, bigNo + 'B');
    }
    if (input >= 1000000) {
      let bigNo = (input / 1000000).toFixed(1);
      return curr.replace(no, bigNo + 'M');
    }
    if (input >= 1000) {
      let bigNo = (input / 1000).toFixed(1);
      return curr.replace(no, bigNo + 'K');
    }
    return no;
  }
};
