import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BrowserUpdate, JsonApiDecorator } from 'reactifi';
import * as contentActionCreators from '../actions/contentActionCreators';
import ContentApp from '../../../externals/content-player';
import { sanitizeUrl } from '@braintree/sanitize-url';

function mapStateToProps(state, ownProps) {
  let props = {
    contentServiceUrl: ownProps.route.contentServiceUrl,
    progress_id: ownProps.route.progress_id,
    event_id: ownProps.route.event_id || '',
    viewer: ownProps.route.viewer,
    eventCode: ownProps.route.eventCode,
    locale: ownProps.route.locale,
    primaryColor: ownProps.route.primaryColor,
    secondaryColor: ownProps.route.secondaryColor,
    iframed: ownProps.route.iframed,
  };

  let apiStore = new JsonApiDecorator(state.api);
  let currentUser = '';

  if (apiStore.attendees) {
    currentUser = apiStore.attendees.first();
  }

  if (apiStore.event_users) {
    currentUser = apiStore.event_users.first();
  }

  if (currentUser) {
    props.progress = currentUser.progress_ids.find(progress => progress.progress_id === props.progress_id);
  }

  return props;
}

class ContentContainer extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    contentServiceUrl: PropTypes.string.isRequired,
    viewer: PropTypes.string.isRequired,
    event_id: PropTypes.string,
    progress: PropTypes.shape({
      progress_id: PropTypes.string,
      content_id: PropTypes.string,
      access_token: PropTypes.string,
    }),
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    eventCode: PropTypes.string,
    locale: PropTypes.string,
    iframed: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(contentActionCreators, this.props.dispatch);
  }

  async componentDidMount() {
    if (this.props.viewer === 'volunteer') {
      if (!this.props.event_id) {
        window.location.href = sanitizeUrl(
          `${window.location.origin}/${this.props.locale}/events/${this.props.eventCode}`
        );
      }
      await this.actions.loadCurrentVolunteer(this.props.event_id);
    } else {
      await this.actions.loadCurrentAttendee();
    }
    BrowserUpdate.checkBrowser();
  }

  onContentComplete = () => {
    let attendeeDashboardPath = `${window.location.origin}/${this.props.locale}/events/${this.props.eventCode}#/${this.props.event_id}/dashboard`;
    window.location.href = sanitizeUrl(attendeeDashboardPath);
  };

  render() {
    let origin =
      window.location.origin ||
      `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? ':' + window.location.port : ''
      }`;
    return (
      <div className="content-container">
        {this.props.progress && (
          <ContentApp
            endpointHost={this.props.contentServiceUrl}
            endpointPath="api/v1"
            locale={this.props.locale}
            accessToken={this.props.progress.access_token}
            progressId={this.props.progress.progress_id}
            portalEndpoint={`${origin}/api/data`}
            primaryColor={this.props.primaryColor}
            secondaryColor={this.props.secondaryColor}
            onComplete={this.onContentComplete}
            iframed={this.props.iframed}
          />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(ContentContainer);
