import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import i18n from 'lib/i18n';
import { FormGroup } from 'react-bootstrap';
import {
  AddressPickerControl,
  buildGenericProps,
  FieldGroup,
  FieldGroupCheckboxList,
  FloatingLabelFormField,
  Form,
  MaterialCheckbox,
  RadioGroupControl,
  SelectControl
} from 'reactifi';
import * as registrationActionCreators from '../actions/attendeeActionCreators';
import moment from 'moment';
import { incomeOptions } from '../functions/helpers';
import AttendeeRegistrationQuestions from './AttendeeRegistrationQuestions';

function mapStateToProps(state, ownProps) {
  let props = buildGenericProps(state, 'attendees');
  return props;
}

class AttendeeRegistration extends React.Component {

  static propTypes = {
    currentAttendee: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    eventId: PropTypes.string,
    from: PropTypes.string,
    googleMapsApiKey: PropTypes.string.isRequired,
    isWebinar: PropTypes.bool,
    location: PropTypes.object,
    router: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.actions = bindActionCreators(registrationActionCreators, this.props.dispatch);
    this.state = {
      emailCheckbox: false,
      event: props.location && props.location.state.event ? props.location.state.event : null,
      from: props.location && props.location.state.from ? props.location.state.from : null,
      housePhoneCheckbox: false,
      showBusiness: false,
      showContactOptions: false,
      showEmail: false,
      showPhone: false
    };
  }

  onConfirmRegistration = (attendee) => {
    this.actions.createRegisteredAttendee(attendee).then((response) => {
      const path = (this.state.from === 'vportal') ? '' : this.state.event.id + '/dashboard';
      if (response) {
        this.props.router.push({
          pathname: '/' + path
        });
      }
    });
  }

  onCancelRegistration = () => {
    this.props.router.push({
      pathname: '/'
    });
  }

  toggleShow(e, show) {
    this.setState({ [show]: e === "true" ? true : false }, this.checkEmailDisplay);

  }

  toggleChecked(e, checked) {
    this.setState({ [checked]: e });
  }

  checkEmailDisplay = () => {
    const { showEmail, showContactOptions } = this.state;
    if (!showEmail && !showContactOptions) {
      this.setState({ emailCheckbox: false })
    }
  }

  createTitle = () => {
    let title = i18n.t('Attendee Registration');
    if (this.state.event) {
      title = `${this.state.event.title} -  ${moment(this.state.event.start_time).format('LL')}`;
    }
    return (<h1 className="event-header">{title}</h1>);
  }

  smallBusiness = () => {
    const annualRevenues = [
      { value: '$0 (startup) - $250,000', label: '$0 (startup) - $250,000' },
      { value: '$500,000 - $1,000,000', label: '$500,000 - $1,000,000' },
      { value: 'Greater than $1,000,000', label: 'Greater than $1,000,000' }
    ]

    return (
      <>
        <FormGroup>
          <FloatingLabelFormField dataValue="false" name="business_owner" caption={i18n.t('Are you also a small business owner?')} isFloatOutside={true}>
            <RadioGroupControl
              name="business_owner"
              valueList={[{ value: 'true', label: i18n.t('Yes') }, { value: 'false', label: i18n.t('No') }]}
              onChange={(e) => this.toggleShow(e, 'showBusiness')}
              valueKey="value"
              labelKey="label"
              inline={true}
            />
          </FloatingLabelFormField>
        </FormGroup>

        {this.state.showBusiness &&
          <div id="small_business">
            <input name="user[small_business]" type="hidden" value={this.state.showBusiness} />
            <h3>{i18n.t('For Small Business Owners ONLY:')}</h3>

            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField isFloatOutside={true} name="business_name" caption={i18n.t("Business Name")} required={false} />
              </FieldGroup>
            </FieldGroup>
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField isFloatOutside={true} name="business_email" caption={i18n.t("Business Email")} required={false} />
              </FieldGroup>
            </FieldGroup>
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField isFloatOutside={true} name="business_street" caption={i18n.t("Business Street")} required={false} />
              </FieldGroup>
            </FieldGroup>
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField isFloatOutside={true} name="business_city" caption={i18n.t("Business City")} required={false} />
              </FieldGroup>
            </FieldGroup>
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField isFloatOutside={true} name="business_state" caption={i18n.t("Business State")} required={false} />
              </FieldGroup>
            </FieldGroup>
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField isFloatOutside={true} name="business_zip" caption={i18n.t("Business Zip")} required={false} />
              </FieldGroup>
            </FieldGroup>
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField isFloatOutside={true} name="business_revenue" caption={i18n.t("Business Revenue")}>
                  <SelectControl options={annualRevenues} valueKey="value" labelKey="label" placeholder="" />
                </FloatingLabelFormField>
              </FieldGroup>
            </FieldGroup>
          </div>}
      </>);
  }

  contactOptions = () => {
    return (
      <FieldGroup>
        <FieldGroup>
          <FloatingLabelFormField dataValue="false" name="contact_followup" caption={i18n.t('May we email you to follow up about this session?')} isFloatOutside={true}>
            <RadioGroupControl
              name="contact_followup"
              valueList={[{ value: 'true', label: i18n.t('Yes') }, { value: 'false', label: i18n.t('No') }]}
              onChange={(e) => this.toggleShow(e, 'showEmail')}
              valueKey="value"
              labelKey="label"
              inline={true}
            />
          </FloatingLabelFormField>
        </FieldGroup>
        <FieldGroup>
          <FloatingLabelFormField className="override-ellipsis" dataValue="false" name="contact_promotional" caption={i18n.t('May we contact you to share information about specials or new products and services?')} isFloatOutside={true}>
            <RadioGroupControl
              name="contact_promotional"
              valueList={[{ value: 'true', label: i18n.t('Yes') }, { value: 'false', label: i18n.t('No') }]}
              onChange={(e) => this.toggleShow(e, 'showContactOptions')}
              valueKey="value"
              labelKey="label"
              inline={true}
            />
          </FloatingLabelFormField>
        </FieldGroup>

        {this.state.showContactOptions &&
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField isFloatOutside={true} caption={i18n.t('How may we share this information with you?')}>
                <FieldGroupCheckboxList>
                  <MaterialCheckbox className="m-l-25" name="email" checked={true} onChange={(e) => this.toggleChecked(e, 'emailCheckbox')} inline={true}> {i18n.t('Email')}</MaterialCheckbox>
                  {!this.props.isWebinar && <MaterialCheckbox className="m-l-25" name="mail" checked={true} inline={true}>{i18n.t('Mail')}</MaterialCheckbox>}
                  <MaterialCheckbox className="m-l-25" name="mobile_phone" checked={true} onChange={(e) => this.toggleChecked(e, 'showPhone')} inline={true}>{i18n.t('Mobile Phone')}</MaterialCheckbox>
                  <MaterialCheckbox className="m-l-25" name="house_phone" checked={true} onChange={(e) => this.toggleChecked(e, 'housePhoneCheckbox')} inline={true}>{i18n.t('Home Phone')}</MaterialCheckbox>
                </FieldGroupCheckboxList>
              </FloatingLabelFormField>
            </FieldGroup>
          </FieldGroup>
        }
        <br />

        {(this.state.showEmail || this.state.emailCheckbox) &&
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField isFloatOutside={true} name="contact_email" caption={i18n.t("Email")} required={true} />
            </FieldGroup>
          </FieldGroup>
        }

        {this.state.showContactOptions && this.state.showPhone &&
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField isFloatOutside={true} name="contact_phone" caption={i18n.t("Mobile Phone Number")} required={true} />
            </FieldGroup>
          </FieldGroup>
        }
        {this.state.showContactOptions && this.state.housePhoneCheckbox &&
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField isFloatOutside={true} name="home_phone" caption={i18n.t("Home Phone Number")} required={true} />
            </FieldGroup>
          </FieldGroup>
        }
      </FieldGroup>
    )
  }

  mainForm = () => {
    const event = this.state.event;
    return (
      <Form
        addAction={this.onConfirmRegistration}
        buttonGroupClass="modal-footer"
        className="simple_form form-material new_attendee_wizard"
        data={this.props.currentAttendee}
        errorMessage={this.props.errorMessage}
        onCancel={this.onCancelRegistration}
      >
        <FieldGroup className="p-b-30">
          <FieldGroup className="row">
            <FieldGroup className="col-lg-12">
              <FloatingLabelFormField isFloatOutside={true} name="first_name" caption={i18n.t("First Name")} required={true} />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-lg-12">
              <FloatingLabelFormField isFloatOutside={true} name="last_name" caption={i18n.t("Last Name")} required={true} />
            </FieldGroup>
          </FieldGroup>
          {event.capture_family_income &&
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField isFloatOutside={true} name="income_level" caption={i18n.t("What is your family income?")} required={event.capture_family_income} >
                  <SelectControl options={incomeOptions} valueKey="value" labelKey="label" placeholder="" />
                </FloatingLabelFormField>
              </FieldGroup>
            </FieldGroup>}
          {event.enforce_address_collection &&
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField isFloatOutside={true} name="location_latitude" caption={i18n.t("Address (Type and select below)")}>
                  <AddressPickerControl googleMapsApiKey={this.props.googleMapsApiKey} placeholder="" required={false} />
                </FloatingLabelFormField>
              </FieldGroup>
            </FieldGroup>}

          {event.enforce_address_collection && <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField isFloatOutside={true} name="apartment_suite_info" caption={i18n.t("Apartment/Suite Info")} required={false} />
            </FieldGroup>
          </FieldGroup>}

          {this.props.from === 'vportal' &&
            <input name="from" type="hidden" value="vportal" />
          }
          <input name="event_id" type="hidden" value={this.props.eventId} />
          <input name="set_current_attendee" type="hidden" value={true} />

          {event.capture_small_business && this.smallBusiness()}
          {this.contactOptions()}
          <AttendeeRegistrationQuestions event={event} />
        </FieldGroup>
      </Form>
    )
  }

  render() {
    return (
      <>
        <div className="row event-title">
          <div className="col-lg-5 col-lg-offset-1 col-12 mobileCenter">
            {this.createTitle()}
          </div>
        </div>
        <div className="form-modal">
          <div className="form-modal-header">
            <h1>{i18n.t('Welcome!')}</h1>
            <p>{i18n.t('Please fill out the form below to access the materials for this event.')}</p>
          </div>
          <div className="form-modal-content">
            {this.mainForm()}
          </div>
        </div>
      </>
    )
  }
}

export default connect(mapStateToProps)(AttendeeRegistration);
