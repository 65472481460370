import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AttendeeRegistration from '../components/AttendeeRegistration';
import { JsonApiDecorator } from 'reactifi';
import { buildGenericProps } from 'reactifi';
import * as attendeeActionCreators from '../actions/attendeeActionCreators';

function mapStateToProps(state, ownProps) {
  let apiStore = new JsonApiDecorator(state.api);
  let props = buildGenericProps(state, 'attendees');
  props.googleMapsApiKey = ownProps.route.googleMapsApiKey;
  props.from = ownProps.route.from;
  props.eventType = ownProps.route.eventType;
  props.eventId = ownProps.route.eventId;
  props.currentAttendee = apiStore.newObject('attendees');
  return props;
}

class AttendeeRegistrationContainer extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    currentAttendee: PropTypes.object,
    eventType: PropTypes.string,
    eventId: PropTypes.string,
    from: PropTypes.string,
    googleMapsApiKey: PropTypes.string,
    location: PropTypes.object,
    router: PropTypes.shape({
      push: PropTypes.func
    })
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(attendeeActionCreators, this.props.dispatch);
  }


  isWebinar = (eventType) => {
    return eventType === "Events::Webinar";
  }

  render() {
    return (
      <AttendeeRegistration
        currentAttendee={this.props.currentAttendee}
        eventId={this.props.eventId}
        eventType={this.props.eventType}
        from={this.props.from}
        googleMapsApiKey={this.props.googleMapsApiKey}
        isWebinar={this.isWebinar(this.props.eventType)}
        location={this.props.location}
        router={this.props.router} />
    );
  }
}

export default connect(mapStateToProps)(AttendeeRegistrationContainer);
