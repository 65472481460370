import mirrorCreator from 'mirror-creator';
import { selectEntity, createEntity, readEntity, updateEntityAndRefreshAll, deleteEntity, S3Uploader } from 'reactifi';
export { clearMessages } from 'reactifi';
import i18n from 'lib/i18n';

export const actionTypes = mirrorCreator([
  'SET_TOPIC_ID',
  'UPLOAD_IN_PROGRESS',
  'UPLOAD_COMPLETE'
]);

export function loadTopics(){
  return readEntity('topics', 'offline_contents');
}

export function selectTopic(topicId){
  return selectEntity(topicId, 'topics', 'offline_contents');
}

export function updateTopic(topic, filters){
  return updateEntityAndRefreshAll(topic, '', filters, '', (dispatch)=>{
    dispatch(selectEntity(null, 'topics'));
  }, i18n.t("Topic updated successfully"));
}

export function createTopic(topic, addAnother){
  return createEntity(topic, (dispatch) => {
    //do it this way so the modal unloads and reloads, which makes sure the form clears
    dispatch(selectEntity(null, 'topics'));
    if (addAnother) {
      dispatch(selectEntity('add', 'topics'));
    }
  });
}

export function deleteTopic(topic){
  return deleteEntity(topic, 'topics');
}

export function setTopic(topicId){
  return {
    type: actionTypes.SET_TOPIC_ID,
    topicId
  }
}

export function uploadInProgress(){
  return {
    type: actionTypes.UPLOAD_IN_PROGRESS
  }
}

export function uploadComplete(){
  return {
    type: actionTypes.UPLOAD_COMPLETE
  }
}

export function createContent(file, offline_content){
  return createEntity(offline_content, async (dispatch, getState, updatedOfflineContent) => {
    dispatch(uploadInProgress())
    await S3Uploader(file, updatedOfflineContent.data.attributes.content_upload_url_fields, updatedOfflineContent.data.attributes.content_upload_url);
    dispatch(selectEntity(null, 'offline_contents'));
    dispatch(uploadComplete())
  })
}

export function selectContent(contentId){
  return selectEntity(contentId, 'offline_contents');
}

export function deleteContent(offline_content){
  return deleteEntity(offline_content, 'offline_contents');
}

export function loadAccounts() {
  return readEntity('accounts');
}

export function loadEverfiContents() {
  return readEntity('everfi_contents');
}
