import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import { ENGAGE_DEMO_IMG } from 'lib/constants';
import { sanitizeUrl } from '@braintree/sanitize-url';

const numberOfTopicsToDisplay = 4;
export default class TopicResults extends React.Component {
  static propTypes = {
    topics: PropTypes.array.isRequired
  }

  render() {
    return (
      <div className="topics">
        <h3 className="subsection-title"> {i18n.t('Schedule a new event or workshop')}</h3>
        <div className="">
          {
            this.props.topics.slice(0, numberOfTopicsToDisplay).map((topic) => {
              return (
                <div className="event-item-card">
                  <div className="event-item-card-body">
                    <div className="event-item-card-image-container">
                      <img alt={topic.name} className="fixed-aspect-ratio-image" src={topic.image_url ? topic.image_url : ENGAGE_DEMO_IMG} />
                    </div>
                    <div className="event-item-card-details-container">
                      <h4>{topic.name}</h4>
                      <span>{topic.description}</span>
                    </div>
                    <div className="event-item-card-see-details-container">
                      <a href={sanitizeUrl(`./topics#/${topic.id}`)}>{i18n.t('View Details')}</a>
                    </div>
                  </div>
                </div>
              )
            })
          }
          {(this.props.topics && this.props.topics.length > numberOfTopicsToDisplay) &&
            <div className="see-all">
              <a href="./topics#">{i18n.t('See All Topics')}</a>
            </div>
          }
        </div>
      </div>
    )
  }
}


