import { selectEntity, updateEntity, readEntity, displayError, readEntityById } from 'reactifi';
export { clearMessages, displaySuccessMessage } from 'reactifi'

export function loadAccount(id){
  let include='logo,background_image';
  return readEntityById(id, 'accounts', include);
}

export function updateAccount(account) {
  return updateEntity(account);
}

export function findEvents(filters){
  return readEntity('events', '', filters);
}

export function selectGoal(goalId){
  return selectEntity(goalId, 'goals');
}

export function uploadError(response){
  return displayError(response);
}
