import PropTypes from 'prop-types';
import React from 'react';
import i18n from 'lib/i18n';
import { Icon } from 'reactifi';
import { Button, Tooltip, OverlayTrigger  } from 'react-bootstrap';
import { sanitizeUrl } from '@braintree/sanitize-url';

export default class CourseModule extends React.Component {
  static propTypes = {
    courseModule: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string
    }),
    event_id: PropTypes.string.isRequired,
    status: PropTypes.string,
    content_id: PropTypes.string,
    currentViewer: PropTypes.object,
    disabled: PropTypes.boolean
  };

  contentOpenText = () => {
    if(this.props.status === 'in_progress') { return i18n.t('Continue') }
    if(this.props.status === 'completed') { return i18n.t('Review') }
    else { return i18n.t('Start') }
  }

  onClick = () => {
    const queryString = `event_id=${this.props.event_id}&viewer_type=${this.props.currentViewer.type}&viewer_id=${this.props.currentViewer.id}`;
    const path = `${window.location.pathname}/contents/${this.props.content_id}?${queryString}`;
    window.location.href = sanitizeUrl(path);
  }

  renderButton = () => {
    const { disabled } = this.props;

    if (disabled) {
      return(
        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">{i18n.t('Item Disabled in Admin View')}</Tooltip>}>
          <Button bsStyle="primary disabled">
            <span>{this.contentOpenText()}</span>
            <Icon iconType="chevron-right" />
          </Button>
        </OverlayTrigger>
      )
    }

    return(
      <Button bsStyle="primary" onClick={this.onClick}>
        <span>{this.contentOpenText()}</span>
        <Icon iconType="chevron-right" />
      </Button>
    );
  }

  render() {
    return (
      <div className="course-module">
        <h4>{this.props.courseModule.name}</h4>
        <div className="row">
          <div className="col-md-6 col-lg-6 col-9">
            <p>{this.props.courseModule.description}</p>
          </div>
          <div className="col-md-6 col-lg-6 text-right col-3">
            {this.renderButton()}
          </div>
        </div>
      </div>
    );
  }
}
