import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PagingDataList, buildGenericProps, Page } from 'reactifi';
import * as orgTopicsActionCreators from '../actions/orgTopicsActionCreators';
import i18n from 'lib/i18n';
import debounce from 'lodash/debounce';

function mapStateToProps(state, ownProps) {
  let props = buildGenericProps(state, 'topics');
  if (ownProps.route) {
    props.accountId = ownProps.route.accountId ? ownProps.route.accountId.toString() : null;
    props.isEventManagement = ownProps.route.isEventManagement;
  }
  return props;
}

class ListContainer extends React.Component {
  static propTypes = {
    accountId: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    isLoadingTopics: PropTypes.bool,
    isEventManagement: PropTypes.bool,
    topics: PropTypes.array,
    topicsMeta: PropTypes.shape({
      total_count: PropTypes.number
    })
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(orgTopicsActionCreators, this.props.dispatch);
    this.loadTopics = debounce(this.loadTopics, 350, { leading: false, trailing: true });

    this.state = {
      activePage: 1
    };
  }

  componentDidMount() {
    this.loadTopics();
  }

  loadTopics = (pageNumber = 1) => {
    const { accountId, isEventManagement } = this.props;

    const event_types = isEventManagement ?
      ["Events::Webinar", "Events::InPerson", "Events::InPersonAndWebinar"] :
      ["Events::FinancialEducation", "Events::BusinessDevelopment", "Events::OtherCommunityService"];

    this.actions.loadTopics(
      {
        accountId,
        enabled: true,
        event_types
      },
      pageNumber
    );
  }

  onPageChange = activePage => {
    this.setState({ activePage }, () => this.loadTopics(activePage));
  }

  render() {
    const isMobile = window.isMobile;
    let fields = [
      {
        name: 'name',
        title: i18n.t('Title'),
        canSort: true,
        defaultSort: true,
        columnOptions: { width: 300 },
        className: 'td-auto-width',
        formatter: (cell, row) => <Link to={`/${row.id}`}>{row.name}</Link>,
        mobileFormatter: (cell, row) => row.name
      },
      { name: 'description', title: i18n.t('Description'), canSort: true, formatter: (cell, row) => row.description }
    ];

    if (isMobile) {
      fields.push({
        name: 'topic_details', title: i18n.t('Topic Details'), canSort: true, mobileFormatter: (cell, row) => {
          return (
            <Link to={`/${row.id}`}>{i18n.t('Topic Details')}</Link>
          )
        }
      });
    }

    const tableOptions = {
      bordered: false,
      hover: true,
      striped: false,
      defaultSortName: 'name',
      defaultSortOrder: 'asc'
    };

    return (
      <Page
        pageType="parent"
        contentType="list"
        title={i18n.t('Workshop Topics')}
        usePageWrapper={true}
      >
        <PagingDataList
          data={this.props.topics}
          fields={fields}
          keyField="id"
          onPageChange={this.onPageChange}
          activePage={this.state.activePage}
          options={tableOptions}
          totalCount={this.props.topicsMeta ? this.props.topicsMeta.total_count : 0}
          isLoadingData={this.props.isLoadingTopics}
        />
      </Page>);
  }
}

export default connect(mapStateToProps)(ListContainer);
