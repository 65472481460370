import React from 'react'; 
import { ErrorBoundedProvider } from 'reactifi';
import createStore from './store/contentStore';
import  Content from './containers/ContentContainer';
import { Router, Route, hashHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'

export default (props, _railsContext) => {
  const store = createStore(props);
  const history = syncHistoryWithStore(hashHistory, store); 
  const reactComponent = (
    <ErrorBoundedProvider store={store}>
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <Route path="/" component={Content} currentUserId={props.currentUserId} eventCode={props.eventCode} event_id={props.eventId} locale={props.locale}
          contentServiceUrl={_railsContext.contentServiceUrl} evaluFiUrl={_railsContext.evaluFiUrl} jwtToken={_railsContext.jwtToken}
          primaryColor={props.dark_color} secondaryColor={props.light_color}
          viewer={props.viewer} progress_id={props.progress_id} iframed={props.iframed}/>
      </Router>
    </ErrorBoundedProvider>
  );
  return reactComponent;
};
