import PropTypes from 'prop-types';
import React from 'react';
import i18n from "lib/i18n";
import EventFinderForm from "../../Event/components/EventFinderFormComponent";

const FilterSection = (props) => (
  <div className="row">
    <div className="col-lg-4">
      <h3 className="subsection-title m-l-15">{ i18n.t("Workshops and Events") }</h3>
    </div>
    <div className="col-lg-8">
      <EventFinderForm {...props} />
    </div>
  </div>
);

FilterSection.propTypes = {
  isEventManagement: PropTypes.bool,
  topics: PropTypes.array.isRequired
};

export default FilterSection;