import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { PageWrapper, MessagesModule, Header, Section, PagingDataList, buildGenericProps, MappedLocation, NoResultsCard, Icon } from 'reactifi';
import * as workLocationActionCreators from '../actions/workLocationActionCreators'
import WorkLocationForm from '../components/WorkLocationForm';
import WorkLocationSearchForm from '../components/WorkLocationSearchForm';
import i18n from 'lib/i18n';

function mapStateToProps(state) {
  let props = buildGenericProps(state, 'work_locations');
  if (props.currentEntity) {
    props.currentWorkLocation = props.currentEntity;
  }
  return props;
}

class WorkLocation extends React.Component {
  static propTypes = {
    currentWorkLocation: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    googleMapsApiKey: PropTypes.string.isRequired,
    isLoadingWorkLocations: PropTypes.bool,
    successMessage: PropTypes.string,
    work_locations: PropTypes.array,
    work_locationsMeta: PropTypes.shape({
      total_count: PropTypes.number
    })
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(workLocationActionCreators, this.props.dispatch);
    this.noResultsMessage = "Work locations are where employees work";
    this.noResultsButtons = [
      {
        label: "Create Work Location",
        onClick: (e) => this.actions.selectWorkLocation('add')
      }
    ];
    this.state = {
      activePage: 1,
      filters: {}
    }
  }

  getMarkerInfoContent = selectedPlace => {
    let workLocation = this.props.work_locations.find((workLocation) => workLocation.id === selectedPlace.id);
    if (workLocation) {
      return (
        <div>
          <span> <b> {workLocation.name} </b> </span> <br />
          <span> {workLocation.location_formatted_address} </span>
        </div>
      )
    }
  }

  findWorkLocations = filters => {
    let filtersArr = Object.assign({}, this.state.filters, filters);
    this.actions.findWorkLocations(filtersArr);
    this.setState({
      activePage: 1,
      filters: filtersArr
    });
  }

  onPageChange = activePage => {
    if (activePage !== 1 && activePage === this.state.activePage) return;
    const { filters } = this.state;
    this.setState({
      activePage
    }, () => this.actions.findWorkLocations(filters, activePage));
  }

  preventClickthrough = event => {
    event.stopPropagation();
  }

  render() {
    const {
      isLoadingWorkLocations,
      work_locations,
      work_locationsMeta
    } = this.props;
    const { activePage } = this.state;

    let isMobile = window.isMobile;
    let fields = [
      { name: 'external_id', title: i18n.t('ID'), width: '20%', canSort: true, defaultSort: true },
      {
        name: 'volunteer_count', title: i18n.t('Volunteers'), width: '10%', canSort: true, formatter: (cell, row) =>
          <a onClick={this.preventClickthrough} href={`volunteers#?filterField=work_location_id&filterValue=${row.id}`}>{row.volunteer_count}</a>
      },
      { name: 'location_formatted_address', title: i18n.t('Address'), width: '30%', canSort: true },
      { name: 'location_room', title: i18n.t('Address Line 2'), width: '20%', canSort: true },
      { name: 'contact_name', title: i18n.t('Contact'), width: '20%', canSort: true }
    ];

    if (isMobile) {
      fields.push({
        name: 'edit_work_location', title: i18n.t('Edit Work Location'), canSort: false, formatter: (cell, row) => {
          return (
            <span>
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-edit">{i18n.t('Edit Work Location')}</Tooltip>}>
                <a onClick={(e) => this.actions.selectWorkLocation(row.id)} data-id={row.id}><Icon iconType="edit" /></a>
              </OverlayTrigger>
            </span>
          )
        }
      })
    }

    const tableOptions = {
      onRowClick: (e, row) => this.actions.selectWorkLocation(row.id)
    };

    let locations = [];
    this.props.work_locations.forEach(function (workLocation) {
      if (workLocation.location_latitude && workLocation.location_longitude) {
        return locations.push({ id: workLocation.id, lat: workLocation.location_latitude, lng: workLocation.location_longitude });
      }
    });

    let actionButton = {
      label: i18n.t('Create Work Location'),
      action: (e) => this.actions.selectWorkLocation('add')
    };

    return (
      <PageWrapper
        pageType="parent"
        contentType="fullwidth"
      >
        <MessagesModule
          errorMessage={this.props.currentWorkLocation ? null : this.props.errorMessage}
          successMessage={this.props.currentWorkLocation ? null : this.props.successMessage}
          clearMessages={this.actions.clearMessages}
        />
        <Header title={i18n.t('Work Location List')} button={actionButton} />
        <Section>
          <div className="row">
            <div className="col-lg-6">
              <WorkLocationSearchForm searchAction={this.findWorkLocations} disabled={isLoadingWorkLocations} />
            </div>
            <div className="col-lg-6">
              <MappedLocation googleMapsApiKey={this.props.googleMapsApiKey} locations={locations} getMarkerInfoContent={this.getMarkerInfoContent} width="100%" height="200px" />
            </div>
          </div>
        </Section>
        <Section type="list">
          <div className="row">
            <div className="col-lg-6">
              <WorkLocationForm currentWorkLocation={this.props.currentWorkLocation} selectWorkLocation={this.actions.selectWorkLocation} clearMessages={this.actions.clearMessages}
                googleMapsApiKey={this.props.googleMapsApiKey} errorMessage={this.props.errorMessage}
                updateWorkLocation={this.actions.updateWorkLocation} createWorkLocation={this.actions.createWorkLocation} />
            </div>
          </div>
          {(isLoadingWorkLocations || this.props.work_locations.length || Object.keys(this.state.filters).length) && work_locationsMeta ?
            <PagingDataList
              actions={this.actions}
              activePage={activePage}
              data={work_locations}
              fields={fields}
              isLoadingData={isLoadingWorkLocations}
              keyField="id"
              onPageChange={this.onPageChange}
              options={tableOptions}
              totalCount={work_locationsMeta.total_count}
            /> :
            <NoResultsCard message={this.noResultsMessage} buttons={this.noResultsButtons} />}
        </Section>
      </PageWrapper>
    );
  }
}

export default connect(mapStateToProps)(WorkLocation);
