import PropTypes from 'prop-types';
import React from 'react';
import {
  ModalForm, AddressPickerControl, SelectAsyncControl, MaskedFormControl, SelectControl, FieldGroup,
  GenericModal, FloatingLabelFormField, MaterialCheckbox
} from 'reactifi';
import i18n from 'lib/i18n';

export default class VolunteerForm extends React.Component {
  static propTypes = {
    isAdmin: PropTypes.bool,
    currentVolunteer: PropTypes.object,
    errorMessage: PropTypes.string,
    selectVolunteer: PropTypes.func.isRequired,
    googleMapsApiKey: PropTypes.string.isRequired,
    loadWorkLocation: PropTypes.func.isRequired,
    updateVolunteer: PropTypes.func.isRequired,
    clearMessages: PropTypes.func.isRequired,
    languages: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.state = {
      isEmployee: false,
      doesNotBelongToAnyWorkLocation: false,
      offWorkEmail: false
    }
    this.onCancel = this.onCancel.bind(this);
    this.onWorkLocationChange = this.onWorkLocationChange.bind(this);
    this.onToggleIsEmployee = this.onToggleIsEmployee.bind(this);
    this.onToggleNoWorkLocation = this.onToggleNoWorkLocation.bind(this);
    this.getInitialWorkLocationData = this.getInitialWorkLocationData.bind(this);
    this.onOffWorkEmail = this.onOffWorkEmail.bind(this);
    this.displayWorkLocation = this.displayWorkLocation.bind(this);
  }

  onToggleIsEmployee() {
    this.setState({
      isEmployee: !this.state.isEmployee
    }, () => {
      if (!this.state.isEmployee) {
        this.props.currentVolunteer.doesNotBelongToAnyWorkLocation = false;
        this.props.currentVolunteer.work_location = null;
        this.props.currentVolunteer.work_location_id = null;
      }
    });
  }

  onOffWorkEmail() {
    this.setState({
      offWorkEmail: !this.state.offWorkEmail
    }, () => {
      if (!this.state.offWorkEmail) {
        this.props.currentVolunteer.alternate_email = '';
        this.props.currentVolunteer.off_work_email = false;
      }
    })
  }

  onWorkLocationChange(val) {
    if (val && val.id) {
      this.props.loadWorkLocation(val.id);
      this.props.currentVolunteer.doesNotBelongToAnyWorkLocation = false;
      this.setState({ doesNotBelongToAnyWorkLocation: false });
    }
  }

  onToggleNoWorkLocation() {
    this.setState({
      doesNotBelongToAnyWorkLocation: !this.state.doesNotBelongToAnyWorkLocation
    }, () => {
      this.props.currentVolunteer.doesNotBelongToAnyWorkLocation = this.state.doesNotBelongToAnyWorkLocation;
      this.props.currentVolunteer.work_location = null;
      this.props.currentVolunteer.work_location_id = null;
    });
  }

  onCancel() {
    this.props.selectVolunteer(null);
    this.props.clearMessages();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.currentVolunteer && !this.props.currentVolunteer) {
      this.setState({
        isEmployee: newProps.currentVolunteer.is_employee,
        doesNotBelongToAnyWorkLocation: newProps.currentVolunteer.doesNotBelongToAnyWorkLocation,
        offWorkEmail: !!newProps.currentVolunteer.alternate_email
      });
      if (newProps.currentVolunteer.alternate_email) {
        newProps.currentVolunteer.off_work_email = !!newProps.currentVolunteer.alternate_email
      }
    }
  }

  getInitialWorkLocationData() {
    return this.props.currentVolunteer && this.props.currentVolunteer.work_location_id ?
      { id: this.props.currentVolunteer.work_location.id, display: this.props.currentVolunteer.work_location.location_formatted_address } : {};
  }

  displayWorkLocation() {
    return this.props.currentVolunteer && this.props.currentVolunteer.work_location_id ?
      this.props.currentVolunteer.work_location.location_formatted_address : i18n.t('No work location');
  }

  buildDisplay(i) {
    return (i.location_formatted_address);
  }

  // eslint-disable-next-line complexity
  render() {
    const { isAdmin } = this.props;
    const modalTitle = this.props.currentVolunteer && this.props.currentVolunteer.id
      ? `${this.props.currentVolunteer.first_name} ${this.props.currentVolunteer.last_name}` : i18n.t('Add a New Volunteer');
    const cancelButtonText = isAdmin ? i18n.t('Cancel') : i18n.t('Close');

    return this.props.currentVolunteer && this.props.currentVolunteer.is_admin ?
      (<GenericModal canClose={true} title={i18n.t("Admin Details")} show={!!this.props.currentVolunteer} onClose={this.onCancel}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-5">
              {i18n.t('First Name:')}
            </div>
            <div className="col-7">
              {this.props.currentVolunteer && this.props.currentVolunteer.first_name}
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              {i18n.t('Last Name:')}
            </div>
            <div className="col-7">
              {this.props.currentVolunteer && this.props.currentVolunteer.last_name}
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              {i18n.t('Email:')}
            </div>
            <div className="col-7">
              {this.props.currentVolunteer && this.props.currentVolunteer.email}
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              {i18n.t('Mobile Phone:')}
            </div>
            <div className="col-7">
              {this.props.currentVolunteer && this.props.currentVolunteer.phone}
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              {i18n.t('Employee Work Location:')}
            </div>
            <div className="col-7">
              {this.displayWorkLocation()}
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              {i18n.t('Current Position/Job Title:')}
            </div>
            <div className="col-7">
              {this.props.currentVolunteer && this.props.currentVolunteer.title}
            </div>
          </div>
        </div>
      </GenericModal>) : (
        <ModalForm
          cancelButtonText={cancelButtonText}
          data={this.props.currentVolunteer}
          errorMessage={this.props.errorMessage}
          onCancel={this.onCancel}
          saveButton={isAdmin}
          title={modalTitle}
          updateAction={this.props.updateVolunteer}
        >
          <FieldGroup className="container-fluid">
            <FieldGroup className="row">
              <FieldGroup className="col-6">
                <FloatingLabelFormField
                  caption={i18n.t("First Name")}
                  disabled={!isAdmin}
                  name="first_name"
                  required={true}
                  type="text"
                />
              </FieldGroup>
              <FieldGroup className="col-6">
                <FloatingLabelFormField
                  caption={i18n.t("Last Name")}
                  disabled={!isAdmin}
                  name="last_name"
                  required={true}
                  type="text"
                />
              </FieldGroup>
            </FieldGroup>
            <FieldGroup className="row">
              <FieldGroup className="col-6">
                <FloatingLabelFormField
                  caption={i18n.t("Email")}
                  disabled={!isAdmin}
                  name="email"
                  required={true}
                  type="email"
                />
              </FieldGroup>
              <FieldGroup className="col-6">
                <FloatingLabelFormField
                  caption={i18n.t("Mobile Phone")}
                  disabled={!isAdmin}
                  name="phone"
                >
                  <MaskedFormControl mask="(111) 111-1111" type="tel" pattern="^\(\d{3}\) \d{3}-\d{4}$"
                    placeholderChar=" " placeholder=" " />
                </FloatingLabelFormField>
              </FieldGroup>
            </FieldGroup>
            {this.state.offWorkEmail ?
              <FieldGroup className="row">
                <FieldGroup className="col-6">
                  <FloatingLabelFormField
                    caption={i18n.t("Off-work Email")}
                    disabled={!isAdmin}
                    name="alternate_email"
                    required={true}
                    type="email" />
                </FieldGroup>
              </FieldGroup> : null}
            <FieldGroup className="checkbox-group">
              <FieldGroup className="row">
                <FieldGroup className="col-12">
                  <MaterialCheckbox
                    disabled={!isAdmin}
                    inline={false}
                    name="off_work_email"
                    onChange={this.onOffWorkEmail}
                  >
                    {i18n.t('I cannot receive emails at the above email address off-work hours.')}
                  </MaterialCheckbox>
                </FieldGroup>
                <FieldGroup className="col-12">
                  <MaterialCheckbox
                    disabled={!isAdmin}
                    inline={false}
                    name="is_employee"
                    onChange={this.onToggleIsEmployee}
                  >
                    {i18n.t('Is Employee')}
                  </MaterialCheckbox>
                </FieldGroup>
              </FieldGroup>
              {this.state.isEmployee && !this.state.doesNotBelongToAnyWorkLocation ?
                <FieldGroup className="row">
                  <FieldGroup className="col-12">
                    <FloatingLabelFormField name="work_location_id" caption={i18n.t("Work Locations (Type and select below)")} required={false} >
                      <SelectAsyncControl autocomplete="off" multi={false} onChange={this.onWorkLocationChange} filterField="keyword" placeholder=""
                        dataUrl="/api/data/work_locations.json" displayProperty="external_id" initialData={this.getInitialWorkLocationData()}
                        buildDisplay={this.buildDisplay} sortBy="name" />
                    </FloatingLabelFormField>
                  </FieldGroup>
                </FieldGroup>
                : null}
              {this.state.isEmployee ?
                <FieldGroup className="row">
                  <FieldGroup className="col-12">
                    <MaterialCheckbox name="doesNotBelongToAnyWorkLocation" onChange={this.onToggleNoWorkLocation} inline={true}>{i18n.t("I don't work at any of the work locations listed.")}</MaterialCheckbox>
                  </FieldGroup>
                </FieldGroup>
                : null}
            </FieldGroup>
            
            {!this.state.isEmployee || (this.state.isEmployee && this.state.doesNotBelongToAnyWorkLocation) ?
              <FieldGroup className="row">
                <FieldGroup className="col-12">
                  <FloatingLabelFormField
                    caption={i18n.t("Address (Type and select below)")}
                    disabled={!isAdmin}
                    name="location"
                    required={true}
                  >
                    <AddressPickerControl googleMapsApiKey={this.props.googleMapsApiKey} initialData={this.props.currentVolunteer} placeholder="" />
                  </FloatingLabelFormField>
                </FieldGroup>
              </FieldGroup>
              : null}
            <FieldGroup className="row">
              {!this.state.isEmployee || (this.state.isEmployee && this.state.doesNotBelongToAnyWorkLocation) ?
                <FieldGroup className="col-6">
                  <FloatingLabelFormField
                    caption={i18n.t("Address Line 2")}
                    disabled={!isAdmin}
                    name="location_room"
                  />
                </FieldGroup> : null}
              <FieldGroup className="col-6">
                <FloatingLabelFormField
                  caption={i18n.t("Current Position/Job Title")}
                  disabled={!isAdmin}
                  name="title"
                  required={this.state.isEmployee}
                  type="text"
                />
              </FieldGroup>
            </FieldGroup>
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField
                  caption={i18n.t("Presentation Language Capabilities")}
                  disabled={!isAdmin}
                  name="languages"
                  required={true}
                >
                  <SelectControl options={this.props.languages} multi={true} valueKey="value" labelKey="label" />
                </FloatingLabelFormField>
              </FieldGroup>
            </FieldGroup>
          </FieldGroup>
        </ModalForm>)
  }
}
