import { selectEntity, readEntity, downloadReport, updateEntity, updateEntityAndRefreshAll, readEntityById, endTimeout  } from 'reactifi';
import { prepareReportFilters } from '../../common/preprocess';
export { clearMessages } from 'reactifi';

export function loadVolunteers(){
  return readEntity('volunteers');
}

export function findVolunteers(filters, page){
  return readEntity('volunteers', '', filters, null, page);
}

export function selectVolunteer(volunteerId){
  return selectEntity(volunteerId, 'volunteers', 'work_location');
}

export function updateVolunteer(volunteer, filters){
  if(Object.keys(filters).length === 0){
    return updateEntity(volunteer, (dispatch) => {
      dispatch(selectEntity(null, 'volunteers'));
    });
  }
  else{
    return updateEntityAndRefreshAll(volunteer, '', filters, '', (dispatch)=> {
      dispatch(selectEntity(null, 'volunteers'));
    });
  }
}

export function closeTimeoutModal(){
  return endTimeout();
}

export function loadWorkLocation(id){
  return readEntityById(id, 'work_locations');
}

export function downloadVolunteers(accountId, filters){
  return downloadReport('Reports::Volunteers', { account_id: accountId, filters: prepareReportFilters(filters) });
}

export function loadAccount(id){
  let include = 'topics';
  return readEntityById(id, 'accounts', include);
}
