import { readEntity, selectEntity, updateEntityAndRefreshAll } from 'reactifi';
export { clearMessages } from 'reactifi';
import i18n from 'lib/i18n';

export function findUsers(filters){
  return readEntity('account_admins', '', filters);
}

export function updateUser(user, filters){
  return updateEntityAndRefreshAll(user, '', filters, '', (dispatch)=>{
    dispatch(selectEntity(null, 'account_admins'));
  }, i18n.t("User updated successfully"));
}
