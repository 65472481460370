import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as orgActionCreators from '../actions/orgActionCreators';
import { buildGenericProps } from 'reactifi';

import Organizations from '../components/Organizations';

function mapStateToProps(state) {
  let props = buildGenericProps(state, 'accounts', ['account_admins', 'topics']);
  props.currentOrganization = props.currentEntity;
  return props;
}


class OrganizationsContainer extends Component {

  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(orgActionCreators, this.props.dispatch);
    this.state = {
      isLoadingData: true
    }
  }

  async componentDidMount() {
    await this.actions.loadOrganizations({ number: 1 });
    this.setState({ isLoadingData: false });
  }

  render() {
    return (
      <Organizations {...this.props} {...this.actions} />
    );
  }
}

export default connect(mapStateToProps)(OrganizationsContainer);
