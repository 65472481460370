import PropTypes from 'prop-types';
import React from 'react';
import { ModalForm, FieldGroup, FloatingLabelFormField, FileUpload, S3MediaUploader, SelectSortableControl, SelectAsyncControl } from 'reactifi';
import * as topicsActionCreators from '../actions/orgTopicsActionCreators'
import { bindActionCreators } from 'redux';
import i18n from 'lib/i18n';

const MAX_TOTAL_COUNT = 1000;

export default class OrganizationTopicsForm extends React.Component {
  static propTypes = {
    account_id: PropTypes.string,
    currentTopic: PropTypes.object,
    errorMessage: PropTypes.string,
    selectTopic: PropTypes.func.isRequired,
    createTopic: PropTypes.func.isRequired,
    updateTopic: PropTypes.func.isRequired,
    clearMessages: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    show: PropTypes.bool,
    uploadError: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(topicsActionCreators, this.props.dispatch);
    this.state = {
      files: [],
      surveyPopulated: false,
      everfiContentIds: []
    };
  }

  onChange = (value) => {
    let display = value !== "" ? true : false;
    this.setState({ surveyPopulated: display });
  }

  onCancel = () => {
    this.props.selectTopic(null);
    this.props.clearMessages();
    this.setState({ surveyPopulated: false });
  }

  onImageUpload = (response) => {
    this.props.currentTopic.image_id = response.data.id;
  }

  onUploadFailure = (response) => {
    this.props.uploadError(response);
  }

  updateTopic = (currentTopic) => {
    currentTopic.everfi_content_ids = currentTopic.everfi_content_ids.map(content_id => content_id);
    this.props.updateTopic(currentTopic);
    this.setState({ surveyPopulated: false });
  }

  createTopic = (currentTopic) => {
    currentTopic.account_id = this.props.account_id;
    this.props.createTopic(currentTopic);
    this.setState({ surveyPopulated: false });
  }

  imageURL = () => {
    const imageUrl = this.props.currentTopic?.image_url;

    return imageUrl ?
      imageUrl.substring(imageUrl.indexOf('_') + 1, imageUrl.indexOf('?')) :
      null;
  };

  render() {
    let title = this.props.currentTopic && this.props.currentTopic.id ? i18n.t('editNumber', { postProcess: 'sprintf', sprintf: [this.props.currentTopic.name] }) : i18n.t('Add a New Topic');
    let parameters = { account_id: this.props.account_id }
    let languages = [{ en: 'English' }, { es: 'Spanish' }];
    let event_types = this.props.currentTopic && this.props.currentTopic.id ? this.props.currentTopic.event_types : null;
    let content_types = this.props.currentTopic && this.props.currentTopic.id ? this.props.currentTopic.everfi_content_ids : null;

    return (
      <ModalForm data={this.props.currentTopic} title={title} errorMessage={this.props.errorMessage}
        onCancel={this.onCancel} addAction={this.createTopic} updateAction={this.updateTopic} show={this.props.show} saveAndAddButton={false} languages={languages} size="large" >
        <div className="row">
          <div className="col-12">
            <h4>{i18n.t('Topic Details')}</h4>
          </div>
        </div>
        <FieldGroup className="row">
          <FieldGroup className="col-12">
            <FloatingLabelFormField name="name" caption={i18n.t("Name")} required={true} multilingual={true} />
          </FieldGroup>
        </FieldGroup>
        <FieldGroup className="row">
          <FieldGroup className="col-12">
            <FloatingLabelFormField name="description" caption={i18n.t("Topic Description")} required={true} multilingual={true} />
          </FieldGroup>
        </FieldGroup>
        <FieldGroup className="row">
          <FieldGroup className="col-12">
            <FloatingLabelFormField name="event_types" caption={i18n.t("Event Type")} required={true} multi={true} >
              <SelectAsyncControl
                multi={true}
                placeholder=""
                valueKey="event_types"
                filterField="event_types"
                initialData={event_types}
                dataUrl={"/api/data/events/types.json"}
                displayProperty="label"
                sortBy="label"
                searchPromptText={i18n.t("Select Event Type:")}
                scrollMenuIntoView={true} />
            </FloatingLabelFormField>
          </FieldGroup>
        </FieldGroup>
        <FieldGroup className="row">
          <FieldGroup className="col-12">
            <FloatingLabelFormField name="everfi_content_ids" caption={i18n.t("Content Type")} required={true}>
              <SelectSortableControl
                dataUrl={"/api/data/everfi_contents.json"}
                displayProperty="name"
                filterField="everfi_content_ids"
                initialData={content_types}
                labelKey="display"
                pageSize={MAX_TOTAL_COUNT}
                placeholder=""
                scrollMenuIntoView={true}
                searchPromptText={i18n.t("Select Content Type:")}
                sortBy="name"
                valueKey="id" />
            </FloatingLabelFormField>
          </FieldGroup>
        </FieldGroup>
        <FieldGroup className="row">
          <FieldGroup className="col-9">
            <FloatingLabelFormField name="survey_url" caption={i18n.t("Survey URL")} type="url" onChange={this.onChange} required={this.state.surveyPopulated} />
          </FieldGroup>
          <FieldGroup className="col-3">
            <FloatingLabelFormField name="send_survey" caption={i18n.t("Send After (Days):")} type="number" min="1" onChange={this.onChange} required={this.state.surveyPopulated} />
          </FieldGroup>
        </FieldGroup>
        <FieldGroup className="row">
          <FieldGroup className="col-12">
            {this.props.currentTopic ? <FileUpload uploader={S3MediaUploader}
              description={{ textArray: ['Image format: JPG, PNG or GIF'] }}
              header={{ text: 'Topic Image' }}
              name="image"
              tooltip={i18n.t("Uploaded image for this Topic")}
              icon={{ className: 'zmdi zmdi-collection-image zmdi-hc-lg', fileClassName: 'icon-file', noFileClassName: 'icon-no-file' }}
              input={{ name: 'image' }}
              button={{ text: i18n.t('Upload Image'), className: 'btn btn-primary' }}
              actions={{ success: this.onImageUpload, failure: this.onUploadFailure }}
              parameters={parameters}
              label={{ text: this.imageURL() || i18n.t('No File'), closable: false }} /> : null}
          </FieldGroup>
        </FieldGroup>
      </ModalForm>)
  }
}
