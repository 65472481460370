import PropTypes from 'prop-types';
import { Storage } from 'reactifi';

const GenerateAttendeeViewEventLink = (event) => {
  const locale = Storage.getItem('i18nextLng') || 'en';
  return (
    `/${locale}/events/${event.code}#/${event.id}`
  )
};

GenerateAttendeeViewEventLink.propTypes = {
  event: PropTypes.isRequired
};

export default GenerateAttendeeViewEventLink;