import PropTypes from 'prop-types';
import React from 'react';
import { ModalForm, FieldGroup, FileUpload, SelectControl, FloatingLabelFormField, ProgressSpinner } from 'reactifi';
import * as orgTopicsActionCreators from '../actions/orgTopicsActionCreators'
import { bindActionCreators } from 'redux';
import i18n from 'lib/i18n';

export default class OfflineContentForm extends React.Component {
  static propTypes = {
    currentOfflineContent: PropTypes.object,
    errorMessage: PropTypes.string,
    clearMessages: PropTypes.func.isRequired,
    selectContent: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    uploadFiles: PropTypes.func,
    uploadInProgress: PropTypes.bool,
    currentTopic: PropTypes.object,
    everfiContents: PropTypes.array,
    updateContent: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(orgTopicsActionCreators, this.props.dispatch);
    this.state = {
      files: []
    }
  }

  storeFileForUpload = (files) => {
    this.setState({
      files: files
    })
  }

  uploadFiles = (currentOfflineContent) => {
    let file = this.state.files[0];
    currentOfflineContent.filename = file.name;
    currentOfflineContent.language = this.props.currentOfflineContent.language;
    this.props.uploadFiles(file, currentOfflineContent, false)
  }

  onCancel = () => {
    this.props.selectContent(null);
    this.props.clearMessages();
  }

  getContentsByTopic = () => {
    let { currentTopic, everfiContents, currentOfflineContent } = this.props;
    let availableEverfiContents = []
    if (currentOfflineContent && currentOfflineContent.id && everfiContents) {
      availableEverfiContents = everfiContents.filter(content => currentOfflineContent.topic.everfi_content_ids.includes(content.id))
    } else if (currentTopic && everfiContents && !currentOfflineContent.id) {
      availableEverfiContents = everfiContents.filter(content =>
        currentTopic.everfi_content_ids.includes(content.id)
      );
    }
    return availableEverfiContents;
  }

  fileUploader = () => {
    return (
      <div>
        <FieldGroup className="row">
          <FieldGroup className="col-12">
            <FileUpload onUpload={this.storeFileForUpload}
              description={{ textArray: [i18n.t('Upload documents for your event')] }}
              header={{ text: i18n.t('Upload File') }}
              name="offline_contents"
              tooltip={i18n.t("Upload files needed for your event")}
              icon={{ className: 'zmdi zmdi-collection-image zmdi-hc-lg', fileClassName: 'icon-file', noFileClassName: 'icon-no-file' }}
              input={{ name: 'offline_contents' }}
              button={{ text: i18n.t('Select File'), className: 'btn btn-primary' }}
              label={{ text: i18n.t('No File'), closable: false }} />
          </FieldGroup>
        </FieldGroup>
      </div>
    )
  }

  renderForm = () => {
    let languages = [ { id: 'en', name: 'English' }, { id: 'es', name: 'Spanish' } ];
    let availableEverfiContents = this.getContentsByTopic();

    return (
      <FieldGroup data={this.props.currentOfflineContent} >
        {this.props.currentOfflineContent && this.props.currentOfflineContent.id ? null : this.fileUploader()}
        <FieldGroup className="row">
          <FieldGroup className="col-12">
            <FloatingLabelFormField name="name" caption={i18n.t("Content Name")} required={true} />
          </FieldGroup>
        </FieldGroup>
        <FieldGroup className="row">
          <FieldGroup className="col-12">
            <FloatingLabelFormField name="language" caption={i18n.t("Language")} required={true}>
              <SelectControl options={languages} valueKey="id" labelKey="name" placeholder="" />
            </FloatingLabelFormField>
          </FieldGroup>
        </FieldGroup>
        <FieldGroup className="row">
          <FieldGroup className="col-12">
            <FloatingLabelFormField name="everfi_content_ids" caption={i18n.t("Content Type")}>
              <SelectControl options={availableEverfiContents}
                valueKey="id"
                labelKey="name"
                multi={true}
                placeholder=""/>
            </FloatingLabelFormField>
          </FieldGroup>
        </FieldGroup>
      </FieldGroup>
    )

  }

  uploadSpinner = () => {
    return (
      <span>
        <FieldGroup className="row">
          <FieldGroup className="col-12 text-center">
            <ProgressSpinner classNames="preloader pl-xxl pls-blue"/>
          </FieldGroup>
        </FieldGroup>
        <FieldGroup className="row">
          <FieldGroup className="col-12 text-center">
            <p>{i18n.t("It may take several minutes to upload large files. Please do NOT close this browser tab until the upload is complete.")}</p>
          </FieldGroup>
        </FieldGroup>
      </span>
    )
  }

  render() {
    let title = this.props.currentOfflineContent && this.props.currentOfflineContent.id ? `Edit ${this.props.currentOfflineContent.name}` : 'Add a New Content';

    return (
      <ModalForm data={this.props.currentOfflineContent} updateAction={this.props.updateContent} title={title} errorMessage={this.props.errorMessage}
        onCancel={this.onCancel} addAction={this.uploadFiles} cancelButton={false} saveAndAddButton={false} >
        { this.props.uploadInProgress ? this.uploadSpinner() : this.renderForm() }
      </ModalForm>)
  }
}
