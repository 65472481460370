import { selectEntity, updateEntity, createEntity, readEntity } from 'reactifi';
export { clearMessages } from 'reactifi';
import i18n from 'lib/i18n';

export function selectWorkLocation(workLocationId){
  return selectEntity(workLocationId, 'work_locations');
}

export function findWorkLocations(filters, page){
  return readEntity('work_locations', '', filters, null, page);
}

export function updateWorkLocation(workLocation){
  return updateEntity(workLocation, (dispatch) => {
    dispatch(selectEntity(null, 'work_locations'));
  }, i18n.t("Work Location updated successfully"));
}

export function createWorkLocation(workLocation, addAnother){
  return createEntity(workLocation, (dispatch) => {
    //do it this way so the modal unloads and reloads, which makes sure the form clears
    dispatch(selectEntity(null, 'work_locations'));
    if (addAnother) {
      dispatch(selectEntity('add', 'work_locations'));
    }
  }, i18n.t("Work Location created successfully"));
}
