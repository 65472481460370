import React from 'react';
import PropTypes from 'prop-types';
import { FieldGroup, FloatingLabelFormField, SelectControl } from 'reactifi';
import { registrationQuestions } from '../functions/helpers';

export const AttendeeRegistrationQuestions = props => {
  const { event } = props;
  const regQuestions = registrationQuestions.filter(question => event[question.id]);
  if (!regQuestions?.length) return null;

  return (
    <FieldGroup className="row" {...props}>
      <FieldGroup className="col-12">
        {
          regQuestions.map((question, index) => {
            return (
              <FloatingLabelFormField
                caption={question.name}
                isFloatOutside={true}
                key={index}
                name={question.formname}
                required={true}
              >
                {question.type === 'select' && (
                  <SelectControl
                    labelKey="label"
                    options={question.options}
                    valueKey="value"
                  />
                )}
              </FloatingLabelFormField>
            )
          })
        }
      </FieldGroup>
    </FieldGroup>
  );
}

AttendeeRegistrationQuestions.propTypes = {
  event: PropTypes.object
}

export default AttendeeRegistrationQuestions;
