import reducers from '../reducers';
import { genericStoreSetup, readEntity } from 'reactifi';

export default props => {
  const initialState = {};
  const store = genericStoreSetup(reducers, initialState);
  store.dispatch(readEntity('topics', ['offline_contents', 'events', 'course_modules']));

  return store;
};
