import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  AutoSavingForm,
  FieldGroup,
  FloatingLabelFormField,
  IconCategoryValue,
  Util
} from 'reactifi';
import GenerateAttendeeViewEventLink from '../../../AttendeeEvent/components/GenerateAttendeeViewEventLink';
import LetterCircleSection from './LetterCircleContentSectionComponent';
import LetterCircleComponent from './LetterCircleContentComponent';
import AdditionalDetailsComponent from './AdditionalDetailsComponent';
import AttendeeContainer from '../../containers/AttendeeContainer';
import QRCode from 'qrcode.react';
import { openInNewTab } from '../../functions/helpers';
import { sanitizeUrl } from '@braintree/sanitize-url';

const DayOfEventTabContent = props => {
  const {
    checkIn,
    checkInDisabled,
    currentEventUser,
    currentLead,
    dataObjects,
    errorMessage,
    evaluFiUrl,
    event,
    eventUsers,
    googleMapsApiKey,
    goToAttendeeRegistrationSite,
    isEventManagement,
    isWebinar,
    preRegister,
    updateEvent,
    updateEventUser
  } = props;

  const displaySetUp = () => {
    return (
      <LetterCircleComponent {...dataObjects('set_up')} header={i18n.t('Set up')}>
        <ul>
          <li>{i18n.t('Test your computer, internet connection, and display hardware (if applicable)')}</li>
          <li>{i18n.t('Set up print material')}</li>
        </ul>
      </LetterCircleComponent>
    );
  }

  const displayVolunteerCheckIn = () => {
    return (
      <LetterCircleComponent {...dataObjects('volunteer_check_in')} header={i18n.t('Volunteer Check In')}>
        <div className="row">
          <div className="col-lg-8">
            <ul>
              <li>{i18n.t('Remind all volunteers to check in to the event on their mobile devices. That includes you!')}</li>
            </ul>
          </div>
          <div className="col-lg-4">
            {event ?
              <OverlayTrigger placement="top" overlay={
                <Tooltip id="tooltip-disabled">
                  {i18n.t('Record your volunteer work. All volunteers must check in to events in order for records to be collected.')}
                </Tooltip>
              }>
                <Button className="pull-right" bsStyle="primary" disabled={checkInDisabled} onClick={(e) => checkIn()}>{i18n.t('Volunteer Check-In')}</Button>
              </OverlayTrigger>
              : null}
          </div>
        </div>
      </LetterCircleComponent>
    );
  }

  const displayAttendeeCheckIn = () => {
    const eventCancelled = !!event.cancelled;

    return (
      <LetterCircleComponent {...dataObjects('attendee_check_in')} header={i18n.t('Attendee Check In')}>
        <div className="row">
          <div className="col-lg-8">
            <ul>
              <li>{i18n.t('Welcome your session attendees and thank them for coming.')}</li>
              <li>{i18n.t('Distribute and collect the paper registration and assessment sheets.')}</li>
            </ul>
          </div>
          <div className="col-lg-4">
            {event && !eventCancelled &&
              <Button
                {...Util.dataAttribsForEl(event, { action: 'open-self-registration' })}
                bsStyle="primary"
                className="pull-right"
                disabled={preRegister}
                onClick={() => goToAttendeeRegistrationSite()}
              >
                {i18n.t('Online Self-Registration')}
              </Button>
            }
          </div>
        </div>
      </LetterCircleComponent>
    );
  }

  const isEventVolunteer = () => {
    const isVolunteer = !!eventUsers.find(user => user.user_id === currentEventUser.user_id.toString());
    return (currentEventUser && eventUsers) && (currentLead || isVolunteer);
  }

  const displayManualAttendeeCount = () => {
    return (
      <LetterCircleComponent {...dataObjects('count_attendees')} header={i18n.t('Count Attendees')}>
        <div className="auto-saving-form m-0">
          <AutoSavingForm data={event} updateAction={updateEvent}
            saveMessageClassName="autosave-message events-autosave-form-save-message" errorMessage={errorMessage}>
            <FieldGroup className="container">
              <FieldGroup className="row">
                <FieldGroup className="col-lg-12">
                  <FieldGroup className="form-group">
                    <FloatingLabelFormField className="m-t-0" name="manual_attendee_count" caption={i18n.t("Manual Attendee Count")} type="number" />
                  </FieldGroup>
                </FieldGroup>
              </FieldGroup>
            </FieldGroup>
          </AutoSavingForm>
        </div>
      </LetterCircleComponent>
    );
  }

  const displayPresentEvent = () => {
    return (
      <>
        {displaySetUp()}
        {displayVolunteerCheckIn()}
        {displayAttendeeCheckIn()}
        {isEventVolunteer() && displayManualAttendeeCount()}
        <LetterCircleComponent {...dataObjects('present_event')} header={i18n.t('Present Event')} />
      </>
    );
  }

  const updateTimeLog = time_log => {
    Object.assign(currentEventUser.time_log, time_log);
    updateEventUser(currentEventUser);
  }

  const getLogMyTimeField = (caption, name) => {
    return (
      <FieldGroup className="col-lg-6">
        <FieldGroup className="form-group">
          <FloatingLabelFormField
            caption={caption}
            dataObjectId={currentEventUser.id}
            dataObjectType={currentEventUser.type}
            name={name}
            type="number"
          />
        </FieldGroup>
      </FieldGroup>
    );
  }

  const displayLogMyTime = () => {

    return (
      <>
        {!isEventManagement && <h3>{i18n.t('Log My Time')}</h3>}
        <div className="auto-saving-form">
          <AutoSavingForm data={currentEventUser.time_log} updateAction={updateTimeLog}
            saveMessageClassName="autosave-message events-autosave-form-save-message" errorMessage={errorMessage}>
            <FieldGroup className="container">
              <FieldGroup>
                {!isEventManagement && (
                  <FieldGroup className="m-0">
                    {getLogMyTimeField(i18n.t('Preparation'), "preparation")}
                    {getLogMyTimeField(i18n.t('Event Setup'), "event_setup")}
                  </FieldGroup>
                )}
                {getLogMyTimeField(i18n.t('Event'), "event")}
                {!isEventManagement && getLogMyTimeField(i18n.t('Data Entry'), "evidence_capture")}
                {getLogMyTimeField(i18n.t('Other'), "other")}
              </FieldGroup>
            </FieldGroup>
          </AutoSavingForm>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {i18n.t('Total Hours:')} {currentEventUser.total_time}
            </div>
          </div>
        </div>
      </>
    );
  }

  const displayAdditionalDetails = () => {
    return (
      <>
        {!isEventManagement && <h3>{i18n.t('Additional Details')}</h3>}
        <AdditionalDetailsComponent
          caption={i18n.t("Expenses")}
          data={event}
          errorMessage={errorMessage}
          name="estimated_expense"
          type="text"
          updateAction={updateEvent}
        />

        <AdditionalDetailsComponent
          caption={i18n.t("Notes")}
          componentClass="textarea"
          data={currentEventUser}
          errorMessage={errorMessage}
          name="notes"
          updateAction={updateEventUser}
        />
      </>
    );
  }

  const displayParticipantsInfo = () => {
    return (
      <div>
        <h3>{i18n.t('Attendees')}</h3>
        <AttendeeContainer event={event} updateEvent={updateEvent}
          evaluFiUrl={evaluFiUrl} googleMapsApiKey={googleMapsApiKey}
          isWebinar={isWebinar} />
      </div>
    );
  }

  const displayDocumentEventInfo = () => {
    return (
      <>
        <h2>{i18n.t('Document Event Info')}</h2>

        {displayLogMyTime()}
        {displayAdditionalDetails()}
        {(event.event_type !== "Events::OtherCommunityService" && !event.for_kids) && displayParticipantsInfo()}
      </>
    );
  }

  const displayRegisterAttendees = () => {
    const attendeeEventLink = window.location.origin + GenerateAttendeeViewEventLink(event);

    return (
      <LetterCircleComponent {...dataObjects('register_attendees')} header={i18n.t('Register Attendees')}>
        <p>{i18n.t('You have three options for registering attendees')}</p>
        <ul>
          <li>
            <IconCategoryValue
              {...Util.dataAttribsForEl(event, { id: 'register_attendees_option_1' })}
              category={i18n.t('Option 1 (In-person Events only)')}
              value={
                <>
                  {i18n.t('Have attendees register on a shared device: ')}
                  <a
                    {...Util.dataAttribsForEl(event, { action: 'open-self-registration' })}
                    onClick={goToAttendeeRegistrationSite}
                    className="shared-device-link"
                  >
                    {i18n.t('Shared device sign-up')}
                  </a>
                </>
              }
            />
          </li>
          <li>
            <IconCategoryValue
              {...Util.dataAttribsForEl(event, { id: 'register_attendees_option_2' })}
              category={i18n.t('Option 2')}
              copyValue={attendeeEventLink}
              value={
                <>
                  {i18n.t('Share the following link so attendees can register on their own devices: ')}
                  <a
                    {...Util.dataAttribsForEl(event, { action: 'open-promote-url-dayof-event' })}
                    href={sanitizeUrl(attendeeEventLink)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {attendeeEventLink}
                  </a>
                </>
              }
            />
          </li>
          <li>
            <IconCategoryValue
              {...Util.dataAttribsForEl(event, { id: 'register_attendees_option_3' })}
              category={i18n.t('Option 3')}
              value={i18n.t('Display the QR Code below so attendees can register on their own devices (click to expand)')}
            />
            <QRCode
              className="m-t-10 cursor-pointer"
              data-action="expand-qr-code"
              data-object-id={event.id}
              data-object-type="events"
              onClick={() => openInNewTab(`${attendeeEventLink}/qr_code`)}
              value={attendeeEventLink}
            />
          </li>
        </ul>
      </LetterCircleComponent>
    );
  }

  const displayDocumentAttendees = () => {

    return (
      <LetterCircleComponent {...dataObjects('document_attendees')} header={i18n.t('Document Attendees')}>
        <div className="auto-saving-form m-0">
          <AutoSavingForm data={event} updateAction={updateEvent}
            saveMessageClassName="autosave-message events-autosave-form-save-message" errorMessage={errorMessage}>
            {isEventVolunteer() && (
              <FieldGroup className="container">
                <FieldGroup className="row">
                  <FieldGroup className="col-lg-12">
                    <FieldGroup className="form-group">
                      <FloatingLabelFormField name="manual_attendee_count" caption={i18n.t("Manual Attendee Count")} type="number" />
                    </FieldGroup>
                  </FieldGroup>
                </FieldGroup>
              </FieldGroup>
            )}
            <AttendeeContainer
              evaluFiUrl={evaluFiUrl}
              event={event}
              googleMapsApiKey={googleMapsApiKey}
              isEventManagement={isEventManagement}
              updateEvent={updateEvent}
            />
          </AutoSavingForm>
        </div>
      </LetterCircleComponent>
    );
  }

  const displayDocumentEventInfoEventManagement = () => {
    return (
      <LetterCircleComponent {...dataObjects('document_event_info')} header={i18n.t('Document Event Info')}>
        {displayLogMyTime()}
        {displayAdditionalDetails()}
      </LetterCircleComponent>
    );
  }

  const displayEventManagementTabInfo = () => {
    return (
      <>
        {displayRegisterAttendees()}
        {displayDocumentAttendees()}
        {displayDocumentEventInfoEventManagement()}
      </>
    );
  }

  const presentEventHeader = isEventManagement ? '' : i18n.t('When you arrive');

  return (
    <>
      <LetterCircleSection eventKey={2} header={presentEventHeader}>
        {
          isEventManagement ?
            displayEventManagementTabInfo() :
            event.event_type !== "Events::OtherCommunityService" && displayPresentEvent()
        }
      </LetterCircleSection>

      {/* Document Info section is ommitted from A-B-C List format */}
      {!isEventManagement && displayDocumentEventInfo()}
    </>
  );
}

DayOfEventTabContent.propTypes = {
  checkIn: PropTypes.func,
  checkInDisabled: PropTypes.bool,
  currentEventUser: PropTypes.object,
  currentLead: PropTypes.bool.isRequired,
  dataObjects: PropTypes.func,
  errorMessage: PropTypes.string,
  evaluFiUrl: PropTypes.string.isRequired,
  event: PropTypes.object,
  eventUsers: PropTypes.array,
  googleMapsApiKey: PropTypes.string.isRequired,
  goToAttendeeRegistrationSite: PropTypes.func,
  isEventManagement: PropTypes.bool,
  isWebinar: PropTypes.bool,
  preRegister: PropTypes.bool,
  updateEvent: PropTypes.func,
  updateEventUser: PropTypes.func
};

export default DayOfEventTabContent;
