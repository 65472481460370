import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { PagingDataList, Storage } from 'reactifi';
import i18n from 'lib/i18n';

const downloadAction = ({ accountId, downloadAttendees }) => {
  Storage.setItem('suppressLoadingClass', true);
  downloadAttendees(accountId);
}

const getTableOptions = onSortChange => ({
  onSortChange: onSortChange
})

const getFields = isEventManagement => [
  {
    name: 'full_name',
    canSort: true,
    formatter: (cell, row) => row.full_name, mobileFormatter: (cell, row) => row.full_name,
    title: i18n.t('Name'),
    width: '30%'
  },
  {
    name: 'contact_email',
    canSort: true,
    formatter: (cell, row) => <a onClick={event => event.stopPropagation()} href={`mailto:${row.contact_email}`}>{row.contact_email}</a>,
    title: i18n.t('Email'),
    width: '45%'
  },
  // Show City and Zip Code Columns for Non-Event Management
  {
    ...isEventManagement ? [] : [{
      name: 'location_locality',
      canSort: true,
      formatter: (cell, row) => row.location_locality,
      title: i18n.t('City'),
      width: '25%'
    }, {
      name: 'location_postal_code',
      canSort: true,
      formatter: (cell, row) => row.location_postal_code,
      title: i18n.t('Zip Code'),
      width: 110
    }]
  },
  {
    name: 'contact_followup',
    align: 'false',
    canSort: false,
    formatter: (cell, row) => row.contact_followup ? i18n.t('Yes') : i18n.t('No'),
    title: i18n.t('Follow-up Consent'),
    width: 170
  },
  {
    name: 'contact_promotional',
    align: 'center',
    canSort: false,
    columnOptions: { dataAlign: 'center' },
    formatter: (cell, row) => row.contact_promotional ? i18n.t('Yes') : i18n.t('No'),
    title: i18n.t('Marketing Consent'),
    width: 170
  },
  // Show LMI Columns for Non-Event Management
  ...isEventManagement ? [] : [{
    name: 'is_lmi',
    align: 'center',
    canSort: false,
    formatter: (cell, row) => row.is_lmi ? i18n.t('Yes') : i18n.t('No'),
    title: i18n.t('LMI'),
    width: 100
  }, {
    name: 'lmi_qualification',
    canSort: false,
    columnOptions: { className: 'lmi' },
    formatter: (cell, row) => i18n.t(row.lmi_qualification),
    title: i18n.t('LMI Qualification'),
    width: 160
  }]
].filter(Boolean);


// eslint-disable-next-line react/no-multi-comp
const AttendeeList = props => {
  const {
    accountId,
    activePage,
    attendees,
    closeTimeoutModal,
    downloadAttendees,
    downloadProcessing,
    downloadTimedOut,
    handlePageChange,
    handleSortChange,
    isEventManagement,
    isLoadingData,
    totalCount
  } = props;

  const onPageChange = useCallback(activePage => handlePageChange(activePage), [handlePageChange]);
  const onSortChange = useCallback((name, order) => handleSortChange({ value: name, direction: order }));

  return (
    <PagingDataList
      activePage={activePage}
      closeTimeoutModal={closeTimeoutModal}
      data={attendees}
      downloadAction={() => downloadAction({ accountId, downloadAttendees })}
      downloadProcessing={downloadProcessing}
      downloadTimedOut={downloadTimedOut}
      fields={getFields(isEventManagement)}
      isLoadingData={isLoadingData}
      onPageChange={onPageChange}
      options={getTableOptions(onSortChange)}
      keyField="id"
      showDownloadLink={true}
      totalCount={totalCount}
    />
  );
};

AttendeeList.propTypes = {
  accountId: PropTypes.string,
  activePage: PropTypes.number,
  attendees: PropTypes.array,
  closeTimeoutModal: PropTypes.func,
  downloadAttendees: PropTypes.func,
  downloadProcessing: PropTypes.bool,
  downloadTimedOut: PropTypes.bool,
  handlePageChange: PropTypes.func,
  handleSortChange: PropTypes.func,
  isEventManagement: PropTypes.bool,
  isLoadingData: PropTypes.bool,
  totalCount: PropTypes.number
};

AttendeeList.defaultProps = {
  attendees: []
};

export default AttendeeList;
