import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { JsonApiDecorator, buildGenericProps } from 'reactifi';
import * as eventActionCreators from '../actions/eventActionCreators';
import { default as CportalEventList } from '../components/cportal/EventList';
import { default as VportalDashboard } from '../components/vportal/EventDashboard';
import { default as VportalAllEventList } from '../components/vportal/EventList';
import utils from 'lib/utils';
import debounce from 'lodash/debounce';

const addUrlParamFilters = props => {
  if (!props.filter) {
    props.filter = {};
  }

  const urlParams = new URLSearchParams(window.location.search);
  const paramsInterator = urlParams.keys();

  for (let param of paramsInterator) {
    props.filter[param] = urlParams.get(param);
  }
};

function mapStateToProps(state, ownProps) {
  let apiStore = new JsonApiDecorator(state.api);
  let props = buildGenericProps(state, 'events');
  Object.assign(props, {
    accountId: ownProps.route.accountId,
    currentUserId: ownProps.route.currentUserId,
    isEventManagement: ownProps.route.isEventManagement,
    path: ownProps.route.path.indexOf('dashboard') !== -1 ? 'dashboard' : null,
    viewer: ownProps.route.viewer
  });

  if (!apiStore.events) {
    props.events = [];
  } else if (props.viewer === 'volunteer') {
    props.events = apiStore.events.all(['venue', 'event_users']);
    props.events.forEach((event) => {
      event.joined = event.event_users.some((eu) => eu.user_id.toString() === props.currentUserId);
      event.lead_volunteer = event.event_users.find((eu) => eu.role === 'lead_volunteer');
    });
  } else {
    props.events = apiStore.events.all();
  }

  if (!apiStore.accounts) {
    props.account = { eventTypes: [], eventStatuses: [], languageOptions: [] };
  } else {
    props.account = apiStore.accounts.first('topic');
    props.events.forEach((event) => {
      event.statusDisplay = Object.keys(props.account.event_statuses).find((key) => {
        return props.account.event_statuses[key] === event.status;
      })
    });
    props.account.eventTypes = utils.mapObjToOptions(props.account.event_types);
    props.account.eventStatuses = utils.mapObjToOptions(props.account.event_statuses);
    props.account.languageOptions = utils.mapObjToOptions(props.account.languages);
  }

  if (ownProps?.location?.query) {
    const { topic, location, filterField, filterValue } = ownProps.location.query;
    props.filter = {};

    if (filterField) {
      props.filter = { [filterField]: filterValue };
    }
    if (topic) {
      props.filter.topic_id = [parseInt(topic, 10)];
    }
    if (location) {
      props.filter.location = location;
    }
  }

  addUrlParamFilters(props);

  props.downloadProcessing = state.api.downloadProcessing;
  props.downloadTimedOut = state.api.downloadTimedOut;
  props.downloadUrl = state.api.downloadUrl;
  props.errorMessage = state.api.errorMessage;
  props.showAlertMessage = true;

  if (props.account.topics) {
    let topics = props.account.topics.filter((t) => t.enabled);
    props.account.topics = topics;
  }
  return props;
}


class ListContainer extends React.Component {
  static propTypes = {
    account: PropTypes.shape({
      topics: PropTypes.array,
      eventTypes: PropTypes.array,
      eventStatuses: PropTypes.array,
      languageOptions: PropTypes.array
    }),
    accountId: PropTypes.number.isRequired,
    currentUserId: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    downloadUrl: PropTypes.string,
    downloadProcessing: PropTypes.bool,
    downloadTimedOut: PropTypes.bool,
    errorMessage: PropTypes.string,
    events: PropTypes.array,
    eventsMeta: PropTypes.object,
    filter: PropTypes.object,
    isLoadingEvents: PropTypes.bool,
    isEventManagement: PropTypes.bool,
    showAlertMessage: PropTypes.bool,
    successMessage: PropTypes.string,
    path: PropTypes.string,
    router: PropTypes.object,
    viewer: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(eventActionCreators, this.props.dispatch);
    this.state = {
      isFirstRead: false,
      isFiltered: false
    };
  }

  componentDidMount() {
    this.actions.loadAccount(this.props.accountId);
  }

  downloadEvents = (filters) => {
    this.actions.downloadEvents(this.props.accountId, filters);
  }

  closeTimeoutModal = () => {
    this.actions.closeTimeoutModal();
  }

  filterEvents = debounce((filters, page = 1, sort) => {
    this.actions.readEvents('topic', Object.assign({}, this.props.filter, filters), sort, page);
  }, 350)

  readEvents = (includes, filters, sorts) => {
    this.actions.readEvents(includes, Object.assign({}, this.props.filter, filters), sorts);
    if (!this.state.isFirstRead) {
      this.setState({
        isFirstRead: true
      });
    }
    else {
      this.setState({
        isFiltered: true
      });
    }
  }

  render() {
    const { isEventManagement, account } = this.props;
    const eventTypes = isEventManagement ? account.eventTypes : account.eventTypes.filter(item => item.label !== "Webinar")

    let vportalProps = {
      events: this.props.events,
      isEventManagement: isEventManagement,
      readEvents: this.readEvents,
      eventTypes: eventTypes,
      eventStatuses: account.eventStatuses,
      languages: account.languageOptions,
      topics: account.topics,
      currentUserId: this.props.currentUserId,
      defaultSearchParams: this.props.filter,
      errorMessage: this.props.errorMessage,
      showAlertMessage: this.props.showAlertMessage,
      successMessage: this.props.successMessage,
      clearMessages: this.actions.clearMessages,
      isLoadingEvents: this.props.isLoadingEvents,
      isFiltered: this.state.isFiltered,
      router: this.props.router
    };

    if (this.props.viewer === 'volunteer') {
      if (this.props.path === 'dashboard') {
        return <VportalDashboard {...vportalProps} />
      } else {
        return <VportalAllEventList {...vportalProps} />
      }
    } else {
      return (
        <CportalEventList
          account={account}
          clearMessages={this.actions.clearMessages}
          closeTimeoutModal={this.closeTimeoutModal}
          downloadEvents={this.downloadEvents}
          downloadProcessing={this.props.downloadProcessing}
          downloadTimedOut={this.props.downloadTimedOut}
          downloadUrl={this.props.downloadUrl}
          errorMessage={this.props.errorMessage}
          events={this.props.events}
          eventsMeta={this.props.eventsMeta}
          eventStatuses={this.props.account.eventStatuses}
          eventTypes={eventTypes}
          filterEvents={this.filterEvents}
          isEventManagement={isEventManagement}
          isLoadingEvents={this.props.isLoadingEvents}
          showAlertMessage={this.props.showAlertMessage}
          successMessage={this.props.successMessage}
        />
      );
    }
  }
}

export default connect(mapStateToProps)(ListContainer);
