import $ from 'jquery';

(function (scope) {

  var _volunteer = function () {
    this.userEmployeeCheckbox = $('#user_is_employee');
    this.userEmployeeFields = $('#work-location-options');
    this.alternateWorkEmailCheckbox = $('#user_alternate_email');
    this.alternateWorkEmailFields = $('#alternate_email');
    this.alternateWorkLocationCheckbox = $('#work-location-status');
    this.alternateWorkLocationFields = $('#user-location-options');
    this.alternateWorkLocationHideFields = $('#work-location-selector');
    this.init();
  };

  _volunteer.prototype.init = function () {
    this.alternateWorkLocationCheckbox.prop('checked', this.alternateWorkLocationFields.data('hasLocation'));
    var conditionalFieldsNames = ['userEmployee', 'alternateWorkEmail', 'alternateWorkLocation'];
    for (var i = 0, k = conditionalFieldsNames.length; i < k; i++) {
      this.initConditionalFields(conditionalFieldsNames[i]);
    }
    this.alternateWorkLocationCheckbox.on('click', function () {
      if ($(this).prop('checked'))
        $("#user-work-location").val(null).trigger('change')
    });
  };

  _volunteer.prototype.initConditionalFields = function (fieldName) {
    var _this = this;
    this[fieldName + 'Checkbox'].on('click', function () {
      _this.viewConditionalFields(fieldName);
    });
    this.viewConditionalFields(fieldName);
  };

  _volunteer.prototype.viewConditionalFields = function (fieldName) {
    this[fieldName + 'Fields'].toggle(this[fieldName + 'Checkbox'].prop('checked'));
    var hideFields = this[fieldName + 'HideFields'];
    if (hideFields)
      hideFields.toggle(!this[fieldName + 'Checkbox'].prop('checked'));
  };

  scope.Volunteer = _volunteer;

}(window));

$(function () {
  // eslint-disable-next-line no-new
  new window.Volunteer;
});
