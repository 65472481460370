import React from 'react';
import PropTypes from 'prop-types';

export default class LetterCircleSection extends React.Component {

  static propTypes = {
    children: PropTypes.object,
    header: PropTypes.string
  };

  render() {
    const { children, header } = this.props;

    return (
      <div className="letter-circle-sections">
        {header && <h2>{header}</h2>}
        {children}
      </div>
    );
  }
}
