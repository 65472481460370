import { actionTypes } from '../actions/eventActionCreators';

export default function eventReducer(state = {}, action) {
  switch (action.type) {
  case actionTypes.TOGGLE_EDIT_CONFERENCING_INFO:
    return Object.assign({}, state, { showEditConferencingInfo: action.show });
  case actionTypes.TOGGLE_EDIT_EVENT_TYPE:
    return Object.assign({}, state, { showEditEventType: action.show });
  case actionTypes.TOGGLE_EDIT_DATE_TIME:
    return Object.assign({}, state, { showEditDateTime: action.show });
  case actionTypes.TOGGLE_EDIT_VENUE:
    return Object.assign({}, state, { showEditVenue: action.show });
  case actionTypes.TOGGLE_EDIT_PARTNER:
    return Object.assign({}, state, { showEditPartner: action.show });
  case actionTypes.TOGGLE_ADD_REG_QUESTIONS:
    return Object.assign({}, state, { showAddRegQuestions: action.show });
  case actionTypes.TOGGLE_EDIT_REG_QUESTIONS:
    return Object.assign({}, state, { showEditRegQuestions: action.show });
  default:
    return state;
  }
}
