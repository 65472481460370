import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RadioGroupControl, Icon, ModalForm, ConfirmationModal, FieldGroup, buildGenericProps, FloatingLabelFormField } from 'reactifi';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import i18n from 'lib/i18n';
import Cookies from 'js.cookie';
import * as eventActionCreators from '../actions/eventActionCreators';
import AddToCalendarButton from './AddToCalendarButton';


function mapStateToProps(state, ownProps) {
  let props = buildGenericProps(state, 'reservations');
  return props;
}

class SaveMySpotButton extends React.Component {
  static propTypes = {
    accountName: PropTypes.string,
    clearMessages: PropTypes.func.isRequired,
    currentEntity: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    event: PropTypes.object,
    onComplete: PropTypes.func,
    errorMessage: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      showCompleteModal: false,
      saveMySpotModal: false
    }
    this.actions = bindActionCreators(eventActionCreators, this.props.dispatch);
  }

  setCookie = () => {
    const { event } = this.props;
    const days = (moment(event.start_time).diff(moment(), 'days') + 1);
    const preRegCookie = Cookies.get('pre-registered');
    let cookieArray;
    if (preRegCookie) {
      cookieArray = (preRegCookie.indexOf(',') > -1) ? preRegCookie.split(',') : [preRegCookie];
      cookieArray.push(event.id);
    } else {
      cookieArray = [event.id];
    }
    Cookies.set(
      'pre-registered',
      cookieArray.toString(),
      { expires: days, path: '/' }
    );
  }

  onSaveMySpot = async (e) => {
    await this.actions.saveMySpotPreregistration(e);
    this.setCookie();
    this.setState({ saveMySpotModal: false, showCompleteModal: true });
  }

  onComplete = () => {
    this.setState({ showCompleteModal: false });
    if (this.props.onComplete) {
      this.props.onComplete();
    }
  }

  onClosePreregModal = () => {
    this.setState({ saveMySpotModal: false });
    this.props.clearMessages();
  }

  openSaveSpot = () => {
    this.actions.createReservationEntity({ contact_promotional: 'false' });
    this.setState({ saveMySpotModal: true });
  }

  modalForm = () => {
    return (<Fragment>
      {this.props.currentEntity && <ModalForm
        data={this.props.currentEntity}
        show={this.state.saveMySpotModal}
        errorMessage={this.props.errorMessage}
        title={i18n.t("Save your spot")}
        onCancel={this.onClosePreregModal}
        addAction={this.onSaveMySpot}
        saveAndAddButton={false}
        saveButtonText={i18n.t('Submit')}>
        <input name="event_id" type="hidden" value={this.props.event.id} />
        <FieldGroup className="container-fluid">
          <div className="row">
            <div className="col-12">
              <p className="info-panel">{i18n.t("Please fill out the form below to save your spot for the event.")}</p>
            </div>
          </div>
          <FieldGroup className="row">
            <FieldGroup className="col-6">
              <FloatingLabelFormField
                name="first_name"
                caption={i18n.t("First Name")}
                required={true}
              />
            </FieldGroup>
            <FieldGroup className="col-6">
              <FloatingLabelFormField
                name="last_name"
                caption={i18n.t("Last Name")}
                required={true}
              />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField
                className="m-b-5 p-b-0"
                name="contact_email"
                caption={i18n.t("Email")}
                required={true}
              />
              <p className="m-b-20">{i18n.t("Event organizers may email you to confirm details for getting into the event")}</p>
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <p className="p-b-0 m-b-0">{i18n.t('May we contact you to share information about specials or new products and services?')}</p>
              <RadioGroupControl
                valueList={[{ value: "true", label: i18n.t("Yes") }, { value: "false", label: i18n.t("No") }]}
                name="contact_promotional"
                valueKey="value"
                labelKey="label"
                inline={true}
              />
            </FieldGroup>
          </FieldGroup>
        </FieldGroup>
      </ModalForm>}
    </Fragment>);
  }

  confirmBody = () => {
    return (
      <div>
        <p>{i18n.t("We look forward to seeing you at this event. Please add this to your calendar and make sure to come back to this page 10 minutes before the scheduled time to get in.")}</p>
        <div className="pull-right m-t-30">
          <AddToCalendarButton accountName={this.props.accountName} event={this.props.event} />
        </div>
      </div>
    );
  }

  modalComplete = () => {
    return (
      <ConfirmationModal
        title={i18n.t("You're all set!")}
        modalClass="spotSaved"
        onConfirm={this.onComplete}
        onCancel={this.onComplete}
        confirmButtonClass="hidden"
        canClose={true}
        canClickOff={true}
        confirmOnly={true}
        show={this.state.showCompleteModal}
        children={this.confirmBody()}
      />
    );
  }

  render() {
    return (<>
      <Button
        bsStyle="primary"
        className="m-l-0"
        data-action="save-my-spot"
        onClick={this.openSaveSpot}
      >
        <Icon iconType="calendar" additionalClasses="m-l-0 m-r-5" />
        {i18n.t("Save My Spot")}
      </Button>
      {this.modalForm()}
      {this.modalComplete()}
    </>);
  }
}

export default connect(mapStateToProps)(SaveMySpotButton);
