import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Page, PagingDataList, SearchComponent, Toolbar } from 'reactifi';
import { Link } from 'react-router';
import OrganizationsForm from './OrganizationsForm';
import i18n from 'lib/i18n';
import debounce from 'lodash/debounce';

export default class Organizations extends Component {

  static propTypes = {
    accounts: PropTypes.array,
    accountsMeta: PropTypes.shape({
      total_count: PropTypes.number
    }),
    clearMessages: PropTypes.func.isRequired,
    createOrganization: PropTypes.func.isRequired,
    currentOrganization: PropTypes.object,
    errorMessage: PropTypes.string,
    isLoadingData: PropTypes.bool,
    loadOrganizations: PropTypes.func,
    selectOrganization: PropTypes.func.isRequired,
    successMessage: PropTypes.string,
    updateOrganization: PropTypes.func.isRequired
  };

  static defaultProps = {
    accounts: []
  }

  constructor(props) {
    super(props);
    this.loadOrganizations = debounce(this.loadOrganizations, 350, { leading: false, trailing: true });

    this.state = {
      activePage: 1,
      sortName: 'name',
      sortOrder: 'asc'
    };
  }

  loadOrganizations = () => {
    this.props.loadOrganizations(this.state.filters, { number: this.state.activePage });
  }

  onPageChange = activePage => {
    this.setState({ activePage }, this.loadOrganizations);
  }

  onSearch = filters => {
    this.setState({
      filters,
      activePage: 1
    }, this.loadOrganizations);
  }

  disableOrganizationToggle = organization => {
    organization.enabled = !organization.enabled;
    this.props.updateOrganization(organization, true);
  }

  createOrg = (...args) => {
    this.props.createOrganization(args[0], args[1], (this.state.activePage));
  }

  render() {

    let fields = [
      { name: 'name', title: i18n.t('Name'), canSort: true, formatter: (cell, row) => row.name },
      { name: 'code', title: i18n.t('Code'), formatter: (cell, row) => row.code ? row.code : '' },
      {
        name: 'topics',
        title: i18n.t('Topics'),
        formatter: (cell, row) => <Link to={{ pathname: `/${row.id}/topics`, state: { orgName: row.name } }}>view ({row.topics_count})</Link>
      },
      {
        name: 'users',
        title: i18n.t('Users'),
        formatter: (cell, row) => row.account_admins ?
          <Link to={{ pathname: `/${row.id}/account_admins`, state: { orgName: row.name } }}>view ({row.account_admins_count})</Link> : null
      },
      { name: 'organization_uuid', title: i18n.t('Foundry UUID'), formatter: (cell, row) => row.organization_uuid }
    ];

    const actions = [
      {
        icon: "block",
        title: i18n.t('Disable'),
        tooltip: i18n.t('Disable Organization'),
        type: "view",
        filter: row => row.enabled,
        actionType: "callToAction",
        action: (e, row) => {
          e.preventDefault();
          e.stopPropagation();
          this.disableOrganizationToggle(row);
        }
      },
      {
        icon: 'edit',
        title: i18n.t('Edit'),
        tooltip: i18n.t('Edit Organization'),
        type: 'view',
        filter: row => row.enabled,
        actionType: 'callToAction',
        action: (e, row) => {
          e.preventDefault();
          e.stopPropagation();
          this.props.selectOrganization(row.id);
        }
      },
      {
        icon: "replay",
        title: i18n.t('Enable'),
        tooltip: i18n.t('Enable Organization'),
        type: "view",
        filter: row => !row.enabled,
        actionType: "callToAction",
        action: (e, row) => {
          e.preventDefault();
          e.stopPropagation();
          this.disableOrganizationToggle(row);
        }
      }
    ];

    const tableOptions = {
      bordered: false,
      hover: true,
      striped: true,
      defaultSortName: 'name',
      defaultSortOrder: 'asc'
    };
    let actionButton = {
      label: i18n.t('Create Organization'),
      action: () => this.props.selectOrganization('add')
    };

    return (
      <Page
        contentType="list"
        title={i18n.t('Engage Organizations List')}
        actionButton={actionButton}
        errorMessage={this.props.currentOrganization ? null : this.props.errorMessage}
        successMessage={this.props.currentOrganization ? null : this.props.successMessage}
        clearMessages={this.props.clearMessages}
        toolbar={
          <Toolbar toolbarClass="filters-toolbar">
            <SearchComponent
              name="keyword"
              placeholder={i18n.t('Name, Foundry UUID, or Subdomain')}
              searchAction={this.onSearch}
              wrapperClass="full-width"
            />
          </Toolbar>
        }
        usePageWrapper={true}
      >
        <div className="page-panel">
          <OrganizationsForm currentOrganization={this.props.currentOrganization}
            selectOrganization={this.props.selectOrganization}
            updateOrganization={this.props.updateOrganization} createOrganization={this.createOrg}
            clearMessages={this.props.clearMessages} errorMessage={this.props.errorMessage} />
          <PagingDataList
            data={this.props.accounts}
            fields={fields}
            actions={actions}
            keyField="id"
            options={tableOptions}
            onPageChange={this.onPageChange}
            isLoadingData={this.props.isLoadingData}
            totalCount={this.props.accountsMeta ? this.props.accountsMeta.total_count : 0}
            activePage={this.state.activePage} />
        </div>
      </Page>
    )
  }
}
