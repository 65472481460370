import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { JsonApiDecorator, clearMessages, displayErrorMessage, displaySuccessMessage, ProgressSpinner } from 'reactifi';
import * as eventActionCreators from '../actions/eventActionCreators';
import * as partnerActionCreators from '../../Partner/actions/partnerActionCreators';
import * as orgTopicsActionCreators from '../../FinancialEducationContent/actions/orgTopicsActionCreators';
import * as attendeeActionCreators from '../../Attendee/actions/attendeeActionCreators';
import MyEvent from '../components/MyEvent';
import OthersEvent from '../components/OthersEvent';
import moment from 'moment';
import MetaTags from 'react-meta-tags';
import i18n from 'lib/i18n';
import { ENGAGE_DEMO_IMG } from 'lib/constants';
import { eventFinderRedirectByViewer } from '../libs/EventFinderRedirectionFunc';
import UpcomingEventFinder from "../components/UpcomingEventFinderComponent";
import { isWebinar } from "../functions/helpers";
import isEmpty from 'lodash/isEmpty';

const addEvents = (apiStore, props, ownProps) => {
  const eventId = ownProps.routeParams.id || props.eventId.toString();
  if (apiStore.events) {
    props.event = apiStore.events.find(
      eventId,
      {
        event_users: [],
        partner: [],
        reservations: [],
        topic: ['course_modules', 'offline_contents'],
        venue: []
      }
    );

    if (props.event) props.event.cancelled = !!props.event?.cancelled_at;
    props.newEntity = apiStore.newObject;
  }

  props.eventId = eventId;
  if (!props.event) props.event = {};
};

const addEventUsers = props => {
  if (props?.event?.event_users) {
    props.event.event_users.forEach((eventUser) => {
      eventUser.user_id = eventUser.user_id.toString();
      eventUser.current_user = eventUser.user_id === props.currentUserId;
      eventUser.lead = eventUser.user_id.toString() === props.event.lead_user_id && props.event.lead_user_id.toString();
      if (eventUser.current_user) props.currentEventUser = eventUser;
    });
  }
};

const addAccounts = (apiStore, props, ownProps) => {
  if (!apiStore.accounts) {
    props.account = ownProps.route.account || { id: ownProps.route.accountId.toString() };
  } else {
    props.account = apiStore.accounts.first('topic');
    if (props.account.event_statuses) {
      props.event.statusDisplay = Object.keys(props.account.event_statuses).find((key) => {
        return props.account.event_statuses[key] === props.event.status;
      });
    }
  }
};

const addEverfiContents = (apiStore, props) => {
  if (apiStore.everfi_contents) {
    props.everfiContents = apiStore.everfi_contents.all();
  }
}

const addLanguages = props => {
  if (props.account.languages) {
    const languageKeys = Object.keys(props.account.languages);
    const languages = props.account.languages;
    const eventLanguages = props.event.languages || props.eventLanguages;
    if (eventLanguages) {
      props.event.languageDisplay = eventLanguages.map((lang) => {
        return languageKeys.find((key) => languages[key] === lang);
      }).join(', ');
      if (props.event.topic) {
        props.event.topic.languages = []
        props.event.topic.offline_contents.forEach((oc) => {
          let languageName = languageKeys.find((key) => languages[key] === oc.language);
          if (!props.event.topic.languages.some((lang) => lang.name === languageName)) {
            props.event.topic.languages.push({ name: languageName, code: oc.language });
          }
        });
        props.event.topic.languageDisplay = props.event.topic.languages.map(lang => lang.name).join(', ');
      }
    }
    props.account.languageOptions = languageKeys.map((k) => {
      return { label: k, value: languages[k] };
    });
  }
};

const mapStateToProps = (state, ownProps) => {
  let apiStore = new JsonApiDecorator(state.api);
  let props = {
    adminifiUrl: ownProps.route.adminifiUrl,
    currentUserId: ownProps.route.currentUserId,
    eventId: ownProps.route.eventId,
    eventLanguages: ownProps.route.eventLanguages,
    eventType: ownProps.route.eventType,
    isAdmin: ownProps.route.isAdmin,
    showEditEventType: !!state.eventReducer.showEditEventType,
    showEditDateTime: !!state.eventReducer.showEditDateTime,
    showEditConferencingInfo: !!state.eventReducer.showEditConferencingInfo,
    showEditVenue: !!state.eventReducer.showEditVenue,
    showEditPartner: !!state.eventReducer.showEditPartner,
    showEditRegQuestions: !!state.eventReducer.showEditRegQuestions,
    viewer: ownProps.route.viewer,
    googleMapsApiKey: ownProps.route.googleMapsApiKey,
    evaluFiUrl: ownProps.route.evaluFiUrl,
    errorMessage: state.api.errorMessage,
    showAlertMessage: !state.eventReducer.showEditEventType && !state.eventReducer.showEditVenue && !state.eventReducer.showEditPartner && !state.eventReducer.showEditDateTime,
    subdomain: ownProps.route.subdomain,
    successMessage: state.api.successMessage,
    from: ownProps.route.from,
    isEventManagement: ownProps.route.isEventManagement
  };
  addEvents(apiStore, props, ownProps);
  addEventUsers(props);
  addAccounts(apiStore, props, ownProps);
  addEverfiContents(apiStore, props);
  addLanguages(props);
  props.partners = apiStore.partners ? apiStore.partners.all() : [];
  return props;
};

const metaTags = event => {
  const url = window.location;
  const locale = url.pathname.split("/")[1];
  let startTime = moment(event.start_time).format("L");
  let eventTitle = event[`title_${locale}`];
  let eventDescription = event[`description_${locale}`]

  return (
    <MetaTags>
      <title>{eventTitle}</title>
      <meta id="meta-description" name="description" content={eventDescription} />
      <meta id="og-title" property="og:title" content={eventTitle} />
      <meta id="og-type" property="og:type" content="article" />
      <meta id="og-url" property="og:url" content={url.href} />
      <meta id="og-image" property="og:image" content={(event.topic && event.topic.image_url) ? event.topic.image_url : ENGAGE_DEMO_IMG} />
      <meta id="og-description" property="og:description" content={i18n.t("signUpForWorkshopOnAt", { postProcess: 'sprintf', sprintf: [event.account_name, eventTitle, startTime, event.venue_title] })} />
      <meta id="og-site-name" property="og:site_name" content={i18n.t("numEventsPoweredByEverfi", { postProcess: 'sprintf', sprintf: [event.account_name] })} />
    </MetaTags>
  )
}

const ShowContainer = props => {
  const {
    account,
    adminifiUrl,
    currentEventUser,
    currentUserId,
    dispatch,
    errorMessage,
    evaluFiUrl,
    event,
    eventId,
    eventType,
    everfiContents,
    from,
    googleMapsApiKey,
    isAdmin,
    isEventManagement,
    newEntity,
    partners,
    router,
    showAlertMessage,
    showEditConferencingInfo,
    showEditDateTime,
    showEditEventType,
    showEditPartner,
    showEditVenue,
    showEditRegQuestions,
    subdomain,
    successMessage,
    venues,
    viewer
  } = props;

  const [isLoadingEvent, setIsLoadingEvent] = useState(true);

  const actions = bindActionCreators({
    clearMessages,
    displayErrorMessage,
    displaySuccessMessage,
    ...attendeeActionCreators,
    ...eventActionCreators,
    ...partnerActionCreators,
    ...orgTopicsActionCreators
  }, dispatch);

  useEffect(() => {
    const loadEvent = async () => {
      const include = [
        'attendees',
        'event_users',
        'partner',
        'reservations',
        event.topic_id ? '' : 'topic',
        'venue'
      ].filter(element => !!element).join();

      await actions.loadEvent(eventId, include);
    }

    loadEvent();
    setIsLoadingEvent(false);
  }, []);

  useEffect(() => { if (event.topic_id) actions.loadTopic(event.topic_id) }, [event.topic_id]);

  /**
   * Display `ProgressSpinner` until the following
   * have loaded:
   * - Event
   * - Event venue (where applicable)
   * - Event partner (where applicable)
   */
  if (
    !event?.id ||
    (event?.venue_id && isEmpty(event?.venue)) ||
    (event?.languages && !event?.languageDisplay) ||
    isLoadingEvent
  ) return <ProgressSpinner />

  const preRegister = (event.start_time ? moment(event.start_time).startOf('day').isAfter(moment().startOf('day')) : true);

  const joinEvent = (userId, specialMessage) => {
    let eventUser = newEntity('event_users');
    eventUser.event_id = event.id;
    eventUser.user_id = userId || currentUserId;
    eventUser.role = 'volunteer';
    return actions.joinEvent(eventUser, specialMessage);
  }

  const sharedProps = {
    accountName: account.name,
    adminifiUrl,
    currentEventUser,
    errorMessage,
    event,
    eventUsers: event.event_users,
    everfiContents,
    googleMapsApiKey,
    isEventManagement,
    isWebinar: isWebinar(event.event_type),
    joinEvent,
    preRegister,
    showAlertMessage,
    successMessage,
    topics: account.topics,
    viewer
  };

  return (
    <div>
      {metaTags(event)}
      {currentEventUser ?
        <MyEvent
          {...sharedProps}
          actions={actions}
          clearMessages={actions.clearMessages}
          displayErrorMessage={actions.displayErrorMessage}
          displaySuccessMessage={actions.displaySuccessMessage}
          evaluFiUrl={evaluFiUrl}
          isAdmin={isAdmin}
          languages={account.languageOptions}
          newEntity={newEntity}
          partners={partners}
          showEditConferencingInfo={showEditConferencingInfo}
          showEditDateTime={showEditDateTime}
          showEditEventType={showEditEventType}
          showEditPartner={showEditPartner}
          showEditVenue={showEditVenue}
          showEditRegQuestions={showEditRegQuestions}
          subdomain={subdomain}
          venues={venues}

        /> :
        event ?
          <OthersEvent
            {...sharedProps}
            clearMessages={actions.clearMessages}
            dispatch={dispatch}
            eventType={eventType}
            from={from}
            router={router}
            selectReservation={actions.selectReservation}
          /> :
          null
      }
      <div>
        <UpcomingEventFinder
          isEventManagement={isEventManagement}
          onSearch={eventFinderRedirectByViewer(viewer)}
          topics={account.topics}
        />
      </div>
    </div>
  );
};

ShowContainer.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.string,
    topics: PropTypes.array,
    name: PropTypes.string,
    languageOptions: PropTypes.array
  }),
  adminifiUrl: PropTypes.string,
  currentEventUser: PropTypes.object,
  currentUserId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  evaluFiUrl: PropTypes.string.isRequired,
  event: PropTypes.object.isRequired,
  eventId: PropTypes.string.isRequired,
  eventType: PropTypes.string,
  everfiContents: PropTypes.array,
  from: PropTypes.string,
  googleMapsApiKey: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool,
  isEventManagement: PropTypes.bool,
  newEntity: PropTypes.func,
  partners: PropTypes.array,
  router: PropTypes.object,
  showAlertMessage: PropTypes.bool,
  showEditConferencingInfo: PropTypes.bool,
  showEditDateTime: PropTypes.bool,
  showEditEventType: PropTypes.bool,
  showEditPartner: PropTypes.bool,
  showEditRegQuestions: PropTypes.bool,
  showEditVenue: PropTypes.bool,
  subdomain: PropTypes.string,
  successMessage: PropTypes.string,
  venues: PropTypes.array,
  viewer: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(ShowContainer);
