import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment-timezone';
import {
  Icon,
  TimezoneAbbr,
  Util
} from 'reactifi';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import GenerateAttendeeViewEventLink from "../../AttendeeEvent/components/GenerateAttendeeViewEventLink";
import i18n from 'lib/i18n';
import { isFutureEvent } from '../functions/helpers';
import { getGoogleMapsLink, registrationQuestions } from '../functions/helpers';
import EventDetailsItemComponent from './EventDetailsItemComponent';


export default class EventDetails extends React.Component {
  static propTypes = {
    currentEventUser: PropTypes.object,
    currentLead: PropTypes.bool,
    deleteEventUser: PropTypes.func,
    event: PropTypes.object.isRequired,
    from: PropTypes.string,
    isEventAdmin: PropTypes.bool,
    isEventManagement: PropTypes.bool,
    isWebinar: PropTypes.bool,
    toggleEditConferencingInfo: PropTypes.func,
    toggleEditDateTime: PropTypes.func,
    toggleEditEventType: PropTypes.func,
    toggleEditVenue: PropTypes.func,
    toggleEditPartner: PropTypes.func,
    toggleEditRegistrationQuestions: PropTypes.func,
    toggleCancelEvent: PropTypes.func,
    viewer: PropTypes.string
  };

  checkIn = () => {
    this.props.currentEventUser.checked_in = true;
  }

  removeUser = () => {
    this.props.deleteEventUser(this.props.currentEventUser, i18n.t("You have successfully quit this event"))
  }

  getMarkerInfoContent = (selectedPlace) => {
    let venue = this.props.event.venue;
    if (venue && venue.id === selectedPlace.id) {
      return (
        <div>
          <span> <b> {venue.title} </b> </span> <br />
          <span> {venue.location_formatted_address} </span>
        </div>
      )
    }
  }

  eventIsToday = eventDate => {
    const today = moment(new Date());
    return today.isSame(eventDate, 'day');
  }

  getVirtualEventContent = eventDate => {
    const { event, viewer } = this.props;
    if (viewer === "attendee") {
      if (isFutureEvent(moment(eventDate))) {
        return event.event_type === "Events::InPersonAndWebinar" ?
          i18n.t('This is an in-person event that can also be attended virtually. Return to this page 10 minutes before the workshop to access materials and conferencing info.') :
          i18n.t('Return to this page 10 minutes before the workshop to access materials and conferencing info.');
      } else if (this.eventIsToday(eventDate)) {
        return i18n.t('Get started via the link above.');
      } else {
        return i18n.t('This event has already taken place.');
      }
    }
    return (
      <>
        <a href={event.url} target="_blank" rel="noopener noreferrer">
          {event.url}
        </a>
        <br />
        {event.additional_info}
      </>
    );
  }

  getPartnerDisplayHeader = () => {
    const { event } = this.props;
    const partnerWebsite = event.partner_website;
    return partnerWebsite ? <a href={partnerWebsite} target="_blank" rel="noopener noreferrer">{event.partner_name}</a> : <span>{event.partner_name}</span>;
  }

  getPartnerDisplay = (event, partnerContactDisplay) => {
    if (event.partner_name) {
      return (<>
        <div>{this.getPartnerDisplayHeader()}</div>
        {partnerContactDisplay && (
          <div>
            {i18n.t('Contact')}: {event.partner && event.partner.contact_name || event.partner_contact_name} <br />{event.partner && event.partner.contact_email || event.partner_contact_email} | {event.partner && event.partner.contact_phone || event.partner_contact_phone}
          </div>
        )}
      </>);
    } else {
      return (<div>{i18n.t('No partner to display')}</div>);
    }
  }

  displayContact = () => {
    const { event } = this.props;
    let name = event.venue_contact_name || event.venue.contact_name;
    let email = event.venue_contact_email || event.venue.contact_email;
    let phone = event.venue_contact_phone || event.venue.contact_phone;
    return (<>
      {name} <div>{email} | {phone}</div>
    </>)
  }

  getDateTimeDisplay = (startTime, endTime, eventDate, timeZone) => {
    return (<>
      <span data-attribute="start_time">{startTime}</span> - <span data-attribute="end_time">{endTime}</span> <span data-attribute="time_zone">{timeZone}</span>
      <br />
      <span data-attribute="date">{eventDate}</span>
    </>);
  }

  getVenueDisplay = (venue, venueDisplay, viewer) => {
    const { isEventManagement } = this.props;
    const venueMapLink = getGoogleMapsLink(venue.location_formatted_address);

    return (
      <>
        {venue && <div>{venue.title}</div>}
        <div>{venueDisplay}</div>
        {
          viewer !== 'attendee' &&
          !isEventManagement && (
            <div>
              {i18n.t('Contact')}: {this.displayContact()}
            </div>
          )
        }
        {venueMapLink && (
          <a href={venueMapLink} target="_blank" rel="noopener noreferrer">
            {i18n.t('View on map')}
          </a>
        )}
      </>
    );
  }

  getVirtualAccessCategory = () => {
    const { event, isEventAdmin } = this.props;

    if (isEventAdmin) return i18n.t('Conferencing Info');

    return event.event_type === "Events::InPersonAndWebinar" ?
      i18n.t('Virtual Access') :
      i18n.t('Virtual Event');
  }

  displayRegQuestions = (canEdit) => {
    const { event, isEventManagement, toggleEditRegistrationQuestions } = this.props;
    if (isEventManagement && canEdit) {
      const questions = registrationQuestions.filter((question) => event[question.id]);
      let questionsLine = (<>
        {questions.map((question) => (
          <div key={question.id}>{question.name}</div>
        ))}
      </>);
      return (
        <EventDetailsItemComponent
          {...Util.dataAttribsForEl(event, { attribute: 'registration_questions' })}
          category={i18n.t('Registration Questions')}
          dataAttributesForEdit={Util.dataAttribsForEl(event, { action: 'edit-registration-questions' })}
          editAction={canEdit && (() => toggleEditRegistrationQuestions(true))}
          icon="view-list"
          value={questions.length > 0 ? questionsLine : i18n.t('No questions selected')}
        />
      );
    }
  }

  // eslint-disable-next-line complexity
  render() {
    const {
      currentEventUser,
      currentLead,
      event,
      isWebinar,
      toggleCancelEvent,
      toggleEditConferencingInfo,
      toggleEditDateTime,
      toggleEditEventType,
      toggleEditVenue,
      toggleEditPartner,
      viewer
    } = this.props;

    /*
      Set up time/time_zone for local or event time depending on editable:
    */
    let startTime = moment(event.start_time).format('LT');
    let endTime = moment(event.end_time).format('LT');
    let timeZone = TimezoneAbbr();
    if (event.start_time && toggleEditDateTime) {
      startTime = moment(event.start_time).tz(event.time_zone).format('h:mm A');
      endTime = moment(event.end_time).tz(event.time_zone).format('h:mm A');
      timeZone = TimezoneAbbr(event.time_zone);
    }

    const eventDate = moment(event.start_time).format('LL');
    const canEdit = currentEventUser && !event.cancelled && viewer !== 'attendee';
    const venue = event.venue;
    const venueDisplay = venue ? `${venue.location_formatted_address} ${venue.location_room}` : '';
    const partnerDisplay = event.partner_name || viewer !== 'attendee';
    const partnerContactDisplay = viewer !== 'attendee' && !isWebinar && event.partner_contact_name && event.partner_contact_email && event.partner_contact_phone;
    return (
      <div className="event-card card">
        <div>
          <div className="container card-body icon-info-edit">
            <div className="row">
              <div className="col-12"><h4>{i18n.t('Event Details')}</h4></div>
            </div>
            <EventDetailsItemComponent
              {...Util.dataAttribsForEl(event, { attribute: 'date_and_time' })}
              category={i18n.t('Date and Time')}
              dataAttributesForEdit={Util.dataAttribsForEl(event, { action: 'edit-date-time' })}
              editAction={canEdit && (() => toggleEditDateTime(true))}
              icon="time"
              value={this.getDateTimeDisplay(startTime, endTime, eventDate, timeZone)}
            />

            {venue && (
              <EventDetailsItemComponent
                {...Util.dataAttribsForEl(event, { attribute: 'venue' })}
                category={i18n.t('Venue')}
                dataAttributesForEdit={Util.dataAttribsForEl(event, { action: 'edit-venue' })}
                editAction={canEdit && (() => toggleEditVenue(true))}
                icon="pin"
                value={this.getVenueDisplay(venue, venueDisplay, viewer)}
              />
            )}

            {!!event?.url && (
              <EventDetailsItemComponent
                {...Util.dataAttribsForEl(event, { attribute: 'url' })}
                category={this.getVirtualAccessCategory()}
                dataAttributesForEdit={Util.dataAttribsForEl(event, { action: 'edit-conferencing-info' })}
                editAction={canEdit && (() => toggleEditConferencingInfo(true))}
                icon="account-box"
                value={this.getVirtualEventContent(event.start_time)}
              />
            )}

            {!!event?.languageDisplay && (
              <EventDetailsItemComponent
                {...Util.dataAttribsForEl(event, { attribute: 'languages' })}
                category={i18n.t('Multilingual Assistance')}
                dataAttributesForEdit={Util.dataAttribsForEl(event, { action: 'edit-multilingual-assistance' })}
                editAction={canEdit && (() => toggleEditEventType(true))}
                icon="globe-alt"
                value={event.languageDisplay}
              />
            )}

            {partnerDisplay && (
              <EventDetailsItemComponent
                {...Util.dataAttribsForEl(event, { attribute: 'partner' })}
                category={i18n.t('Partner')}
                dataAttributesForEdit={Util.dataAttribsForEl(event, { action: 'edit-partner' })}
                editAction={canEdit && (() => toggleEditPartner(true))}
                icon="accounts-alt"
                value={this.getPartnerDisplay(event, partnerContactDisplay)}
              />
            )}

            {this.displayRegQuestions(canEdit)}

            {event.private === true && viewer !== 'attendee' &&
              <div className="row">
                <div className="col-12">
                  <h3><a href={GenerateAttendeeViewEventLink(event)} target="_blank" rel="noopener noreferrer"> {i18n.t("Share Event Link")}</a></h3>
                </div>
              </div>
            }
          </div>
        </div>
        <div id="quit-cancel-event">
          {!event.event_over && !event.cancelled && currentEventUser && currentLead &&
              <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip-disabled">{i18n.t('A cancelled event will not take place. Cancelled events can be filtered out in reporting.')}</Tooltip>}>
                <a
                  className="btn btn-secondary"
                  onClick={() => toggleCancelEvent(true)}
                  data-object-type="events"
                  data-object-id={event.id}
                  data-action="cancel-event">
                  <Icon iconType="close" /> {i18n.t('Cancel Event')}
                </a>
              </OverlayTrigger>
          }
          {event.cancelled ? `${i18n.t('Cancel Reason:')} ${event.cancellation_reason}` : ''}
          {!event.cancelled && currentEventUser && !currentLead && (
            <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip-disabled">{i18n.t('Your name will be removed from the volunteer list for this event.')}</Tooltip>}>
              <a
                className="btn warning"
                onClick={() => this.removeUser()}
                data-object-type="event_users"
                data-object-id={currentEventUser.id}
                data-action="quit-event">
                <Icon iconType="close" /> {i18n.t('Quit Event')}
              </a>
            </OverlayTrigger>
          )}
        </div>
      </div>
    );
  }
}
