import moment from 'moment';
import i18n from 'lib/i18n';

export const isFutureEvent = eventDate => {
  const hoursBefore = moment.duration(eventDate.diff(moment())).asHours();
  return hoursBefore > 1;
}

export const isWebinar = eventType => {
  return eventType === "Events::Webinar";
}

export const formatTime = time => {
  return moment(time).format('YYYY-MM-DDTHH:mm:ss.sssZ');
}

export const getEventTypes = isEventMangement => {
  return isEventMangement ?
    ["Events::InPerson", "Events::InPersonAndWebinar", "Events::Webinar"] :
    ["Events::FinancialEducation", "Events::BusinessDevelopment", "Events::OtherCommunityService"];
}

export const incomeOptions = [
  { value: "$0 - $20,000", label: "$0 - $20,000" },
  { value: "$20,001 - $34,999", label: "$20,001 - $34,999" },
  { value: "$35,000 - $49,999", label: "$35,000 - $49,999" },
  { value: "$50,000 - $74,999", label: "$50,000 - $74,999" },
  { value: "$75,000 - $99,999", label: "$75,000 - $99,999" },
  { value: "$100,000 - $149,999", label: "$100,000 - $149,999" },
  { value: "$150,000 - $199,999", label: "$150,000 - $199,999" },
  { value: "$200,000 or more", label: i18n.t("$200,000 or more") },
  { value: "Prefer not to say", label: i18n.t("Prefer not to say") }
];

export const ageOptions = [
  { value: "18-24", label: "18-24" },
  { value: "25-34", label: "25-34" },
  { value: "35-44", label: "35-44" },
  { value: "45-54", label: "45-54" },
  { value: "55-64", label: "55-64" },
  { value: "65-74", label: "65-74" },
  { value: "75+", label: "75+" },
  { value: "Prefer not to say", label: i18n.t('Prefer not to say') }
];

export const genderOptions = [
  { value: "Female", label: i18n.t('Female') },
  { value: "Male", label: i18n.t('Male') },
  { value: "Prefer to self-describe", label: i18n.t('Prefer to self-describe') },
  { value: "Prefer not to say", label: i18n.t('Prefer not to say') }
];

export const registrationQuestions = [
  {
    name: i18n.t('What is your household income?'), id: 'income_level_enabled', state: false,
    type: 'select', options: incomeOptions, formname: 'income_level'
  },
  {
    name: i18n.t('What is your home Zip / Postal code?'), id: 'home_zip_enabled', state: false,
    type: 'input', formname: 'home_zip'
  },
  {
    name: i18n.t('What is the name of your current company or employer?'), id: 'employer_name_enabled', state: false,
    type: 'input', formname: 'employer_name'
  },
  {
    name: i18n.t('What is your age?'), id: 'age_enabled', state: false,
    type: 'select', options: ageOptions, formname: 'age'
  },
  {
    name: i18n.t('How do you identify your gender?'), id: 'gender_enabled', state: false,
    type: 'select', options: genderOptions, formname: 'gender'
  }
];

/**
 * Opens `url` in a new window and avoids security flaw
 * that comes with `_blank`
 *
 * @param {String} url
 */
export const openInNewTab = url => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

/**
 *
 * @param {String} address
 * @returns Google Maps address link
 *
 * See https://stackoverflow.com/a/1300922
 */
export const getGoogleMapsLink = address => `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;

