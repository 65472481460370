import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { SearchForm, SelectControl, DateTimePickerControl, FieldGroup, FloatingLabelFormField, Icon } from 'reactifi';
import moment from 'moment';
import i18n from 'lib/i18n';
import { getEventTypes } from '../../functions/helpers';

const getSearchFieldHelpText = isEventManagement => isEventManagement ? i18n.t("Event Title") : i18n.t("Event Title or Location");

const formatTime = time => (time && moment(time).format('YYYY-MM-DD')) || null;

const displayOptions = [
  { value: 'full', label: i18n.t('Full') },
  { value: 'my', label: i18n.t('My') },
  { value: 'available', label: i18n.t('Available') },
  { value: 'past', label: i18n.t('Past') }
];

const sortOptions = [
  { label: i18n.t('Chronological'), value: 'start_time' },
  { label: i18n.t('Event Title'), value: 'title' }
];

const VolunteerEventSearchForm = props => {
  const {
    currentUserId,
    defaultSearchParams,
    eventStatuses,
    eventTypes,
    isEventManagement,
    isLoadingEvents,
    languages,
    readEvents,
    topics
  } = props;

  const getDefaultFilters = () => {
    return {
      start_date: moment().format('YYYY-MM-DD'),
      ...defaultSearchParams?.display ? { user_ids: [currentUserId] } : {},
      ...defaultSearchParams
    };
  }

  const [currentFilter, setCurrentFilter] = useState(getDefaultFilters());
  const [currentSort, setCurrentSort] = useState([{ value: 'start_time', direction: 'asc' }]);
  const [endTime, setEndTime] = useState();
  const [startTime, setStartTime] = useState(moment().format('YYYY-MM-DD'));


  useEffect(() => {
    readEvents('venue,event_users', { ...currentFilter, start_date: startTime, end_date: endTime }, currentSort);
  }, [currentFilter, currentSort, endTime, startTime]);

  const searchEvents = searchParams => {
    switch (searchParams.display) {
    case 'full':
      searchParams.full = true;
      break;
    case 'my':
      searchParams.user_ids = [currentUserId];
      break;
    case 'available':
      searchParams.full = false;
      break;
    case 'past':
      searchParams.past = true;
      break;
    }

    if (!searchParams.display !== 'my') delete searchParams.user_ids;

    setCurrentFilter(searchParams);
  };

  const sortEvents = option => {
    setCurrentSort([{ value: option.value, direction: 'asc' }]);
  };

  const getSearchForm = (displayOptions, availableEventTypes, sortOptions) => (
    <SearchForm searchAction={searchEvents} disabled={isLoadingEvents} className="search-form" defaultValues={currentFilter} valueOverride={false}>
      <FieldGroup className="row">
        <FieldGroup className="col-md-3">
          <FloatingLabelFormField name="keyword" caption={getSearchFieldHelpText(isEventManagement)} searchField={true} placeholder="Use keyboard for predictive text" />
        </FieldGroup>
        <FieldGroup className="col-md-3">
          <FloatingLabelFormField name="event_type" caption={i18n.t("Event Type")}>
            <SelectControl options={availableEventTypes} valueKey="value" labelKey="label" multi={true} placeholder=" " searchField={true} />
          </FloatingLabelFormField>
        </FieldGroup>
        <FieldGroup className="col-md-2">
          <FloatingLabelFormField name="topic_id" caption={i18n.t("Topic")}>
            <SelectControl options={topics} valueKey="id" labelKey="name" multi={true} placeholder=" " />
          </FloatingLabelFormField>
        </FieldGroup>
        <FieldGroup className="col-md-2">
          <FloatingLabelFormField caption={i18n.t("From")} className="icon-label" name="start_date" onChange={startTime => { setStartTime(formatTime(startTime)) }} >
            <DateTimePickerControl />
          </FloatingLabelFormField>
        </FieldGroup>
        <FieldGroup className="col-md-2">
          <FloatingLabelFormField caption={i18n.t("To")} className="icon-label" name="end_date" onChange={endTime => { setEndTime(formatTime(endTime)) }} >
            <DateTimePickerControl className="datetime-right-aligned" />
          </FloatingLabelFormField>
        </FieldGroup>
      </FieldGroup>
      <FieldGroup className="row">
        <FieldGroup className="col-md-3">
          <FloatingLabelFormField name="languages" caption={i18n.t("Multi-lingual Assistance")}>
            <SelectControl options={languages} valueKey="value" labelKey="label" multi={true} placeholder=" " />
          </FloatingLabelFormField>
        </FieldGroup>
        <FieldGroup className="col-md-3">
          <FloatingLabelFormField name="status" caption={i18n.t("Status")}>
            <SelectControl options={eventStatuses} valueKey="value" labelKey="label" multi={true} placeholder=" " />
          </FloatingLabelFormField>
        </FieldGroup>
        <FieldGroup className="col-md-2">
          <FloatingLabelFormField name="display" caption={i18n.t("Only Display")}>
            <SelectControl options={displayOptions} valueKey="value" labelKey="label" placeholder=" " />
          </FloatingLabelFormField>
        </FieldGroup>
        <FieldGroup className="col-md-2">
          {/*this div ensures the search form doesn't try to use this ordering field as part of the search*/}
          <div>
            <FloatingLabelFormField name="order_by" dataValue={sortOptions[0]} caption={i18n.t("Order By")}>
              <SelectControl onChange={sortEvents} clearable={false} options={sortOptions} valueKey="label" labelKey="label" placeholder=" " />
            </FloatingLabelFormField>
          </div>
        </FieldGroup>
      </FieldGroup>
    </SearchForm>
  );


  const availableEventTypes = eventTypes.reduce((eventTypes, eventType, index) => {
    if (getEventTypes(isEventManagement).includes(eventType.value)) {
      eventTypes.push({ ...eventType, id: `event_type_${index}` });
    } return eventTypes;
  }, []);

  return (
    <div className="form-group search-panel event-search-form">
      <button type="button" className="btn btn-primary btn-lg btn-filtertoggle" data-toggle="collapse" data-target="#searchcard" aria-expanded="false"><Icon iconType="filter-list" />{i18n.t('Filter List')}</button>
      <div className="card search-panel" >
        {isLoadingEvents ? <div className="progress-blocker"><span>loading...</span></div> : null}
        <div className="card-body collapse" id="searchcard">
          {getSearchForm(displayOptions, availableEventTypes, sortOptions)}
        </div>
      </div>
    </div>
  );
};

VolunteerEventSearchForm.propTypes = {
  currentUserId: PropTypes.string.isRequired,
  defaultSearchParams: PropTypes.object,
  eventStatuses: PropTypes.array,
  eventTypes: PropTypes.array,
  isEventManagement: PropTypes.bool,
  isLoadingEvents: PropTypes.bool,
  languages: PropTypes.array,
  readEvents: PropTypes.func.isRequired,
  topics: PropTypes.array
};

export default VolunteerEventSearchForm;
