import PropTypes from 'prop-types';
import React from 'react';
import { ModalForm, AddressPickerControl, FieldGroup, FloatingLabelFormField, FormSection } from 'reactifi';
import i18n from 'lib/i18n';

export default class WorkLocationForm extends React.Component {
  static propTypes = {
    currentWorkLocation: PropTypes.object,
    errorMessage: PropTypes.string,
    selectWorkLocation: PropTypes.func.isRequired,
    createWorkLocation: PropTypes.func.isRequired,
    updateWorkLocation: PropTypes.func.isRequired,
    clearMessages: PropTypes.func.isRequired,
    googleMapsApiKey: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
  }

  onCancel() {
    this.props.selectWorkLocation(null);
    this.props.clearMessages();
  }

  render() {
    let modalTitle = this.props.currentWorkLocation && this.props.currentWorkLocation.id ? i18n.t('editNumber', { postProcess: 'sprintf', sprintf: [this.props.currentWorkLocation.name] }) : i18n.t('Add a New Work Location');

    return (
      <ModalForm data={this.props.currentWorkLocation} errorMessage={this.props.errorMessage} title={modalTitle}
        onCancel={this.onCancel} addAction={this.props.createWorkLocation} updateAction={this.props.updateWorkLocation}>
        <FieldGroup className="container-fluid">
          <FormSection title={i18n.t('Work Location Details')}>
            <FieldGroup className="row">
              <FieldGroup className="col-6">
                <FloatingLabelFormField name="external_id" caption={i18n.t("Location ID")} required={true}/>
              </FieldGroup>
            </FieldGroup>
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField name="location_latitude" caption={i18n.t("Address (Type and select below)")} required={true}>
                  <AddressPickerControl googleMapsApiKey={this.props.googleMapsApiKey} initialData={this.props.currentWorkLocation} placeholder="" />
                </FloatingLabelFormField>
              </FieldGroup>
            </FieldGroup>
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField name="location_room" caption={i18n.t("Address Line 2")}/>
              </FieldGroup>
            </FieldGroup>
          </FormSection>
          <FormSection title={i18n.t('Contact Information')}>
            <FieldGroup className="row">
              <FieldGroup className="col-6">
                <FloatingLabelFormField name="contact_name" caption={i18n.t("Name")}/>
              </FieldGroup>
              <FieldGroup className="col-6">
                <FloatingLabelFormField name="contact_phone" caption={i18n.t("Phone No.")} required={false}/>
              </FieldGroup>
            </FieldGroup>
            <FieldGroup className="row">
              <FieldGroup className="col-6">
                <FloatingLabelFormField name="contact_email" type="email" caption={i18n.t("Email")} required={false}/>
              </FieldGroup>
            </FieldGroup>
          </FormSection>
        </FieldGroup>
      </ModalForm>)
  }
}
