import mirrorCreator from 'mirror-creator';
import { selectEntity, createEntity, readEntity, updateEntity, deleteEntity, S3Uploader, displayError } from 'reactifi';
export { clearMessages, displayError } from 'reactifi';

export const actionTypes = mirrorCreator([
  'SET_TOPIC_ID',
  'UPLOAD_IN_PROGRESS',
  'UPLOAD_COMPLETE'
]);


export function loadTopics(filters, page = 1){
  return readEntity('topics', ['offline_contents', 'events', 'everfi_content', 'course_modules', 'image'], filters, null, page);
}

export function selectTopic(topicId){
  return selectEntity(topicId, 'topics', 'offline_contents,image,everfi_contents');
}

export function updateTopic(topic, filters){
  let sendSurvey = topic.send_survey ? [parseInt(topic.send_survey, 10)] : [];
  topic.send_survey = sendSurvey;
  return updateEntity(topic, (dispatch) => {
    dispatch(selectEntity(null, 'topics'));
    loadTopics(filters);
  });
}

export function createTopic(topic, addAnother){
  let sendSurvey = topic.send_survey ? [parseInt(topic.send_survey, 10)] : [];
  topic.send_survey = sendSurvey;
  return createEntity(topic, (dispatch) => {
    //do it this way so the modal unloads and reloads, which makes sure the form clears
    dispatch(selectEntity(null, 'topics'));
    if (addAnother) {
      dispatch(selectEntity('add', 'topics'));
    }
  });
}

export function setTopic(topicId){
  return {
    type: actionTypes.SET_TOPIC_ID,
    topicId
  }
}

function uploadInProgress(){
  return {
    type: actionTypes.UPLOAD_IN_PROGRESS
  }
}

function uploadComplete(){
  return {
    type: actionTypes.UPLOAD_COMPLETE
  }
}

export function uploadError(response){
  return displayError(response);
}

export function loadContent(filters){
  return readEntity('everfi_contents', 'course_modules', filters);
}

export function createContent(file, offline_content, addAnother){
  return createEntity(offline_content, async (dispatch, getState, updatedOfflineContent) => {
    dispatch(uploadInProgress())
    await S3Uploader(file, updatedOfflineContent.data.attributes.content_upload_url_fields, updatedOfflineContent.data.attributes.content_upload_url);
    dispatch(selectEntity(null, 'offline_contents'));
    dispatch(uploadComplete());
  })
}

export function updateContent(content, filters){
  return updateEntity(content, (dispatch) => {
    dispatch(selectEntity(null, 'offline_contents'));
  });
}

export function selectContent(contentId){
  return selectEntity(contentId, 'offline_contents', 'topic');
}

export function deleteContent(offline_content){
  return deleteEntity(offline_content, 'offline_contents');
}
