/* eslint-disable react/no-multi-comp */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import {
  buildGenericProps,
  ConfirmationModal,
  Header,
  Icon,
  IconCategoryValue,
  MessagesModule,
  NoResultsCard,
  PageWrapper,
  PagingDataList,
  Section,
  Storage
} from 'reactifi';
import * as partnerActionCreators from '../actions/partnerActionCreators'
import PartnerForm from '../components/PartnerForm';
import PartnerDetails from '../components/PartnerDetails';
import PartnerSearchForm from '../components/PartnerSearchForm';
import switchOn from '../../../../images/switch-on.svg';
import switchOff from '../../../../images/switch-off.svg';

import i18n from 'lib/i18n';

const mapStateToProps = (state, ownProps) => {
  let props = buildGenericProps(state, 'partners', ['headquarters_partner']);
  if (props.currentEntity) {
    props.currentPartner = props.currentEntity;
  }
  props.isEventManagement = ownProps.isEventManagement;
  return props;
}

const preventClickthrough = event => {
  event.stopPropagation();
};

const getFields = ({ actions, isEventManagement, togglePartnerEnabled, togglePartnerPublished, viewer }) => {
  return [
    {
      name: 'name', title: i18n.t("Name"), width: isEventManagement ? '100%' : '20%', canSort: true,
      formatter: (cell, row) => (
        <IconCategoryValue icon={row.headquarters_partner_id ? null : 'city'} value={cell} />
      ),
      mobileFormatter: (cell, row) => (
        <IconCategoryValue icon={row.headquarters_partner_id ? null : 'city'} value={cell} />
      )
    },
    ...!isEventManagement ? [
      { name: 'location_formatted_address', title: i18n.t("Address"), width: '40%', canSort: true },
      { name: 'location_room', title: i18n.t("Address Line 2"), width: '20%', canSort: true },
      { name: 'contact_name', title: i18n.t("Contact"), width: '20%', canSort: true }
    ] : [],
    {
      name: 'event_count', title: i18n.t("Events"), width: 100, align: 'center', canSort: true, formatter: (cell, row) =>
        <a onClick={preventClickthrough} href={`events#?filterField=partner_id&filterValue=${row.id}`}>{row.event_count}</a>
    },
    {
      name: 'website', title: i18n.t("Website"), width: 120, align: 'center', canSort: true, formatter: (cell, row) =>
        <a onClick={preventClickthrough} href={cell} target="_blank" rel="noopener noreferrer"><Icon iconType="globe" size="medium" /></a>
    },
    ...viewer === 'account_admin' ? [{
      name: 'enabled', title: i18n.t("Show in Reports"), width: 140, align: 'center', formatter: (cell, row) => {
        return (
          <span><OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-enabled">{i18n.t('Turn off to filter partners out of reports')}</Tooltip>}>
            <a onClick={(e) => togglePartnerEnabled(e, row)}>{row.enabled ? <img className="switch-icon" alt={i18n.t("Switch On")} src={switchOn} /> : <img className="switch-icon" alt={i18n.t("Switch Off")} src={switchOff} />}</a>
          </OverlayTrigger></span>
        )
      }
    }, {
      name: 'published', title: i18n.t("Available"), width: 100, align: 'center', formatter: (cell, row) => {
        return (
          <span><OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-published">{i18n.t('Allow other volunteers to select this partner')}</Tooltip>}>
            <a onClick={(e) => togglePartnerPublished(e, row)}>{row.master ? <img className="switch-icon" alt={i18n.t("Switch On")} src={switchOn} /> : <img className="switch-icon" alt={i18n.t("Switch Off")} src={switchOff} />}</a>
          </OverlayTrigger></span>
        )
      }
    }] : [],
    ...window.isMobile ? [{
      name: 'edit_partner', title: i18n.t("Edit Partner"), canSort: false, formatter: (cell, row) => {
        return (
          <span>
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-edit">{i18n.t('Edit Partner')}</Tooltip>}>
              <a onClick={(e) => actions.selectPartner(row.id)} data-id={row.id}><Icon iconType="edit" /></a>
            </OverlayTrigger>
          </span>
        )
      }
    }] : []
  ];
};

const getActionButton = (actions, viewer) => {
  return viewer === 'account_admin' ?
    {
      action: (e) => actions.selectPartner('add'),
      label: i18n.t('Create Partner')
    } :
    null;
};

const getNoResultsMessage = viewer => {
  return viewer === 'volunteer' ?
    i18n.t("Partner data will appear here") :
    i18n.t("Partners are external collaborators");
};

const getNoResultsButtons = (actions, viewer) => {
  return [
    ...viewer !== 'volunteer' ? [{
      label: i18n.t("Create Partner"),
      onClick: (e) => actions.selectPartner('add')
    }] : []
  ]
};

const PartnerContainer = props => {
  const {
    collection,
    currentPartner,
    dispatch,
    errorMessage,
    googleMapsApiKey,
    isEventManagement,
    isLoadingPartners,
    partners,
    partnersMeta,
    successMessage,
    viewer
  } = props;

  const actions = bindActionCreators(partnerActionCreators, dispatch);

  const [activePage, setActivePage] = useState(1);
  const [confirmation, setConfirmation] = useState({
    body: '',
    data: null,
    confirmButtonText: '',
    title: '',
    which: ''
  });
  const [filters, setFilters] = useState({});
  const [pageSize, setPageSize] = useState(Storage.pageSize);
  const [activeSortName, setActiveSortName] = useState('name');
  const [activeSortOrder, setActiveSortOrder] = useState('asc');

  useEffect(() => {
    actions.findPartners(filters, { number: activePage, size: pageSize }, { value: activeSortName, direction: activeSortOrder });
  }, [activePage, filters, pageSize, activeSortName, activeSortOrder]);

  const searchAction = filters => {
    setFilters(filters);
    setActivePage(1);
  };

  const onPageChange = page => {
    if (page !== 1 && page === activePage) return;
    setActivePage(page);
    setPageSize(Storage.pageSize);
  }

  const onSortChange = (sortName, sortOrder) => {
    if (sortName === activeSortName && sortOrder === activeSortOrder) return;
    setActivePage(1);
    setActiveSortName(sortName);
    setActiveSortOrder(sortOrder);
  }

  const updatePartner = currentPartner => {
    actions.updatePartner(currentPartner, filters);
  };

  const clearConfirmationLabels = () => {
    setConfirmation({
      body: '',
      data: null,
      title: '',
      confirmButtonText: '',
      which: ''
    });
  };

  const onConfirmConfirmation = partner => {
    if (confirmation.which === 'enabled') {
      partner.enabled = !partner.enabled;
    } else {
      partner.master = !partner.master;
    }
    updatePartner(partner);
    clearConfirmationLabels();
  };

  const togglePartnerEnabled = (event, partner) => {
    event.preventDefault();
    event.stopPropagation();

    setConfirmation({
      body: partner.enabled ?
        i18n.t("Are you sure you want to remove this partner from reports?") :
        i18n.t("Are you sure you want to add this partner to reports?"),
      data: partner,
      confirmButtonText: partner.enabled ?
        i18n.t("Disable Partner") :
        i18n.t("Enable Partner"),
      title: partner.enabled ?
        i18n.t("Disable Partner") :
        i18n.t("Enable Partner"),
      which: 'enabled'
    });
  };


  const togglePartnerPublished = (event, partner) => {
    event.preventDefault();
    event.stopPropagation();

    setConfirmation({
      body: partner.master ?
        i18n.t("Are you sure you want to make this partner unavailable?") :
        i18n.t("Are you sure you want to make this partner available?"),
      data: partner,
      confirmButtonText: partner.master ?
        i18n.t("Unpublish Partner") :
        i18n.t("Publish Partner"),
      title: partner.master ?
        i18n.t("Unpublish Partner") :
        i18n.t("Publish Partner"),
      which: 'published'
    });
  }

  const createPartner = async (partner, addAnother, isCreateEvent) => {
    await actions.createPartner(partner, !!addAnother, !!isCreateEvent);
    actions.findPartners(filters);
  }

  const tableOptions = {
    onRowClick: (_, row) => actions.selectPartner(row.id),
    onSortChange,
    defaultSorted: [{ dataField: activeSortName, order: activeSortOrder }]
  };

  return (
    <PageWrapper>
      <MessagesModule
        errorMessage={currentPartner ? null : errorMessage}
        successMessage={currentPartner ? null : successMessage}
        clearMessages={actions.clearMessages}
      />
      <Header title={i18n.t('Partner List')} button={getActionButton(actions, viewer)} />
      <Section>
        <PartnerSearchForm
          disabled={isLoadingPartners}
          isEventManagement={isEventManagement}
          searchAction={searchAction}
          viewer={viewer}
        />
        {viewer !== 'volunteer' ?
          <PartnerForm
            clearMessages={actions.clearMessages}
            createPartner={createPartner}
            currentPartner={currentPartner}
            electPartner={actions.selectPartner}
            errorMessage={errorMessage}
            googleMapsApiKey={googleMapsApiKey}
            isEventManagement={isEventManagement}
            loadPartner={actions.loadPartner}
            selectPartner={actions.selectPartner}
            updatePartner={updatePartner}
            viewer={viewer}
          /> :
          <PartnerDetails data={currentPartner} selectPartner={actions.selectPartner} />}
      </Section>
      <Section type="list">
        {(isLoadingPartners || partners.length || Object.keys(filters).length) && partnersMeta ?
          <PagingDataList
            actions={actions}
            activePage={activePage}
            data={collection}
            fields={getFields({ actions, isEventManagement, togglePartnerEnabled, togglePartnerPublished, viewer })}
            isLoadingData={isLoadingPartners}
            keyField="id"
            onPageChange={onPageChange}
            options={tableOptions}
            totalCount={partnersMeta.total_count}
          /> :
          <NoResultsCard message={getNoResultsMessage(viewer)} buttons={getNoResultsButtons(actions, viewer)} />
        }
      </Section>
      <ConfirmationModal
        {...confirmation}
        canClickOff={true}
        onCancel={clearConfirmationLabels}
        onConfirm={onConfirmConfirmation}
      >
        <div>{confirmation.body}</div>
      </ConfirmationModal>
    </PageWrapper>
  );
}

PartnerContainer.propTypes = {
  collection: PropTypes.object,
  currentPartner: PropTypes.object,
  data: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isEventManagement: PropTypes.bool,
  isLoadingPartners: PropTypes.bool,
  googleMapsApiKey: PropTypes.string.isRequired,
  partners: PropTypes.array,
  partnersMeta: PropTypes.shape({
    total_count: PropTypes.number
  }),
  successMessage: PropTypes.string,
  viewer: PropTypes.string
};

export default connect(mapStateToProps)(PartnerContainer);
