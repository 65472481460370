import { actionTypes } from '../actions/topicsActionCreators';

export default function topicReducer(state = {}, action) {
  switch (action.type) {
  case actionTypes.SET_TOPIC_ID:
    return Object.assign({}, state, { currentTopicId: action.topicId });
  case actionTypes.UPLOAD_IN_PROGRESS:
    return Object.assign({}, state, { uploadInProgress: true });
  case actionTypes.UPLOAD_COMPLETE:
    return Object.assign({}, state, { uploadInProgress: false });
  default:
    return state;
  }
}
