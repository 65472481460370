import React from 'react';
import { Router, Route, hashHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux'
import { ErrorBoundedProvider } from 'reactifi';
import createStore from './store/contentStore';
import ContentContainer from './containers/ContentContainer';


export default (props, _railsContext) => {
  const store = createStore(props);
  const history = syncHistoryWithStore(hashHistory, store);
  const reactComponent = (
    <ErrorBoundedProvider store={store}>
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <Route path="/" component={ContentContainer} contentServiceUrl={_railsContext.contentServiceUrl} accountId={props.accountId} currentUserId={props.currentUserId} jwtToken={_railsContext.jwtToken} viewer={props.viewer}/>
      </Router>
    </ErrorBoundedProvider>
  );

  return reactComponent;
};
