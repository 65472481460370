import PropTypes from 'prop-types';
import React from 'react';
import {
  FieldGroup, ModalForm, MaterialCheckbox
} from 'reactifi';
import i18n from 'lib/i18n';

export default class RegQuestionsForm extends React.Component {
  static propTypes = {
    clearMessages: PropTypes.func,
    event: PropTypes.object,
    errorMessage: PropTypes.string,
    regQuestions: PropTypes.object,
    toggleAddRegQuestions: PropTypes.func,
    updateEvent: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      event: null,
      savedQuestions: []
    }
  }

  onCancel = () => {
    this.props.clearMessages();
    let event = this.state.event;
    this.props.regQuestions.map(question => {
      if(this.state.savedQuestions.length > 0 && this.state.savedQuestions.some(saved => saved.id === question.id)) {
        event[question.id] = true;
      } else {
        event[question.id] = false;
      }
      this.setState({ event });
    });
    this.props.toggleAddRegQuestions(false);
  }

  onAdd = async event => {
    const { updateEvent, regQuestions, toggleAddRegQuestions } = this.props;
    regQuestions.map(question => {
      event[question.id] = event[question.id] === 'true' ? true : false;
    });
    updateEvent(event, null, toggleAddRegQuestions);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.event) {
      const savedQuestions = this.props.regQuestions.filter(question => {
        return newProps.event[question.id];
      });
      this.setState({ event: newProps.event, savedQuestions  });
    }
  }


  render() {
    const { errorMessage, event, regQuestions } = this.props;
    const questions = [];
    regQuestions.map((question, index) => {
      questions.push(<MaterialCheckbox name={question.id} key={index}>{question.name}</MaterialCheckbox>);
    });

    return event &&
      (
        <ModalForm
          addAction={this.onAdd}
          data={this.state.event}
          errorMessage={errorMessage}
          onCancel={this.onCancel}
          title={i18n.t("Registration Questions")}
          saveAndAddButton={false}
          updateAction={this.onAdd}
        >
          <p>{i18n.t('Please select which questions you would like attendees to answer during registration.')}</p>

          <FieldGroup className="container-fluid">
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                {questions}
              </FieldGroup>
            </FieldGroup>
          </FieldGroup>
        </ModalForm>
      )
  }
}
