import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { JsonApiDecorator, PageWrapper, Section } from 'reactifi';
import moment from 'moment';
import * as eventActionCreators from '../actions/eventActionCreators';
import GenerateAttendeeViewEventLink from '../../AttendeeEvent/components/GenerateAttendeeViewEventLink';
import QRCode from 'qrcode.react';

const addEvents = (apiStore, props, ownProps) => {
  const eventId = ownProps.routeParams.id;
  if (apiStore.events) {
    props.event = apiStore.events.find(eventId);
  }

  if (!props.event) {
    props.event = { id: eventId };
  }
}

const mapStateToProps = (state, ownProps) => {
  let apiStore = new JsonApiDecorator(state.api);
  let props = {};
  addEvents(apiStore, props, ownProps);
  return props;
}

class QrCodeContainer extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    event: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(
      { ...eventActionCreators },
      this.props.dispatch
    );
  }

  componentDidMount() {
    this.actions.loadEvent(this.props.event.id);
  }

  render() {
    const { event } = this.props;
    const eventDataAttributes = {
      'data-object-id': event.id,
      'data-object-type': 'events'
    };
    const attendeeEventLink = window.location.origin + GenerateAttendeeViewEventLink(event);

    return (
      <PageWrapper>
        <Section className="text-center" type="list">
          <h1 {...eventDataAttributes} data-attribute="title">
            {event.title}
          </h1>
          <h2 {...eventDataAttributes} data-attribute="start-date">
            {moment(event.start_time).format('LL')}
          </h2>
          <QRCode
            {...eventDataAttributes}
            className="m-t-30"
            size={177}
            value={attendeeEventLink}
          />
        </Section>
      </PageWrapper>
    );
  }
}

export default connect(mapStateToProps)(QrCodeContainer);
