import PropTypes from 'prop-types';
import React from 'react';
import {
  IconCategoryValue
} from 'reactifi';

const EventDetailsItemComponent = (props) => {
  return (
    <div className="row event-detail-item">
      <div className="col-12">
        <IconCategoryValue
          type="stacked"
          {...props}
        />
      </div>
    </div>
  );
}

EventDetailsItemComponent.propTypes = {
  category: PropTypes.string,
  dataAttributesForEdit: PropTypes.object,
  editAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func
  ]),
  icon: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ])
}

export default EventDetailsItemComponent;
