import React, { Component } from 'react';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';

import { Button, Icon, Util, WindowHelper } from 'reactifi';

/**
  This component is used to generate a Header menu from an object array.
  - It supports two types of menu items: Links and Dropdowns
  - Format for menuData:
    {
      submenu_items: [{
        icon: 'user',
        title: 'Menu Title',
        target_url: '/users',
        css_class: '',
        slug: '',
        submenu_items: [{
          icon: 'user',
          title: 'Submenu title',
          css_class: '',
          target_url: '/users/add',
          target_method: 'GET',
          new_window: false,
          slug: ''
        }]
      }]
    }
*/

export default class HeaderMenu extends Component {
  constructor(props) {
    super(props);
    const serverSide = typeof window === 'undefined';
    const dropdowns = {};
    this.windowHelper = WindowHelper;
    props.menuData.submenu_items.forEach((item, key) => {
      if (item.submenu_items && item.submenu_items.length) dropdowns[`headerMenu-${key}`] = false;
    });

    this.state = {
      showConfirmationModal: false,
      showLanguageModal: false,
      showHelpModal: false,
      serverSide,
      dropdowns,
      redirectRuleSetName: null,
    };
  }

  shouldChangeLogoutForImpersonate(item) {
    return item.slug === 'logout' && this.props.isImpersonating;
  }

  getMenuLabel(item) {
    const prefix = item.css_class === 'account' && this.props.isImpersonating ? i18n.t('Impersonating ') : '';
    return `${prefix}${item.title}`;
  }

  goBackToAdminPortal = () => {
    const { redirectRuleSetName } = this.state;

    let url = `${this.props.adminifiDomain}/en/${this.props.organizationSlug}/cportal/reports`;
    if (redirectRuleSetName) url = `${url}?current_rule_set_name=${redirectRuleSetName}`;

    this.windowHelper.redirect(url);
  };

  onToggleDropdown = (open, id, event) => {
    if (event?.source === 'rootClose') {
      this.setState({ dropdowns: {} });
    } else {
      this.toggleDropdown(id, open);
    }
  };

  toggleDropdown = (key, open) => {
    this.setState({ dropdowns: { [key]: open } });
  };

  buttonOrLink = (item, target_url, isDropdown = false) => {
    const buttonClick = {};
    buttonClick.href = target_url;
    buttonClick.target = item.new_window ? '_blank' : '_self';
    if (isDropdown) {
      buttonClick['data-method'] = item.target_method && !this.props.isImpersonating ? item.target_method : 'GET';
    }
    return buttonClick;
  };

  runSwitch = redirectUrl => {
    const url = new URL(redirectUrl);
    const current_rule_set_name = url.searchParams.get('current_rule_set_name');
    if (current_rule_set_name === 'cc_admin' && this.props.selectedLanguage !== 'en') {
      this.setState({
        showConfirmationModal: true,
        redirectRuleSetName: current_rule_set_name,
      });
    } else {
      this.windowHelper.redirect(redirectUrl);
    }
  };

  renderDropdown = (menuItem, key) => {
    const { showCloseButton, pullRight } = this.props;

    const isAccount = menuItem.css_class === 'account';
    const menuId = `headerMenu-${key}`;
    return (
      <Dropdown
        id={menuId}
        className={menuItem.css_class}
        onToggle={(open, event) => this.onToggleDropdown(open, menuId, event)}
        show={this.state.dropdowns[menuId]}
        pullRight={pullRight}
        rootCloseEvent="mousedown"
      >
        <Dropdown.Toggle noCaret={true}>
          {!isAccount && menuItem.icon && <Icon additionalClasses="left" iconType={menuItem.icon} />}
          <span aria-hidden={true}>{this.getMenuLabel(menuItem)}</span>
          {isAccount && <Icon additionalClasses="right" iconType="menu" />}
        </Dropdown.Toggle>
        <Dropdown.Menu className="user-menu">
          {showCloseButton && (
            <div
              className="mobile-close"
              aria-label={i18n.t('Close')}
              onClick={() => this.toggleDropdown(`headerMenu-${key}`, false)}
            >
              &times;
            </div>
          )}
          {this.renderDropdownMenuItems(menuItem)}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  renderDropdownMenuItems = (menuItem, menuItemElements = []) => {
    menuItem.submenu_items.forEach((item, subKey) => {
      if (item.subsection) {
        if (subKey) {
          menuItemElements.push(<MenuItem key={menuItemElements.length} divider={true} />);
        }
        this.renderDropdownMenuItems(item, menuItemElements);
        return;
      }
      const href = this.shouldChangeLogoutForImpersonate(item)
        ? `${this.props.adminifiDomain}/admin/impersonation/sign_out`
        : item.target_url;
      const buttonClick = this.buttonOrLink(item, href, true);
      const menuTitle = menuItem.slug || menuItem.css_class;
      menuItemElements.push(
        <MenuItem
          key={menuItemElements.length}
          id={`headerMenu-${menuTitle}-headerMenuItem-${subKey}`}
          {...buttonClick}
        >
          {item.icon && <Icon iconType={item.icon} />}
          {this.shouldChangeLogoutForImpersonate(item) ? i18n.t('Stop Impersonating') : item.title}
        </MenuItem>
      );
    });
    return menuItemElements;
  };

  renderLink(menuItem, key) {
    const buttonClick = this.buttonOrLink(menuItem, menuItem.target_url);
    const className = Util.formatClassName(['branded-link', menuItem.css_class]);

    return (
      <Button
        id={`headerMenu-${key}`}
        className={className}
        style="default"
        label={menuItem.title}
        title={menuItem.title}
        aria-label={menuItem.title}
        tabIndex={0}
        data-action={menuItem.title.toLowerCase()}
        icon={menuItem.icon}
        {...buttonClick}
      />
    );
  }

  renderChildren() {
    let menuData = this.props.menuData;

    return menuData.submenu_items.map((item, key) => {
      return (
        <li key={key}>
          {item.submenu_items && item.submenu_items.length
            ? this.renderDropdown(item, key)
            : this.renderLink(item, key)}
        </li>
      );
    });
  }

  setOpenDropdownClass = () => {
    if (typeof window === 'undefined') {
      return;
    }
    const openDropdownClass = 'header-menu-dropdown-open';
    const body = document.querySelector('body');
    const anyDropdownsOpen =
      Object.keys(this.state.dropdowns).length && Object.values(this.state.dropdowns).some(val => !!val);
    const bodyHasOpenClass = body.classList.contains(openDropdownClass);
    if (anyDropdownsOpen && !bodyHasOpenClass) {
      body.classList.add(openDropdownClass);
    } else if (!anyDropdownsOpen && bodyHasOpenClass) {
      body.classList.remove(openDropdownClass);
    }
  };

  render() {
    const { hideNameOnMobile, pullRight } = this.props;
    this.setOpenDropdownClass();
    const className = Util.formatClassName([
      'header-menu',
      { 'mobile-hide-name': hideNameOnMobile },
      { 'pull-right': pullRight },
    ]);
    return (
      <nav className={className}>
        <ul role="presentation">{this.renderChildren()}</ul>
      </nav>
    );
  }
}

HeaderMenu.propTypes = {
  adminifiDomain: PropTypes.string,
  currentRuleSets: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
    })
  ),
  hideNameOnMobile: PropTypes.bool,
  isImpersonating: PropTypes.bool,
  languages: PropTypes.object,
  menuData: PropTypes.shape({
    submenu_items: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        title: PropTypes.string.isRequired,
        /** if you set the css class to account, the menu item will have a different set of style rules */
        css_class: PropTypes.string,
        target_url: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        /** this will indicate if the menu item is either a Link or a Dropdown */
        submenu_items: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string.isRequired,
            target_url: PropTypes.string.isRequired,
            target_method: PropTypes.string,
            new_window: PropTypes.bool.isRequired,
            slug: PropTypes.string.isRequired,
          })
        ),
      })
    ),
  }).isRequired,
  organizationSlug: PropTypes.string,
  pullRight: PropTypes.bool,
  selectedLanguage: PropTypes.string,
  showCloseButton: PropTypes.bool,
  user: PropTypes.object,
  windowOverride: PropTypes.object,
};

HeaderMenu.defaultProps = {
  hideNameOnMobile: true,
  menuData: {},
  pullRight: true,
  showCloseButton: true,
  user: null,
};
