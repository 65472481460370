import mirrorCreator from 'mirror-creator';
import { selectEntity, updateEntity, createEntity, displayError, clearMessages } from 'reactifi';
import { readEndpoint, deleteEntity } from 'redux-json-api';
import i18n from 'lib/i18n';
export { clearMessages } from 'reactifi';

export const actionTypes = mirrorCreator([
  'SELECT_WIZARD_STEP',
  'CLOSE_WIZARD'
]);

const cleanupLocation = (attendee) => {
  delete attendee.location_administrative_area_level_1;
  delete attendee.location_administrative_area_level_2;
  delete attendee.location_country;
  delete attendee.location_latitude;
  delete attendee.location_locality;
  delete attendee.location_longitude;
  delete attendee.location_neighborhood;
  delete attendee.location_postal_code;
  delete attendee.location_route;
  delete attendee.location_street_number;
  delete attendee.location_formatted_address;
  delete attendee.location_name;
  delete attendee.location_room;
  return attendee;
}

export const selectWizardStep = (wizardStep) => {
  return {
    type: actionTypes.SELECT_WIZARD_STEP,
    wizardStep
  }
};

export const setWizardCancelMessage = (confirmMessage, onConfirm) => {
  return {
    type: actionTypes.CLOSE_WIZARD,
    wizardCancelConfirm: {
      confirmMessage,
      onConfirm
    }
  }
}

export function selectAttendee(attendeeId, wizardStep){
  return async (dispatch) => {
    await dispatch(selectEntity(attendeeId, 'attendees'));
    dispatch(clearMessages);
    dispatch(selectWizardStep(wizardStep));
  }
}

export function loadAttendeeResponseSets(attendeeId){
  return readEndpoint(`attendees/${attendeeId}/response_sets?include=course_module`)
}

export function updateAttendee(attendee, disableAssessment, wizardStep){
  if(!attendee.location_latitude) {
    attendee = cleanupLocation(attendee);
  }
  return async (dispatch) => {
    let successMessage = wizardStep === 2 || (wizardStep === 1 && disableAssessment) ? i18n.t('Attendee saved successfully.') : false;
    await dispatch(updateEntity(attendee, null, successMessage));
    if (disableAssessment){
      dispatch(selectAttendee(null));
    } else if (wizardStep === 1) {
      dispatch(selectWizardStep(1));
    }
  }
}

export function createAttendee(attendee, disableAssessment){
  if(!attendee.location_latitude) {
    attendee = cleanupLocation(attendee);
  }
  return async (dispatch) => {
    let newAttendee = await dispatch(createEntity(attendee, null, disableAssessment ? i18n.t('Attendee saved successfully.') : false));
    if (newAttendee){
      if (!disableAssessment){
        dispatch(selectAttendee(newAttendee.data.id, 1));
      } else {
        dispatch(selectAttendee(null));
      }
    }
  }
}

export function createRegisteredAttendee(attendee){
  if(!attendee.location_latitude) {
    attendee = cleanupLocation(attendee);
  }
  return createEntity(attendee, null, i18n.t('Registration complete.'));
}

export function deleteAttendee(attendee){
  return async (dispatch) => {
    try{
      await dispatch(deleteEntity(attendee.unwrap(), 'attendees', i18n.t("Attendee deleted successfully.")));
      dispatch(selectAttendee(null));
    } catch(e){
      dispatch(displayError(e));
    }
  }
}
