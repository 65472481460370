import $ from 'jquery';
import 'select2';
import { sanitize as sanitizeHTML } from 'dompurify';

$(document).ready(function () {
  var selectedSlug = $('#assessment_slug').val();

  if (selectedSlug) {
    $('#modules').val(selectedSlug);
    $('#modules').trigger('change');
  }
});

$("#modules").change(function () {
  var value = $('#modules').val();
  if (value) {
    var path = "next_data?assessment_slug=" + value;

    return $.ajax({
      type: "GET",
      url: path,
      dataType: "html",
      beforeSend: function () {
        $('#select-assessment').hide();
        $('#assessment-table').hide();
        $('#indicator').show();
      },
      success: function (data) {
        $("#select-assessment").hide();
        $("#assessment-table").html(sanitizeHTML(data));
        $('[data-toggle="tooltip"]').tooltip();
        $('.attendee-select-box').select2({ width: '180px', selectOnClose: true });
        if ($('.tbody-full').find('.attendee-select-box option').length > 1) {
          $('.tbody-full').show();
        }
      },
      error: function (data) {
        let error_message = data.responseText
        if (data.status === 401) {
          error_message = "Your session may have timed out. " + data.responseText
        }
        $('#error').html(sanitizeHTML(error_message));
        $('#error').show();
      },
      complete: function () {
        $("#indicator").hide();
        $('#assessment-table').show();
      }
    })
  }
});
