import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import {
  buildGenericProps,
  ConfirmationModal,
  JsonApiDecorator,
  NoResultsCard,
  PageWrapper,
  MessagesModule,
  Header,
  Section,
  PagingDataList,
  Icon,
  Storage
} from 'reactifi';
import * as volunteerActionCreators from '../actions/volunteerActionCreators';
import VolunteerForm from '../components/VolunteerForm';
import VolunteerSearchForm from '../components/VolunteerSearchForm';
import utils from 'lib/utils';
import i18n from 'lib/i18n';
import debounce from 'lodash/debounce';


function mapStateToProps(state, ownProps) {
  let props = buildGenericProps(state, 'volunteers', ['work_location']);
  if (ownProps.location && ownProps.location.query && ownProps.location.query.filterField) {
    props.filters = { [ownProps.location.query.filterField]: ownProps.location.query.filterValue };
  }
  props.currentVolunteer = props.currentEntity;
  props.googleMapsApiKey = ownProps.route.googleMapsApiKey
  props.viewer = ownProps.route.viewer;

  if (props.currentVolunteer) {
    if (props.currentVolunteer.is_employee && !props.currentVolunteer.work_location) {
      props.currentVolunteer.doesNotBelongToAnyWorkLocation = true;
    }
    else {
      props.currentVolunteer.doesNotBelongToAnyWorkLocation = false;
    }
  }

  let apiStore = new JsonApiDecorator(state.api);
  if (!apiStore.accounts) {
    props.account = { id: ownProps.route.accountId.toString(), languages: [] };
  } else {
    props.account = apiStore.accounts.first('topic');
    props.languages = utils.mapObjToOptions(props.account.languages);
  }

  return props;
}

class VolunteerContainer extends React.Component {
  static propTypes = {
    volunteers: PropTypes.array,
    dispatch: PropTypes.func.isRequired,
    filter: PropTypes.object,
    accountId: PropTypes.number.isRequired,
    errorMessage: PropTypes.string,
    currentVolunteer: PropTypes.object,
    filters: PropTypes.array,
    languages: PropTypes.array,
    downloadUrl: PropTypes.string,
    googleMapsApiKey: PropTypes.string.isRequired,
    downloadProcessing: PropTypes.bool,
    account: PropTypes.shape({
      id: PropTypes.string,
      account: PropTypes.string,
      languages: PropTypes.object
    }),
    successMessage: PropTypes.string,
    isLoadingVolunteers: PropTypes.bool,
    viewer: PropTypes.string,
    volunteersMeta: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(volunteerActionCreators, this.props.dispatch);
    this.filterVolunteers = debounce(this.filterVolunteers, 350, { leading: false, trailing: true });

    this.state = {
      activePage: 1,
      filters: {},
      showToggleVolunteerEnabled: null,
      confirmationTitle: "",
      confirnmationBody: "",
      confirmationOKButtonLabel: ""
    };
  }

  componentDidMount() {
    if (this.props.filters) {
      this.actions.findVolunteers(this.props.filters);
    } else {
      this.actions.loadVolunteers();
    }
    this.actions.loadAccount(this.props.account.id);
  }

  filterVolunteers = (filters, page) => {
    let filtersArr = Object.assign({}, this.state.filters, filters, this.props.filters);
    for (const key of Object.keys(filtersArr)) {
      if (filtersArr[key] === "all") {
        delete filtersArr[key];
      }
    }
    this.actions.findVolunteers(filtersArr, page);
    this.setState({ filters: filtersArr },
    );
  }

  updateVolunteer = currentVolunteer => {
    this.actions.updateVolunteer(currentVolunteer, this.state.filters);
  }

  downloadVolunteers = () => {
    Storage.setItem('suppressLoadingClass', true);
    this.actions.downloadVolunteers(
      this.props.accountId, 
      Object.assign({}, this.state.filters, {
        event_id: this.props.filters?.event_id
      })
    );
  }

  componentWillReceiveProps = newProps => {
    if (!this.props.downloadUrl && newProps.downloadUrl) {
      window.location.href = newProps.downloadUrl;
    }
  }

  endisableVolunteer = volunteer => {
    volunteer.enabled = !volunteer.enabled;
    this.actions.updateVolunteer(volunteer);
  }

  editVolunteer = (e, row) => {
    e.preventDefault();
    this.actions.selectVolunteer(row && row.id || e.currentTarget.attributes['data-id'].value);
  }

  clearConfirmationLabels = () => {
    this.setState({ confirmationTitle: "", confirmationBody: "", confirmationOKButtonLabel: "" });
  }

  toggleVolunteerEnabled = volunteer => {
    this.setState({
      confirmationTitle: (volunteer.enabled ? i18n.t("Disable Volunteer") : i18n.t("Enable Volunteer")),
      confirmationBody: (volunteer.enabled ? i18n.t("Are you sure you want to disable this volunteer?") : i18n.t("Are you sure you want to enable this volunteer?")),
      confirmationOKButtonLabel: (volunteer.enabled ? i18n.t("Disable Volunteer") : i18n.t("Enable Volunteer")),
      showToggleVolunteerEnabled: volunteer
    });
  }

  onCancelToggleVolunteerEnabled = () => {
    this.setState({ showToggleVolunteerEnabled: null });
    this.clearConfirmationLabels();
  }

  onConfirmToggleVolunteerEnabled = volunteer => {
    volunteer.enabled = !volunteer.enabled;
    this.updateVolunteer(volunteer);
    this.setState({ showToggleVolunteerEnabled: null });
    this.clearConfirmationLabels();
  }

  onPageChange = activePage => {
    const { filters } = this.state
    this.setState({ activePage }, () => this.filterVolunteers(filters, activePage));
  }

  preventClickthrough = event => {
    event.stopPropagation();
  }

  render() {
    const { viewer, volunteersMeta } = this.props;
    const { activePage } = this.state;
    let shouldHideEventInfo = this.props.volunteersMeta ? this.props.volunteersMeta.is_customer_admin : false;
    let isMobile = window.isMobile;
    const isAdmin = viewer === 'account_admin';

    const totalCount = volunteersMeta && volunteersMeta.total_count ? volunteersMeta.total_count : 0;

    // let filterStatuses = utils.translatedMapObjToOptions({ "All": "all", "Active Volunteers": "true", "Removed Volunteers": "false" });
    let fields = [
      {
        name: 'id', title: i18n.t('Name'), width: '30%', tooltip: row => (
          row.is_admin ?
            i18n.t('View Admin Details') :
            (isAdmin ?
              i18n.t('Edit Volunteer') :
              i18n.t('View Volunteer')
            )
        ), formatter: (cell, row) => {
          return row.is_admin ?
            `${row.first_name} ${row.last_name} (${i18n.t('Admin')})` :
            `${row.first_name} ${row.last_name}`
        }, mobileFormatter: (cell, row) => `${row.first_name} ${row.last_name}`
      },
      // { name: 'id', title: i18n.t('Name'), canSort: true,
      //   formatter: (cell, row) => <a href="#" onClick={this.editVolunteer} data-id={cell}>{row.first_name} {row.last_name}</a> },
      { name: 'email', title: i18n.t('Email'), width: '45%', canSort: true, formatter: (cell, row) => row.email ? <a onClick={this.preventClickthrough} href={"mailto:" + row.email}>{row.email}</a> : '' },
      { name: 'phone', title: i18n.t('Phone'), width: 130, canSort: true, formatter: (cell, row) => row.phone ? row.phone : '' },
      { name: 'title', title: i18n.t('Job Title'), width: '25%', canSort: true, formatter: (cell, row) => row.title ? row.title : i18n.t('') },
      //What user's refer to as 'Location ID' is the 'external_id' in the back end
      { name: 'external_id', title: i18n.t('Location Id'), width: 130, canSort: true, formatter: (cell, row) => row.work_location ? row.work_location.external_id : '' }
      // Hidden for FIL-2463
      // {
      //   name: 'enabled', title: i18n.t('Enabled'), columnOptions: { dataAlign: 'center', columnClassName: 'icon-only-column' }, formatter: (cell, row) => {
      //     return row.is_admin ? (
      //       <span><OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">{i18n.t('Admins cannot be disabled')}</Tooltip>}>
      //         {row.enabled ? <img className="switch-icon icon-disabled" alt={i18n.t("Switch On")} src={switchOn} /> : <img className="switch-icon icon-disabled" alt={i18n.t("Switch Off")} src={switchOff} />}
      //       </OverlayTrigger></span>
      //     ) : (
      //       <span><OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-enabled">{i18n.t('Only enabled volunteers can join or create events')}</Tooltip>}>
      //         <a onClick={(e) => this.toggleVolunteerEnabled(row)} data-id={row.id}>{row.enabled ? <img className="switch-icon" alt={i18n.t("Switch On")} src={switchOn} /> : <img className="switch-icon" alt={i18n.t("Switch Off")} src={switchOff} />}</a>
      //       </OverlayTrigger></span>
      //     )
      //   }
      // }
    ];

    const eventCount = {
      name: 'event_count',
      title: i18n.t('Events'),
      width: 120,
      canSort: true,
      formatter: (cell, row) => <a onClick={this.preventClickthrough} href={`events#?filterField=volunteer_id&filterValue=${row.id}`}>{row.event_count}</a>
    };

    if (!shouldHideEventInfo) {
      fields.splice(5, 0, eventCount);
    }

    if (isMobile) {
      fields.push({
        name: 'edit_volunteer', title: i18n.t('Edit Volunteer'), canSort: false, formatter: (cell, row) => {
          return row.is_admin ?
            (
              <span>
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-view">{i18n.t('View Admin Details')}</Tooltip>}>
                  <a onClick={this.editVolunteer} data-id={row.id}><Icon iconType="eye" /></a>
                </OverlayTrigger>
              </span>
            ) : (
              <span>
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-edit">{i18n.t('Edit Volunteer')}</Tooltip>}>
                  <a onClick={this.editVolunteer} data-id={row.id}><Icon iconType="edit" /></a>
                </OverlayTrigger>
              </span>
            )
        }
      })
    }

    fields = fields.filter(Boolean);

    const tableOptions = {
      onRowClick: this.editVolunteer
    };

    return (
      <PageWrapper
        pageType="parent"
        contentType="fullwidth"

      >
        <MessagesModule
          errorMessage={this.props.currentVolunteer ? null : this.props.errorMessage}
          successMessage={this.props.currentVolunteer ? null : this.props.successMessage}
          clearMessages={this.actions.clearMessages}
        />
        <Header title={i18n.t('Volunteer List')} />
        <Section>
          <VolunteerSearchForm searchAction={this.filterVolunteers} disabled={this.props.isLoadingVolunteers} languages={this.props.languages} hideEventInfo={shouldHideEventInfo} />
        </Section>
        <Section type="list">
          <VolunteerForm
            clearMessages={this.actions.clearMessages}
            currentVolunteer={this.props.currentVolunteer}
            errorMessage={this.props.errorMessage}
            googleMapsApiKey={this.props.googleMapsApiKey}
            isAdmin={isAdmin}
            languages={this.props.languages}
            loadWorkLocation={this.actions.loadWorkLocation}
            selectVolunteer={this.actions.selectVolunteer}
            updateVolunteer={this.updateVolunteer}
          />
          {this.props.isLoadingVolunteers || this.props.volunteers.length || Object.keys(this.state.filters).length ?
            <PagingDataList
              {...this.props}
              activePage={activePage}
              data={this.props.volunteers}
              fields={fields}
              keyField="id"
              onPageChange={this.onPageChange}
              options={tableOptions}
              isLoadingData={this.props.isLoadingVolunteers}
              totalCount={totalCount}
              showDownloadLink={true}
              downloadAction={this.downloadVolunteers}
            /> :
            <NoResultsCard message={i18n.t("No volunteers have registered")} />
          }
        </Section>
        <ConfirmationModal title={this.state.confirmationTitle} data={this.state.showToggleVolunteerEnabled} canClickOff={true} onCancel={this.onCancelToggleVolunteerEnabled} onConfirm={this.onConfirmToggleVolunteerEnabled} confirmButtonText={this.state.confirmationOKButtonLabel}>
          <div>{this.state.confirmationBody}</div>
        </ConfirmationModal>
      </PageWrapper>
    );
  }
}

export default connect(mapStateToProps)(VolunteerContainer);
