import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as orgUsersActionCreators from '../actions/orgUsersActionCreators';
import { buildGenericProps } from 'reactifi';

import OrganizationUsers from '../components/OrganizationUsers';

function mapStateToProps(state, ownProps) {
  let props = buildGenericProps(state, 'account_admins');
  props.currentOrganizationId = ownProps.routeParams.id;
  props.currentUser = props.currentEntity;
  return props;
}

class OrganizationUsersContainer extends Component {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    currentOrganizationId: PropTypes.string
  }

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(orgUsersActionCreators, this.props.dispatch);
  }

  componentDidMount() {
    this.actions.findUsers({ account_id: this.props.currentOrganizationId });
  }

  render() {

    return (
      <OrganizationUsers {...this.props} {...this.actions} />
    );
  }
}



export default connect(mapStateToProps)(OrganizationUsersContainer);
