import PropTypes from 'prop-types';
import React from 'react';
import { ResponsiveDataList, Icon } from 'reactifi';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import i18n from 'lib/i18n';

export default class VolunteerList extends React.Component {
  static propTypes = {
    eventUsers: PropTypes.array,
    currentEventUser: PropTypes.object,
    setLeadEventUser: PropTypes.func,
    deleteEventUser: PropTypes.func
  };

  static defaultProps = {
    eventUsers: []
  };

  removeUser(eventUser){
    this.props.deleteEventUser(eventUser, i18n.t("Volunteer removed successfully!"))
  }

  makeLead(eventUser){
    this.props.setLeadEventUser(eventUser);
  }

  render() {
    let { eventUsers, currentEventUser } = this.props;

    let joinedFields = [
      { name: 'id', title: i18n.t('Name'), canSort: true, formatter: (cell, row) => {
        return (
          <span className="action-icon-floatleft">
            {currentEventUser.user_id !== row.user_id ?
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-edit">{i18n.t('Remove Volunteer')}</Tooltip>}>
                <a onClick={(e) => this.removeUser(row)}><Icon iconType="delete" /></a>
              </OverlayTrigger>
              : null}
            <span className="col-text">{row.first_name} {row.last_name} {row.current_user ? ` (${i18n.t('you')})` : ''} {row.lead ? <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-leadmarker">{i18n.t('Lead Volunteer')}</Tooltip>}><Icon iconType="flag" /></OverlayTrigger> : ''}</span>
          </span>)
      }, mobileFormatter: (cell, row) => {
        return <span>{row.first_name} {row.last_name} {row.current_user ? ' (you)' : ''} {row.lead ? <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-leadmarker">{i18n.t('Lead Volunteer')}</Tooltip>}><Icon iconType="flag" /></OverlayTrigger> : ''}</span>
      }
      },
      { name: 'email', title: i18n.t('Email'), canSort: true, formatter: (cell, row) => <a href={"mailto:" + row.email}>{row.email}</a>
      },
      { name: 'media_consent', title: i18n.t('Media Consent'), canSort: true,
        formatter: (cell, row) => cell ? 'Yes' : 'No' },
      { name: 'total_time', title: i18n.t('Hours Volunteered'), canSort: true },
      { name: 'notes', title: i18n.t('Notes'), columnOptions: { width:'150', columnClassName:'truncate-150' }, canSort: true,
        formatter: (cell, row) => {
          return <span>{cell ?
            <OverlayTrigger placement="left" overlay={<Tooltip id={`${row.id}-notes-tooltip`}>{cell}</Tooltip>}>
              <span>{cell}</span>
            </OverlayTrigger> : ''}</span>;
        }
      }
    ];

    let actionField = { name: 'actions', title: i18n.t('Change Lead'), columnOptions: { dataAlign:'center' }, formatter: (cell,row) => {
      return (<span>
        {!row.lead ? <OverlayTrigger placement="top"
          overlay={<Tooltip id="tooltip-edit">{i18n.t('Make Lead')}</Tooltip>}><a data-object-type="events" data-object-id={row.id} data-action="change-lead" onClick={() => this.makeLead(row)}><Icon iconType="flag" /></a></OverlayTrigger> : null}
      </span>); },
    mobileFormatter: (cell, row) => {
      return (<span>
        {row.lead ? <span>{i18n.t('Current Leader')}</span> : <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-edit">{i18n.t('Make Lead')}</Tooltip>}><a data-object-type="events" data-object-id={row.id} data-action="make-lead" onClick={() => this.makeLead(row)}><Icon iconType="flag" /> {i18n.t('Make Leader')}</a></OverlayTrigger>}</span>
      )
    }
    };
    joinedFields.push(actionField);

    let unjoinedFields = [
      { name: 'id', title: i18n.t('Name'), canSort: true,
        formatter: (cell, row) => <span>{row.lead ? <Icon iconType="flag" /> : ''} {row.first_name} {row.last_name} {row.current_user ? ` (${i18n.t('you')}` : ''}</span>
      },
      { name: 'email', title: i18n.t('Email'), canSort: true },
      { name: 'title', title: i18n.t('Job Title'), canSort: true }
    ];

    const tableOptions = {
      bordered: false,
      hover: true,
      striped: false
    };

    return (
      <div>
        <ResponsiveDataList data={eventUsers} fields={currentEventUser ? joinedFields : unjoinedFields} keyField="id" options={tableOptions} />
      </div>
    );
  }
}
