import PropTypes from 'prop-types';
import React from 'react';
import EventDetails from '../../bundles/Event/components/EventDetails';

export default class EventPreview extends React.Component {
  static propTypes = {
    event: PropTypes.object.isRequired,
    eventUsers: PropTypes.array,
    googleMapsApiKey: PropTypes.string.isRequired,
    viewer: PropTypes.string
  };

  render(){
    return (<EventDetails {...this.props} joinEvent={()=>{}} />);
  }
}
