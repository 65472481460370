import get from 'lodash/get';
import $ from 'jquery';

(function (scope) {
  var _multilingualForm = function () {
    this.multilingualFieldsSelector = $("[multilingual='true']");
    this.multilingualFormSelector = $("#multilingual_form_languages");
    this.languageButtonSelector = $("#multilingual_form_languages button");
    this.previousData = $("#previous_data").data('previous');
    this.init();
  };

  _multilingualForm.prototype = {
    init: function () {

      var self = this;
      var activeCode = this.multilingualFormSelector.data('active-code');
      var availableCodes = this.multilingualFormSelector.data('available-codes');
      if (availableCodes.indexOf(activeCode))
        activeCode = availableCodes[0];

      this.localeFieldInputs = {};

      this.multilingualFieldsSelector.each(function (index, field) {
        field = $(field);
        var parent = field.parent();
        var id = field.attr('id');
        var name = field.attr('name');
        var insertIndex = name.lastIndexOf(']');
        if (insertIndex === -1)
          insertIndex = name.length;
        var localeInputs = self.localeFieldInputs[name] = { element: field };
        $(availableCodes).each(function (index, code) {
          var newName = name.substr(0, insertIndex) + '_' + code + name.substr(insertIndex);
          var localeInput = localeInputs[code] = $("<input type='hidden'>");
          localeInput.attr('id', id + '_' + code);
          localeInput.attr('name', newName);
          var previousVal = get(self.previousData, newName);
          if (previousVal)
            localeInput.val(previousVal);
          localeInput.appendTo(parent);
        });
        field.on('change keyup', self.onFieldChange.bind(self));
      });

      this.setLanguage(activeCode);
      this.languageButtonSelector.on('click', this.onLanguageButtonClick.bind(this));
    },
    onLanguageButtonClick: function (e) {
      e.preventDefault();
      this.setLanguage($(e.currentTarget).data('code'));
    },
    setLanguage: function (code) {
      if (this.currentCode === code) return;

      this.currentCode = code;
      this.multilingualFormSelector.data('active-code', code);
      this.languageButtonSelector.each(function () {
        var button = $(this);
        button.toggleClass("active", button.data('code') === code);
      });

      // set the input fields values to be equal to the hidden locale input values
      for (var fieldInputsKey in this.localeFieldInputs) {
        if (this.localeFieldInputs.hasOwnProperty(fieldInputsKey)) {
          var fieldInputs = this.localeFieldInputs[fieldInputsKey];
          fieldInputs.element.val(fieldInputs[code].val()); 
        }
      }
    },
    onFieldChange: function (e) {
      var field = $(e.currentTarget);
      var name = field.attr('name');
      var val = field.val();
      var localeInput = this.localeFieldInputs[name][this.currentCode];
      localeInput.val(val);
    }
  };

  scope.MultilingualForm = _multilingualForm;

}(window));

$(function () {
  // Don't load this script unless we're on a page that uses it
  if ($('#multilingual_form_languages').length > 0) {
    // eslint-disable-next-line no-new
    new window.MultilingualForm;
  }
});
