import React from 'react';
import { ErrorBoundedProvider } from 'reactifi';
import createStore from './store/venueStore';
import VenueContainer from './containers/VenueContainer';

export default (props, _railsContext) => {
  const store = createStore(props);
  const reactComponent = (
    <ErrorBoundedProvider store={store}>
      <VenueContainer
        googleMapsApiKey={_railsContext.googleMapsApiKey}
        isEventManagement={props.isEventManagement}
        viewer={props.viewer}
      />
    </ErrorBoundedProvider>
  );
  return reactComponent;
};
