import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import {
  DateTimePickerControl,
  FieldGroup,
  FloatingLabelFormField,
  TimezoneControl,
  TimePickerControl
} from 'reactifi';

export default class DateTimeComponent extends React.Component {
  static propTypes = {
    selectDate: PropTypes.func,
    selectEndTime: PropTypes.func,
    selectStartTime: PropTypes.func,
    selectTimezone: PropTypes.func
  };

  getTimeComponent = (caption, name, onChange) => {
    return (
      <FieldGroup className="col-md-6">
        <FloatingLabelFormField caption={caption} name={name} required={true} populatedOverride={true}>
          <TimePickerControl
            onChange={onChange}
            timeFormat={true}
          />
        </FloatingLabelFormField>
      </FieldGroup>
    );
  }

  render() {
    const { selectDate, selectEndTime, selectStartTime, selectTimezone } = this.props;

    const minDate = new Date();
    minDate.setHours(0, 0, 0, 0);

    return (
      <FieldGroup {...this.props}>
        <FieldGroup className="row">
          <FieldGroup className="col-md-6">
            <FloatingLabelFormField caption={i18n.t("Date")} name="date" required={true}>
              <DateTimePickerControl
                minDate={minDate}
                onChange={selectDate}
                timeFormat={false}
              />
            </FloatingLabelFormField>
          </FieldGroup>

          <FieldGroup className="col-md-6">
            <FloatingLabelFormField caption={i18n.t("Time Zone")} required={true} name="time_zone" >
              <TimezoneControl onChange={selectTimezone} />
            </FloatingLabelFormField>
          </FieldGroup>
        </FieldGroup>
        <FieldGroup className="row">
          {this.getTimeComponent(
            i18n.t("Start Time"),
            "start_time",
            selectStartTime
          )}
          {this.getTimeComponent(
            i18n.t("End Time"),
            "end_time",
            selectEndTime
          )}
        </FieldGroup>
      </FieldGroup>
    );
  }
}
