import PropTypes from 'prop-types';
import React from 'react';
import { addToCalendar } from '../libs/addToCalendar';
import { Icon } from 'reactifi';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import i18n from 'lib/i18n';

export default class AddToCalendarButton extends React.Component {
  static propTypes = {
    accountName: PropTypes.string,
    event: PropTypes.object
  };

  onAddToCalendar = (e) => {
    const { accountName, event } = this.props;
    const partner = event.partner_name ? 'Partner: ' + event.partner_name : '';
    const lang = document.documentElement.lang ? document.documentElement.lang : 'en';
    const eventURL = `http://${window.location.host}/${lang}/events/${event.code}#/${event.id}`;
    const availableAccountName = accountName || '';
    const title = `${i18n.t(event.title)} - ${availableAccountName}`;
    const desc = `
    ${event.title} presented by ${availableAccountName}
    ${partner}
    To register for this event or see more details go to: ${eventURL}
    `;

    const calendarEvent = {
      cal: e,
      text: title,
      details: i18n.t(desc),
      location: event.venue && event.venue.location_formatted_address
        ? event.venue.location_formatted_address : '',
      start: event.start_time,
      end: event.end_time,
      url: window.location.href
    };
    addToCalendar(calendarEvent);
  }

  render() {
    const { event } = this.props;

    return (
      <DropdownButton
        title={(
          <span><Icon iconType="calendar" /> {i18n.t("Add to Calendar")}</span>
        )}
        id="bg-nested-dropdown"
        onSelect={(e) => { this.onAddToCalendar(e) }}
        className="btn btn-primary"
        data-object-type="events"
        data-object-id={event.id}
        data-action="add-to-calendar-reveal-options"
      >
        <MenuItem
          name="add-to-google-calendar"
          data-object-type="events"
          data-object-id={event.id}
          data-action="add-google-calendar"
          data-state="upcoming-google-calendar"
          eventKey="google"
        >{i18n.t('Google Calendar')}</MenuItem>
        <MenuItem
          name="add-to-ical-calendar"
          data-object-type="events"
          data-object-id={event.id}
          data-action="add-ical-calendar"
          data-state="upcoming-ical-calendar"
          eventKey="ics">{i18n.t('iCal')}</MenuItem>
        <MenuItem
          name="add-to-outlook-calendar"
          data-object-type="events"
          data-object-id={event.id}
          data-action="add-outlook-calendar"
          data-state="upcoming-outlook-calendar"
          eventKey="outlook">{i18n.t('Outlook')}</MenuItem>
      </DropdownButton>
    );
  }
}
