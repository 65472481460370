import PropTypes from 'prop-types';
import React from 'react';
import { SearchForm, DateTimePickerControl, SelectControl, FieldGroup, FloatingLabelFormField, MaterialCheckbox, InfoTooltip } from 'reactifi';
import i18n from 'lib/i18n';

export default class VolunteerSearchForm extends React.Component {
  static propTypes = {
    searchAction: PropTypes.func.isRequired,
    languages: PropTypes.array,
    disabled: PropTypes.bool,
    hideEventInfo: PropTypes.bool
  };

  constructor(props){
    super(props);
    this.search = this.search.bind(this);
  }

  search(searchValues){
    if (searchValues.is_employee === false){
      searchValues.is_employee = undefined;
    }
    this.props.searchAction(searchValues);
  }

  render(){
    return (
      <SearchForm searchAction={this.search} disabled={this.props.disabled} className="search-form container-fluid">
        <FieldGroup className="row">
          <FieldGroup className="col-md-2">
            <FloatingLabelFormField name="keyword" caption={i18n.t("Name, Email, Phone, Job Title")} searchField={true} />
          </FieldGroup>
          <FieldGroup className="col-md-2">
            <FloatingLabelFormField name="location" caption={i18n.t("City, State, Zip, Loc ID")} searchField={true} />
          </FieldGroup>
          {!this.props.hideEventInfo ?
            <FieldGroup className="col-md-2">
              <FloatingLabelFormField name="event_start_date" caption={i18n.t("Event Start Date")} className="icon-label" >
                <DateTimePickerControl timeFormat={false}/>
              </FloatingLabelFormField>
            </FieldGroup> : null}
          {!this.props.hideEventInfo ?
            <FieldGroup className="col-md-2">
              <FloatingLabelFormField name="event_end_date" caption={i18n.t("Event End Date")} className="icon-label" >
                <DateTimePickerControl timeFormat={false}/>
              </FloatingLabelFormField>
            </FieldGroup> : null}
          <FieldGroup className="col-md-2">
            <FloatingLabelFormField name="languages" caption={i18n.t("Languages")} tooltip={i18n.t('Languages the volunteer can present in')} trigger="hover" searchField={true}>
              <SelectControl options={this.props.languages} valueKey="value" labelKey="label" placeholder=" " />
            </FloatingLabelFormField>
          </FieldGroup>
          <FieldGroup className="col-md-2 checkbox-group contains-1 is-employee">
            <MaterialCheckbox className="m-l-25" name="is_employee" inline={true}>{i18n.t('Is Employee')}</MaterialCheckbox>
            <InfoTooltip
              trigger="hover"
              iconTooltip="help"
              tooltip={i18n.t('Include only employees of your institution.')}
              tooltipPosition="top" />
          </FieldGroup>
        </FieldGroup>
      </SearchForm>
    );
  }
}
