import React from 'react';
import { ErrorBoundedProvider } from 'reactifi';
import { Router, Route, hashHistory } from 'react-router'
import createStore from './store/attendeeStore';
import AttendeeEventListContainer from './containers/AttendeeEventListContainer';
import { syncHistoryWithStore } from 'react-router-redux'

export default (props, _railsContext) => {
  const store = createStore(props);
  const history = syncHistoryWithStore(hashHistory, store);
  const reactComponent = (
    <ErrorBoundedProvider store={store}>
      <Router history={history}>
        <Route
          path="/"
          component={AttendeeEventListContainer}
          accountId={props.accountId}
          isEventManagement={props.isEventManagement}
        />
      </Router>
    </ErrorBoundedProvider>
  );
  return reactComponent;
};
