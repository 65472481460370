import { readEntity, readEntityById } from 'reactifi';

export function readEvents(includes, filters = {}){
  filters.start_time = new Date().toJSON().slice(0, 10);
  const sorts = [{ value: 'start_time', direction: 'desc' }];
  return readEntity('events', includes, filters, sorts);
}

export function loadAccount(id){
  let include = 'topic';
  return readEntityById(id, 'accounts', include);
}