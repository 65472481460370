import { downloadReport, endTimeout, readEntity, readEntityById } from 'reactifi';
import { prepareReportFilters } from '../../common/preprocess';

export { clearMessages, endTimeout } from 'reactifi';

export function loadAttendees(includes = '', filters) {
  return readEntity('attendees', includes, filters);
}

export function loadReservations(includes = '', filters) {
  return readEntity('reservations', includes, filters);
}

export function findAttendees(filters, sort, page = 1) {
  return readEntity('attendees', '', filters, sort && [sort], page);
}

export function downloadAttendees(accountId, filters) {
  return downloadReport('Reports::Attendees', {
    account_id: accountId,
    filters: prepareReportFilters(filters)
  });
}

export function loadAccount(id) {
  return readEntityById(id, 'accounts');
}

export function closeTimeoutModal() {
  return endTimeout();
}
