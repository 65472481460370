import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EverfiContentItem from "./EverfiContentItemComponent";
import { Icon } from 'reactifi';

export default class EverfiContentListByType extends Component {

  static propTypes = {
    deleteContent: PropTypes.func,
    everfiContents: PropTypes.arrayOf(PropTypes.object),
    filterByEverfiContentFunction: PropTypes.func,
    offlineContents: PropTypes.arrayOf(PropTypes.object),
    selectContent: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      className: 'open-section'
    };
  }

  toggleClass = () => {
    this.setState({ className: this.state.className === 'open-section' ? 'close-section' : 'open-section' });
  }

  renderEntry = (hasOfflineContents, elementId, contents, everfiContent, index) => {
    const { selectContent, deleteContent } = this.props;
    const i = index ? index : 0;
    return (
      <div>
        {hasOfflineContents && <div className="resource-sub-section clearfix">
          <div onClick={this.toggleClass} className="toggle-section" data-toggle="collapse" data-target={'#' + elementId}>
            <Icon iconType={this.state.className === 'close-section' ? 'chevron-right' : 'chevron-down'}/>
          </div>
          <div className="resource-section">
            <EverfiContentItem
              deleteContent={deleteContent}
              elementId={elementId}
              everfiContent={everfiContent}
              key={i}
              offlineContents={contents}
              selectContent={selectContent}
            />
            <span className="left-border" />
          </div>
        </div>}
      </div>
    );
  }

  renderGeneralEntry = () => {
    const { offlineContents } = this.props;
    const hasOfflineContents = offlineContents.length > 0;
    const elementId = 'col-general';
    return this.renderEntry(hasOfflineContents, elementId, offlineContents);
  }

  render() {
    const { everfiContents, filterByEverfiContentFunction } = this.props;
    return (<>
      {
        everfiContents ?
          everfiContents.map((everfiContent, index) => {
            const contents = filterByEverfiContentFunction(everfiContent);
            const elementId = 'col-' + index;
            const hasOfflineContents = contents.length > 0;
            return (this.renderEntry(hasOfflineContents, elementId, contents, everfiContent, index));
          })
          : this.renderGeneralEntry()
      }
    </>);
  }
}
