import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { debounce, isEqual } from 'lodash';
import { buildGenericProps, Page, JsonApiDecorator, Icon } from 'reactifi';
import * as orgTopicsActionCreators from '../actions/orgTopicsActionCreators';
import OfflineContentSection from "../../Event/components/VolunteerTab/OfflineContentSectionComponent";
import i18n from 'lib/i18n';
import { ENGAGE_DEMO_IMG } from 'lib/constants';
import { getEventTypes } from '../../Event/functions/helpers';


function addLanguages(apiStore, props, ownProps) {
  if (props.topic) {
    let languages = props.topic.account ? props.topic.account.languages : {};
    let languageKeys = Object.keys(languages);
    props.topic.languages = [];
    props.topic.offline_contents.forEach((oc) => {
      let languageName = languageKeys.find((key) => languages[key] === oc.language);
      if (!props.topic.languages.some((lang) => lang.name === languageName)) {
        props.topic.languages.push({ name: languageName, code: oc.language });
      }
    });
    props.topic.languageDisplay = props.topic.languages.map(lang => lang.name).join(', ');
    props.languages = props.topic.languages;
  }
}

function addEverfiContents(apiStore, props) {
  if (apiStore.everfi_contents) {
    props.everfiContents = apiStore.everfi_contents.all();
  }
}

function mapStateToProps(state, ownProps) {
  const topicId = ownProps.routeParams.id;
  let props = buildGenericProps(state, 'topics', ['offline_contents', 'events', 'everfi_content', 'course_modules']);
  props.topicId = topicId;
  props.isEventManagement = ownProps.route.isEventManagement;
  let apiStore = new JsonApiDecorator(state.api);
  const { offline_contents } = apiStore;
  if (apiStore.topics) {
    props.topic = apiStore.topics.find(topicId, ['account', 'course_modules', 'offline_contents', 'everfi_content', 'image']);
  }
  if (props.topic) {
    props.offlineContents = props.topic.offline_contents;
  }
  addLanguages(apiStore, props, ownProps);
  addEverfiContents(apiStore, props);

  if (state.api.currentEntityId && state.api.currentEntityType === 'topics') {
    props.currentTopic = props.currentEntity;
    if (props.currentTopic && props.currentTopic.everfi_content_id) {
      props.currentTopic.everfi_content_id = props.currentTopic.everfi_content_id.toString()
    }
  } else if (state.api.currentEntityId === 'add' && state.api.currentEntityType === 'offline_contents') {
    props.currentTopic = props.topic;
    props.currentOfflineContent = apiStore.newObject('offline_contents', 'topic');
    props.currentOfflineContent.topic_id = state.topics.currentTopicId;
  } else if (state.api.currentEntityId && state.api.currentEntityType === 'offline_contents') {
    props.currentOfflineContent = offline_contents ? offline_contents.find(state.api.currentEntityId, 'topic') : {};
    if (props.currentOfflineContent && props.currentOfflineContent.offline_content_for_everfi_contents) {
      let { offline_content_for_everfi_contents } = props.currentOfflineContent;
      props.currentOfflineContent.everfi_content_ids = offline_content_for_everfi_contents.map((offlineContent) => {
        return offlineContent.everfi_content_id;
      })
    }
  }

  props.uploadInProgress = state.topics.uploadInProgress;

  return props;
}

class ShowContainer extends React.Component {
  static propTypes = {
    currentVenue: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    everfiContents: PropTypes.array,
    isEventManagement: PropTypes.bool,
    offlineContents: PropTypes.array,
    router: PropTypes.object,
    successMessage: PropTypes.string,
    topic: PropTypes.object,
    topicId: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(orgTopicsActionCreators, this.props.dispatch);
    this.calculateLayout = debounce(this.calculateLayout, 150).bind(this);
    this.handleCreateButtonPosition = debounce(this.handleCreateButtonPosition, 150).bind(this);
    this.state = {
      selectedLanguage: 'en'
    }
  }

  componentDidMount() {
    this.actions.loadTopic(this.props.topicId);
    window.addEventListener("resize", this.calculateLayout);
    window.addEventListener("orientationchange", this.handleOrientationScrollEvent);
    this.calculateLayout();
  }

  async componentWillReceiveProps(newProps) {
    const { offlineContents, topicId } = this.props;
    const newOfflineContents = newProps?.offlineContents;
    if (!offlineContents?.length) {
      if (!this.topicIdPoller) this.topicIdPoller = await this.actions.pollTopic(topicId);
    } else if (this.topicIdPoller && offlineContents && !isEqual(newOfflineContents, offlineContents) && newOfflineContents?.some(content => !content.content_url)) {
      clearInterval(this.topicIdPoller);
      this.topicIdPoller = null;
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.calculateLayout);
    window.removeEventListener("scroll", this.handleCreateButtonPosition);
    window.removeEventListener("orientationchange", this.handleOrientationScrollEvent);
    clearInterval(this.topicIdPoller);
  }

  calculateLayout() {
    if (window.innerWidth < 992) {
      window.addEventListener("scroll", this.handleCreateButtonPosition);
    } else {
      this.resetCreateButtonPosition();
      window.removeEventListener("scroll", this.handleCreateButtonPosition);
    }
  }

  handleCreateButtonPosition() {
    let rect = $('#createBtnPlaceholder')[0].getBoundingClientRect();

    if (rect.top > 85 && rect.top < window.innerHeight) {
      this.resetCreateButtonPosition();
    } else {
      this.setCreateButtonPosition();
    }
  }

  resetCreateButtonPosition() {
    $('#createBtnPlaceholder').removeClass('btn-placeholder');
    $('#createBtn').removeClass('stick-to-top');
  }

  setCreateButtonPosition() {
    $('#createBtn').addClass('stick-to-top');
    $('#createBtnPlaceholder').addClass('btn-placeholder');
  }

  handleOrientationScrollEvent = () => {
    if (screen.orientation.angle === 90) {
      this.resetCreateButtonPosition();
      window.removeEventListener("scroll", this.handleCreateButtonPosition);
    } else {
      window.addEventListener("scroll", this.handleCreateButtonPosition);
    }
  }

  hasDowloadableResources() {
    return this.props.topic && this.props.topic.languages.length;
  }

  displayHeading() {
    if (!this.props.topic || !this.props.everfiContents) {
      return;
    }
    if (this.props.topic && this.props.topic.languages.length) {
      return (<p>{i18n.t('Download and print/copy all relevant materials for the session you are facilitating')}</p>);
    } else {
      return (<p>{i18n.t('There are no downloadable resources available')}</p>);
    }
  }

  displayDownloadableResources = () => {
    if (!this.props.topic || !this.props.everfiContents) {
      return;
    }
    return (
      <div className="col-lg-12 resources">
        <div>
          <OfflineContentSection {...this.actions} {...this.props} />
        </div>
      </div>
    )
  }

  isSelected(language) {
    return language.code === this.state.selectedLanguage ? 'selected' : '';
  }


  selectLanguage = language => {
    this.setState({ selectedLanguage: language.code });
  }

  getFirstEventType = () => {
    const { isEventManagement, topic } = this.props;
    return getEventTypes(isEventManagement).find(eventType => topic?.event_types.includes(eventType));
  }

  generateCreateLink = () => {
    let link = `events#/event_wizard?event_type=${encodeURIComponent(this.getFirstEventType())}`;
    const topicId = this.props.topicId ? this.props.topicId : this.props.topic && this.props.topic.id;
    const title = this.props.topic ? this.props.topic.name : null;
    const title_es = this.props.topic ? this.props.topic.name_es : null;
    const description = this.props.topic ? this.props.topic.description : null;
    const description_es = this.props.topic ? this.props.topic.description_es : null;

    link = `${link}${topicId && ('&topicId=' + encodeURIComponent(topicId))}`;
    link = `${link}${title && ('&title=' + encodeURIComponent(title))}${title_es && ('&title_es=' + encodeURIComponent(title_es))}`;
    link = `${link}${description && ('&description=' + encodeURIComponent(description))}${description_es && ('&description_es=' + encodeURIComponent(description_es))}`;
    return link;
  }

  render() {
    const actionButton = (
      <a id="createBtn" className="btn btn-primary btn-with-icon pull-right" href={this.generateCreateLink()}>
        <Icon iconType="file-text" />
        <span>{i18n.t('Create this Workshop')}</span>
      </a>
    );

    return (
      <Page
        pageType="parent"
        contentType="cards education-content"
        title={this.props.topic && this.props.topic.name}
        actionButton={actionButton}
        backButton={this.props.router.goBack}
        errorMessage={this.props.currentVenue ? null : this.props.errorMessage}
        successMessage={this.props.currentVenue ? null : this.props.successMessage}
        clearMessages={this.actions.clearMessages}
        usePageWrapper={true}
      >
        <div className="row">
          <div className="col-lg-6">
            <div className="card event-card">
              <div className="card-header">
                <img alt={this.props.topic ? i18n.t(this.props.topic.name + ' Topic Image') : i18n.t('Topic Image')}
                  src={(this.props.topic && this.props.topic.image_url) ? this.props.topic.image_url : ENGAGE_DEMO_IMG} />
              </div>
              <div className="card-body p-20">
                {this.props.topic && this.props.topic.description}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div id="createBtnPlaceholder" />
            <div className={`card event-card card-padding downloadable-resources blue-border ${!this.hasDowloadableResources() ? 'noMargin' : ''}`}>
              <div className="card-body">
                <div className="clearfix">
                  <div className="info-text">
                    <h3>{i18n.t('Downloadable Resources')}</h3>
                    {this.displayHeading()}
                  </div>
                  {this.displayDownloadableResources()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

export default connect(mapStateToProps)(ShowContainer);
