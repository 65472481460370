import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { JsonApiDecorator, Icon, TimezoneAbbr } from 'reactifi';
import CourseModule from '../components/CourseModule';
import i18n from 'lib/i18n';
import * as eventActionCreators from '../actions/eventActionCreators';
import * as contentActionCreators from '../../Content/actions/contentActionCreators';
import { ENGAGE_DEMO_IMG } from 'lib/constants';
import { eventFinderRedirectByViewer } from '../libs/EventFinderRedirectionFunc';
import UpcomingEventFinder from "../components/UpcomingEventFinderComponent";
import get from 'lodash/get';
import { isWebinar } from '../functions/helpers';
import { engageLoginUrl } from '../../common/urlHelpers';
import { sanitizeUrl } from '@braintree/sanitize-url';

function addEvents(apiStore, props, ownProps) {

  const eventId = ownProps.routeParams.id || props.eventId.toString();
  if (apiStore.events) {
    props.event = apiStore.events.find(eventId,
      { topic: ['course_modules', 'offline_contents', 'everfi_contents'] });
  }

  if (apiStore.attendees) {
    props.currentUser = apiStore.attendees.first();
  }

  if (apiStore.event_users) {
    props.currentUser = apiStore.event_users.first();
  }
  props.originalViewer = ownProps.location.query && ownProps.location.query.viewer ? ownProps.location.query.viewer : null;
  if (!props.event) {
    props.event = {
      id: eventId,
      volunteers: []
    };
  }
}

function addAccounts(apiStore, props, ownProps) {
  if (!apiStore.accounts) {
    props.account = { id: ownProps.route.accountId.toString() };
  } else {
    props.account = apiStore.accounts.first('topic');
    if (props.account.event_statuses) {
      props.event.statusDisplay = Object.keys(props.account.event_statuses).find((key) => {
        return props.account.event_statuses[key] === props.event.status;
      });
    }
  }
}

function addActivitiesStatus(apiStore, props) {
  props.activitiesStatus = apiStore.progress ? apiStore.progress.all() : [];
}

function mapStateToProps(state, ownProps) {
  let apiStore = new JsonApiDecorator(state.api);
  let props = {
    currentUserId: ownProps.route.currentUserId,
    eventId: ownProps.route.eventId,
    viewer: ownProps.route.viewer,
    contentServiceUrl: ownProps.route.contentServiceUrl,
    errorMessage: state.api.errorMessage,
    showAlertMessage: !state.eventReducer.showEdit,
    successMessage: state.api.successMessage,
    isEventManagement: ownProps.route.isEventManagement
  };

  addEvents(apiStore, props, ownProps);
  addAccounts(apiStore, props, ownProps);
  addActivitiesStatus(apiStore, props);
  props.showBackBtn = (props.viewer !== 'attendee');
  return props;
}

class DashboardContainer extends React.Component {
  static propTypes = {
    account: PropTypes.shape({
      id: PropTypes.string,
      topics: PropTypes.array,
      name: PropTypes.string,
      languageOptions: PropTypes.array
    }),
    activitiesStatus: PropTypes.array,
    attendee: PropTypes.shape({
      progress_ids: PropTypes.arrayOf(PropTypes.shape({
        progress_id: PropTypes.string
      }))
    }),
    currentUser: PropTypes.object,
    currentUserId: PropTypes.number,
    contentServiceUrl: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    event: PropTypes.object.isRequired,
    isEventManagement: PropTypes.bool,
    location: PropTypes.object,
    originalViewer: PropTypes.string,
    showBackBtn: PropTypes.bool,
    viewer: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators({ ...eventActionCreators, ...contentActionCreators }, this.props.dispatch);
    this.onSearchSubmit = eventFinderRedirectByViewer(this.props.viewer);
  }

  async componentDidMount() {
    const { account, contentServiceUrl, event, viewer } = this.props;
    if (viewer === 'volunteer') {
      await this.actions.loadCurrentVolunteer(event.id);
    } else {
      let result = await this.actions.loadCurrentAttendee();
      if(result?.error){
        window.location.href = '/events'
      }
    }

    // Do not destructure `currentUser`! Removed to fix
    // FIL-2968: https://everfi.atlassian.net/browse/FIL-2968
    // PRs:
    // https://github.com/EverFi/fi-live/pull/2010
    // https://github.com/EverFi/fi-live/pull/2011
    if (this.props.currentUser) {
      this.actions.loadActivitiesStatus(this.props.currentUser.progress_ids, contentServiceUrl);
    }
    this.actions.loadEventDashboardInfo(event.id);
    this.actions.loadAccount(account.id);
  }

  renderCourseModules(courses) {
    const { currentUser } = this.props;
    if (currentUser) {
      return courses.map(course => {
        const hide_from_event_users = course.hide_from_event_users;
        const disabledCourse = hide_from_event_users && currentUser.type !== 'attendees';
        let progress = this.props.currentUser.progress_ids.find((progress) => progress.content_id === course.content_id);
        let status = 'not_started';

        if (progress) {
          status = get(this.props.activitiesStatus.find((activity) => activity.id === progress.progress_id), 'status');
        }
        let content_id = course.content_id;
        return <CourseModule disabled={disabledCourse} currentViewer={this.props.currentUser} courseModule={course} content_id={content_id} status={status} event_id={this.props.event.id} />
      });
    }
  }

  render() {
    const {
      account,
      currentUser,
      currentUserId,
      event,
      isEventManagement,
      viewer
    } = this.props;

    if(this.props.location.query.viewer === 'account_admin' && !currentUser && !currentUserId){
      window.location.href = engageLoginUrl();
    }

    const courses = event.topic ? event.topic.everfi_contents : [];
    const eventImage = (event.topic && event.topic.image_url) ? event.topic.image_url : ENGAGE_DEMO_IMG;
    let who = isWebinar(event.event_type) ? 'cportal' : viewer === 'volunteer' ? 'vportal' : 'cportal';
    if (this.props.originalViewer) {
      // handle from cportal or vportal event where it can be account_admin or volunteer respectively
      who = this.props.originalViewer === 'account_admin' ? 'cportal' : 'vportal';
    }
    const url = window.location.href.split('/events/')[0] + '/' + who + '/events#/' + event.id;
    const eventDate = moment(event.start_time).format('LL');
    return (
      <div className="container event-dashboard-container">
        <div className="row header" />
        <div className="event-dashboard-content">
          <div className="row event-details">
            <div className="event-copy">
              <div className="row">
                <div className="col-md-12">
                  <h1>
                    {this.props.showBackBtn && <a href={sanitizeUrl(url)} title={i18n.t('Back to Event Page')}>
                      <Icon iconType="arrow-left" />
                    </a>}
                    {event.title} - {eventDate}
                  </h1>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div aria-label="Main event dashboard image showing 2 people sitting with moving boxes in front of a new house" className="event-image" style={{ backgroundImage: `url(${eventImage})` }} />
                </div>
                <div className="col-md-6">
                  <div className="row event-details-container">
                    <div className="col-md-6 col-md-push-6 event-access-details">
                      <h2>{i18n.t("Event Access")}</h2>
                      {event.url && (
                        <div className="icon-column">
                          <div className="icon"><Icon iconType="rocket" fontFamily="fa" /></div>
                          <h3>{i18n.t('Conferencing Info')}</h3>
                          <a href={event.url} target="_blank" rel="noopener noreferrer">{event.url}</a>
                          {event.additional_info && <p>{event.additional_info}</p>}
                        </div>
                      )}
                      <div className="icon-column">
                        <div className="icon"><Icon iconType="time" /></div>
                        <h3>{i18n.t('Date and Time')}</h3>
                        <span data-attribute="start_time">{moment(event.start_time).format('LT')}</span> - <span data-attribute="end_time">{moment(event.end_time).format('LT')}</span> <span data-attribute="time_zone">{TimezoneAbbr()}</span>
                        <br />
                        {eventDate}
                      </div>
                    </div>
                    <div className="col-md-6 col-md-pull-6">
                      <h2>{i18n.t("What We'll Cover")}</h2>
                      <p>{event.description}</p>
                      {isWebinar(event.event_type) &&
                        <>
                          <h2>{i18n.t("Instructions")}</h2>
                          <p>{i18n.t("If you're not in the conference already, join using the info above. When your facilitator prompts you, we will do the exercises below together.")}</p>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.renderCourseModules(courses)}

        <div>
          <UpcomingEventFinder
            isEventManagement={isEventManagement}
            topics={account.topics}
            onSearch={this.onSearchSubmit}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(DashboardContainer);
