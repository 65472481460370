import PropTypes from 'prop-types';
import React from 'react';
import Assessment from './containers/AssessmentContainer'
import storeCreator from './store/assessmentStore'
import { ErrorBoundedProvider } from 'reactifi';
import { loadResponseSet } from './actions/assessmentActionCreators'
import { setEndpointHost, setEndpointPath, setHeader, setAccessToken } from 'redux-json-api'
import i18n from 'lib/i18n'

export default class AssessmentApp extends React.Component {
  static propTypes = {
    endpointHost: PropTypes.string.isRequired,
    endpointPath: PropTypes.string.isRequired,
    accessToken: PropTypes.string.isRequired,
    saveClickTime: PropTypes.string,
    responseSetId: PropTypes.string,
    afterSaved: PropTypes.func,
    className: PropTypes.string
  };

  constructor(props) {
    super(props);
    let host = this.props.endpointHost[this.props.endpointHost.length - 1] !== '/' ? this.props.endpointHost + '/' : this.props.endpointHost;
    let path = this.props.endpointPath[0] === '/' ? this.props.endpointPath.substring(1, this.props.endpointPath.length) : this.props.endpointPath;
    path = path[path.length - 1] === '/' ? path.substring(0, path.length - 1) : path;

    const store = storeCreator();
    store.dispatch(setEndpointHost(host));
    store.dispatch(setEndpointPath(path));
    store.dispatch(setAccessToken(this.props.accessToken));
    store.dispatch(setHeader({ "Content-Type": "application/json" }));
    store.dispatch(setHeader({ "Accept-Language": i18n.language }));
    store.dispatch(loadResponseSet(this.props.responseSetId));
    this.store = store;
  }

  render() {
    return (
      <ErrorBoundedProvider store={this.store}>
        <Assessment saveClickTime={this.props.saveClickTime} afterSaved={this.props.afterSaved}
          responseSetId={this.props.responseSetId} className={this.props.className} />
      </ErrorBoundedProvider>);
  }
}
