import PropTypes from 'prop-types';
import React from 'react';
import { SearchForm, FloatingLabelFormField } from 'reactifi';
import i18n from 'lib/i18n';
export default class WorkLocationSearchForm extends React.Component {
  static propTypes = {
    searchAction: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  };

  render(){
    return (
      <SearchForm searchAction={this.props.searchAction} disabled={this.props.disabled} className="search-form container">
        <FloatingLabelFormField name="keyword" caption={i18n.t("Name, Location, Zip Code")} searchField={true} />
        <input style={{ display:"none" }}/>
      </SearchForm>);
  }
}
