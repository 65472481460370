import { routerReducer } from 'react-router-redux'
import eventReducer from './eventReducer';
import attendeeReducer from './attendeeReducer';
import { ApiReducer as api } from 'reactifi';

export default {
  routing: routerReducer,
  attendeeReducer,
  eventReducer,
  api
};
