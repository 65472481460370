/* eslint-disable react/no-multi-comp */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { Header, Icon, MessagesModule, NoResultsCard, PageWrapper, PagingDataList, Section, Storage } from 'reactifi';
import EventSearchForm from './EventSearchForm';
import i18n from 'lib/i18n';

const getFields = (isEventManagement, isMobile) => {
  return [
    {
      name: 'title',
      defaultSort: true,
      title: i18n.t('Title'),
      canSort: true,
      formatter: (_, row) => <Link to={`/${row.id}`}>{row.title}</Link>,
      mobileFormatter: (_, row) => row.title
    },
    {
      name: 'start_time',
      title: i18n.t('Date'),
      width: 120,
      canSort: true,
      formatter: cell => new Date(cell).toLocaleDateString()
    },
    ...!isEventManagement ? [{
      name: 'is_lmi',
      title: i18n.t('LMI?'),
      canSort: true,
      width: 100,
      formatter: cell => cell ?
        <Icon iconType="true" fontFamily="engage" /> :
        <Icon iconType="false" fontFamily="engage" />
    }] : [],
    {
      name: 'statusDisplay',
      title: i18n.t('Status'),
      width: 130,
      canSort: true
    },
    {
      name: 'attendees_count',
      title: i18n.t('Attendees'),
      width: 130,
      canSort: true,
      formatter: (_, row) => <a href={`attendees#?filterField=event_id&filterValue=${row.id}`}>{row.attendee_count}</a>
    },
    {
      name: 'volunteers_count',
      title: i18n.t('Volunteers'),
      width: 130,
      canSort: true,
      formatter: (_, row) => <a href={`volunteers#?filterField=event_id&filterValue=${row.id}`}>{row.volunteer_count}</a>
    },
    {
      name: 'volunteer_hours',
      title: i18n.t('Hours'),
      width: 100,
      canSort: true
    },
    ...isMobile ? [{
      name: 'event_details',
      title: i18n.t('Event Details'),
      canSort: false,
      mobileFormatter: (_, row) => <Link to={`/${row.id}`}>{i18n.t('Event Details')}</Link>
    }] : []
  ].filter(Boolean);
};

const tableOptions = onSortChange => {
  return {
    bordered: false,
    hover: true,
    striped: false,
    defaultSortName: 'title',
    defaultSortOrder: 'asc',
    noDataText: i18n.t('There is no data to display.'),
    onSortChange: onSortChange
  };
};

const EventList = props => {
  const {
    account,
    clearMessages,
    downloadEvents,
    downloadUrl,
    errorMessage,
    events,
    eventsMeta,
    eventStatuses,
    eventTypes,
    filterEvents,
    isEventManagement,
    isMobile,
    isLoadingEvents,
    successMessage
  } = props;

  const [activePage, setActivePage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState({});
  const [pageSize, setPageSize] = useState(Storage.pageSize);
  const [sort, setSort] = useState({ value: 'title', direction: 'asc' });

  useEffect(() => {
    const searchValues = { ...searchValue, ...filters };
    filterEvents(searchValues, activePage, sort);
  }, [activePage, filters, searchValue, sort, pageSize]);

  useEffect(() => {
    if (downloadUrl) {
      Storage.setItem('suppressLoadingClass', true);
      window.location.href = downloadUrl;
    }
  }, [downloadUrl]);

  const onPageChange = useCallback(activePage => {
    setActivePage(activePage);
    setPageSize(Storage.pageSize);
  });

  const onSortChange = useCallback((value, direction) => {
    setSort({ value, direction });
    setActivePage(1);
  });

  const prepareDownload = () => {
    downloadEvents(Object.assign({}, filters, searchValue, { sort }));
  }

  return (
    <PageWrapper>
      <MessagesModule
        clearMessages={clearMessages}
        errorMessage={errorMessage}
        successMessage={successMessage}
      />
      <Header
        title={i18n.t('Event List')}
        button={{
          action: () => { window.location.href = 'events#/event_wizard' },
          label: i18n.t('Create New Event')
        }}
      />
      <Section type="list">
        <EventSearchForm
          account={account}
          disabled={isLoadingEvents}
          eventStatuses={eventStatuses}
          eventTypes={eventTypes}
          isEventManagement={isEventManagement}
          searchValue={searchValue}
          setActivePage={setActivePage}
          setFilters={setFilters}
          setSearchValue={setSearchValue}
        />
        {isLoadingEvents || events?.length ?
          <PagingDataList
            {...props}
            activePage={activePage}
            data={events}
            downloadAction={prepareDownload}
            fields={getFields(isEventManagement, isMobile)}
            isLoadingData={isLoadingEvents}
            keyField="id"
            onPageChange={onPageChange}
            options={tableOptions(onSortChange)}
            showDownloadLink={true}
            totalCount={eventsMeta?.total_count || 0}
          /> :
          <NoResultsCard
            buttons={[{
              label: i18n.t("Create New Event"),
              link: "event_wizard"
            }]}
            message={i18n.t("Looks like there aren't any events")}
          />
        }
      </Section>
    </PageWrapper>
  )
}

EventList.propTypes = {
  account: PropTypes.object,
  clearMessages: PropTypes.func,
  downloadEvents: PropTypes.func.isRequired,
  downloadUrl: PropTypes.string,
  errorMessage: PropTypes.string,
  events: PropTypes.array.isRequired,
  eventsMeta: PropTypes.shape({
    aggregations: PropTypes.object,
    is_customer_admin: PropTypes.bool,
    total_count: PropTypes.number
  }),
  eventStatuses: PropTypes.array,
  eventTypes: PropTypes.array,
  filterEvents: PropTypes.func.isRequired,
  isEventManagement: PropTypes.bool,
  isLoadingEvents: PropTypes.bool,
  isMobile: PropTypes.bool,
  successMessage: PropTypes.string
};

export default EventList;
