import moment from 'moment';
import { isFutureEvent } from '../functions/helpers';

const ics = (event) => {
  const formattedDescription = (event.details || "")
    .replace(/,/gm, "\,")
    .replace(/;/gm, "\;")
    .replace(/\n/gm, "\\n")
    .replace(/(\\n)[\s\t]+/gm, "\\n");
  const formattedLocation = (event.location || "")
    .replace(/,/gm, "\,")
    .replace(/;/gm, "\;")
    .replace(/\n/gm, "\\n")
    .replace(/(\\n)[\s\t]+/gm, "\\n");
  const start = event.start;
  const end = event.end;
  const calendarChunks = [
    {
      key: "BEGIN",
      value: "VCALENDAR"
    },
    {
      key: "VERSION",
      value: "2.0"
    },
    {
      key: "BEGIN",
      value: "VEVENT"
    },
    {
      key: "URL",
      value: event.url
    },
    {
      key: "DTSTART",
      value: start
    },
    {
      key: "DTEND",
      value: end
    },
    {
      key: "SUMMARY",
      value: event.text
    },
    {
      key: "DESCRIPTION",
      value: formattedDescription
    },
    {
      key: "LOCATION",
      value: formattedLocation
    },
    {
      key: "END",
      value: "VEVENT"
    },
    {
      key: "END",
      value: "VCALENDAR"
    }
  ];
  let calendarUrl = '';
  calendarChunks.forEach((chunk) => {
    if (chunk.value) {
      calendarUrl += `${chunk.key}:${encodeURIComponent(`${chunk.value}\n`)}`;
    }
  });
  return `data:text/calendar;charset=utf8,${calendarUrl}`;
}

const formatTime = (event) => {
  const format = event.cal === 'outlook' ? 'YYYY-MM-DD[T]HH:mm:SS[Z]' : 'YYYYMMDD[T]HHmmss[Z]';
  const start = moment.utc(event.start).format(format);
  const end = moment.utc(event.end).format(format);
  event.dates = start + '%2F' + end;
  event.start = start;
  event.end = end;
  return event;
}

export const addToCalendar = (event) => {
  let link = null;
  event = formatTime(event);
  if (event.cal === 'google') {
    link = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(event.text)}&details=${encodeURIComponent(event.details)}&location=${encodeURIComponent(event.location)}&dates=${event.dates}`;
  }
  if (event.cal === 'outlook') {
    link = `https://outlook.live.com/calendar/0/deeplink/compose?path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${event.start}&enddt=${event.end}&subject=${encodeURIComponent(event.text)}&body=${encodeURIComponent(event.details)}&location=${encodeURIComponent(event.location)}`;
  }
  if (event.cal === 'ics') {
    link = ics(event);
  }
  if (link) {
    window.open(link, '_blank');
  }
}

export const displayAttendeeButtons = (event, viewer, currentEventUser) => {
  const isEventAdmin = ['account_admin', 'event_staff'].includes(viewer);
  const adminDisplayButtons = isEventAdmin ? currentEventUser : true;
  return isFutureEvent(moment(event.start_time)) &&
    !event.cancelled && (viewer === 'attendee' || isEventAdmin || !viewer) &&
    adminDisplayButtons;
}


