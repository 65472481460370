import React from 'react';
import PropTypes from 'prop-types';
import OfflineContentItem from "./OfflineContentItemComponent";
import { Icon } from 'reactifi';

const EverfiContentItem = ({ everfiContent, offlineContents, selectContent, deleteContent, elementId }) => (
  <div className="col-lg-12 offline-content-doc">
    { everfiContent ? <h4>{ everfiContent.name }</h4> : <h4>General</h4> }
    <div className="collapse in" id={elementId}>
      {
        offlineContents.map((offlineContent, index) =>
          <div className="resource-entry">
            <OfflineContentItem everfiContent={everfiContent} offlineContent={offlineContent} key={index} />
            <div className="row-tools">
              <a onClick={(e) => selectContent(offlineContent.id)}> <Icon iconType="edit" /></a>
              <a onClick={(e) => deleteContent(offlineContent)}> <Icon iconType="delete" /></a>
            </div>
          </div>
        )
      }
    </div>
  </div>
);

EverfiContentItem.propTypes = {
  everfiContent: PropTypes.object,
  offlineContents: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectContent: PropTypes.func,
  deleteContent: PropTypes.func,
  elementId: PropTypes.string
};

export default EverfiContentItem;
