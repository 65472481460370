import PropTypes from 'prop-types';
import React from 'react';
import { FieldGroup, ModalForm, FloatingLabelFormField } from 'reactifi';
import i18n from 'lib/i18n';

export default class EventConferencingInfoForm extends React.Component {
  static propTypes = {
    clearMessages: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    event: PropTypes.object,
    toggleEditConferencingInfo: PropTypes.func.isRequired,
    updateEvent: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
  }

  onCancel = () => {
    this.props.toggleEditConferencingInfo(false);
    this.props.clearMessages();
  }

  onUpdate = event => {
    this.props.updateEvent(event, "", this.props.toggleEditConferencingInfo);
  }

  render() {
    const { errorMessage, event } = this.props;
    let languages = [{ en: 'English' }]
    if (event && event.title_es) {
      languages.push({ es: 'Spanish' });
    }
    return (
      <ModalForm
        data={event}
        errorMessage={errorMessage}
        onCancel={this.onCancel}
        title={i18n.t('Conferencing Info')}
        updateAction={this.onUpdate}
        languages={languages}
      >
        <FieldGroup className="container-fluid">
          <FieldGroup className="row">
            <FieldGroup className="col-md-12">
              <FloatingLabelFormField
                caption={i18n.t("Event URL")}
                multilingual={true}
                name="url"
                required={true}
                type="url"
                validationType="url"
              />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-md-12">
              <FloatingLabelFormField
                caption={i18n.t("Additional Information")}
                componentClass="textarea"
                name="additional_info"
                multilingual={true}
              />
            </FieldGroup>
          </FieldGroup>
        </FieldGroup>
      </ModalForm>
    )
  }
}
