import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as eventActionCreators from '../actions/eventActionCreators';
import { buildGenericProps, ProgressSpinner, JsonApiDecorator } from 'reactifi';
import FilterSection from '../components/FilterSectionComponent';
import EventList from '../components/EventListComponent';
import { eventFinderRedirect } from "../../Event/libs/EventFinderRedirectionFunc";
import EmptyEventListPlaceholder from "../components/EmptyEventListPlaceholderComponent";
import GenerateAttendeeViewEventLink from "../components/GenerateAttendeeViewEventLink";

function mapStateToProps(state, ownProps) {
  let props = buildGenericProps(state, 'events');
  let apiStore = new JsonApiDecorator(state.api);
  if (apiStore.events) {
    props.events = apiStore.events.all(['venue', 'event_users', 'topic']);
    props.events.forEach((event) => {
      event.joined = event.event_users.some((eu) => eu.user_id.toString() === props.currentUserId);
      event.lead_volunteer = event.event_users.find((eu) => eu.role === 'lead_volunteer');
    });
  }
  if (apiStore.accounts) {
    props.topics = apiStore.accounts.first('topic').topics;
  }
  props.isEventManagement = ownProps.route.isEventManagement;
  return props;
}

class AttendeeEventListContainer extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    isEventManagement: PropTypes.bool,
    events: PropTypes.array,
    router: PropTypes.object,
    route: PropTypes.shape({
      accountId: PropTypes.string
    }),
    topics: PropTypes.array
  };


  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false
    };
    this.actions = bindActionCreators(eventActionCreators, this.props.dispatch);
    this.redirect = eventFinderRedirect('/#/');
  }

  async componentDidMount() {
    await this.searchEvents(this.props.router.location.query);
    await this.actions.loadAccount(this.props.route.accountId);
    this.setState({ dataLoaded: true });
  }

  async searchEvents({ topic: topic_id, location }) {
    const filter = {
      topic_id,
      location,
      status: ['planned', 'in_progress', 'pending_completion', 'completed', 'private', 'demo']
    }
    this.actions.readEvents('venue,event_users,topic', filter);
  }

  filterEvents = searchedFields => {
    this.redirect(searchedFields);
    this.searchEvents(searchedFields);
  };

  getQueryParams = () => ({
    topic: this.props.router.location.query.topic,
    location: this.props.router.location.query.location
  });

  render() {
    const { isEventManagement } = this.props;

    if (!this.state.dataLoaded) {
      return (
        <div className="text-center">
          <ProgressSpinner classNames="preloader pl-xxl pls-blue" />
        </div>
      );
    }

    return (
      <div className="attendee-event-container">
        <FilterSection
          isEventManagement={isEventManagement}
          onSearch={this.filterEvents}
          selectedOptions={this.getQueryParams()}
          topics={this.props.topics}
        />
        {this.props.events.length > 0 ?
          <EventList events={this.props.events} generateViewEventLink={GenerateAttendeeViewEventLink} /> :
          <EmptyEventListPlaceholder />
        }
      </div>
    );
  }
}

export default connect(mapStateToProps)(AttendeeEventListContainer);
