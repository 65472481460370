import ReactOnRails from 'react-on-rails';
import RailsAddressPicker from '../lib/components/RailsAddressPicker';
import EventPreview from '../lib/components/EventPreview';
import HeaderMenu from '../lib/components/HeaderMenu/HeaderMenu';
import {
  AttentionGrabber,
  AutoLogout,
  InteractiveNotifications,
  ProgressSpinner,
  SidebarMenu,
  SystemNotifications,
} from 'reactifi';

const bundles = [
  { VenueApp: './Venue' },
  { EventApp: './Event' },
  { WorkLocationApp: './WorkLocation' },
  { PartnerApp: './Partner' },
  { AttendeeApp: './Attendee' },
  { VolunteerApp: './Volunteer' },
  { CustomerAdminApp: './CustomerAdmin' },
  { TopicsApp: './Topics' },
  { OrganizationsApp: './Admin/Organizations' },
  { ContentApp: './Admin/Content' },
  { FinancialEducationContentApp: './FinancialEducationContent' },
  { AttendeeEventListApp: './AttendeeEvent' },
  { EventContentApp: './Content' },
];

bundles.forEach(value => {
  if (value !== null && typeof value === 'object') {
    const key = Object.keys(value)[0];
    const bundle = require(`${value[key]}/index`).default; // eslint-disable-line global-require
    ReactOnRails.register({ [key]: bundle });
  } else {
    const bundle = require(`./${value}/index`).default; // eslint-disable-line global-require
    ReactOnRails.register({ [`${value}App`]: bundle });
  }
});

ReactOnRails.register({
  AttentionGrabber,
  AutoLogout,
  EventPreview,
  HeaderMenu,
  InteractiveNotifications,
  ProgressSpinner,
  RailsAddressPicker,
  SidebarMenu,
  SystemNotifications,
});
