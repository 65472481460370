import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon, Storage, FieldGroup, ResponsiveDataList, buildGenericProps, MaterialCheckbox } from 'reactifi';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import * as eventActionCreators from '../actions/eventActionCreators';
import i18n from "lib/i18n";
import { sanitizeUrl } from '@braintree/sanitize-url';

function mapStateToProps(state) {
  let props = buildGenericProps(state, 'reservations');
  props.dispatch = state.dispatch;
  return props;
}

class PreregListContainer extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    event: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { allow_pre_registration: false, tableClass: '', initialized: false }
    this.actions = bindActionCreators(eventActionCreators, this.props.dispatch);
  }

  componentDidMount() {
    this.setState({
      allow_pre_registration: this.props.event.allow_pre_registration
    });
  }

  get fields() {
    return [
      { name: 'first_name', defaultSort: true, canSort: true, title: i18n.t('Name'), formatter: (cell, row) => {
        return <span className="col-text">{row.first_name} {row.last_name}</span>;
      }, mobileFormatter: (cell, row) => `${row.first_name} ${row.last_name}`
      },
      { name: 'contact_email', title: i18n.t('Email'), canSort: true },
      { name: 'contact_promotional', title: i18n.t('Promotional Consent'), canSort: true, formatter: (cell, row) => {
        return row.contact_promotional ? 'yes' : 'no';
      }
      }
    ];
  }

  onPreregChange = (e) => {
    this.setState({
      allow_pre_registration: e || false,
      tableClass: e ? '' : 'disabled-table'
    });
    if(this.state.initialized) {
      this.actions.updateEvent(
        Object.assign(this.props.event, {
          allow_pre_registration: e || false
        })
      );
    } else {
      this.setState({ initialized: true });
    }
  }

  downloadCSV = (e) => {
    e.preventDefault();
    Storage.setItem('suppressLoadingClass', true);
    window.location.href = sanitizeUrl(`/api/data/events/${this.props.event.id}/reservations.csv`);
  }

  render()  {
    const { event } = this.props;
    const tableOptions = {
      bordered: false,
      hover: true,
      striped: false,
      sortName: 'first_name',
      sortOrder: 'asc'
    };

    return (
      <>
        <p className="m-b-5">
          {i18n.t("If you'd like to message your pre-registered attendees in advance, make sure pre-registration is enabled.")}
          <FieldGroup className="p-t-5 p-b-5">
            <MaterialCheckbox
              data-object-type="events"
              data-object-id={event.id}
              data-action="allow-pre-registration-event-tab"
              id="allow_pre_registration"
              className="m-b-10 m-r-5 m-l-0"
              inline={true}
              name="allow_pre_registration"
              valueOverride={true}
              dataValue={this.state.allow_pre_registration}
              onChange={this.onPreregChange} >
              {i18n.t('Allow pre-registration for this event')}
            </MaterialCheckbox>
            <OverlayTrigger
              overlay={<Tooltip>{i18n.t('Pre-registration enables you to send emails or other communications to attendees in advance.')}</Tooltip>}
              placement="top" >
              <span><Icon iconType="info-outline" additionalClasses="zmdi-hc-lg text-info" /></span>
            </OverlayTrigger>
          </FieldGroup>
        </p>
        <span>{i18n.t(`${event.reservations ? event.reservations.length : '0'} pre-registered Attendee(s) in the List`)}</span>
        <a href="#" onClick={this.downloadCSV} className="pull-right">{i18n.t('Download CSV')} <Icon iconType="download" /></a>        <div className={this.state.tableClass}>
          <ResponsiveDataList
            data={event.reservations}
            fields={this.fields}
            keyField="id"
            options={tableOptions}
          />
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps)(PreregListContainer);
