import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import {
  FieldGroup,
  FloatingLabelFormField,
  SelectControl
} from 'reactifi';

export default class TopicDetailsComponent extends React.Component {
  static propTypes = {
    account: PropTypes.object,
    data: PropTypes.shape({
      topic_id: PropTypes.string
    }),
    eventTypes: PropTypes.array,
    onEventDescriptionChange: PropTypes.func,
    onEventTitleChange: PropTypes.func,
    selectTopic: PropTypes.func,
    selectEventType: PropTypes.func,
    topics: PropTypes.array
  };

  constructor(props) {
    super(props);
  }

  selectEventType = eventType => {
    const { selectEventType, account } = this.props;
    selectEventType(eventType, account.topics, true);
  }


  render() {
    const {
      data,
      eventTypes,
      onEventDescriptionChange,
      onEventTitleChange,
      selectTopic,
      topics
    } = this.props;

    return (
      <FieldGroup {...this.props}>
        <FieldGroup>
          <FloatingLabelFormField name="event_type" caption={i18n.t("Event Type")} required={true}>
            <SelectControl
              labelKey="label"
              onChange={this.selectEventType}
              options={eventTypes}
              valueKey="value"
            />
          </FloatingLabelFormField>
        </FieldGroup>
        <FieldGroup>
          <FloatingLabelFormField
            caption={i18n.t("Event Topic")}
            disabled={!topics.length}
            name="topic_id"
            required={true}
          >
            {topics.length &&
              <SelectControl
                labelKey="label"
                onChange={selectTopic}
                options={topics}
                placeholder=""
                valueKey="value"
              />
            }
          </FloatingLabelFormField>
        </FieldGroup>
        <FieldGroup>
          <FloatingLabelFormField
            caption={i18n.t("Event Title")}
            disabled={!data.topic_id}
            multilingual={true}
            name={"title"}
            onChange={onEventTitleChange}
            required={true}
            valueOverride={true}
          />
        </FieldGroup>
        <FieldGroup>
          <FloatingLabelFormField
            caption={i18n.t("Description")}
            componentClass={'textarea'}
            disabled={!data.topic_id}
            multilingual={true}
            name={"description"}
            onChange={onEventDescriptionChange}
            required={true}
            valueOverride={true}
          />
        </FieldGroup>
      </FieldGroup>
    );
  }
}
