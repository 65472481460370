import PropTypes from 'prop-types';
import React from 'react';
import { FieldGroup, ModalForm, SelectAsyncControl, FloatingLabelFormField, AddressPickerControl, MaterialCheckbox, InfoTooltip } from 'reactifi';
import { Radio } from 'react-bootstrap';
import i18n from 'lib/i18n';

export default class EventVenueForm extends React.Component {
  static propTypes = {
    clearMessages: PropTypes.func.isRequired,
    createVenue: PropTypes.func,
    createVenuePartner: PropTypes.func,
    errorMessage: PropTypes.string,
    event: PropTypes.object,
    googleMapsApiKey: PropTypes.string,
    isCreateEvent: PropTypes.bool,
    isEventManagement: PropTypes.bool,
    loadVenue: PropTypes.func.isRequired,
    toggleEditVenue: PropTypes.func.isRequired,
    updateEvent: PropTypes.func.isRequired,
    newEntity: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      isCreateVenue: false,
      venueId: null,
      valueOverride: true
    };
  }

  onCancel = () => {
    this.setState({ venueId: null });
    this.props.clearMessages();
    this.props.toggleEditVenue(false);
  }

  onAdd = async event => {
    const { createVenue, createVenuePartner, event: { id: eventId }, isCreateEvent, isEventManagement, newEntity, toggleEditVenue, updateEvent } = this.props;
    event.id = eventId;
    if (this.state.isCreateVenue) {
      const newVenue = newEntity("venues");
      Object.assign(newVenue, event.venue);
      newVenue.computer_lab = newVenue.computer_lab === "true";
      newVenue.projector = newVenue.projector === "true";
      newVenue.accessibility = newVenue.accessibility === "true";
      newVenue.event_ids = [event.id];
      event.venue = isCreateEvent ? await createVenue(newVenue, null, isCreateEvent, true, isEventManagement) : await createVenuePartner(newVenue, event.id, toggleEditVenue, isEventManagement, isEventManagement);
    }
    updateEvent(event, null, toggleEditVenue);
  }

  onUpdate = event => {
    const { createVenuePartner, newEntity, toggleEditVenue, updateEvent, isEventManagement } = this.props;
    const { editEventContact, switched, isCreateVenue } = this.state;
    const clearVenueContact = switched && !editEventContact;
    if (isCreateVenue) {
      const newVenue = newEntity("venues");
      Object.assign(newVenue, event.venue);
      newVenue.computer_lab = newVenue.computer_lab === "true";
      newVenue.projector = newVenue.projector === "true";
      newVenue.accessibility = newVenue.accessibility === "true";
      newVenue.event_ids = [event.id];
      createVenuePartner(newVenue, event.id, toggleEditVenue, true, isEventManagement);
    } else {
      event.venue_contact_name = clearVenueContact ? null : this.state.event.venue_contact_name;
      event.venue_contact_email = clearVenueContact ? null : this.state.event.venue_contact_email;
      event.venue_contact_phone = clearVenueContact ? null : this.state.event.venue_contact_phone;
      this.setState({ switched: false });
      updateEvent(event, null, toggleEditVenue);
    }
  }

  onVenueChange = async val => {
    if (val && val.id) {
      const switched = this.state.venueId ? true : false;
      this.setState({ venueId: val.id, switched });
      await this.props.loadVenue(val.id);
    }
  }

  componentDidMount() {
    if (!this.props.event || !this.props.event.venue_id) {
      this.onVenueCreateSelect(true);
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.venues && newProps.event && (newProps.event.venue_id || this.state.venueId)) {
      let venueId = !this.state.venueId ? newProps.event.venue_id : this.state.venueId;
      let selectedVenue = newProps.venues.find((venue) => { return venue.id === venueId.toString() });
      if (selectedVenue) {
        let event = newProps.event;
        event.venue = selectedVenue;
        this.setState({ event, isCreateVenue: false, valueOverride: true, editEventContact: false });
      }
    }
  }

  buildDisplay = venue => {
    const hasDisplayProps = Boolean(venue && venue.title && venue.location_locality && venue.location_administrative_area_level_1);

    if (hasDisplayProps) {
      return `${venue.title} (${venue.location_locality}, ${venue.location_administrative_area_level_1})`;
    } else {
      return venue?.title ? `${venue.title}` : '';
    }
  }

  onEventContactEdit = editEventContact => {
    this.setState({ editEventContact });
  }

  venueEditElements = () => {
    let valueOverride = this.state.valueOverride;
    let disabled = !this.state.isCreateVenue;
    let name = 'venue[contact_name]';
    let phone = 'venue[contact_phone]';
    let email = 'venue[contact_email]';
    if (this.state.editEventContact) {
      valueOverride = !this.state.editEventContact;
      disabled = !this.state.editEventContact;
      name = 'venue_contact_name';
      phone = 'venue_contact_phone';
      email = 'venue_contact_email';
    }
    return (
      <FieldGroup>
        <FieldGroup className="row">
          <FieldGroup className="col-6">
            <FloatingLabelFormField
              caption={i18n.t("Name")}
              disabled={disabled}
              name={name}
              required={true}
              valueOverride={valueOverride}
            />
          </FieldGroup>
          <FieldGroup className="col-6">
            <FloatingLabelFormField
              caption={i18n.t("Phone Number")}
              disabled={disabled}
              name={phone}
              required={true}
              valueOverride={valueOverride}
            />
          </FieldGroup>
        </FieldGroup>
        <FieldGroup className="row">
          <FieldGroup className="col-6">
            <FloatingLabelFormField
              caption={i18n.t("Email")}
              disabled={disabled}
              name={email}
              required={true}
              type="email"
              valueOverride={valueOverride}
            />
          </FieldGroup>
        </FieldGroup>
      </FieldGroup>
    );
  }

  onVenueCreateSelect = isCreate => {
    const { event, newEntity } = this.props;

    if (isCreate) {
      const event = { ...this.state.event };
      event.venue = newEntity("venues");
      event.venue = {
        title: "",
        capacity: "",
        location_latitude: "",
        location_room: "",
        contact_name: "",
        contact_phone: "",
        contact_email: "",
        computer_lab: false,
        projector: false,
        accessibility: false
      };
      this.setState({
        event,
        isCreateVenue: true,
        valueOverride: true,
        venueId: null
      });
      setTimeout(() => {
        this.setState({ valueOverride: false })
      }, 0);
    } else {
      this.setState({
        event,
        isCreateVenue: false,
        valueOverride: true,
        venueId: event.venue.id
      });
    }
  }

  render() {
    const { isCreateEvent, isEventManagement, errorMessage, event, googleMapsApiKey } = this.props;
    const { editVenue, isCreateVenue, valueOverride } = this.state;
    const currentVenue = event?.venue ? { id: event.venue.id, display: this.buildDisplay(event.venue) } : {};
    return event && (
      <ModalForm
        addAction={this.onAdd}
        data={this.state.event}
        errorMessage={errorMessage}
        onCancel={this.onCancel}
        saveAndAddButton={!isCreateEvent && !isEventManagement}
        title={i18n.t('Venue Details')}
        updateAction={this.onUpdate}
      >
        <FieldGroup className="container-fluid">
          <FieldGroup className="row">
            <FieldGroup className="col-6">
              <Radio
                checked={isCreateVenue}
                name="venue_type"
                onChange={() => this.onVenueCreateSelect(true)}
              >
                <i className="input-helper" />
                {i18n.t('Create a new venue')}
              </Radio>
            </FieldGroup>
            <FieldGroup className="col-6">
              <Radio
                checked={!isCreateVenue}
                name="venue_type"
                onChange={() => this.onVenueCreateSelect(false)}
              >
                <i className="input-helper" />
                {i18n.t('Choose an existing venue')}
              </Radio>
            </FieldGroup>
          </FieldGroup>
          <br />
          {!isCreateVenue &&
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField
                  name="venue_id"
                  caption={i18n.t('Venue')}
                  disabled={editVenue}
                  hintText={i18n.t('Type and select from the results that appear')}
                  required={true}
                >
                  <SelectAsyncControl
                    buildDisplay={this.buildDisplay}
                    dataUrl="/api/data/venues.json"
                    displayProperty="title"
                    filterField="keyword"
                    filters={{ enabled: true, master: true }}
                    initialData={currentVenue}
                    multi={false}
                    onChange={this.onVenueChange}
                    placeholder=""
                    scrollMenuIntoView={true}
                    searchPromptText={i18n.t("Select A Venue:")}
                    sortBy="title"
                  />
                </FloatingLabelFormField>
              </FieldGroup>
            </FieldGroup>
          }
          <FieldGroup className="row">
            <FieldGroup className={isEventManagement ? 'col-12' : 'col-6'}>
              <FloatingLabelFormField
                caption={i18n.t('Venue Name')}
                className="col-half-width"
                disabled={!isCreateVenue}
                name="venue[title]"
                onChange={() => this.setState({ valueOverride: false })}
                required={true}
                valueOverride={valueOverride}
              />
            </FieldGroup>
            {!isEventManagement && (
              <FieldGroup className="col-6">
                <FloatingLabelFormField
                  caption={i18n.t("Capacity")}
                  className="col-half-width"
                  disabled={!isCreateVenue}
                  name="venue[capacity]"
                  required={true}
                  tooltip={i18n.t("The maximum number of people permitted in this location.")}
                  valueOverride={valueOverride}
                />
              </FieldGroup>
            )}
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField
                caption={i18n.t('Address')}
                className="col-half-width"
                disabled={!isCreateVenue}
                hintText={i18n.t('Type your address and select from the options that appear')}
                name="venue[location_latitude]"
                required={true}
                valueOverride={valueOverride}
              >
                <AddressPickerControl
                  disabled={!isCreateVenue}
                  fieldClassName={`${(event && event.venue_id) ? 'fg-populated' : ''}`}
                  formatAddressPropName={(propName) => `venue[${propName}]`}
                  googleMapsApiKey={googleMapsApiKey}
                  initialData={this.state?.event?.venue ? this.state.event.venue : null}
                  placeholder=""
                  valueOverride={valueOverride}
                />
              </FloatingLabelFormField>
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField
                caption={i18n.t("Address Line 2")}
                className="col-half-width"
                disabled={!isCreateVenue}
                hintText={i18n.t('If relevant, add a unit or suite number')}
                name="venue[location_room]"
                onChange={() => this.setState({ valueOverride: false })}
                valueOverride={valueOverride}
              />
            </FieldGroup>
          </FieldGroup>

          {!isCreateVenue && !isEventManagement &&
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <MaterialCheckbox
                  className="m-l-25"
                  inline={true}
                  label={i18n.t("I'd like to change contact information only for this event")}
                  name="edit_venue_contact"
                  onChange={this.onEventContactEdit}
                />
              </FieldGroup>
            </FieldGroup>
          }

          {!isEventManagement && (
            <FieldGroup>
              <div className="row">
                <div className="col-12">
                  <h4>{i18n.t('Venue Contact Information')}</h4>
                </div>
              </div>

              {this.venueEditElements()}

              <div className="row">
                <div className="col-12">
                  <h4>{i18n.t('Facilities')}</h4>
                </div>
              </div>
              <FieldGroup className="row">
                <FieldGroup className="col-12">
                  <FieldGroup className="checkbox-group">
                    <MaterialCheckbox
                      disabled={!isCreateVenue}
                      inline={true}
                      label={i18n.t('Individual Devices')}
                      name="venue[computer_lab]"
                      value="true"
                      valueOverride={valueOverride}
                    />
                    <InfoTooltip
                      trigger="hover"
                      iconTooltip="help"
                      tooltip={i18n.t('Venue provides individual devices with internet connections for attendees to use at the event')}
                      tooltipPosition="top" />
                    <MaterialCheckbox
                      inline={true}
                      label={i18n.t('Projector')}
                      name="venue[projector]"
                      value="true"
                      valueOverride={valueOverride}
                      disabled={!isCreateVenue}
                    />
                    <InfoTooltip
                      iconTooltip="help"
                      tooltip={i18n.t('Venue provides projector for volunteers to use at the event')}
                      tooltipPosition="top"
                      trigger="hover"
                    />
                    <MaterialCheckbox
                      disabled={!isCreateVenue}
                      inline={true}
                      label={i18n.t('Accessibility')}
                      name="venue[accessibility]"
                      value="true"
                      valueOverride={valueOverride}
                    />
                    <InfoTooltip
                      trigger="hover"
                      iconTooltip="help"
                      tooltip={i18n.t('Venue is accessible to persons with disabilities')}
                      tooltipPosition="top" />
                  </FieldGroup>
                </FieldGroup>
              </FieldGroup>
            </FieldGroup>
          )}
        </FieldGroup>
      </ModalForm>
    )
  }
}
