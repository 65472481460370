import { readEndpoint } from 'redux-json-api';
import { ApiUtil, displayError } from 'reactifi';

export const loadCurrentAttendee = () => {
  return async function (dispatch, getState) {
    let read = readEndpoint(`attendees/me`);
    try {
      await read(dispatch, getState);
    } catch(e){
      dispatch(displayError(e));
      return { error: e }
    }
  }
};

export const loadCurrentVolunteer = (eventId) => {
  return readEndpoint(`events/${eventId}/event_users/me`);
};

export const loadActivitiesStatus = (activitiesProgressArr, contentServiceUrl) => {
  return async (dispatch, getState) => {
    const response = await Promise.all(activitiesProgressArr.map(progress => {
      return ApiUtil.loadExternalData(`${contentServiceUrl}/api/v1/progress/${progress.progress_id}`, progress.access_token);
    }));

    dispatch({
      type: 'API_READ',
      payload: {
        data: response
      }
    });
  }
}
