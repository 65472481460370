import PropTypes from 'prop-types';
import React from 'react';
import AssessmentApp from '../../Assessment';
import { Step, Form, FieldGroup, MaterialCheckbox, Icon } from 'reactifi';
import i18n from 'lib/i18n';
import { chunk } from 'lodash';

export default class AttendeeAssessment extends Step {
  static propTypes = {
    currentAttendee: PropTypes.object,
    selectAttendee: PropTypes.func.isRequired,
    updateAttendee: PropTypes.func.isRequired,
    loadAttendeeResponseSets: PropTypes.func.isRequired,
    setWizardCancelMessage: PropTypes.func.isRequired,
    courseModules: PropTypes.array,
    selectWizardStep: PropTypes.func,
    evaluFiUrl: PropTypes.string,
    renderSteps: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      attendeeResponseSets: this.props.currentAttendee.response_sets || [],
      responseSetsLoadAttempts: 0
    };
  }

  componentDidMount() {
    if (this.shouldDisplayResponseSets()) {
      let reloader = setInterval(() => {
        if (this.state.attendeeResponseSets.length === 0 && this.state.responseSetsLoadAttempts < 5) {
          this.reloadAttendeeResponseSets()
        } else {
          clearInterval(reloader);
        }
      }, 2000);
    }
  }

  componentWillReceiveProps(nextProps) {
    if ([null, undefined].includes(this.props.goingToStep) && this.props.goingToStep !== nextProps.goingToStep) {
      if (nextProps.goingDirection === 'forward') {
        this.setState({ saveClicked: true });
      } else {
        this.props.stepChangeComplete();
      }
    }
    if (nextProps.createAndAdd && !this.props.createAndAdd) {
      this.setState({ saveAndAddClicked: true });
    }
    if (nextProps.canceling && !this.props.canceling) {
      this.setState({ cancelClicked: true });
    }
  }

  reloadAttendeeResponseSets = () => {
    fetch(`/api/data/attendees/${this.props.currentAttendee.id}/response_sets?include=course_module`)
      .then(response => response.json())
      .then(json => {
        if (json.data && json.data.length > 0) {
          this.setState({ attendeeResponseSets: json.data.map(data => data.attributes) })
        }
        this.setState({ responseSetsLoadAttempts: (this.state.responseSetsLoadAttempts + 1) })
      });
  }

  setSkip = skipChecked => {
    this.setState({ skipAssessments: skipChecked });
  }

  saveClick = async () => {
    const addAnother = this.state.saveAndAddClicked;
    await this.props.updateAttendee(this.props.currentAttendee, false, 2);
    if (this.state.skipAssessments) {
      this.save(addAnother);
    } else {
      this.state.attendeeResponseSets.forEach((rs) => { rs.saved = false });
      this.setState({ saveClickTime: new Date().toString(), addAnother }, () => { this.save(addAnother) });
    }
  }

  assessmentSaved = responseSet => {
    let localResponseSet = this.state.attendeeResponseSets.find((rs) => rs.uid === responseSet.id);
    localResponseSet.saved = true;
    let totalQuestions = responseSet.sections.reduce((agg, section) => agg + section.questions.length, 0);
    localResponseSet.completed = responseSet.answers.length === totalQuestions;
    if (this.state.attendeeResponseSets.every((rs) => rs.saved)) {
      if (this.state.attendeeResponseSets.every((rs) => rs.completed)) {
        this.save(this.state.addAnother);
      } else {
        this.props.setWizardCancelMessage('You didn\'t fill out all the questions, are you sure you\'re done?',
          () => this.save(this.state.addAnother));
      }
    }
  }

  save = async addAnother => {
    try {
      await this.props.selectAttendee(null);
      if (addAnother) {
        this.props.selectAttendee('add', 0);
        this.setState({ saveAndAddClicked: false }, this.props.resetSteps);
      } else {
        this.props.stepChangeComplete();
      }
    } catch (e) {
      this.props.stepChangeCancel();
    }
  }

  displayResponseSetGroup = courseModules => {
    return (
      <div>
        {courseModules.map((module) => {
          return (<div key={module.id} className="col-lg-6 assessment-container">
            <h3 className="assessment-heading">{module.name}</h3>
            <div className="pre-assessment">
              {this.state.attendeeResponseSets
                .filter((rs) => rs.assessment_slug === module.pre_assessment_slug)
                .map((rs) => {
                  return <AssessmentApp
                    key={rs.uid}
                    endpointHost={this.props.evaluFiUrl}
                    endpointPath="api/v1/"
                    className="assessment"
                    saveClickTime={this.state.saveClickTime}
                    afterSaved={this.assessmentSaved}
                    accessToken={rs.access_token}
                    responseSetId={rs.uid} />
                })
              }
            </div>
            <div className="post-assessment">
              {this.state.attendeeResponseSets
                .filter((rs) => rs.assessment_slug === module.post_assessment_slug)
                .map((rs) => {
                  return <AssessmentApp
                    key={rs.uid}
                    endpointHost={this.props.evaluFiUrl}
                    endpointPath="api/v1/"
                    className="assessment"
                    saveClickTime={this.state.saveClickTime}
                    afterSaved={this.assessmentSaved}
                    accessToken={rs.access_token}
                    responseSetId={rs.uid} />
                })
              }
            </div>
          </div>)
        })}
      </div>)
  }

  shouldDisplayResponseSets = () => {
    return this.props.currentAttendee &&
      (this.props.courseModules.length > 0) &&
      !this.props.currentAttendee.disable_assessment
  }

  displayResponseSets = () => {
    if (this.shouldDisplayResponseSets()) {
      if (this.state.attendeeResponseSets.length > 0) {
        return (
          <div className="assessments">
            {chunk(this.props.courseModules, 4).map((courseModules, index) => {
              return (<div key={index} className="row assessment-group">
                {this.displayResponseSetGroup(courseModules)}
              </div>)
            })}
          </div>
        )
      } else if (this.state.responseSetsLoadAttempts >= 5) {
        return (
          <div className="assessments">
            <div className="row assessment-group">
              <Icon iconType="refresh-sync-alert" />
              {i18n.t('Assessment data form is delayed. Reload page to try again.')}
            </div>
          </div>
        )
      } else {
        return (
          <div className="assessments">
            <div className="row assessment-group">
              <Icon iconType="spinner" spin={true} />
              {i18n.t('Assessment data form is loading.')}
            </div>
          </div>
        )
      }
    }
  }

  saveComplete = formValidated => {
    if (!formValidated) {
      this.props.stepChangeCancel();
    }
    this.setState({ saveClicked: false });
  }

  cancelClose = () => {
    this.setState({ cancelClicked: false });
    this.props.cancelClose();
  }

  render() {
    const { currentAttendee } = this.props;
    if (!currentAttendee) {
      return null;
    }

    return (
      <div>
        <Form
          data={currentAttendee}
          errorMessage={this.props.errorMessage}
          onCancel={this.props.cancelComplete}
          onCancelClose={this.cancelClose}
          addAction={this.saveClick}
          updateAction={this.saveClick}
          hasFormButtons={false}
          triggerSave={this.state.saveClicked}
          triggerSaveAndAdd={this.state.saveAndAddClicked}
          triggerCancel={this.state.cancelClicked}
          saveComplete={this.saveComplete}
          saveButtonText={this.state.skipAssessments ? i18n.t('Done') : i18n.t('Save')}
        >
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <MaterialCheckbox name="disable_assessment" onChange={this.setSkip} dataValue={currentAttendee.disable_assessment}>
                {i18n.t('No assessment data for this attendee')}
              </MaterialCheckbox>
            </FieldGroup>
          </FieldGroup>
        </Form>
        {this.displayResponseSets()}
      </div>
    );
  }
}
