import PropTypes from 'prop-types';
import React from 'react';
import EventResults from './EventResults';
import TopicResults from './TopicResults';
import { Page } from 'reactifi'
import i18n from 'lib/i18n';
import moment from 'moment';

export default class EventDashboard extends React.Component{
  static propTypes = {
    events: PropTypes.array.isRequired,
    currentUserId: PropTypes.string.isRequired,
    readEvents: PropTypes.func.isRequired,
    topics: PropTypes.array,
    errorMessage: PropTypes.string,
    showAlertMessage: PropTypes.bool,
    successMessage: PropTypes.string,
    clearMessages: PropTypes.func,
    isLoadingEvents: PropTypes.bool,
    router: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.currentSort = [ { value: 'start_time', direction: 'asc' } ];
    this.currentFilter = { user_ids: [ this.props.currentUserId ],  start_date: moment().format('YYYY-MM-DD') };
  }

  componentDidMount(){
    this.props.readEvents('venue,event_users', this.currentFilter, this.currentSort);
  }


  actionButtonClick = () => {
    this.props.router.push('/event_wizard');
  }

  render() {
    let actionButton = {
      label: i18n.t('Create New Event'),
      action: this.actionButtonClick,
      icon: 'calendar'
    };

    return (
      <Page
        pageType="parent"
        contentType="fullwidth"
        title={i18n.t('Welcome to Engage')}
        actionButton={actionButton}
        errorMessage={this.props.errorMessage && this.props.showAlertMessage}
        successMessage={this.props.successMessage && this.props.showAlertMessage}
        clearMessages={this.props.clearMessages}
        usePageWrapper={true}
      >
        <EventResults events={this.props.events} isLoadingEvents={this.props.isLoadingEvents} dashboardEvents={true}/>
        {
          this.props.topics && this.props.topics.length ? <TopicResults topics={this.props.topics}/> : null
        }
      </Page>
    )
  }
}
