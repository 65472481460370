import React from 'react';
import PropTypes from 'prop-types';

export default class LetterCircleContentComponent extends React.Component {

  static propTypes = {
    children: PropTypes.object,
    dataObjectAttribute: PropTypes.string,
    dataObjectId: PropTypes.string,
    dataObjectType: PropTypes.string,
    header: PropTypes.string
  };

  render() {
    const { children, dataObjectAttribute, dataObjectId, dataObjectType, header } = this.props;

    return (
      <div
        data-object-type={dataObjectType}
        data-object-id={dataObjectId}
        data-object-attribute={dataObjectAttribute}
      >
        <h3>{header}</h3>
        {children && (
          <div className="row letter-circle-content-div">
            <div className="col-lg-12">
              {children}
            </div>
          </div>
        )}
      </div>
    );
  }
}
