import $ from 'jquery';

(function (scope) {

  var _attendee = function () {
    this.isSmallBusinessOwner = $('#user_small_business_owner');
    this.smallBusiness = $('#small_business');
    this.wizardContactFollowup = $('#attendee_wizard_contact_followup');
    this.contactFollowup = $('#work-location-status');
    this.wizardContactPromotional = $('#attendee_wizard_contact_promotional');
    this.contactPromotional = $('#user_is_employee');
    this.attendeeContactPhone = $('#attendee_wizard_contact_phone');
    this.phoneMask = "(999) 999-9999";
    this.eraseMessage = $("#erase-msg");
    this.init();
  };

  _attendee.prototype.init = function () {
    var _this = this;

    this.attendeeContactPhone.mask(_this.phoneMask);

    this.isSmallBusinessOwner.on('click', function () {
      _this.viewBusinessOwnerForm();
    });
    _this.viewBusinessOwnerForm();

    this.wizardContactFollowup.on('click', function () {
      _this.setContactFollowup();
    });
    _this.setContactFollowup();

    this.wizardContactPromotional.on('click', function () {
      _this.setContactPromotional();
    });
    _this.setContactPromotional();

    // Initial state of message is empty
    this.eraseMessage.hide();
  };

  _attendee.prototype.setContactFollowup = function () {
    if (this.wizardContactFollowup.prop('checked')) {
      this.contactMessage(true, this.wizardContactPromotional.prop('checked'))
    } else {
      this.contactMessage(false, this.wizardContactPromotional.prop('checked'))
    }
  }

  _attendee.prototype.setContactPromotional = function () {
    if (this.wizardContactPromotional.prop('checked')) {
      this.contactMessage(true, this.wizardContactFollowup.prop('checked'))
    } else {
      this.contactMessage(false, this.wizardContactFollowup.prop('checked'))
    }
  }

  _attendee.prototype.contactMessage = function (checked, otherCheckboxChecked) {
    if (!checked && !otherCheckboxChecked) {
      this.eraseMessage.show();
    } else {
      this.eraseMessage.hide();
    }
  }

  _attendee.prototype.viewBusinessOwnerForm = function () {
    if (this.isSmallBusinessOwner.prop('checked')) {
      this.smallBusiness.show();
    } else {
      this.smallBusiness.hide();
    }
  };


  scope.Attendee = _attendee;

}(window));

$(function () {
  // eslint-disable-next-line no-new
  new window.Attendee;
});
