import { actionTypes } from '../actions/assessmentActionCreators';

export default function assessmentReducer(state = {}, action) {
  switch (action.type) {
  case actionTypes.RESPONSE_SET_SAVE:
    return Object.assign({}, state, { responseSetSaved: action.responseSetSaved });
  default:
    return state;
  }
}
