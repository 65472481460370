import PropTypes from 'prop-types';
import React from 'react';
import { Step, Form, FieldGroup, AddressPickerControl, SelectControl, FloatingLabelFormField, MaterialCheckbox, MaskedFormControl } from 'reactifi';
import { FormGroup, Radio, ControlLabel } from 'react-bootstrap';
import i18n from 'lib/i18n';
import { incomeOptions } from '../functions/helpers';
import AttendeeRegistrationQuestions from './AttendeeRegistrationQuestions';

export default class AttendeeForm extends Step {
  static propTypes = {
    createAttendee: PropTypes.func.isRequired,
    currentAttendee: PropTypes.object,
    deleteAttendee: PropTypes.func.isRequired,
    disableAssessment: PropTypes.bool,
    errorMessage: PropTypes.string,
    event: PropTypes.object,
    eventType: PropTypes.string,
    googleMapsApiKey: PropTypes.string.isRequired,
    isEventManagement: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    renderSteps: PropTypes.func,
    updateAttendee: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      contactFollowUp: this.props.currentAttendee?.contact_followup,
      emailCheckbox: !!this.props.currentAttendee?.contact_email,
      housePhoneCheckbox: !!this.props.currentAttendee?.house_phone,
      mobilePhoneCheckbox: !!this.props.currentAttendee?.mobile_phone,
      showContactOptions: this.props.currentAttendee.contact_promotional !== null ? this.props.currentAttendee.contact_promotional : null,
      showPhone: this.props.currentAttendee.mobile_phone ? this.props.currentAttendee.mobile_phone : null,
      showHomePhone: this.props.currentAttendee.home_phone ? this.props.currentAttendee.home_phone : null
    };
  }

  componentWillReceiveProps(nextProps) {
    if ([null, undefined].includes(this.props.goingToStep) && this.props.goingToStep !== nextProps.goingToStep) {
      if (nextProps.goingDirection === 'forward') {
        this.setState({ saveClicked: true });
      } else {
        this.props.stepChangeComplete();
      }
    }
    if (nextProps.createAndAdd && !this.props.createAndAdd) {
      this.setState({ saveAndAddClicked: true });
    }
    if (nextProps.canceling && !this.props.canceling) {
      this.setState({ cancelClicked: true });
    }
  }

  updateAttendee = async attendee => {
    if ((!attendee?.contact_followup || attendee?.contact_followup === 'false') && (!attendee?.contact_promotional || attendee?.contact_promotional === 'false')) {
      attendee.contact_email = null;
      attendee.contact_phone = null;
      attendee.home_phone = null;
      attendee.mobile_phone = false;
      attendee.house_phone = false;
      attendee.email = false;
    }
    try {
      await this.props.updateAttendee(attendee, this.props.disableAssessment, 1);
      this.props.stepChangeComplete();
    } catch (err) {
      this.props.stepChangeCancel();
    }
  }

  createAttendee = async attendee => {
    if ((!attendee?.contact_followup || attendee?.contact_followup === 'false') && (!attendee?.contact_promotional || attendee?.contact_promotional === 'false')) {
      attendee.contact_email = null;
      attendee.contact_phone = null;
      attendee.home_phone = null;
      attendee.mobile_phone = false;
      attendee.house_phone = false;
      attendee.email = false;
    }
    try {
      await this.props.createAttendee(attendee, this.props.disableAssessment);
      this.props.stepChangeComplete();
    } catch (err) {
      this.props.stepChangeCancel();
    }
  }

  toggleContactFollowUp = event => this.setState({ contactFollowUp: event?.target?.value === "true" })

  toggleShowPhone = event => {
    if (event.target.value === "true") {
      this.setState({ showPhone: true });
    } else {
      this.setState({ showPhone: false });
    }
  }

  toggleShowContactOptions = event => {
    if (event.target.value === "true") {
      this.setState({ showContactOptions: true });
    } else {
      this.setState({ showContactOptions: false });
    }
  }

  mobilePhoneChecked = showPhone => this.setState({ showPhone });
  homePhoneChecked = housePhoneCheckbox => this.setState({ housePhoneCheckbox });
  emailChecked = emailCheckbox => this.setState({ emailCheckbox });

  saveComplete = formValidated => {
    if (!formValidated) {
      this.props.stepChangeCancel();
    }
    this.setState({ saveClicked: false });
  }

  cancelClose = () => {
    this.setState({ cancelClicked: false });
    this.props.cancelClose();
  }

  render() {
    const {
      cancelComplete,
      currentAttendee,
      errorMessage,
      event,
      eventType,
      googleMapsApiKey,
      isEventManagement
    } = this.props;

    const {
      cancelClicked,
      contactFollowUp,
      emailCheckbox,
      housePhoneCheckbox,
      saveAndAddClicked,
      saveClicked,
      showContactOptions,
      showPhone
    } = this.state;

    const attendee = currentAttendee || {};
    const showEmail = contactFollowUp || (showContactOptions && emailCheckbox);
    const showContactHeader = emailCheckbox || showPhone || housePhoneCheckbox;

    return (
      <Form
        addAction={this.createAttendee}
        data={attendee}
        errorMessage={errorMessage}
        hasFormButtons={false}
        onCancel={cancelComplete}
        onCancelClose={this.cancelClose}
        saveComplete={this.saveComplete}
        triggerSave={saveClicked}
        triggerSaveAndAdd={saveAndAddClicked}
        triggerCancel={cancelClicked}
        updateAction={this.updateAttendee}
      >
        <FieldGroup className="container-fluid">
          <FieldGroup className="row">
            <FieldGroup className="col-6">
              <FloatingLabelFormField name="first_name" caption={i18n.t("First Name")} required={true} />
            </FieldGroup>
            <FieldGroup className="col-6">
              <FloatingLabelFormField name="last_name" caption={i18n.t("Last Name")} required={true} />
            </FieldGroup>
          </FieldGroup>
          {eventType === "Events::FinancialEducation" &&
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                {attendee.is_protected ?
                  <FloatingLabelFormField name="income_level" caption={i18n.t("What is your family income?")} disabled={true} /> :
                  <FloatingLabelFormField name="income_level" caption={i18n.t("What is your family income?")}>
                    <SelectControl options={incomeOptions} valueKey="value" labelKey="label" placeholder="" />
                  </FloatingLabelFormField>
                }
              </FieldGroup>
            </FieldGroup>
          }
          {!isEventManagement &&
            <FieldGroup>
              <FieldGroup className="row">
                <FieldGroup className="col-12">
                  {attendee.is_protected ?
                    <FloatingLabelFormField name="location_formatted_address" caption={i18n.t("Address (Type and select below)")} disabled={true} /> :
                    <FloatingLabelFormField name="location_latitude" caption={i18n.t("Address (Type and select below)")}>
                      <AddressPickerControl googleMapsApiKey={googleMapsApiKey} initialData={currentAttendee} placeholder="" required={false} />
                    </FloatingLabelFormField>
                  }
                </FieldGroup>
              </FieldGroup>

              <FieldGroup className="row">
                <FieldGroup className="col-12">
                  <FloatingLabelFormField name="apartment_suite_info" caption={i18n.t("Apartment/Suite Info")} required={false} />
                </FieldGroup>
              </FieldGroup>
            </FieldGroup>
          }

          <ControlLabel>{i18n.t('May we email you to follow up about this session?')}</ControlLabel>
          <FormGroup>
            <Radio name="contact_followup" value={true} inline={true} checked={contactFollowUp} onChange={(e) => this.toggleContactFollowUp(e)}>
              {i18n.t('Yes')}
            </Radio>{' '}
            <Radio name="contact_followup" value={false} inline={true} checked={!contactFollowUp} onChange={(e) => this.toggleContactFollowUp(e)}>
              {i18n.t('No')}
            </Radio>
          </FormGroup>

          <label>{i18n.t('May we contact you to share information about specials or new products and services?')}</label>
          <FormGroup>
            <Radio name="contact_promotional" value={true} inline={true} checked={showContactOptions} onChange={(e) => this.toggleShowContactOptions(e)}>
              {i18n.t('Yes')}
            </Radio>{' '}
            <Radio name="contact_promotional" value={false} inline={true} checked={!showContactOptions} onChange={(e) => this.toggleShowContactOptions(e)}>
              {i18n.t('No')}
            </Radio>
          </FormGroup>

          {showContactOptions &&
            <div className="row">
              <div className="col-12">
                <label>{i18n.t('How may we share this information with you?')}</label>
              </div>
            </div>
          }
          {showContactOptions &&
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <MaterialCheckbox className="m-l-25" name="email" checked={true} onChange={(e) => this.emailChecked(e)} inline={true}> {i18n.t('Email')}</MaterialCheckbox>
                {!isEventManagement && <MaterialCheckbox className="m-l-25" name="mail" checked={true} inline={true}>{i18n.t('Mail')}</MaterialCheckbox>}
                <MaterialCheckbox className="m-l-25" name="mobile_phone" checked={true} onChange={(e) => this.mobilePhoneChecked(e)} inline={true}>{i18n.t('Mobile Phone')}</MaterialCheckbox>
                <MaterialCheckbox className="m-l-25" name="house_phone" checked={true} onChange={(e) => this.homePhoneChecked(e)} inline={true}>{i18n.t('Home Phone')}</MaterialCheckbox>
              </FieldGroup>
            </FieldGroup>
          }
          <br />
          {(contactFollowUp || showContactHeader) && <label>{i18n.t('Contact information')}</label>}
          {showEmail &&
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField name="contact_email" caption={i18n.t("Email")} dataValue={attendee.contact_email} disabled={attendee.is_protected} required={true} />
              </FieldGroup>
            </FieldGroup>
          }

          {showContactOptions && showPhone &&
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField
                  name="contact_phone"
                  caption={i18n.t("Mobile Phone Number")}
                  required={true}
                >
                  <MaskedFormControl
                    mask="(111) 111-1111"
                    type="tel"
                    pattern="^\(\d{3}\) \d{3}-\d{4}$"
                    placeholderChar=" "
                    placeholder=""
                  />
                </FloatingLabelFormField>
              </FieldGroup>
            </FieldGroup>
          }
          {showContactOptions && housePhoneCheckbox &&
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField
                  name="home_phone"
                  caption={i18n.t("Home Phone Number")}
                  required={true}
                >
                  <MaskedFormControl
                    mask="(111) 111-1111"
                    type="tel"
                    pattern="^\(\d{3}\) \d{3}-\d{4}$"
                    placeholderChar=" "
                    placeholder=""
                  />
                </FloatingLabelFormField>
              </FieldGroup>
            </FieldGroup>
          }
          <AttendeeRegistrationQuestions event={event} />
        </FieldGroup>
      </Form>)
  }
}
