import PropTypes from 'prop-types';
import React from 'react';
import { AddressPickerControl, FloatingLabelFormField } from 'reactifi';

export default class RailsAddressPicker extends React.Component {
  static propTypes = {
    data: PropTypes.any,
    id: PropTypes.string,
    disabled: PropTypes.any,
    fieldPrefix: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
    googleMapsApiKey: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    ariaRequired: PropTypes.boolean
  };

  constructor(props){
    super(props);
    this.addressData = {};
    if(props.data) {
      Object.keys(props.data).forEach((key) => {
        this.addressData[`location_${key}`] = props.data[key]
      });
    }
  }

  render(){
    const _placeholder = this.props.placeholder ? this.props.placeholder : "";
    return (
      <FloatingLabelFormField caption={this.props.caption} dataValue={this.addressData.location_formatted_address}>
        <AddressPickerControl googleMapsApiKey={this.props.googleMapsApiKey} placeholder={_placeholder} ariaRequired={this.props.ariaRequired}
          initialData={this.addressData} id={this.props.id} disabled={this.props.disabled}
          formatAddressPropName={(propName) => `${this.props.fieldPrefix}[${propName}]`}/>
      </FloatingLabelFormField>);
  }
}
