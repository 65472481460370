import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  ActionButtons,
  AutoSavingForm,
  buildGenericProps,
  Button,
  ConfirmationModal,
  IconCategoryValue,
  MaterialCheckbox,
  JsonApiDecorator,
  ResponsiveDataList
} from 'reactifi';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import * as attendeeActionCreators from '../actions/attendeeActionCreators';
import AttendeeWizard from '../components/AttendeeWizard';
import i18n from "lib/i18n";
import { sortBy, filter, includes } from 'lodash';

function mapStateToProps(state, ownProps) {
  let props = buildGenericProps(state, 'attendees', { response_sets: ['course_module'] });
  if (props.currentEntity) {
    props.currentAttendee = props.currentEntity;
    if (!props.currentAttendee.event_id) {
      props.currentAttendee.event_id = ownProps.event.id;
    }
  }
  let apiStore = new JsonApiDecorator(state.api);
  if (apiStore.course_modules && ownProps.event.topic) {
    const everfi_content_ids = ownProps.event.topic.everfi_content_ids.map((id) => id.toString());
    props.courseModules = sortBy(filter(apiStore.course_modules.all(), (course_module) => { return includes(everfi_content_ids, course_module.relationships.everfi_content.data.id.toString()) }), "id");
  } else {
    props.courseModules = [];
  }

  props.wizardStep = state.attendeeReducer.wizardStep;
  props.wizardCancelConfirm = state.attendeeReducer.wizardCancelConfirm;
  return props;
}

class AttendeeContainer extends React.Component {
  static propTypes = {
    attendees: PropTypes.array,
    courseModules: PropTypes.array,
    currentAttendee: PropTypes.object,
    data: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    evaluFiUrl: PropTypes.string.isRequired,
    event: PropTypes.object.isRequired,
    googleMapsApiKey: PropTypes.string.isRequired,
    isEventManagement: PropTypes.bool,
    updateEvent: PropTypes.func.isRequired,
    wizardStep: PropTypes.number,
    wizardCancelConfirm: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(attendeeActionCreators, this.props.dispatch);

    this.state = {
      attendeeToDelete: null
    };
  }

  onConfirmDeleteAttendee = async () => {
    await this.actions.deleteAttendee(this.state.attendeeToDelete);
    this.setState({ attendeeToDelete: null });
  }

  onCancelDeleteAttendee = () => this.setState({ attendeeToDelete: null });

  get fields() {
    const { event, isEventManagement } = this.props;
    let fields = [
      {
        name: 'id',
        title: i18n.t('Name'),
        canSort: true,
        formatter: (cell, row) => {
          return `${row.first_name} ${row.last_name}`;
        },
        mobileFormatter: (cell, row) => `${row.first_name} ${row.last_name}`
      },
      {
        name: 'contact_email',
        title: i18n.t('Email'),
        canSort: true
      },
      ...(!isEventManagement ? [{ name: 'location_formatted_address', title: i18n.t('Address'), canSort: true }] : []),
      {
        name: 'contact_phone',
        title: i18n.t('Phone'),
        canSort: true,
        formatter: (cell, row) => {
          return row.contact_phone || row.home_phone;
        }
      },
      {
        name: 'contact_followup',
        title: i18n.t('Follow-up Consent'),
        canSort: true,
        formatter: (cell, row) => {
          return row.contact_followup ? i18n.t('Yes') : i18n.t('No');
        }
      },
      {
        name: 'contact_promotional',
        title: i18n.t('Promotional Consent'),
        canSort: true,
        formatter: (cell, row) => {
          return row.contact_promotional ? i18n.t('Yes') : i18n.t('No');
        }
      }
    ];

    if (!isEventManagement) {
      //conditional render of assessment field if MaterialCheckbox checked
      const disableAssessment = event.disable_assessment;
      if (!disableAssessment) {
        fields.push({
          name: 'assessment',
          title: i18n.t('Assessment Data'),
          formatter: (cell, row) => row.is_protected ? <span>{i18n.t('Protected')}</span> : <a onClick={() => this.actions.selectAttendee(row.id, 1)}>{i18n.t('View Assessment')}</a>
        });
      }
    }

    fields.push({
      name: 'actions',
      title: i18n.t('Actions'),
      formatter: (cell, row) => {
        const actionButtons = [
          {
            icon: 'zmdi-edit',
            title: '',
            tooltip: 'Edit',
            type: 'edit',
            actionType: 'callToAction',
            action: (e) => this.actions.selectAttendee(row.id, 0)
          },
          {
            icon: 'zmdi-delete',
            title: '',
            tooltip: 'Delete',
            type: 'delete',
            actionType: 'callToAction',
            action: () => this.setState({ attendeeToDelete: row })
          }
        ];

        return <ActionButtons row={Object.assign(row, { can_edit: true, can_delete: true })} buttons={actionButtons} />;
      }
    });

    return fields;
  }

  render() {
    const {
      attendees,
      courseModules,
      currentAttendee,
      data,
      event,
      errorMessage,
      evaluFiUrl,
      isEventManagement,
      googleMapsApiKey,
      updateEvent,
      wizardCancelConfirm,
      wizardStep
    } = this.props;
    const eventType = event.event_type;
    const tableOptions = {
      bordered: false,
      hover: true,
      striped: false,
      sortName: 'id',
      sortOrder: 'desc'
    };

    return (
      <div className="container attendee-list">
        {(!isEventManagement && event.topic && event.has_content) &&
          <div className="row list-header">
            <div className="col-lg-12">
              <AutoSavingForm
                data={event}
                errorMessage={errorMessage}
                updateAction={updateEvent}
              >
                <MaterialCheckbox name="disable_assessment">{i18n.t('No Assessment Data for this event')}</MaterialCheckbox>
              </AutoSavingForm>

            </div>
          </div>
        }
        <div className="row m-b-20">
          <div className="col-lg-6">
            {data && (
              <IconCategoryValue
                category={i18n.t('Results')}
                value={`${data.attendee_count} ${i18n.t('Attendee(s) in the list')}`}
              />
            )}
          </div>
          <div className="col-lg-6">
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip id="tooltip-disabled">
                  {i18n.t('Facilitate event registration and collect important information about individual attendees.')}
                </Tooltip>
              }>
              <Button
                className="pull-right"
                data-action="add-attendee"
                data-object-id={event.id}
                data-object-type="events"
                onClick={e => this.actions.selectAttendee('add', 0)}
                style="secondary"
              >
                {i18n.t('Add Attendee')}
              </Button>
            </OverlayTrigger>
          </div>
        </div>
        {
          wizardStep !== undefined &&
          <AttendeeWizard
            actions={this.actions}
            courseModules={courseModules}
            currentAttendee={currentAttendee}
            disableAssessment={isEventManagement || event.disable_assessment}
            errorMessage={errorMessage}
            evaluFiUrl={evaluFiUrl}
            event={event}
            eventType={eventType}
            googleMapsApiKey={googleMapsApiKey}
            isEventManagement={isEventManagement}
            wizardCancelConfirm={wizardCancelConfirm}
            wizardStep={wizardStep}
          />
        }

        <ResponsiveDataList
          data={attendees}
          fields={this.fields}
          keyField="id"
          options={tableOptions}
        />

        <ConfirmationModal title={i18n.t('Delete Attendee')} show={!!this.state.attendeeToDelete} canClickOff={true}
          onConfirm={this.onConfirmDeleteAttendee} onCancel={this.onCancelDeleteAttendee}
          confirmButtonText={i18n.t('Yes')} cancelButtonText={i18n.t('No')}>
          <div>{i18n.t('Are you sure you want to delete this attendee?')}</div>
        </ConfirmationModal>
      </div >
    );
  }
}

export default connect(mapStateToProps)(AttendeeContainer);
