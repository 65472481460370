import { selectEntity, createEntity, readEntity, updateEntity, updateEntityAndRefreshAll } from 'reactifi';
export { clearMessages } from 'reactifi';
import i18n from 'lib/i18n';

export function selectVenue(venueId) {
  return selectEntity(venueId, 'venues');
}

export function findVenues(filters, sort, page) {
  return readEntity('venues', '', filters, sort && [sort], { number: page });
}

export function updateVenue(venue, filters, skipValidations = false) {
  if (skipValidations) {
    venue.skip_contact_validation = true;
    venue.skip_capacity_validation = true;
  }
  if (Object.keys(filters).length === 0) {
    return updateEntity(venue, (dispatch) => {
      dispatch(selectEntity(null, 'venues'));
    });
  }
  else {
    return updateEntityAndRefreshAll(venue, '', filters, '', (dispatch) => {
      dispatch(selectEntity(null, 'venues'));
    }, i18n.t("Venue updated successfully"));
  }
}

export function createVenue(venue, addAnother, isCreateEvent = false, skipValidations = false, isEventManagement = false) {
  return async dispatch => {
    if (skipValidations) {
      venue.skip_contact_validation = true;
      venue.skip_capacity_validation = true;
      if (isEventManagement) {
        venue.location_skip_geocode_and_lmi = true;
        venue.master = true;
      }
    }
    const newVenue = await dispatch(createEntity(venue));
    if (isCreateEvent) {
      return newVenue.data;
    } else {
      //do it this way so the modal unloads and reloads, which makes sure the form clears
      dispatch(selectEntity(null, 'venues'));
      if (addAnother) {
        dispatch(selectEntity('add', 'venues'));
      }
    }
  }
}
