import PropTypes from 'prop-types';
import React from 'react';
import VolunteerList from '../components/VolunteerList';
import EventDetails from '../components/EventDetails';
import TopicDetails from './TopicDetails'
import { Button } from 'react-bootstrap';
import { AlertMessage, AlertMessageTypes, Icon, Storage, Util } from 'reactifi';
import i18n from 'lib/i18n';
import Cookies from 'js.cookie';
import moment from 'moment';
import AddToCalendarButton from './AddToCalendarButton';
import SaveMySpotButton from './SaveMySpotButton';
import { displayAttendeeButtons } from '../libs/addToCalendar';

export default class OthersEvent extends React.Component {
  static propTypes = {
    accountName: PropTypes.string,
    className: PropTypes.string,
    clearMessages: PropTypes.func,
    currentEventUser: PropTypes.object,
    errorMessage: PropTypes.string,
    event: PropTypes.object.isRequired,
    eventUsers: PropTypes.array,
    from: PropTypes.string,
    isWebinar: PropTypes.bool,
    isEventManagement: PropTypes.bool,
    joinEvent: PropTypes.func.isRequired,
    preRegister: PropTypes.bool,
    showAlertMessage: PropTypes.bool,
    successMessage: PropTypes.string,
    viewer: PropTypes.string,
    router: PropTypes.shape({
      push: PropTypes.func
    })
  };

  static defaultProps = {
    eventUsers: []
  };

  eventHasPassed = eventDate => {
    const hoursAfter = moment.duration(moment().diff(eventDate)).asHours();
    return hoursAfter > 1;
  }

  onPreregComplete = () => {
    this.setState({ ...this.state });
  }

  attendeeButtons = () => {
    let { accountName, event, clearMessages, errorMessage } = this.props;
    const preRegCookie = Cookies.get('pre-registered');
    let cookieArray;
    if (preRegCookie) {
      cookieArray = (preRegCookie.indexOf(',') > -1) ? preRegCookie.split(',') : [preRegCookie];
    }
    const showSaveMySpot = (!preRegCookie || (cookieArray && !cookieArray.includes(event.id)));
    const showAddToCalendar = (preRegCookie && cookieArray.includes(event.id));

    return (<>
      {(showSaveMySpot && event.allow_pre_registration) &&
        <SaveMySpotButton
          accountName={accountName}
          clearMessages={clearMessages}
          errorMessage={errorMessage}
          event={event}
          onComplete={this.onPreregComplete}
        />}
      {(showAddToCalendar || !event.allow_pre_registration) && <AddToCalendarButton accountName={accountName} event={event} />}
    </>)
  }

  openRegistration = () => {
    this.props.router.push({
      pathname: '/registration',
      state: {
        event: this.props.event,
        from: this.props.from
      }
    });
  }

  // eslint-disable-next-line complexity
  render() {
    const {
      className,
      clearMessages,
      currentEventUser,
      errorMessage,
      event,
      eventUsers,
      from,
      isEventManagement,
      isWebinar,
      joinEvent,
      preRegister,
      showAlertMessage,
      successMessage,
      viewer
    } = this.props;

    const locale = Storage.getItem('i18nextLng') || 'en';

    const eventCancelled = event.cancelled;
    const eventHasPassed = this.eventHasPassed(moment(event.end_time));

    const workshopDasboardLink = `/${locale}/#/`

    const isEventAdmin = ['account_admin', 'event_staff'].includes(viewer);

    const volsNeeded = event.event_users ? event.desired_volunteer_count - event.event_users.length : 0;
    const registerButton = (preRegister || event.cancelled ? null :
      <Button
        className="btn-primary"
        data-action="get-started"
        onClick={this.openRegistration}
      >
        {i18n.t('Get Started')}
      </Button>);

    const displayAttendeeRegisterButton = (
      !displayAttendeeButtons(event, viewer) &&
      !isEventAdmin
    );

    const registerButtonWithLabel = (<div>
      {registerButton}
    </div>);

    const joinEventButtonWithLabel = (
      <div>
        <div className="action-label">
          Want to volunteer?
        </div>
        <Button
          bsStyle="primary"
          onClick={() => joinEvent()}
          data-object-type="events"
          data-object-id={event.id}
          data-action="join">{i18n.t('Join Event')}</Button>
      </div>);

    return (
      <div className={`others-event ${className}`}>
        <AlertMessage message={errorMessage} messageType={AlertMessageTypes.danger} visible={errorMessage && showAlertMessage} clearMessages={clearMessages} />
        <AlertMessage message={successMessage} messageType={AlertMessageTypes.success} visible={successMessage && showAlertMessage} clearMessages={clearMessages} />
        <div className="row event-title m-0">
          <div className="col-lg-5 offset-lg-1 col-12">
            <h1 className="event-header">{event.title} - {moment(event.start_time).format('LL')}</h1>
          </div>
          <div className="col-lg-5 col-12 mobileCenter">
            <div className="header-actions">
              {eventCancelled || eventHasPassed ?
                <>
                  {eventCancelled && (
                    <>
                      <Icon additionalClasses="m-r-5 text-danger" iconType="alert-circle" size="medium" />
                      <span>This event has been cancelled. View more events <a href={workshopDasboardLink}>here</a>.</span>
                    </>
                  )}
                  {!eventCancelled && eventHasPassed && (
                    <>
                      <Icon additionalClasses="m-r-5 text-success" iconType="check-circle" size="medium" />
                      <span>This event has already taken place. View more events <a href={workshopDasboardLink}>here</a>.</span>
                    </>
                  )}
                </>
                :
                <>
                  {displayAttendeeRegisterButton && (
                    <div className="action">
                      {viewer === 'attendee' ?
                        registerButtonWithLabel :
                        volsNeeded ? joinEventButtonWithLabel : <span>{i18n.t('Event Full')}</span>
                      }
                    </div>
                  )}
                  {isEventAdmin ?
                    <div className="action">
                      {joinEventButtonWithLabel}
                    </div>
                    : null
                  }
                  {displayAttendeeButtons(event, viewer, currentEventUser) && this.attendeeButtons()}
                </>
              }

            </div>
          </div>
        </div>
        <div className={Util.formatClassName([
          'row rowcards event-row-card m-0',
          { 'dim': eventCancelled || eventHasPassed }
        ])}>
          <div className="col-lg-6 offset-lg-1 rowcard event-row-card-left" id="topic-details">
            <TopicDetails event={event} currentEventUser={currentEventUser} />
          </div>
          <div className="col-lg-4 rowcard event-row-card-right" id="event-details">
            <EventDetails
              currentEventUser={currentEventUser}
              event={event}
              from={from}
              isEventManagement={isEventManagement}
              isWebinar={isWebinar}
              joinEvent={joinEvent}
              toggleEdit={this.toggleEdit}
              viewer={viewer} />
          </div>
        </div>
        {viewer === 'attendee' ? null :
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-4">
                        <h2>{i18n.t('Volunteers')}</h2>
                      </div>
                      <div className="col-lg-8">
                        <div className="pull-right">{i18n.t('Volunteers Needed:')} {event.desired_volunteer_count}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <VolunteerList eventUsers={eventUsers} />
                </div>
              </div>
            </div>
          </div>}

      </div>)
  }
}
