import React from 'react';
import { ErrorBoundedProvider } from 'reactifi';
import { Router, Route, hashHistory } from 'react-router'
import createStore from './store/topicsStore';
import TopicsContainer from './containers/TopicsContainer';
import { syncHistoryWithStore } from 'react-router-redux'

export default (props, _railsContext) => {
  const store = createStore(props);
  const history = syncHistoryWithStore(hashHistory, store);
  const reactComponent = (
    <ErrorBoundedProvider store={store}>
   		<Router history={history}>
        <Route path="/" component={TopicsContainer} googleMapsApiKey={_railsContext.googleMapsApiKey} accountId={props.accountId}/>
      </Router>
    </ErrorBoundedProvider>
  );
  return reactComponent;
};
