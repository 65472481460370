import PropTypes from 'prop-types';
import React from 'react';
import EventResult from './EventResult';
import i18n from 'lib/i18n';

const numberOfEventsToDisplay = 2;
export default class EventResults extends React.Component {
  static propTypes = {
    events: PropTypes.array.isRequired,
    isLoadingEvents: PropTypes.bool,
    dashboardEvents: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.noResultsMessage = i18n.t("No scheduled workshop or events");
    this.displayNoDataCopy = this.displayNoDataCopy.bind(this);
    this.displayEvents = this.displayEvents.bind(this);
  }

  displayNoDataCopy() {
    return (
      <div className="container flex-card-container event-results">
        <div className="flex-row row">
          {this.props.isLoadingEvents === false ?
            <div className="col-md-12 col-xl-12">
              <div className="card card-shadow event-minicard">
                <div className="card-body card-padding">
                  {i18n.t('There is no data to display.')}
                </div>
              </div>
            </div> : null
          }
        </div>
      </div>
    );
  }

  generateViewEventLink = event => {
    const url = window.location;
    const locale = url.pathname.split("/")[1];
    return `/${locale}/vportal/events#/${event.id}`;
  };

  displayEvents(){
    if(this.props.dashboardEvents){
      return (
        <>
          <div className="upcoming-events">
            <h3 className="subsection-title">{i18n.t('Your upcoming events')}</h3>
          </div>
          { this.props.isLoadingEvents || this.props.events.length ?
            <div className="container-fluid">
              <div className="upcoming-events-list row">
                {this.props.events.slice(0, numberOfEventsToDisplay).map((event) => {
                  return <EventResult event={event} generateViewEventLink={this.generateViewEventLink} />
                })
                }
                {
                  this.props.events.length > 0 && this.props.events.length < numberOfEventsToDisplay && !window.isMobile &&
                    <div className="col-lg-6">
                      <div className="event-item-card card"/>
                    </div> }
                { this.props.events.length > numberOfEventsToDisplay &&
                    <div className="see-all col-lg-12">
                      <a href="./events?events=my#/?filterField=display&filterValue=my">{i18n.t('See All Your Events')}</a>
                    </div>
                }
              </div>
            </div> :
            <div className="row">
              <div className="upcoming-events-list upcoming-events-list-empty">
                {this.noResultsMessage}
              </div>
            </div>
          }
        </>
      )
    } else {
      return (
        <div className="events-list">
          {
            this.props.events.map((event) => {
              return (
                <EventResult event={event}
                  generateViewEventLink={this.generateViewEventLink}
                  showEventRow={true} />
              )
            })
          }
        </div>
      )
    }
  }

  render() {
    return (
      <div>
        {!this.props.isLoadingEvents ? this.displayEvents() : this.displayNoDataCopy() }
      </div>
    );
  }
}
