import PropTypes from 'prop-types';
import React from 'react';
import { Modal }  from 'react-bootstrap';
import { ModalHeader }  from 'reactifi';
import i18n from 'lib/i18n';

export default class PartnerDetails extends React.Component {
  static propTypes = {
  	data: PropTypes.object.isRequired,
  	selectPartner: PropTypes.func.isRequired,
  	show: PropTypes.boolean
  };

  constructor(props){
  	super(props);
  	this.onCancel = this.onCancel.bind(this);
  }

  onCancel() {
  	this.props.selectPartner(null);
  }

  render(){
  	let partner = this.props.data;
  	return (<div>	
  		{ partner ? 
  		<Modal className="form-modal" show={this.props.show === undefined ? !!this.props.data : this.props.show} onHide={this.onCancel}>
  			<ModalHeader onCancel={this.onCancel} canClickOff={true} title="Partner Details"/>
  			<Modal.Body className="container-fluid">
  				<div className="row">
  					<div className="col-12">
  						<h4>{partner.name}</h4>
  					</div>
  				</div>
  				<div className="row">
  					<div className="col-12">
  						{partner.website}
  					</div>
  				</div>
  				<div className="row">
  					<div className="col-12">
  						{partner.location_formatted_address}
  					</div>
  				</div>
  				<div className="row">
  					<div className="col-12">
  						{i18n.t('Contact:')} {partner.contact_name}
  					</div>
  				</div>
  				<div className="row">
  					<div className="col-12">
  						{i18n.t('Phone:')} {partner.contact_phone}
  					</div>
  				</div>
  				<div className="row">
  					<div className="col-12">
  						{i18n.t('Email:')} {partner.contact_email}
  					</div>
  				</div>
  				<div className="row">
  					<div className="col-12">
  						{i18n.t('Mission:')} <br/>{partner.mission}
  					</div>
  				</div>
  			</Modal.Body>
  		</Modal>
  		: null }
  	</div>);
  }
}