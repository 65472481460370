import React from 'react';
import { ErrorBoundedProvider } from 'reactifi';
import createStore from './store/partnerStore';
import PartnerContainer from './containers/PartnerContainer';

export default (props, _railsContext) => {
  const store = createStore(props);
  const reactComponent = (
    <ErrorBoundedProvider store={store}>
      <PartnerContainer
        googleMapsApiKey={_railsContext.googleMapsApiKey}
        isEventManagement={props.isEventManagement}
        viewer={props.viewer}
      />
    </ErrorBoundedProvider>
  );
  return reactComponent;
};
