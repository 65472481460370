import { routerReducer } from 'react-router-redux'
import { ApiReducer as api } from 'reactifi';
import eventReducer from '../../Event/reducers/eventReducer';
import attendeeReducer from '../../Event/reducers/attendeeReducer';

export default {
  routing: routerReducer,
  attendeeReducer,
  eventReducer,
  api
};
