import mirrorCreator from 'mirror-creator';
import { createCurrentEntity, clearApiState, updateEntity, createEntity, readEntity, downloadReport, deleteEntity, readEntityById, endTimeout, selectEntity, setCurrentEntityValues } from 'reactifi';
export { clearMessages } from 'reactifi';
import i18n from 'lib/i18n';
import { portalPath } from '../libs/EventFinderRedirectionFunc';
import { prepareReportFilters } from '../../common/preprocess';
import { sanitizeUrl } from '@braintree/sanitize-url';

export function clearEvents() {
  return clearApiState('events');
}

export function readEvents(includes, filters, sorts = { value: 'title', direction: 'asc' }, page = 1) {
  return readEntity('events', includes, filters, sorts, page);
}

export function loadEvents(include) {
  return readEntity('events', include);
}

export function loadEvent(id, include = 'venue,partner,event_users,attendees,topic,reservations,topic.everfi_contents,topic.offline_contents,topic.course_modules') {
  return readEntityById(id, 'events', include);
}

export function createEventEntity(defaultValues) {
  return createCurrentEntity('events', defaultValues);
}

export function setEventData(eventData) {
  return setCurrentEntityValues(eventData);
}

export function createReservationEntity(defaultValues) {
  return createCurrentEntity('reservations', defaultValues);
}

export function createEvent(event, viewer) {
  return async (dispatch) => {
    await dispatch(createEntity(event,
      (dispatch, getState, newEvent) => {
        window.location.href = sanitizeUrl(`${window.location.origin}/${window.localStorage.i18nextLng || 'en'}/${portalPath(viewer)}/events#/${newEvent.data.id}`);
      })
    );
  }
}

export function loadEventDashboardInfo(id) {
  let include = 'topic,topic.everfi_contents,topic.offline_contents,topic.course_modules';
  return readEntityById(id, 'events', include);
}

export function loadAccount(id, include = 'topic') {
  return readEntityById(id, 'accounts', include);
}

export function loadVenue(id) {
  return readEntityById(id, 'venues');
}

export function loadPartner(id) {
  return readEntityById(id, 'partners');
}

export function updateEvent(event, specialMessage, actionToCloseModal) {
  return updateEntity(event, (dispatch) => {
    if (actionToCloseModal) {
      dispatch(actionToCloseModal(false));
    }
    dispatch(loadEvent(event.id));
  }, (specialMessage ? specialMessage : i18n.t("Event updated!")));
}

export function saveMySpotPreregistration(registration) {
  return createEntity(registration, null, false);
}

export function createVenuePartner(entity, eventId, actionToCloseModal, skipValidations = false, isEventManagement = false) {
  if (skipValidations) {
    entity.skip_contact_validation = true;
    entity.skip_capacity_validation = true;
    if (isEventManagement) {
      entity.location_skip_geocode_and_lmi = true;
      entity.master = true;
    }
  }
  return createEntity(entity, (dispatch) => {
    dispatch(actionToCloseModal(false));
    dispatch(loadEvent(eventId));
  }, i18n.t("Event updated!"));
}

export function downloadEvents(accountId, filters) {
  return downloadReport('Reports::Events', { account_id: accountId, filters: prepareReportFilters(filters) });
}

export function updateEventUser(eventUser, specialMessage) {
  return updateEntity(eventUser, null, specialMessage || i18n.t("Event updated!"));
}

export function joinEvent(eventUser, specialMessage) {
  return createEntity(eventUser, null, specialMessage || i18n.t("Event Joined!"));
}

export function deleteEventUser(eventUser, successMessage) {
  return async (dispatch) => {
    await dispatch(deleteEntity(eventUser.unwrap(), 'event_users', successMessage));
  }
}

export function selectReservation(reservation, successMessage) {
  return selectEntity(reservation, 'reservations');
}

export function closeTimeoutModal() {
  return endTimeout();
}

export const actionTypes = mirrorCreator([
  'TOGGLE_EDIT_CONFERENCING_INFO',
  'TOGGLE_EDIT_EVENT_TYPE',
  'TOGGLE_EDIT_DATE_TIME',
  'TOGGLE_EDIT_VENUE',
  'TOGGLE_EDIT_PARTNER',
  'TOGGLE_ADD_REG_QUESTIONS',
  'TOGGLE_EDIT_REG_QUESTIONS',
  'LOADING_EVENTS'
]);

export function toggleEditConferencingInfo(show) {
  return {
    type: actionTypes.TOGGLE_EDIT_CONFERENCING_INFO,
    show
  }
}

export function toggleEditEventType(show) {
  return {
    type: actionTypes.TOGGLE_EDIT_EVENT_TYPE,
    show
  }
}

export function toggleEditDateTime(show) {
  return {
    type: actionTypes.TOGGLE_EDIT_DATE_TIME,
    show
  }
}

export function toggleEditVenue(show) {
  return {
    type: actionTypes.TOGGLE_EDIT_VENUE,
    show
  }
}

export function toggleEditPartner(show) {
  return {
    type: actionTypes.TOGGLE_EDIT_PARTNER,
    show
  }
}

export function toggleEditRegistrationQuestions(show) {
  return {
    type: actionTypes.TOGGLE_EDIT_REG_QUESTIONS,
    show
  }
}

export function toggleAddRegQuestions(show) {
  return {
    type: actionTypes.TOGGLE_ADD_REG_QUESTIONS,
    show
  }
}
