import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import i18n from 'lib/i18n';
import { Icon } from 'reactifi';

export default class AddVolunteer extends React.Component {
  static propTypes = {
    joinEvent: PropTypes.func.isRequired,
    event_id: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.state = {};
  }

  async onAdd(){
    await this.props.joinEvent(this.state.value.id);
    this.setState({ value: undefined });
  }

  onChange(value){
    this.setState({ value });
  }

  async getUsers(input){
    if (!input) {
      return Promise.resolve({ options: [] });
    }

    let response = await fetch(`/api/data/volunteers.json?filter[keyword]=${input}`, {
      credentials: 'same-origin'
    });
    let json = await response.json();
    let options = json.data.map((i) => { return { id: i.id, display: `${i.attributes.first_name} ${i.attributes.last_name} - ${i.attributes.email}` }});
    return { options };
  }

  render() {
    let translationProps = {
      searchPromptText: i18n.t("Type to search"),
      noResultsText: i18n.t("No results found"),
      loadingPlaceholder: i18n.t("Loading..."),
      clearAllText: i18n.t("Clear all"),
      clearValueText: i18n.t("Clear value")
    }

    return (
      <div className="container">
        <div
          className="col-md-6 p-b-10"
          data-object-type="events"
          data-object-id={this.props.event_id}
          data-action="select-volunteer-user-search"
        >
          <Select.Async
            multi={false}
            onChange={this.onChange}
            value={this.state.value}
            placeholder={i18n.t("Add a Volunteer")}
            inputProps={
              {
                'data-object-type': 'events',
                'data-object-id': this.props.event_id,
                'data-action': 'select-volunteer-user'
              }
            }
            {...translationProps}
            valueKey="id"
            labelKey="display"
            loadOptions={this.getUsers}
            menuBuffer={20}
          />
        </div>
        <div className="col-md-6 p-t-10"
          data-object-type="events"
          data-object-id={this.props.event_id}
          data-action="select-volunteer-user-add"
        >
          {
            this.state.value &&
            <a data-action="add-volunteer" data-object-id={this.props.event_id} data-object-type="events" onClick={this.onAdd}>
              {i18n.t('Add')} <Icon iconType="plus" />
            </a>
          }
        </div>
      </div>);
  }
}
