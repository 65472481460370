import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { JsonApiDecorator } from 'reactifi'
import { saveResponseSet } from '../actions/assessmentActionCreators'
import { Radio } from 'react-bootstrap';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import i18n from 'lib/i18n';

const mapStateToProps = (state, currProps) => {
  let apiStore = new JsonApiDecorator(state.api);
  let props = {
    dispatch: state.dispatch,
    responseSetSaved: state.assessmentReducer.responseSetSaved
  };

  if (apiStore.response_sets){
    props.responseSet = apiStore.response_sets.find(currProps.responseSetId,
      { sections: { questions: [ 'answers' ] }, evaluation: '' });
    if (props.responseSet && !props.responseSet.answers){
      props.responseSet.answers = [];
    }
  }
  return props;
};

export class Assessment extends React.Component {
  static propTypes = {
    responseSet: PropTypes.shape({
      id: PropTypes.string.isRequired,
      answers: PropTypes.array.isRequired,
      sections: PropTypes.array.isRequired,
      evaluation: PropTypes.object,
      status: PropTypes.string
    }),
    responseSetSaved: PropTypes.bool,
    saveClickTime: PropTypes.string,
    afterSaved: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    className: PropTypes.string
  };

  constructor(props){
    super(props);

    this.answerChange = this.answerChange.bind(this);
    this.saveResponseSet = this.saveResponseSet.bind(this);
    this.state = { answers: {} };
    if(props.responseSet) {
      props.responseSet.answers.forEach((res) => { this.state.answers[res.question_id] = res.value });
    }
  }

  componentWillReceiveProps(newProps){
    if (!this.props.responseSet && newProps.responseSet){
      newProps.responseSet.answers.forEach((res) => { this.state.answers[res.question_id] = res.value });
    }

    if (newProps.saveClickTime !== this.props.saveClickTime){
      this.saveResponseSet();
    }

    if (newProps.responseSetSaved && !this.props.responseSetSaved){
      this.props.afterSaved(this.props.responseSet);
    }
  }

  answerChange(questionId, answerId){
    let answers = Object.assign({}, this.state.answers);
    answers[questionId] = answerId;
    this.setState({ answers: answers })
  }

  saveResponseSet(){
    this.props.responseSet.answers = Object.keys(this.state.answers).map((key) => {
      return { question_id: key, value: this.state.answers[key], response_type: 'single_selection' };
    });
    this.props.dispatch(saveResponseSet(this.props.responseSet));
  }

  render() {
    let questionCount = 0;
    return (
      <CSSTransitionGroup transitionName="assessment-transition" transitionEnterTimeout={1000} transitionLeaveTimeout={1000}>
        {this.props.responseSet ?
          <div className={this.props.className}>
            <h4>{this.props.responseSet.evaluation.name}</h4>
            <table className="table table-striped assessment-table">
              <tbody>
                <tr>
                  <th/>
                  <th>{i18n.t('A')}</th>
                  <th>{i18n.t('B')}</th>
                  <th>{i18n.t('C')}</th>
                  <th>{i18n.t('D')}</th>
                </tr>
                {this.props.responseSet.sections.map((sec) => {
                  return (sec.questions.map((question) => {
                    return (
                      <tr key={question.id}
                        className={!this.state.answers[question.id] && this.props.responseSetSaved ? 'error' : ''}>
                        <td>{++questionCount}</td>{question.answers.map((answer, i) =>
                          <td key={answer.id}>
                            <Radio name={`question_${question.id}`} checked={this.state.answers[question.id] && this.state.answers[question.id].toString() === answer.id.toString()}
                              onChange={() => this.answerChange(question.id, answer.id)}>
                              <i className="input-helper"/>
                            </Radio>
                          </td>)
                        }</tr>);
                  }));
                })}
              </tbody>
            </table>
            <div>
              {this.props.responseSetSaved ? i18n.t('Saved') : ''}
            </div>
          </div> : null}
      </CSSTransitionGroup>);
  }
}

export default connect(mapStateToProps)(Assessment)
