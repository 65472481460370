import React from 'react';
import PropTypes from 'prop-types';
import { DateFilter, FilterBar, SelectFilter } from 'reactifi';
import i18n from 'lib/i18n';
import debounce from 'lodash/debounce';

const filterConfig = [
  {
    name: 'event_date',
    isMulti: true,
    label: i18n.t('Event Date'),
    type: DateFilter
  },
  {
    name: 'future_contact',
    isMulti: true,
    label: i18n.t('Future Contact'),
    options: [{
      id: 'followup_consent',
      label: i18n.t('Follow-up Consent'),
      value: 'followup_consent'
    }, {
      id: 'marketing_consent',
      label: i18n.t('Marketing Consent'),
      value: 'marketing_consent'
    }],
    type: SelectFilter
  }
];

const onSelectFilters = debounce((handleFilterChange) => (filterSelections) => {
  const filters = {};

  Object.entries(filterSelections).forEach(([key, value]) => {
    switch (key) {
    case 'future_contact':
      value.forEach(value => {
        filters[value.value] = true;
      });
      break;
    case 'event_date':
      if (value.from_date) filters.event_start_date = value.from_date;
      if (value.to_date) filters.event_end_date = value.to_date;
      break;
    default:
      break;
    }
  });

  handleFilterChange(filters);
}, 350);

const searchAction = (searchValue, handleSearch) => handleSearch({ keyword: searchValue?.searchValue });

const AttendeeSearchForm = props => {
  const {
    disabled,
    handleFilterChange,
    handleSearch,
    keyword
  } = props;

  return (
    <FilterBar
      disabled={disabled}
      filterConfig={filterConfig}
      onSelectFilters={onSelectFilters(handleFilterChange)}
      searchBox={{
        fullwidth: false,
        placeholder: i18n.t('Search Name or Email'),
        searchAction: searchValue => searchAction(searchValue, handleSearch),
        value: keyword
      }}
    />
  );
};

AttendeeSearchForm.propTypes = {
  disabled: PropTypes.bool,
  handleFilterChange: PropTypes.func,
  handleSearch: PropTypes.func,
  keyword: PropTypes.string
};

export default AttendeeSearchForm;
