import PropTypes from 'prop-types';
import React from 'react';
import EventSearchForm from './EventSearchForm';
import EventResults from './EventResults';
import { Page, NoResultsCard } from 'reactifi'
import i18n from 'lib/i18n';
import { eventFinderRedirect } from '../../libs/EventFinderRedirectionFunc';
import UpcomingEventFinder from "../UpcomingEventFinderComponent";
import TopicResults from "./TopicResults";

export default class EventList extends React.Component{
  static propTypes = {
    clearMessages: PropTypes.func,
    currentUserId: PropTypes.string.isRequired,
    defaultSearchParams: PropTypes.object,
    errorMessage: PropTypes.string,
    events: PropTypes.array.isRequired,
    eventStatuses: PropTypes.array,
    eventTypes: PropTypes.array,
    isEventManagement: PropTypes.bool,
    isFiltered: PropTypes.bool,
    isLoadingEvents: PropTypes.bool,
    languages: PropTypes.array,
    readEvents: PropTypes.func.isRequired,
    router: PropTypes.object,
    successMessage: PropTypes.string,
    topics: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.noResultsMessage = i18n.t("Looks like there aren't any events");
    this.noResultsButtons = [
      {
        label: i18n.t("Create New Event"),
        link: "event_wizard"
      }
    ];
    this.myNoResultsMessage = i18n.t("You don't have any events scheduled");
    this.myNoResultsButtons = [
      {
        label: i18n.t("Browse Events"),
        link: "events"
      },
      {
        label: i18n.t("Create New Event"),
        link: "event_wizard"
      }
    ];
    this.onSearchSubmit = eventFinderRedirect('/vportal/events#/');
  }

  getQueryParams = () => ({
    topic: this.props.router.location.query.topic,
    location: this.props.router.location.query.location
  });

  render() {
    const {
      clearMessages,
      currentUserId,
      errorMessage,
      events,
      eventStatuses,
      eventTypes,
      defaultSearchParams,
      isEventManagement,
      isFiltered,
      isLoadingEvents,
      languages,
      readEvents,
      successMessage,
      topics
    } = this.props;

    let isMyEvents = defaultSearchParams ? defaultSearchParams.display === 'my' : false;

    return (
      <Page
        pageType="parent"
        contentType="fullwidth"
        title={isMyEvents ? i18n.t('My Events') : i18n.t('All Workshops and Events')}
        errorMessage={errorMessage}
        successMessage={successMessage}
        clearMessages={clearMessages}
        usePageWrapper={true}
      >
        <div className="event-results-filter-row">
          <EventSearchForm
            readEvents={readEvents}
            eventTypes={eventTypes}
            topics={topics}
            defaultSearchParams={defaultSearchParams}
            eventStatuses={eventStatuses}
            languages={languages}
            currentUserId={currentUserId}
            isLoadingEvents={isLoadingEvents}
            isEventManagement={isEventManagement}
          />
        </div>

        { isLoadingEvents || events.length || isFiltered ?
          <EventResults events={events} isLoadingEvents={isLoadingEvents} dashboardEvents={false}/> :
          <NoResultsCard message={isMyEvents ? this.myNoResultsMessage : this.noResultsMessage} buttons={isMyEvents ? this.myNoResultsButtons : this.noResultsButtons}/>
        }
        {
          topics && topics.length ? <TopicResults topics={topics}/> : null
        }
        <div>
          { topics && <UpcomingEventFinder topics={topics}
            onSearch={this.onSearchSubmit}
            selectedOptions={this.getQueryParams()}/> }
        </div>
      </Page>)
  }
}
