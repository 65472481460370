import mirrorCreator from 'mirror-creator';
import { readEndpoint, updateEntity } from 'redux-json-api';

export const actionTypes = mirrorCreator([
  'RESPONSE_SET_SAVE'
]);

export const loadResponseSet = (responseSetId) => {
  return readEndpoint(`response_sets/${responseSetId}.json?include=evaluation,sections.questions.answers`);
};

const startResponseSetSave = () => {
  return {
    type: actionTypes.RESPONSE_SET_SAVE,
    responseSetSaved: false
  }
};

const finishResponseSetSave = () => {
  return {
    type: actionTypes.RESPONSE_SET_SAVE,
    responseSetSaved: true
  }
};

export const saveResponseSet = (responseSet) => {
  return async function(dispatch, getState){
    dispatch(startResponseSetSave());
    let update = updateEntity(responseSet.unwrap());
    await update(dispatch, getState);
    dispatch(finishResponseSetSave());
  }
};
