import { readEntity, selectEntity, createEntity, updateEntity, readLocal, ApiUtil } from 'reactifi';
export { clearMessages, displayError } from 'reactifi';
import i18n from 'lib/i18n';

export function loadContent(sort, page){
  return readEntity('everfi_contents', ['course_modules'], null, sort && [sort], page);
}

export function selectContent(contentId){
  return selectEntity(contentId, 'everfi_contents');
}

export function updateContent(content, filters){
  return updateEntity(content, (dispatch)=> {
    dispatch(selectEntity(null, 'everfi_contents'));
  }, i18n.t("Content updated successfully"));
}

export function createContent(content, addAnother){
  return createEntity(content, (dispatch, getState, entity) => {
    //do it this way so the modal unloads and reloads, which makes sure the form clears
    dispatch(selectEntity(null, 'everfi_contents'));
    if (addAnother) {
      dispatch(selectEntity('add', 'everfi_contents'));
    }
  }
  );
}

export function findContent(filters){
  return readEntity('everfi_contents', '', filters);
}

export function readContentServiceContentById(contentServiceUrl, id, authToken){
  return async (dispatch) => {
    let content = await ApiUtil.loadExternalData(`${contentServiceUrl}/api/v1/contents/${id}.json`, authToken, false);
    dispatch(readLocal(content));
  }
}
