import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Page, FileUpload, S3MediaUploader, JsonApiDecorator, buildGenericProps } from 'reactifi';
import { bindActionCreators } from 'redux';
import * as customerAdminActionCreators from '../actions/customerAdminActionCreators';
import i18n from 'lib/i18n';

function mapStateToProps(state, ownProps) {
  let apiStore = new JsonApiDecorator(state.api);
  let props = buildGenericProps(state, 'goals');
  if (apiStore.accounts) {
    props.account = apiStore.accounts.first(['logo', 'background_image']);
  }
  return props;
}

const CustomerAdminContainer = props => {
  const {
    account,
    currentEntity,
    dispatch,
    errorMessage,
    route,
    successMessage
  } = props;

  const actions = bindActionCreators(customerAdminActionCreators, dispatch);

  useEffect(() => {
    if (route?.accountId) actions.loadAccount(route.accountId);
  }, []);

  const onLogoUpload = response => {
    account.logo_id = response.data.id;
    actions.updateAccount(account);
  }

  const onUploadFailure = response => {
    actions.uploadError(response);
  }

  if (!account) return null;

  return (
    <Page
      pageType="parent"
      contentType="cards"
      title={i18n.t('Advanced Settings')}
      errorMessage={currentEntity ? null : errorMessage}
      successMessage={currentEntity ? null : successMessage}
      clearMessages={actions.clearMessages}
      usePageWrapper={true}
    >
      <div className="card container">
        <div className="card-body p-b-30">
          <div className="letter-circle-sections">
            <h5>{i18n.t('Customize branding')}</h5>
            <div className="row letter-circle-content-div">
              <div className="col-lg-12">
                <FileUpload
                  accept={['image/gif', 'image/jpg', 'image/jpeg', 'image/png']}
                  actions={{ success: onLogoUpload, failure: onUploadFailure }}
                  button={{ text: i18n.t('Upload Logo'), className: 'btn btn-primary' }}
                  description={{ textArray: [i18n.t('Logo format: JPG, PNG or GIF')] }}
                  embedPreviewUrl={account.logo?.url}
                  header={{ text: i18n.t('Logo') }}
                  icon={{ className: 'collection-image', iconSize: 'medium', fileClassName: 'icon-file', noFileClassName: 'icon-no-file' }}
                  input={{ name: 'logo' }}
                  label={{ text: account.logo?.name || i18n.t('No File'), closable: false }}
                  name="logo"
                  tooltip={i18n.t("Uploaded image will appear in top left")}
                  uploader={S3MediaUploader}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

CustomerAdminContainer.propTypes = {
  account: PropTypes.shape({
    welcome_text: PropTypes.string,
    logo_id: PropTypes.string,
    background_image_id: PropTypes.string,
    logo: PropTypes.object,
    background_image: PropTypes.object
  }),
  currentEntity: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  route: PropTypes.shape({
    accountId: PropTypes.number
  }),
  successMessage: PropTypes.string
};

export default connect(mapStateToProps)(CustomerAdminContainer);
