import { selectEntity, createEntity, readEntity, updateEntity, updateEntityAndRefreshAll, readEntityById } from 'reactifi';
export { clearMessages } from 'reactifi';
import isEmpty from 'lodash/isEmpty'

export function findPartners(filters, page, sort = { value: 'name', direction: 'asc' }) {
  return readEntity('partners', '', filters, sort && [sort], page);
}

export function selectPartner(partnerId) {
  return selectEntity(partnerId, 'partners', 'headquarters_partner');
}

export function updatePartner(partner, filters) {
  if (isEmpty(filters)) {
    return updateEntity(partner, (dispatch) => {
      dispatch(selectEntity(null, 'partners'));
    });
  }
  else {
    return updateEntityAndRefreshAll(partner, '', filters, '', (dispatch) => {
      dispatch(selectEntity(null, 'partners'));
    });
  }
}

export function loadPartner(id) {
  return readEntityById(id, 'partners');
}

export function createPartner(partner, addAnother, isCreateEvent) {
  return async dispatch => {
    const newPartner = await dispatch(createEntity(partner));
    if (isCreateEvent) {
      return newPartner.data;
    } else {
      dispatch(selectPartner(null));
      if (addAnother) {
        dispatch(selectPartner('add'));
      }
    }
  }
}
