import i18n from "lib/i18n";
import EventFinderForm from "./EventFinderFormComponent";

const UpcomingEventFinder = (props) => (
  <div className="find-events-box has-border clearfix m-t-20">
    <h2>{i18n.t("Find upcoming workshops and events near you.")}</h2>
    <div className="find-events-form-wrapper clearfix">
      <EventFinderForm {...props} />
    </div>
  </div>
);

export default UpcomingEventFinder;