require.context('../images', true);

import Rails from '@rails/ujs';
Rails.start();

import 'bootstrap-sass';
import 'jquery.maskedinput/src/jquery.maskedinput';
import 'metismenu';
import 'moment';
import 'everfi-material';
import '../app/lib/global';
import '../app/lib/scripts/assessments';
import '../app/lib/scripts/attendees';
import '../app/lib/scripts/multilingual_form';
import '../app/lib/scripts/profile';
import '../app/lib/scripts/titleize';
import '../app/lib/scripts/volunteers';
import '../stylesheets/application.scss';
import '../app/bundles/register';
