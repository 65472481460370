import mirrorCreator from 'mirror-creator';
import { selectEntity, createEntity, readEntity, updateEntity, deleteEntity, S3Uploader, displayError, pollUrl } from 'reactifi';
import { readEndpoint } from 'redux-json-api';
export { clearMessages, displayError } from 'reactifi';

export const actionTypes = mirrorCreator([
  'SET_TOPIC_ID',
  'UPLOAD_IN_PROGRESS',
  'UPLOAD_COMPLETE'
]);

export function loadTopics(filters, page = 1) {
  return readEntity('topics', null, filters, null, page);
}

export function loadTopic(topicId, include = 'account,offline_contents,everfi_contents,course_modules,image') {
  return selectEntity(topicId, 'topics', include);
}

export function pollTopic(topicId) {
  return async dispatch => {
    try {
      return pollUrl(
        1500,
        Number.POSITIVE_INFINITY,
        async () => await dispatch(readEndpoint(`topics/${topicId}?include=offline_contents`)),
        result => {
          return !result?.included?.some(content => !content?.attributes?.content_url);
        }
      );
    } catch (err) {
      dispatch(displayError(err));
    }
  }
}

export function uploadInProgress() {
  return {
    type: actionTypes.UPLOAD_IN_PROGRESS
  }
}

export function uploadComplete() {
  return {
    type: actionTypes.UPLOAD_COMPLETE
  }
}

export function setTopic(topicId) {
  return {
    type: actionTypes.SET_TOPIC_ID,
    topicId
  }
}

export function uploadError(response) {
  return displayError(response);
}


export function createContent(file, offline_content, addAnother) {
  return createEntity(offline_content, async (dispatch, getState, updatedOfflineContent) => {
    dispatch(uploadInProgress())
    await S3Uploader(file, updatedOfflineContent.data.attributes.content_upload_url_fields, updatedOfflineContent.data.attributes.content_upload_url);
    dispatch(selectEntity(null, 'offline_contents'));
    dispatch(uploadComplete());
  })
}

export function updateContent(content, filters) {
  return updateEntity(content, (dispatch) => {
    dispatch(selectEntity(null, 'offline_contents'));
  });
}

export function selectContent(contentId) {
  return selectEntity(contentId, 'offline_contents', 'topic');
}



export function deleteContent(offline_content) {
  return async (dispatch) => {
    await dispatch(deleteEntity(offline_content, 'offline_contents'));
  }
}
