import PropTypes from 'prop-types';
import React from 'react';
import {
  FieldGroup, ModalForm, SelectAsyncControl, FloatingLabelFormField,
  AddressPickerControl, MaterialCheckbox, SelectControl, InfoTooltip
} from 'reactifi';
import { Radio } from 'react-bootstrap';
import i18n from 'lib/i18n';

export default class EventPartnerForm extends React.Component {
  static propTypes = {
    clearMessages: PropTypes.func.isRequired,
    createPartner: PropTypes.func,
    createVenuePartner: PropTypes.func,
    event: PropTypes.object,
    errorMessage: PropTypes.string,
    googleMapsApiKey: PropTypes.string,
    isCreateEvent: PropTypes.bool,
    isEventManagement: PropTypes.bool,
    loadPartner: PropTypes.func.isRequired,
    newEntity: PropTypes.func,
    partners: PropTypes.array,
    toggleEditPartner: PropTypes.func.isRequired,
    updateEvent: PropTypes.func.isRequired,
    updatePartner: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isCreatePartner: false,
      partnerId: null,
      partnerOverride: true,
      valueOverride: true
    };
  }

  onCancel = () => {
    this.setState({ partnerId: null });
    this.props.clearMessages();
    this.props.toggleEditPartner(false);
  }

  updatePartner = async event => {
    const { partners, updatePartner } = this.props;

    const currentPartner = partners.find(partner => partner.id === event.partner_id);
    Object.assign(currentPartner, event.partner);
    const updatedPartner = await updatePartner(currentPartner);
    return updatedPartner.data;
  }

  onAdd = async event => {
    const { createPartner, createVenuePartner, isCreateEvent, newEntity, toggleEditPartner, updateEvent } = this.props;

    if (this.state.isCreatePartner) {
      const newPartner = newEntity("partners");
      Object.assign(newPartner, event.partner);
      newPartner.event_ids = [event.id];
      event.partner = isCreateEvent ?
        await createPartner(newPartner, null, isCreateEvent) :
        await createVenuePartner(newPartner, event.id, toggleEditPartner);
    } else {
      event.partner = this.updatePartner(event);
    }
    updateEvent(event, null, toggleEditPartner);
  }

  onUpdate = async event => {
    if (this.state.isCreatePartner) {
      const newPartner = this.props.newEntity("partners");
      Object.assign(newPartner, event.partner);
      newPartner.event_ids = [event.id];
      this.props.createVenuePartner(newPartner, event.id, this.props.toggleEditPartner);
    } else {
      event.partner = this.updatePartner(event);
      this.props.updateEvent(event, null, this.props.toggleEditPartner);
    }
  }

  onPartnerChange = async val => {
    if (val && val.id) {
      this.setState({ partnerId: val.id });
      await this.props.loadPartner(val.id);
    }
  }

  componentWillReceiveProps(newProps) {
    const partnerId = this.state.partnerId ? this.state.partnerId : newProps.event ? newProps.event.partner_id : null;
    const partnerLoaded = newProps.partners && newProps.partners.length && partnerId ? !!newProps.partners.find(partner => {
      return parseInt(partnerId) === parseInt(partner.id);
    }) : false;

    if (newProps.event) {
      let event;
      if (partnerLoaded && (newProps.event.partner || this.state.partnerId)) {
        let partnerId = !this.state.partnerId ? newProps.event.partner_id : this.state.partnerId;
        let selectedPartner = newProps.partners.find((partner) => { return partner.id === partnerId.toString() });
        event = newProps.event;
        event.partner = selectedPartner;
      } else {
        event = newProps.event;
        event.partner = newProps.newEntity("partners");
        event.partner.location_formatted_address = "Add Address";
      }
      this.setState({ event: event, isCreatePartner: false, valueOverride: true, editPartnerContact: false, partnerOverride: false });
    }
  }

  buildDisplay = (partner) => {
    const hasLocation = Boolean(partner && partner.location_locality && partner.location_administrative_area_level_1);

    if (partner && partner.name) {
      if (hasLocation) {
        return `${partner.name} (${partner.location_locality}, ${partner.location_administrative_area_level_1})`;
      } else {
        return `${partner.name}`;
      }
    }
  }

  onPartnerContactEdit = (isSelected) => {
    this.setState({
      editPartnerContact: isSelected,
      valueOverride: !isSelected
    });
  }

  onPartnerCreateSelect = (isCreate) => {
    if (isCreate) {
      const event = { ...this.state.event }
      event.partner = this.props.newEntity("partners");
      event.partner = {
        name: "",
        website: "",
        mission: "",
        location_latitude: "",
        location_room: "",
        contact_name: "",
        contact_phone: "",
        contact_email: "",
        serves_lmi: false
      };
      this.setState({ event: event, isCreatePartner: true, valueOverride: true, partnerId: null });
      setTimeout(() => {
        this.setState({ valueOverride: false })
      }, 1000);
    } else {
      this.setState({
        event: this.props.event,
        isCreatePartner: false,
        partnerId: this.props.event.partner ? this.props.event.partner.id : null,
        valueOverride: true
      });
    }
  }

  render() {
    const { errorMessage, event, googleMapsApiKey, isEventManagement } = this.props;
    const { editPartnerContact, isCreatePartner, valueOverride } = this.state;
    const involvement = [
      { label: i18n.t('Organize Event'), value: i18n.t('Organize Event') },
      { label: i18n.t('Provide Venue'), value: i18n.t('Provide Venue') },
      { label: i18n.t('Other'), value: i18n.t('Other') }
    ];

    const currentPartner = event && event.partner_id ?
      { id: event.partner_id, display: this.buildDisplay(event.partner) } :
      null;

    return event && event.partner &&
      (
        <ModalForm
          addAction={this.onAdd}
          data={this.state.event}
          errorMessage={errorMessage}
          onCancel={this.onCancel}
          title={i18n.t("Partner Details")}
          saveAndAddButton={false}
          updateAction={this.onUpdate}
        >
          <FieldGroup className="container-fluid">

            <FieldGroup className="row">
              <FieldGroup className="col-6">
                <Radio
                  checked={isCreatePartner}
                  data-action="create_partner"
                  data-object-type="events"
                  name="partner_type"
                  onChange={() => this.onPartnerCreateSelect(true)}
                >
                  <i className="input-helper" />
                  {i18n.t('Create a new partner')}
                </Radio>
              </FieldGroup>
              <FieldGroup className="col-6">
                <Radio
                  checked={!isCreatePartner}
                  data-action="choose_partner"
                  data-object-type="events"
                  name="partner_type"
                  onChange={() => this.onPartnerCreateSelect(false)}
                >
                  <i className="input-helper" />
                  {i18n.t('Choose an existing partner')}
                </Radio>
              </FieldGroup>
            </FieldGroup>

            {!isCreatePartner &&
              <FieldGroup className="row">
                <FieldGroup className="col-12">
                  <FloatingLabelFormField
                    caption={i18n.t("Partner")}
                    name="partner_id"
                    required={true}
                  >
                    <SelectAsyncControl
                      buildDisplay={this.buildDisplay}
                      dataUrl="/api/data/partners.json"
                      displayProperty="name"
                      filterField="keyword"
                      filters={{ enabled: true, master: true }}
                      initialData={currentPartner}
                      pageSize={100}
                      multi={false}
                      onChange={this.onPartnerChange}
                      placeholder=""
                      searchPromptText={i18n.t("Select A Partner:")}
                      scrollMenuIntoView={true}
                      sortBy="name"
                      valueOverride={this.state.partnerOverride}
                    />
                  </FloatingLabelFormField>
                </FieldGroup>
              </FieldGroup>}
            <br />

            {!isEventManagement && isCreatePartner &&
              <FieldGroup className="row">
                <FieldGroup className="col-12">
                  <FloatingLabelFormField
                    caption={i18n.t("Involvement")}
                    name="involvement"
                    required={true}
                    valueOverride={valueOverride}
                  >
                    <SelectControl
                      disabled={!isCreatePartner}
                      labelKey="label"
                      multi={false}
                      options={involvement}
                      placeholder=""
                      valueKey="value"
                      valueOverride={valueOverride}
                    />
                  </FloatingLabelFormField>
                </FieldGroup>
              </FieldGroup>}

            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField
                  caption={i18n.t("Name")}
                  disabled={!isCreatePartner}
                  name="partner[name]"
                  required={true}
                  valueOverride={valueOverride}
                />
              </FieldGroup>
            </FieldGroup>
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField
                  caption={i18n.t("Website")}
                  disabled={!isCreatePartner}
                  name="partner[website]"
                  validationType="url"
                  type="url"
                  valueOverride={valueOverride}
                />
              </FieldGroup>
            </FieldGroup>

            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField
                  caption={i18n.t("Mission")}
                  disabled={!isCreatePartner}
                  name="partner[mission]"
                  required={true}
                  valueOverride={valueOverride}
                />
              </FieldGroup>
            </FieldGroup>
            {!isEventManagement && (
              <FieldGroup className="row">
                <FieldGroup className="col-12">
                  <FloatingLabelFormField
                    caption={i18n.t("Address (Type and select below)")}
                    disabled={!isCreatePartner}
                    name="partner[location_latitude]"
                    required={true}
                    valueOverride={valueOverride}
                  >
                    <AddressPickerControl
                      formatAddressPropName={(propName) => `partner[${propName}]`}
                      googleMapsApiKey={googleMapsApiKey}
                      initialData={this.state.event.partner}
                      placeholder=""
                      valueOverride={valueOverride}
                    />
                  </FloatingLabelFormField>
                </FieldGroup>
              </FieldGroup>
            )}

            {!isEventManagement && (
              <FieldGroup className="row">
                <FieldGroup className="col-12">
                  <FloatingLabelFormField
                    caption={i18n.t("Address Line 2")}
                    className="col-half-width"
                    disabled={!isCreatePartner}
                    name="partner[location_room]"
                    valueOverride={valueOverride}
                  />
                </FieldGroup>
              </FieldGroup>
            )}

            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <MaterialCheckbox
                  disabled={!isCreatePartner}
                  inline={true}
                  name="partner[serves_lmi]"
                  valueOverride={valueOverride}
                >
                  {i18n.t('Serves LMI Community')}
                </MaterialCheckbox>
                <InfoTooltip
                  trigger="hover"
                  iconTooltip="help"
                  tooltip={i18n.t('Does this partner serve predominately low & moderate income members of the community?')}
                  tooltipPosition="top"
                />
              </FieldGroup>
            </FieldGroup>

            {!isEventManagement && !isCreatePartner && (
              <FieldGroup className="row">
                <FieldGroup className="col-12">
                  <MaterialCheckbox
                    inline={true}
                    name="edit_partner_contact"
                    onChange={this.onPartnerContactEdit}
                  >
                    {i18n.t("I'd like to change contact information only for this Partner")}
                  </MaterialCheckbox>
                </FieldGroup>
              </FieldGroup>
            )}

            {!isEventManagement && (
              <FieldGroup>
                <div className="row">
                  <div className="col-12">
                    <h4>{i18n.t('Partner Event Contact')}</h4>
                  </div>
                </div>
                <FieldGroup>
                  <FieldGroup className="row">
                    <FieldGroup className="col-12">
                      <FloatingLabelFormField
                        caption={i18n.t("Name")}
                        disabled={!isCreatePartner && !editPartnerContact}
                        name="partner[contact_name]"
                        valueOverride={valueOverride}
                        required={isCreatePartner}
                      />
                    </FieldGroup>
                  </FieldGroup>
                  <FieldGroup className="row">
                    <FieldGroup className="col-12">
                      <FloatingLabelFormField
                        caption={i18n.t("Phone Number")}
                        disabled={!isCreatePartner && !editPartnerContact}
                        name="partner[contact_phone]"
                        valueOverride={valueOverride}
                        required={isCreatePartner}
                      />
                    </FieldGroup>
                  </FieldGroup>
                  <FieldGroup className="row">
                    <FieldGroup className="col-12">
                      <FloatingLabelFormField
                        caption={i18n.t("Email")}
                        disabled={!isCreatePartner && !editPartnerContact}
                        name="partner[contact_email]"
                        type="email"
                        valueOverride={valueOverride}
                        required={isCreatePartner}
                      />
                    </FieldGroup>
                  </FieldGroup>
                </FieldGroup>
              </FieldGroup>
            )}

          </FieldGroup>
        </ModalForm>
      )
  }
}
