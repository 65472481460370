import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import {
  FieldGroup,
  FloatingLabelFormField
} from 'reactifi';

export default class WebinarDetailsComponent extends React.Component {

  static propTypes = {
    onChangeAdditionalInfo: PropTypes.func,
    onChangeUrl: PropTypes.func
  };

  render() {
    const { onChangeAdditionalInfo, onChangeUrl } = this.props;

    return (
      <FieldGroup {...this.props}>
        <FieldGroup>
          <FloatingLabelFormField
            caption={i18n.t("Event URL")}
            hintText={i18n.t("Example: https://www.zoom.com/company-id")}
            multilingual={true}
            name="url"
            onChange={onChangeUrl}
            required={true}
            type="url"
            validationType="url"
          />
        </FieldGroup>
        <FieldGroup >
          <FloatingLabelFormField
            caption={i18n.t("Additional Conferencing Info")}
            hintText={i18n.t("Example: To call into audio for the webinar, please dial 800-555-1234.")}
            multilingual={true}
            name="additional_info"
            onChange={onChangeAdditionalInfo}
          />
        </FieldGroup>
      </FieldGroup>
    );
  }
}
