import PropTypes from 'prop-types';
import React from 'react';
import { ModalForm, FieldGroup, FileUpload, SelectControl, FloatingLabelFormField, ProgressSpinner } from 'reactifi';
import * as topicsActionCreators from '../actions/topicsActionCreators'
import { bindActionCreators } from 'redux';
import i18n from 'lib/i18n';


export default class OfflineContentForm extends React.Component {
  static propTypes = {
    currentContent: PropTypes.object,
    errorMessage: PropTypes.string,
    clearMessages: PropTypes.func.isRequired,
    selectContent: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    uploadFiles: PropTypes.func,
    uploadInProgress: PropTypes.bool,
    currentTopic: PropTypes.object,
    everfiContents: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(topicsActionCreators, this.props.dispatch);
    this.state = {
      files: []
    }
    this.storeFileForUpload = this.storeFileForUpload.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  storeFileForUpload(files){
    this.setState({
      files: files
    })
  }

  uploadFiles(currentContent){
    let file = this.state.files[0];
    currentContent.filename = file.name;
    this.props.uploadFiles(file, currentContent)
  }

  onCancel() {
    this.props.selectContent(null);
    this.props.clearMessages();
  }

  getContentsByTopic() {
    let { currentTopic, everfiContents } = this.props;
    if (currentTopic && everfiContents) {
      return everfiContents.filter(content =>
        currentTopic.everfi_content_ids.indexOf(content.id) > -1
      );
    }
    return [];
  }

  renderForm(){
    let languages = [ { id: 'en', name: 'English' }, { id: 'es', name: 'Spanish' } ];
    return (
      <span>
        <FieldGroup className="row">
          <FieldGroup className="col-12">
            <FileUpload onUpload={this.storeFileForUpload}
              description={{ textArray: [i18n.t('Upload documents for your event')] }}
              header={{ text: 'Upload File' }}
              name="offline_contents"
              tooltip={i18n.t("Upload files needed for your event")}
              icon={{ className: 'zmdi zmdi-collection-image zmdi-hc-lg', fileClassName: 'icon-file', noFileClassName: 'icon-no-file' }}
              input={{ name: 'offline_contents' }}
              button={{ text: i18n.t('Select File'), className: 'btn btn-primary' }}
              label={{ text: i18n.t('No File'), closable: false }}/>
          </FieldGroup>
        </FieldGroup>
        <FieldGroup className="row">
          <FieldGroup className="col-12">
            <FloatingLabelFormField name="name" caption={i18n.t("Content Name")} required={true} />
          </FieldGroup>
        </FieldGroup>
        <FieldGroup className="row">
          <FieldGroup className="col-12">
            <FloatingLabelFormField name="language" caption={i18n.t("Language")} required={true}>
              <SelectControl options={languages} valueKey="id" labelKey="name" placeholder="" />
            </FloatingLabelFormField>
          </FieldGroup>
        </FieldGroup>
        <FieldGroup className="row">
          <FieldGroup className="col-12">
            <FloatingLabelFormField name="everfi_content_ids" caption={i18n.t("Content Type")}>
              <SelectControl options={this.getContentsByTopic()}
                valueKey="id"
                labelKey="name"
                multi={true}
                placeholder=""/>
            </FloatingLabelFormField>
          </FieldGroup>
        </FieldGroup>
      </span>
    )

  }

  uploadSpinner(){
    return (
      <span>
        <FieldGroup className="row">
          <FieldGroup className="col-12 text-center">
            <ProgressSpinner classNames="preloader pl-xxl pls-blue"/>
          </FieldGroup>
        </FieldGroup>
        <FieldGroup className="row">
          <FieldGroup className="col-12 text-center">
            <p>{i18n.t("Uploading File...")}</p>
          </FieldGroup>
        </FieldGroup>
      </span>
    )
  }

  render() {
    let title = this.props.currentContent && this.props.currentContent.id ? `Edit ${this.props.currentContent.name}` : 'Add a New Content';

    return (
      <ModalForm data={this.props.currentContent} title={title} errorMessage={this.props.errorMessage}
        onCancel={this.onCancel} addAction={this.uploadFiles} cancelButton={false} saveAndAddButton={false} >
        { this.props.uploadInProgress ? this.uploadSpinner() : this.renderForm() }
      </ModalForm>)
  }
}
