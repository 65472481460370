import React from 'react';
import { Router, Route, hashHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux'
import { ErrorBoundedProvider } from 'reactifi';
import createStore from './store/organizationsStore';
import OrganizationsContainer from './containers/OrganizationsContainer';
import OrganizationUsersContainer from './containers/OrganizationUsersContainer';
import OrganizationTopicsContainer from './containers/OrganizationTopicsContainer';


export default (props, _railsContext) => {
  const store = createStore(props);
  const history = syncHistoryWithStore(hashHistory, store);
  const reactComponent = (
    <ErrorBoundedProvider store={store}>
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <Route path="/:id/account_admins" component={OrganizationUsersContainer} accountId={props.accountId} currentUserId={props.currentUserId} viewer={props.viewer} />
        <Route path="/:id/topics" component={OrganizationTopicsContainer} accountId={props.accountId} currentUserId={props.currentUserId} viewer={props.viewer} />
        <Route path="/" component={OrganizationsContainer} accountId={props.accountId} currentUserId={props.currentUserId} viewer={props.viewer}/>
      </Router>
    </ErrorBoundedProvider>
  );

  return reactComponent;
};
