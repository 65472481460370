import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import {
  Form
} from 'reactifi';

export default class VenueDetailsComponent extends React.Component {
  static propTypes = {
    actions: PropTypes.shape({
      toggleEditVenue: PropTypes.func.isRequired
    }).isRequired,
    event: PropTypes.object,
    selectedVenue: PropTypes.shape({
      location_formatted_address: PropTypes.string,
      location_room: PropTypes.string,
      title: PropTypes.string
    })
  };

  render() {
    const { actions, event, selectedVenue } = this.props;

    const venueDisplay = selectedVenue && (
      <>
        <p className="m-b-0"><strong>{selectedVenue.title}</strong></p>
        <p>{selectedVenue.location_formatted_address} {selectedVenue.location_room}</p>
      </>
    );

    return (
      <div className="form-well p-20">
        <h4>{i18n.t('Event Venue')}</h4>
        <p>{i18n.t('Please tell us a little more about where the event will take place.')}</p>
        {venueDisplay}
        <Form
          cancelButton={false}
          data={event}
          saveButton={false}
        >
          <a
            className="btn btn-secondary m-0"
            onClick={() => actions.toggleEditVenue(true)}
          >
            {!selectedVenue ? i18n.t('Add Venue') : i18n.t('Edit Venue')}
          </a>
        </Form>
      </div>
    );
  }
}
