import { actionTypes } from '../actions/attendeeActionCreators';

export default function attendeeReducer(state = {}, action) {
  switch (action.type) {
  case actionTypes.SELECT_WIZARD_STEP:
    return Object.assign({}, state, { wizardStep: action.wizardStep });
  case actionTypes.CLOSE_WIZARD:
    return Object.assign({}, state, { wizardCancelConfirm: action.wizardCancelConfirm })
  default:
    return state;
  }
}
