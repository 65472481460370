import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { DateFilter, FilterBar, SelectFilter } from 'reactifi';
import i18n from 'lib/i18n';

import { getEventTypes } from '../../functions/helpers';

const combineFilters = filterSelections => {
  const filters = {};

  Object.entries(filterSelections).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      filters[key] = value.map(value => value.value);
    } else if (key === 'start_time') {
      ['start_date', 'end_date'].forEach(filter => { filters[filter] = '' });

      const { to_date, from_date } = value;

      if (from_date) filters.start_date = from_date;
      if (to_date) filters.end_date = to_date;

    } else {
      filters[key] = value?.value;
    }
  });

  return filters;
}

const EventSearchForm = props => {
  const {
    disabled,
    eventStatuses,
    eventTypes,
    isEventManagement,
    searchValue,
    setActivePage,
    setFilters,
    setSearchValue
  } = props;

  const validEventTypes = getEventTypes(isEventManagement);
  const availableEventTypes = eventTypes.reduce((eventTypes, eventType, index) => {
    if (validEventTypes.includes(eventType.value)) {
      eventTypes.push({ ...eventType, id: `event_type_${index}` });
    } return eventTypes;
  }, []);

  const filterConfig = [
    {
      isMulti: true,
      label: i18n.t('Event Date'),
      name: 'start_time',
      type: DateFilter
    },
    {
      isMulti: true,
      label: i18n.t('Status'),
      labelKey: 'label',
      name: 'status',
      options: eventStatuses.map((status, index) => ({
        ...status,
        id: `event_status_${index}`
      })),
      type: SelectFilter,
      valueKey: 'value'
    }, ...!isEventManagement ? [{
      label: i18n.t('LMI'),
      labelKey: 'label',
      name: 'is_lmi',
      options: [{
        id: 'lmi_filter_qualified',
        label: i18n.t('Qualified'),
        value: true
      }, {
        id: 'lmi_filter_not_qualified',
        label: i18n.t('Not Qualified'),
        value: false
      }],
      type: SelectFilter,
      valueKey: 'value'
    }] : [], {
      isMulti: true,
      label: i18n.t('Event Type'),
      labelKey: 'label',
      name: 'event_type',
      options: availableEventTypes,
      type: SelectFilter,
      valueKey: 'value'
    }
  ];

  const onSelectFilters = useCallback(filterSelections => {
    setActivePage(1);
    setFilters(combineFilters(filterSelections))
  });

  const searchAction = useCallback(({ searchValue }) => {
    setActivePage(1);
    setSearchValue({ keyword: searchValue })
  });


  return (
    <FilterBar
      disabled={disabled}
      filterConfig={filterConfig}
      onSelectFilters={onSelectFilters}
      searchBox={
        {
          fullwidth: false,
          placeholder: i18n.t('Search Topic or Title'),
          searchAction: searchAction,
          value: searchValue
        }
      }
    />
  );
}

EventSearchForm.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string
  }),
  disabled: PropTypes.bool,
  eventStatuses: PropTypes.array,
  eventTypes: PropTypes.array,
  isEventManagement: PropTypes.bool,
  searchValue: PropTypes.string,
  setActivePage: PropTypes.func,
  setFilters: PropTypes.func,
  setSearchValue: PropTypes.func
};

export default EventSearchForm;
