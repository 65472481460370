/* global i18n:true */

(function (scope) {

  var _profile = function (user) {
    this.user = user;
    this.hasAlternateEmail = $('#user_alternate_email');
    this.alternateEmail = $('.user_alternate_email');
    this.accountEmail = $('.account_email');
    this.workEmailAccount = $('#work_account_email');
    this.userEmail = $('.user_email');
    this.emailLabel = $('label[for="user_email"]');
    this.isEmployeeBox = $('#user_is_employee');
    this.workLocationOptions = $('#work-location-options');
    this.workLocationStatus = $('#work-location-status');
    this.workLocationSelector = $('#user-work-location');
    this.locationOptions = $('#user-location-options');
    this.route = $('#route');
    this.locality = $('#locality');
    this.country = $('#country');
    this.init();
  };

  _profile.prototype.init = function () {
    var _this = this;

    this.isEmployeeBox.on('click', function () {
      _this.viewWorkLocationOptions();
    });
    _this.viewWorkLocationOptions();

    this.hasAlternateEmail.on('click', function () {
      _this.viewAlternateEmail();
    });
    _this.viewAlternateEmail();

    this.workLocationStatus.on('click', function () {
      _this.setWorkLocationStatus();
    });
  };

  _profile.prototype.setWorkLocationStatus = function () {
    if (this.workLocationStatus.prop('checked')) {
      this.workLocationSelector.prop('disabled', true);
      this.workLocationSelector.val([]).trigger('change');
      this.disableLocation(false);
    } else {
      this.workLocationSelector.prop('disabled', false);
      this.disableLocation(true);
    }
  };

  _profile.prototype.disableLocation = function (disabled) {
    this.locationOptions.find('input').prop('disabled', disabled);
    if (disabled) {
      this.locationOptions.hide();
    } else {
      this.locationOptions.show();
    }
  };

  _profile.prototype.viewWorkLocationOptions = function () {
    if (this.isEmployeeBox.prop('checked')) {
      this.userEmail.appendTo(this.workEmailAccount);
      this.emailLabel.text(i18n.t("* Work Email"));
      this.userEmail.show();
      this.workEmailAccount.show();
      this.workLocationOptions.show();
      if (this.user.work_location_id || !this.user.location) {
        this.workLocationSelector.prop('disabled', false);
        this.disableLocation(true);
      } else {
        this.workLocationStatus.prop('checked', true);
        this.workLocationSelector.prop('disabled', true);
        this.disableLocation(false);
      }
    } else {
      this.userEmail.appendTo(this.accountEmail);
      this.emailLabel.text(i18n.t("* Email"));
      this.userEmail.show();
      this.accountEmail.show();
      this.workEmailAccount.hide();
      this.workLocationOptions.hide();
      this.workLocationSelector.prop('disabled', true);
      this.disableLocation(false);
    }
  };

  _profile.prototype.viewAlternateEmail = function () {
    if (this.hasAlternateEmail.prop('checked')) {
      this.alternateEmail.show();
    } else {
      this.alternateEmail.hide();
    }
  };

  scope.Profile = _profile;

}(window));
