import PropTypes from 'prop-types';
import React from 'react';
import { Form, FieldGroup, FloatingLabelFormField, SelectControl, Icon } from 'reactifi';
import { Button } from 'react-bootstrap';
import i18n from "lib/i18n";

export default class EventFinderForm extends React.Component {
  static propTypes = {
    isEventManagement: PropTypes.bool,
    onSearch: PropTypes.func.isRequired,
    selectedOptions: PropTypes.object,
    topics: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.state = {
      searchOptions: this.props.selectedOptions || {}
    }
  }

  onSubmit = e => this.props.onSearch(this.state.searchOptions);

  render() {
    const { isEventManagement, topics } = this.props;
    const topicSelectStyles = isEventManagement ? 'col-lg-9' : 'col-lg-4';

    return (
      <div className="event-finder-form col-lg-12">
        <Form data={this.state.searchOptions}
          saveButton={false} className="col-lg-12"
          cancelButton={false}>
          <FieldGroup className={`select-wrapper ${topicSelectStyles}`}>
            <FloatingLabelFormField name="topic" caption={i18n.t("Select Topic")} placeholder={i18n.t("Use keyboard for predictive text")}>
              <SelectControl
                options={topics}
                multi={false}
                clearable={false}
                valueKey="id"
                labelKey="name" />
            </FloatingLabelFormField>
          </FieldGroup>
          {!isEventManagement && (
            <FieldGroup className="input-wrapper col-lg-5">
              <FloatingLabelFormField name="location" caption={i18n.t("City or Zip Code")} placeholder={i18n.t("Use keyboard for predictive text")} />
            </FieldGroup>
          )}
          <FieldGroup className="col-lg-3">
            <Button className="btn-secondary" onClick={this.onSubmit}>
              {i18n.t("Find Workshops")}<Icon iconType="chevron-right" additionalClasses="right" />
            </Button>
          </FieldGroup>
        </Form>
      </div>
    )
  }
}
