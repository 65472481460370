import { sanitizeUrl } from '@braintree/sanitize-url';

export const eventFinderRedirect = path => ({ topic, location }) => {
  const url = window.location;
  const locale = url.pathname.split("/")[1];
  let queryParams = [];

  if (topic) {
    queryParams.push(`topic=${topic}`);
  }
  if (location) {
    queryParams.push(`location=${location}`);
  }

  window.location.href = sanitizeUrl(`/${locale}${path}?${queryParams.join('&')}`);
};

export const eventFinderRedirectByViewer = viewer => eventFinderRedirect(
  viewer === 'volunteer' ? '/vportal/events#/' : '/#/'
);


export const portalPath = viewer => {
  return viewer === 'volunteer' ? 'vportal' : 'cportal';
};
