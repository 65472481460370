import React from 'react';
import { ErrorBoundedProvider } from 'reactifi';
import createStore from './store/financialEducationContentStore';
import List from './containers/ListContainer';
import Show from './containers/ShowContainer';
import { Router, Route, hashHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'

export default (props, _railsContext) => {
  const store = createStore(props);
  const history = syncHistoryWithStore(hashHistory, store);
  const reactComponent = (
    <ErrorBoundedProvider store={store}>
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <Route
          path={"/:id"}
          component={Show}
          accountId={props.accountId}
          currentUserId={props.currentUserId}
          il8n={props.il8n}
          isEventManagement={props.isEventManagement}
          viewer={props.viewer}
        />
        <Route
          path="/"
          component={List}
          accountId={props.accountId}
          currentUserId={props.currentUserId}
          il8n={props.il8n}
          isEventManagement={props.isEventManagement}
          topics={props.topics}
          viewer={props.viewer}
        />
      </Router>
    </ErrorBoundedProvider>
  );
  return reactComponent;
};
