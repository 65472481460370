import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  ActionButtons,
  ConfirmationModal,
  Page,
  PagingDataList,
  SearchComponent,
  SearchForm,
  SelectControl,
  Toolbar,
  Icon
} from 'reactifi';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import utils from 'lib/utils';
import i18n from 'lib/i18n';
import OrganizationTopicsForm from './OrganizationTopicsForm';
import OfflineContentForm from './OfflineContentForm';
import switchOn from '../../../../../images/switch-on.svg';
import switchOff from '../../../../../images/switch-off.svg';
import debounce from 'lodash/debounce';

export default class OrganizationTopics extends Component {

  static propTypes = {
    everfiContents: PropTypes.array,
    clearMessages: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    loadTopics: PropTypes.func,
    selectTopic: PropTypes.func.isRequired,
    updateTopic: PropTypes.func,
    createTopic: PropTypes.func,
    successMessage: PropTypes.string,
    currentTopic: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    currentOfflineContent: PropTypes.object,
    currentOrganizationId: PropTypes.string,
    selectContent: PropTypes.func,
    setTopic: PropTypes.func,
    createContent: PropTypes.func,
    updateContent: PropTypes.func,
    deleteContent: PropTypes.func,
    location: PropTypes.obj,
    router: PropTypes.obj,
    uploadError: PropTypes.func,
    topics: PropTypes.array,
    topicsMeta: PropTypes.shape({
      total_count: PropTypes.number
    }),
    filter: PropTypes.object,
    isLoadingTopics: PropTypes.bool,
    uploadInProgress: PropTypes.bool
  };

  static defaultProps = {
    topics: [],
    everfiContents: []
  }

  constructor(props) {
    super(props);
    this.filterTopics = debounce(this.filterTopics, 350, { leading: false, trailing: true });

    this.state = {
      activePage: 1,
      filters: {},
      deleteTopicData: null,
      showToggleTopicEnabled: null,
      confirmationTitle: "",
      confirmationBody: "",
      confirmationOKButtonLabel: ""
    };

    let filterObject = {};
    filterObject[i18n.t("All")] = "all";
    filterObject[i18n.t("Enabled Topics")] = "true";
    filterObject[i18n.t("Disabled Topics")] = "false";
    this.enableFilters = utils.mapObjToOptions(filterObject);
  }

  pageTitle = () => {
    const listName = i18n.t('Topics List');

    if(!this.props.location.state)
      return listName;

    const orgName = this.props.location.state.orgName;

    if(orgName) {
      return `${orgName} - ${listName}`;
    } else {
      return listName;
    }
  }

  onPageChange = activePage => {
    this.setState({ activePage }, () => this.filterTopics({}, activePage));
  }

  filterTopics = (filters, page = 1) => {
    let filtersArr = Object.assign({}, this.state.filters, filters, this.props.filter);
    for (const key of Object.keys(filtersArr)) {
      if (filtersArr[key] === "all" || key === "account_id") {
        delete filtersArr[key];
      }
    }
    filtersArr.account_id = this.props.currentOrganizationId;
    this.props.loadTopics(filtersArr, page);
    this.setState({ filters: filtersArr, activePage: page });
  }

  deleteContent = (topic) => {
    this.setState({ deleteTopicData: topic });
  }

  onCancelDeleteContent = () => {
    this.setState({ deleteTopicData: null });
  }

  onConfirmDeleteContent = (topic) => {
    this.props.deleteContent(topic);
    this.setState({ deleteTopicData: null });
  }

  addContent = (topicId) => {
    this.props.setTopic(topicId);
    this.props.selectContent('add');
  }

  updateContent = (contentId) => {
    this.props.updateContent(contentId);
  }

  clearConfirmationLabels = () => {
    this.setState({ confirmationTitle: "", confirmationBody: "", confirmationOKButtonLabel: "" });
  }

  toggleTopicEnabled = (topic) => {
    this.setState({
      confirmationTitle: (topic.enabled ? i18n.t("Disable Topic") : i18n.t("Enable Topic")),
      confirmationBody: (topic.enabled ? i18n.t("Are you sure you want to disable this topic?") : i18n.t("Are you sure you want to enable this topic?")),
      confirmationOKButtonLabel: (topic.enabled ? i18n.t("Disable Topic") : i18n.t("Enable Topic")),
      showToggleTopicEnabled: topic
    });
  }

  onCancelToggleTopicEnabled = () => {
    this.setState({ showToggleTopicEnabled: null });
    this.clearConfirmationLabels();
  }

  onConfirmToggleTopicEnabled = (topic) => {
    topic.enabled = !topic.enabled;
    this.props.updateTopic(topic, this.state.filters);
    this.setState({ showToggleTopicEnabled: null });
    this.clearConfirmationLabels();
  }

  getCurrentTopic = () => {
    if (this.props.currentOfflineContent) {
      if (typeof this.props.currentOfflineContent.topic_id === 'string')
        return this.props.topics.find(topic => topic.id === this.props.currentOfflineContent.topic_id);
      else if (typeof this.props.currentOfflineContent.topic_id === 'object') {
        return this.props.topics.find(topic => topic.id === this.props.currentOfflineContent.topic_id.relationships.topic.data.id);
      }
    }
  }

  render() {

    let fields = [
      { name: 'name', title: i18n.t('Name'), canSort: true, formatter: (cell, row) => row.name },
      {
        name: 'description',
        title: i18n.t('Description'),
        columnOptions: { width: '25%' },
        canSort: true,
        formatter: (cell, row) => row.description
      },
      {
        name: 'content',
        title: i18n.t('Content Type'),
        columnOptions: { width: '12.5%' },
        canSort: true,
        formatter: (cell, row) =>
          row.everfi_content ?
            <div>
              {row.everfi_content.name}
              <ul>
                {row.course_modules.map((course, i) => {
                  return (
                    <li key={i}>{course.name}</li>
                  )
                })}
              </ul>
            </div>
            : null
      },
      {
        name: 'offline_content',
        title: i18n.t('Offline Content'),
        columnOptions: { width: '20%' },
        canSort: false,
        formatter: (cell, row) => {
          return (
            <div>
              <ul>{row.offline_contents.map((content, i) => {
                return (
                  <li key={i}>
                    <a href={content.content_url} target="_blank" rel="noopener noreferrer">{content.name}</a><a
                      onClick={(e) => this.deleteContent(content)}> <Icon iconType="delete" /></a>
                    <a onClick={(e) => this.props.selectContent(content.id)}> <Icon iconType="edit" /></a>
                  </li>)
              })}</ul>
              <a onClick={(e) => this.addContent(row.id)}>{i18n.t('Add File')} <Icon iconType="plus" /></a>
            </div>
          )
        }
      },
      {
        name: 'enabled',
        title: i18n.t('Enabled'),
        columnOptions: { dataAlign: 'center', columnClassName: 'icon-only-column' },
        formatter: (cell, row) =>
          <span><OverlayTrigger placement="top" overlay={<Tooltip
            id="tooltip-enabled">{i18n.t('Only enabled topics show in the Event Creation Wizard')}</Tooltip>}>
            <a onClick={(e) => this.toggleTopicEnabled(row)} data-id={row.id}>{row.enabled ?
              <img className="switch-icon" alt={i18n.t("Switch On")} src={switchOn} /> :
              <img className="switch-icon" alt={i18n.t("Switch Off")} src={switchOff} />}</a>
          </OverlayTrigger></span>
      },
      {
        name: 'actions', title: i18n.t('Actions'), formatter: (cell, row) => {
          const actionButtons = [
            {
              icon: 'edit',
              title: i18n.t('Edit'),
              tooltip: 'Edit Topic',
              type: 'view',
              actionType: 'callToAction',
              action: e => {
                e.preventDefault();
                e.stopPropagation();
                this.props.selectTopic(row.id);
              }
            }
          ];
          const deleteButton = {
            icon: "delete",
            title: i18n.t('Delete'),
            type: "view"
          };
          deleteButton.actionType = "callToAction";
          deleteButton.tooltip = i18n.t('Delete Topic');
          deleteButton.action = () => {
            this.deleteContent(row);
          }
          actionButtons.push(deleteButton);

          return <ActionButtons buttons={actionButtons} row={row} />;
        }
      }
    ];

    const tableOptions = {
      bordered: false,
      hover: true,
      striped: true,
      defaultSortName: 'name',
      defaultSortOrder: 'asc'
    };
    let actionButton = {
      label: i18n.t('Create Topic'),
      action: (e) => this.props.selectTopic('add')
    };
    const toolbar =
      <Toolbar toolbarClass="filters-toolbar">
        <SearchForm searchAction={this.filterTopics} className="w-100" defaultValues={{ enabled: 'all' }}>
          <SelectControl
            className="toolbar-select placeholder-text-align-left"
            labelKey="label"
            name="enabled"
            options={this.enableFilters}
            placeholderText={i18n.t('Enabled')}
            valueKey="value"
          />
        </SearchForm>
        <SearchComponent
          name="keyword"
          placeholder={i18n.t("Name, Description")}
          searchAction={this.filterTopics}
        />
      </Toolbar>;

    let currentTopic = this.getCurrentTopic();

    return (
      <Page
        pageType="parent"
        contentType="fullwidth"
        title={this.pageTitle()}
        actionButton={actionButton}
        backButton={this.props.router.goBack}
        toolbar={toolbar}
        errorMessage={this.props.currentTopic ? null : this.props.errorMessage}
        successMessage={this.props.currentTopic ? null : this.props.successMessage}
        clearMessages={this.props.clearMessages}
        usePageWrapper={true}
      >
        <div className="page-panel">
          <OrganizationTopicsForm
            currentTopic={this.props.currentTopic}
            clearMessages={this.props.clearMessages}
            uploadError={this.props.uploadError}
            updateTopic={this.props.updateTopic}
            selectTopic={this.props.selectTopic}
            createTopic={this.props.createTopic}
            dispatch={this.props.dispatch}
            account_id={this.props.currentOrganizationId} />
          <PagingDataList
            data={this.props.topics}
            fields={fields}
            keyField="id"
            tableOptions={tableOptions}
            isLoadingData={this.props.isLoadingTopics}
            onPageChange={this.onPageChange}
            totalCount={this.props.topicsMeta ? this.props.topicsMeta.total_count : 0}
            activePage={this.state.activePage} />
        </div>
        <OfflineContentForm
          currentOfflineContent={this.props.currentOfflineContent}
          everfiContents={this.props.everfiContents}
          currentTopic={currentTopic}
          uploadFiles={this.props.createContent}
          selectContent={this.props.selectContent}
          clearMessages={this.props.clearMessages}
          dispatch={this.props.dispatch}
          uploadInProgress={this.props.uploadInProgress}
          updateContent={this.props.updateContent} />
        <ConfirmationModal title={this.state.confirmationTitle} data={this.state.showToggleTopicEnabled}
          canClickOff={true} onCancel={this.onCancelToggleTopicEnabled}
          onConfirm={this.onConfirmToggleTopicEnabled}
          confirmButtonText={this.state.confirmationOKButtonLabel}>
          <div>{this.state.confirmationBody}</div>
        </ConfirmationModal>
        <ConfirmationModal title={i18n.t("Delete Item")} data={this.state.deleteTopicData} canClickOff={true}
          onCancel={this.onCancelDeleteContent} onConfirm={this.onConfirmDeleteContent}
          confirmButtonText={i18n.t("Delete Item")}>
          <div>{i18n.t('Are you sure you would like to delete this item?')}</div>
        </ConfirmationModal>
      </Page>
    )
  }
}
