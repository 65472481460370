import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import {
  ActionButtons,
  buildGenericProps,
  ConfirmationModal,
  FilterBar,
  Icon,
  Page,
  PagingDataList,
  SelectFilter
} from 'reactifi';
import * as venueActionCreators from '../actions/venueActionCreators'
import VenueForm from '../components/VenueForm';
import VenueDetails from '../components/VenueDetails';
import debounce from 'lodash/debounce';
import switchOn from '../../../../images/switch-on.svg';
import switchOff from '../../../../images/switch-off.svg';
import i18n from 'lib/i18n';
import { getGoogleMapsLink, openInNewTab } from '../../Event/functions/helpers';

function mapStateToProps(state, ownProps) {
  let props = buildGenericProps(state, 'venues');
  if (props.venues) {
    props.venues.forEach((venue) => {
      if (venue.facilities) {
        venue.facilities.forEach((facility) => { venue[facility] = true });
      }
    });
  }
  if (props.currentEntity) {
    props.currentVenue = props.currentEntity;
    if (props.currentVenue.facilities) {
      props.currentVenue.facilities.forEach((facility) => { props.currentVenue[facility] = true });
    }
    if (state.api.currentEntityId === "add") {
      props.currentVenue.master = true;
      props.currentVenue.enabled = true;
    }
  }
  return props;
}

class VenueContainer extends React.Component {
  static propTypes = {
    currentVenue: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    googleMapsApiKey: PropTypes.string.isRequired,
    isEventManagement: PropTypes.bool,
    isLoadingVenues: PropTypes.bool,
    successMessage: PropTypes.string,
    viewer: PropTypes.string,
    venues: PropTypes.array,
    venuesMeta: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(venueActionCreators, this.props.dispatch);

    this.loadVenues = debounce(this.loadVenues, 350, { leading: false, trailing: true });
    let filterDefaults = {}
    if (this.props.viewer === 'volunteer') {
      filterDefaults = { master: true };
    }

    this.state = {
      activePage: 1,
      confirmationBody: '',
      filters: filterDefaults || {},
      confirmationData: null,
      confirmationOKButtonLabel: '',
      confirmationTitle: '',
      searchValue: '',
      sortName: 'title',
      sortOrder: 'asc',
      whichConfirmation: ''
    };
  }

  loadVenues = () => {
    const { isEventManagement, viewer } = this.props;
    const { activePage, filters, searchValue, sortName, sortOrder } = this.state;

    filters.enabled = isEventManagement || viewer === 'volunteer' || filters.enabled;
    filters.master = ['event_staff', 'volunteer'].includes(viewer) || filters.master;
    filters.keyword = searchValue;

    this.actions.findVenues(filters, { value: sortName, direction: sortOrder }, activePage, viewer);
  }

  componentDidMount() {
    if (this.props.viewer === 'volunteer') {
      this.loadVenues();
    }
  }

  filterVenues = filterOptions => {
    const filters = {};

    Object.entries(filterOptions).forEach(filter => {
      const [key, value] = filter;

      filters[key] = value?.value;
    });

    this.setState({ filters }, this.loadVenues);
  }

  toggleVenueEnabled = (event, venue) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      confirmationTitle: (venue.enabled ? i18n.t("Disable Venue") : i18n.t("Enable Venue")),
      confirmationBody: (venue.enabled ? i18n.t("Are you sure you want to disable this venue?") : i18n.t("Are you sure you want to enable this venue?")),
      confirmationOKButtonLabel: (venue.enabled ? i18n.t("Disable Venue") : i18n.t("Enable Venue")),
      confirmationData: venue,
      whichConfirmation: 'enabled'
    });
  }

  toggleVenuePublished = (event, venue) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      confirmationTitle: (venue.master ? i18n.t("Unpublish Venue") : i18n.t("Publish Venue")),
      confirmationBody: (venue.master ? i18n.t("Are you sure you want to unpublish this venue?") : i18n.t("Are you sure you want to publish this venue?")),
      confirmationOKButtonLabel: (venue.master ? i18n.t("Unpublish Venue") : i18n.t("Publish Venue")),
      confirmationData: venue,
      whichConfirmation: 'published'
    });
  }

  onSortChange = (sortName, sortOrder) => {
    this.setState(
      {
        sort: {
          value: sortName,
          direction: sortOrder
        },
        sortName,
        sortOrder,
        activePage: 1
      },
      this.loadVenues
    );
  }

  handleSearchChange = keyword => {
    const { searchValue } = keyword;
    if (searchValue === this.state.searchValue) return;
    this.setState({ activePage: 1, searchValue }, this.loadVenues)
  };

  renderFilters = () => {
    const { isEventManagement, viewer } = this.props;

    const enabledOptions = [
      { label: i18n.t('Enabled'), value: true },
      { label: i18n.t('Disabled'), value: false }
    ];

    const publishedOptions = [
      { label: i18n.t('Published'), value: true },
      { label: i18n.t('Unpublished'), value: false }
    ];

    const filterConfig = viewer !== 'account_admin' ? [] : [
      {
        name: 'master',
        label: i18n.t('Publish Status'),
        options: publishedOptions,
        type: SelectFilter
      },
      ...!isEventManagement ? [{
        name: 'enabled',
        label: i18n.t('Enabled'),
        options: enabledOptions,
        type: SelectFilter
      }] : []
    ];

    return (
      <FilterBar
        filterConfig={filterConfig}
        searchBox={
          {
            isOnlySearch: false,
            fullWidth: false,
            placeholder: i18n.t('Search by Name or Address'),
            searchAction: this.handleSearchChange,
            value: this.state.searchValue,
            valueChangeDelay: 1500
          }
        }
        onSelectFilters={this.filterVenues}
        disabled={this.props.isLoadingVenues}
      />
    );
  }

  get fields() {
    const { isEventManagement, viewer } = this.props;

    const displayEnableColumn = !isEventManagement && viewer === 'account_admin';
    const displayPublishColumn = viewer === 'account_admin';

    return [
      { name: 'title', title: i18n.t('Name'), canSort: true },
      { name: 'location_formatted_address', title: i18n.t('Address'), canSort: true },
      { name: 'location_room', title: i18n.t('Address Line 2'), canSort: true },
      // CRA Tracking only columns
      ...!isEventManagement ? [
        { name: 'capacity', title: i18n.t('Capacity'), canSort: true },
        { name: 'contact_name', title: i18n.t('Contact'), canSort: true },
        {
          name: 'event_count',
          title: i18n.t('Events'),
          canSort: true,
          formatter: (_, row) =>
            <a onClick={event => event.stopPropagation()} href={`events#?filterField=venue_id&filterValue=${row.id}`}>{row.event_count}</a>
        },
        {
          name: 'facilities',
          title: i18n.t('Facilities'),
          align: 'center',
          formatter: (_, row) => {
            return (
              <span>
                {row.computer_lab ?
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-enabled">{i18n.t('Individual Devices')}</Tooltip>}>
                    <Icon iconType="devices" />
                  </OverlayTrigger> : null}
                {row.projector ?
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-enabled">{i18n.t('Projector')}</Tooltip>}>
                    <Icon iconType="projector" additionalClasses="status-icon" fontFamily="engage" />
                  </OverlayTrigger> : null}
                {row.accessibility ?
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-enabled">{i18n.t('Accessibility')}</Tooltip>}>
                    <Icon iconType="accessible" additionalClasses="status-icon" fontFamily="engage" />
                  </OverlayTrigger> : null}
              </span>)
          }
        }
      ] : [],
      // End CRA Tracking only columns

      // Show "Details" column only for Event Volunteers (CRA)
      ...viewer === 'volunteer' ? [{
        name: 'published',
        title: i18n.t('Details'),
        align: 'center',
        formatter: (_, row) => {
          return (
            <span><OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-published">View Details</Tooltip>}>
              <a onClick={(e) => { e.stopPropagation(); this.actions.selectVenue(row.id) }}><span className="col-text"><Icon iconType="file-text" size="large" /></span></a>
            </OverlayTrigger></span>
          )
        }
      }] : [],

      ...displayEnableColumn ? [{
        name: 'enabled',
        title: i18n.t('Enabled'),
        align: 'center',
        formatter: (_, row) => {
          return (
            <span><OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-enabled">{i18n.t('Disabled venues can be filtered out in reporting')}</Tooltip>}>
              <a onClick={(e) => this.toggleVenueEnabled(e, row)}>{row.enabled ? <img className="switch-icon" alt={i18n.t("Switch On")} src={switchOn} /> : <img className="switch-icon" alt={i18n.t("Switch Off")} src={switchOff} />}</a>
            </OverlayTrigger></span>
          )
        }
      }] : [],

      ...displayPublishColumn ? [{
        name: 'published',
        title: i18n.t('Published'),
        align: 'center',
        formatter: (_, row) => {
          return (
            <span><OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-published">{i18n.t('Allow other volunteers to select this venue')}</Tooltip>}>
              <a onClick={(e) => this.toggleVenuePublished(e, row)}>{row.master ? <img className="switch-icon" alt={i18n.t("Switch On")} src={switchOn} /> : <img className="switch-icon" alt={i18n.t("Switch Off")} src={switchOff} />}</a>
            </OverlayTrigger></span>
          )
        }
      }] : [],

      ...isEventManagement ? [{
        name: 'actions',
        title: i18n.t('Actions'),
        formatter: (_, row) => {
          const googleMapsLink = !!row?.location_formatted_address && getGoogleMapsLink(row.location_formatted_address);

          const buttons = [
            ...viewer === 'account_admin' ? [{
              icon: "zmdi-edit",
              tooltip: i18n.t('Edit Venue'),
              type: "view",
              actionType: "callToAction",
              action: () => this.actions.selectVenue(row.id)
            }] : [],
            ...googleMapsLink ? [{
              icon: "pin",
              title: i18n.t('View Map'),
              tooltip: i18n.t('View Map'),
              type: "view",
              actionType: "callToAction",
              action: () => openInNewTab(googleMapsLink)
            }] : []
          ];

          return (
            row && (
              <ActionButtons
                row={row}
                buttons={buttons}
              />
            )
          );
        }
      }] : []
    ];
  }

  onPageChange = activePage => {
    this.setState({ activePage }, this.loadVenues);
  }

  clearConfirmationLabels = () => {
    this.setState({ confirmationTitle: "", confirmationBody: "", confirmationOKButtonLabel: "", whichConfirmation: "" });
  }

  onCancelConfirmation = () => {
    this.setState({ confirmationData: null });
    this.clearConfirmationLabels();
  }

  updateVenue = (currentVenue) => {
    const { isEventManagement } = this.props;
    this.actions.updateVenue(currentVenue, this.state.filters, isEventManagement);
  }

  onConfirmConfirmation = venue => {
    if (this.state.whichConfirmation === "enabled") {
      venue.enabled = !venue.enabled;
    } else {
      venue.master = !venue.master;
    }
    this.updateVenue(venue);
    this.setState({ confirmationData: null });
    this.clearConfirmationLabels();
  }

  render() {
    const { errorMessage, isEventManagement, successMessage, viewer } = this.props;

    const isManager = !['event_staff', 'volunteer'].includes(viewer);

    const tableOptions = {
      defaultSorted: [{
        dataField: 'title',
        order: 'asc'
      }],
      onSortChange: this.onSortChange,
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder
    };

    if (isManager) tableOptions.onRowClick = (_, row) => this.actions.selectVenue(row.id);

    const actionButton = isManager && {
      label: i18n.t('Create Venue'),
      action: () => this.actions.selectVenue('add')
    };

    return (
      <Page
        actionButton={actionButton}
        clearMessages={this.actions.clearMessages}
        contentType="list"
        errorMessage={errorMessage}
        pageType="parent"
        successMessage={successMessage}
        title={i18n.t('Venues')}
        usePageWrapper={true}
      >
        {this.renderFilters()}
        {(this.props.isLoadingVenues || this.props.venues.length || Object.keys(this.state.filters).length) && (
          <PagingDataList
            activePage={this.state.activePage}
            data={this.props.venues}
            fields={this.fields}
            isLoadingData={this.props.isLoadingVenues}
            keyField="id"
            options={tableOptions}
            onPageChange={this.onPageChange}
            totalCount={this.props.venuesMeta?.total_count || 0}
          />
        )}
        <ConfirmationModal
          canClickOff={true}
          confirmButtonText={this.state.confirmationOKButtonLabel}
          data={this.state.confirmationData}
          onCancel={this.onCancelConfirmation}
          onConfirm={this.onConfirmConfirmation}
          title={this.state.confirmationTitle}
        >
          <div>{this.state.confirmationBody}</div>
        </ConfirmationModal>
        {this.props.viewer !== 'volunteer' ?
          <VenueForm
            clearMessages={this.actions.clearMessages}
            createVenue={this.actions.createVenue}
            currentVenue={this.props.currentVenue}
            errorMessage={this.props.errorMessage}
            googleMapsApiKey={this.props.googleMapsApiKey}
            isEventManagement={isEventManagement}
            selectVenue={this.actions.selectVenue}
            updateVenue={this.updateVenue}
          /> :
          <VenueDetails
            data={this.props.currentVenue}
            selectVenue={this.actions.selectVenue}
            googleMapsApiKey={this.props.googleMapsApiKey}
          />
        }
      </Page>
    );
  }
}

export default connect(mapStateToProps)(VenueContainer);
