import PropTypes from 'prop-types';
import React from 'react';
import { CancelConfirmModal, FloatingLabelFormField, ModalForm } from 'reactifi';
import i18n from 'lib/i18n';

export default class EventCancelForm extends React.Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    event: PropTypes.object,
    show: PropTypes.bool,
    toggleCancelEvent: PropTypes.func,
    updateEvent: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      showEventCancelConfirmation: false
    }
  }

  onCancel = () => {
    this.setState({ showEventCancelConfirmation: true });
  }

  cancelEventAction = event => {
    this.props.updateEvent(Object.assign(event, {
      cancelled_at: new Date(),
      cancellation_reason: event.cancel_message,
      cancelled: true
    }), i18n.t("Event Cancelled!"));
    this.props.toggleCancelEvent(false);
  }

  discardCancelEventChanges = discard => {
    this.setState({ showEventCancelConfirmation: false });
    if(discard) return this.props.toggleCancelEvent(false);
  }

  render() {
    const { showEventCancelConfirmation } = this.state;

    return (
      <>
        <ModalForm
          data={this.props.event}
          errorMessage={this.props.errorMessage}
          onCancel={this.onCancel}
          show={this.props.show}
          title={i18n.t('Cancel Event')}
          updateAction={this.cancelEventAction}
        >
          <FloatingLabelFormField
            caption={i18n.t("Please enter a short reason for cancelling this event.")}
            name="cancel_message"
            required={true}
          />
        </ModalForm>
        <CancelConfirmModal
          show={showEventCancelConfirmation}
          onCancelClose={this.discardCancelEventChanges}
        />
      </>
    )
  }
}
