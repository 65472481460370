import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import {
  FieldGroup,
  FloatingLabelFormField,
  SelectControl
} from 'reactifi';

export default class MultilingualAssistanceComponent extends React.Component {
  static propTypes = {
    languageOptions: PropTypes.array,
    selectLanguages: PropTypes.func
  };

  render() {
    const { languageOptions, selectLanguages } = this.props;

    return (
      <FieldGroup {...this.props}>
        <FloatingLabelFormField
          caption={i18n.t("Multilingual Assistance")}
          name="languages"
        >
          <SelectControl
            labelKey="label"
            multi={true}
            onChange={selectLanguages}
            options={languageOptions}
            placeholder=""
            valueKey="value"
          />
        </FloatingLabelFormField>
      </FieldGroup>
    );
  }
}
