import PropTypes from 'prop-types';
import React from 'react';
import { ModalForm, AddressPickerControl, FieldGroup, SelectAsyncControl, FloatingLabelFormField, MaterialCheckbox, InfoTooltip } from 'reactifi';
import i18n from 'lib/i18n';

export default class PartnerForm extends React.Component {
  static propTypes = {
    clearMessages: PropTypes.func.isRequired,
    createPartner: PropTypes.func.isRequired,
    currentPartner: PropTypes.object,
    errorMessage: PropTypes.string,
    googleMapsApiKey: PropTypes.string.isRequired,
    isEventManagement: PropTypes.bool,
    loadPartner: PropTypes.func.isRequired,
    selectPartner: PropTypes.func.isRequired,
    updatePartner: PropTypes.func.isRequired,
    viewer: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
  }

  onCancel = () => {
    this.props.selectPartner(null);
    this.props.clearMessages();
  }

  onPartnerChange = val => {
    if (val && val.id) {
      this.props.loadPartner(val.id);
    }
  }

  buildDisplay(i) {
    return (i.name + " (" + i.location_locality + ", " + i.location_administrative_area_level_1 + ")");
  }

  render() {
    const {
      createPartner,
      currentPartner,
      errorMessage,
      googleMapsApiKey,
      isEventManagement,
      updatePartner,
      viewer
    } = this.props;
    const title = currentPartner && currentPartner.id ? i18n.t('editNumber', { postProcess: 'sprintf', sprintf: [currentPartner.name] }) : i18n.t('Add a New Partner');
    const headquarters = currentPartner && currentPartner.headquarters_partner_id ? { id: currentPartner.headquarters_partner.id, display: currentPartner.headquarters_partner.name + " (" + currentPartner.headquarters_partner.location_locality + ", " + currentPartner.headquarters_partner.location_administrative_area_level_1 + ")" } : {};
    const languages = [{ en: 'English' }, { es: 'Spanish' }];
    const isAdmin = viewer === 'account_admin';
    const cancelButtonText = isAdmin ? i18n.t('Cancel') : i18n.t('Close');

    return (
      <ModalForm
        addAction={createPartner}
        cancelButtonText={cancelButtonText}
        data={currentPartner}
        errorMessage={errorMessage}
        languages={languages}
        onCancel={this.onCancel}
        saveButton={isAdmin}
        title={title}
        updateAction={updatePartner}
      >
        <FieldGroup className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h4>{i18n.t('Partner Details')}</h4>
            </div>
          </div>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField
                caption={i18n.t("Name")}
                disabled={!isAdmin}
                name="name"
                required={true}
              />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField
                caption={i18n.t("Website")}
                disabled={!isAdmin}
                name="website"
                validationType="url"
                type="url"
              />
            </FieldGroup>
          </FieldGroup>
          {!isEventManagement && (
            <FieldGroup>
              <FieldGroup className="row">
                <FieldGroup className="col-12">
                  <FloatingLabelFormField name="location_latitude" caption={i18n.t("Address (Type and select below)")} required={true}>
                    <AddressPickerControl googleMapsApiKey={googleMapsApiKey} initialData={currentPartner} placeholder="" />
                  </FloatingLabelFormField>
                </FieldGroup>
              </FieldGroup>
              <FieldGroup className="row">
                <FieldGroup className="col-12">
                  <FloatingLabelFormField name="location_room" caption={i18n.t("Address Line 2")} className="col-half-width" />
                </FieldGroup>
              </FieldGroup>
            </FieldGroup>
          )}
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField
                caption={i18n.t("Mission")}
                disabled={!isAdmin}
                multilingual={true}
                name="mission"
                required={true}
                type="text"
              />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FieldGroup className="checkbox-group">
                <MaterialCheckbox
                  disabled={!isAdmin}
                  inline={true}
                  name="serves_lmi"
                >
                  {i18n.t('Serves LMI Community')}
                </MaterialCheckbox>
                <InfoTooltip
                  iconTooltip="help"
                  tooltip={i18n.t('Does this partner serve predominately low & moderate income members of the community?')}
                  tooltipPosition="top"
                  trigger="hover"
                />
              </FieldGroup>
            </FieldGroup>
          </FieldGroup>
          {!isEventManagement &&
            <FieldGroup>
              <FieldGroup className="row">
                <FieldGroup className="col-12">
                  <FloatingLabelFormField name="headquarters_partner_id" caption={i18n.t("Headquarters")} required={false} className="col-half-width" >
                    <SelectAsyncControl multi={false} onChange={this.onPartnerChange} filterField="keyword" placeholder=""
                      dataUrl="/api/data/partners.json" displayProperty="name" initialData={headquarters} buildDisplay={this.buildDisplay} />
                  </FloatingLabelFormField>
                </FieldGroup>
              </FieldGroup>
              <FieldGroup className="row">
                <FieldGroup className="col-12">
                  <h4>{i18n.t('Partner Contact Information')}</h4>
                </FieldGroup>
              </FieldGroup>
              <FieldGroup className="row">
                <FieldGroup className="col-12">
                  <FloatingLabelFormField name="contact_name" caption={i18n.t("Name")} required={true} />
                </FieldGroup>
              </FieldGroup>
              <FieldGroup className="row">
                <FieldGroup className="col-12">
                  <FloatingLabelFormField name="contact_phone" caption={i18n.t("Phone Number")} required={true} />
                </FieldGroup>
              </FieldGroup>
              <FieldGroup className="row">
                <FieldGroup className="col-12">
                  <FloatingLabelFormField name="contact_email" caption={i18n.t("Email")} type="email" required={true} />
                </FieldGroup>
              </FieldGroup>
            </FieldGroup>
          }
        </FieldGroup>
      </ModalForm>)
  }
}
